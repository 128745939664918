import { useApolloClient } from '@apollo/client';
import { Button, Dialog, DialogHeader, Tray } from '@busybusy/webapp-react-ui';
import { MEMBERS_QUERY } from 'apollo/queries/member-queries';
import classNames from 'classnames';
import Panel from 'components/layout/Panel/Panel';
import useMemberLock from 'hooks/models/member-lock/useMemberLock';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { IMember } from 'types';
import { ClassName } from 'types/ClassName';
import MemberPermission from 'types/enum/MemberPermission';
import { getDateString } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { boldText } from 'utils/stringUtils';
import './LockDateDialog.scss';

export enum LockDateDialogType {
  ADD = 'ADD',
  SINGLE_EDIT = 'SINGLE_EDIT',
  MULTIPLE_EDIT = 'MULTIPLE_EDIT',
  TIME_OFF_REQUESTS = 'TIME_OFF_REQUESTS',
}

export interface ILockDateDialogProps {
  className?: ClassName;
  onMoveLockDate?: () => void;
  type: LockDateDialogType;
  memberIds: string[];
  date: DateTime;
  isOpen: boolean;
  onClose: () => void;
}

const LockDateDialog = (props: ILockDateDialogProps) => {
  const { className, onMoveLockDate, type, memberIds, date, isOpen, onClose } = props;

  const classes = classNames(
    {
      'lock-date-dialog': true,
    },
    className
  );
  const newDate = date.startOf('day').minus({ day: 1 });
  const memberLockActions = useMemberLock();
  const client = useApolloClient();
  const [members, setMembers] = useState<IMember[]>([]);

  useEffect(() => {
    if (isOpen) {
      getData();
    }
  }, [isOpen]);

  function isMoveRestricted(): boolean {
    return members.length !== memberIds.length;
  }

  function getData() {
    if (isEmpty(memberIds)) {
      return [];
    }
    client
      .query<{ members: IMember[] }>({
        query: MEMBERS_QUERY,
        variables: {
          filter: {
            id: { contains: memberIds },
            archivedOn: { isNull: true },
            permissions: { permissions: MemberPermission.MEMBER_LOCK, operationType: 'and' },
          },
        },
        fetchPolicy: 'network-only',
      })
      .then((result) => {
        setMembers(result.data.members);
      })
      .catch(() => {
        setMembers([]);
      });
  }

  function renderMessage() {
    const boldDateString = getDateString(newDate, 'DDDD', true);
    let message = '';
    if (type === LockDateDialogType.ADD) {
      if (isMoveRestricted()) {
        message = t(`The selected date range is restricted by the employee's lock date.`);
      } else {
        message = t(
          `The selected date range is restricted by the employee's lock date. Would you like us to move the lock date to ${boldDateString}?`
        );
      }
    } else if (type === LockDateDialogType.SINGLE_EDIT) {
      if (isMoveRestricted()) {
        message = t(`This entry is restricted by the employee's lock date.`);
      } else {
        message = t(
          `This entry is restricted by the employee's lock date. Would you like us to move the lock date to ${boldDateString} so you can edit these entries?`
        );
      }
    } else if (type === LockDateDialogType.MULTIPLE_EDIT) {
      if (isMoveRestricted()) {
        message = t(`One or more of the selected entries are restricted by the employee's lock date.`);
      } else {
        message = t(
          `One or more of the selected entries are restricted by the employee's lock date. Would you like us to move the lock date to ${boldDateString} so you can edit these entries?`
        );
      }
    } else if (type === LockDateDialogType.TIME_OFF_REQUESTS) {
      if (isMoveRestricted()) {
        message = t(`Some or all of the time-off being requested are restricted by the employee's lock date.`);
      } else {
        message = t(
          `Some or all of the time-off being requested is behind a lock date. Would you like us to move the lock date to ${boldDateString} so you can create the time off?`
        );
      }
    }
    return <div className="content">{boldText(message, boldDateString)}</div>;
  }

  async function handleMoveLockDate() {
    const locks = members.filter((m) => !isNil(m.memberLock)).map((m) => m.memberLock!);

    if (!isEmpty(locks)) {
      const lockMemberIds = locks.flatMap((item) => item.memberId);
      const newMemberLockMemberIds = memberIds.filter((item) => !lockMemberIds.includes(item));
      const locksPromises = locks.map(async (element) => {
        return memberLockActions.editLock(element, newDate);
      });
      await Promise.all(locksPromises);
      const newMemberLockMemberIdsPromises = newMemberLockMemberIds.map(async (memberId) => {
        return memberLockActions.createLock(newDate, memberId);
      });
      await Promise.all(newMemberLockMemberIdsPromises);
    } else {
      memberIds.forEach(async (memberId) => {
        await memberLockActions.createLock(newDate, memberId);
      });
      const memberIdsPromises = memberIds.map(async (memberId) => {
        return memberLockActions.createLock(newDate, memberId);
      });
      await Promise.all(memberIdsPromises);
    }
    if (onMoveLockDate) {
      onMoveLockDate();
    }
  }

  if (isOpen) {
    return (
      <Dialog className={classes} isOpen={isOpen} onClose={onClose}>
        <Panel>
          <DialogHeader>
            <h1>{isMoveRestricted() ? t('Lock Date Restriction') : t('Move lock date?')}</h1>
          </DialogHeader>
          {renderMessage()}
          <Tray className="tray-right" align="left">
            <Button className="right-button" type="secondary" onClick={onClose}>
              {isMoveRestricted() ? t('Close') : t('Cancel')}
            </Button>
            {!isMoveRestricted() && (
              <Button className="right-button" type="primary" onClick={handleMoveLockDate}>
                {t('Move Lock Date')}
              </Button>
            )}
          </Tray>
        </Panel>
      </Dialog>
    );
  } else {
    return <></>;
  }
};

export default LockDateDialog;
