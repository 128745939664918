import { useApolloClient } from '@apollo/client';
import { useQueryClient } from '@tanstack/react-query';
import {
  LaborMetricsInterval,
  MemberHasTimeOff,
  MemberPermissions,
  OperationType,
  QueryMembersForTimeMetericsWithMoreQuery,
  QueryMembersForTimeMetericsWithMoreQueryVariables,
} from '__generated__/graphql';
import { MEMBERS_WITH_ALL_JOINED_DATA_QUERY } from 'apollo/queries/member-queries';
import { ArchivedStatus } from 'components/domain/archived/ArchivedPicker/ArchivedPicker';
import { ITimeEntryDataTableRow } from 'components/domain/time-entry/TimeEntryDataTable/TimeEntryDataTable';
import useTimeEntryToTimeEntryRowMap from 'components/domain/time-entry/TimeEntryDataTable/hooks/useTimeEntryToTimeEntryRowMap';
import { useTimeOffToTimeOffRowMap } from 'components/domain/time-entry/TimeEntryDataTable/hooks/useTimeOffToTimeOffRowMap';
import { COST_CODES_WITH_MEMBER_METRICS_QUERY } from 'containers/activity-reports/queries/cost-code-activity-queries';
import { SIMPLE_EQUIPMENT_WITH_MEMBER_TIME_ONLY_METRIC_QUERY } from 'containers/activity-reports/queries/equipment-activity-queries';
import { PROJECT_WITH_TIME_AND_MEMBER_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY_AND_ANCESTORS } from 'containers/activity-reports/queries/project-activity-queries';
import { PayPeriodSignaturesLoaded } from 'containers/timesheets/TimeCardReport/hooks/useTimeCardReportData';
import { useToastOpen } from 'contexts/ToastContext';
import { useApolloPaging, useOrganization } from 'hooks';
import useGraphQLPaging from 'hooks/graphql/useGraphQLPaging/useGraphQLPaging';
import useApolloMemberNameSort from 'hooks/models/member/useApolloMemberNameSort';
import useTimeEntryQuery from 'hooks/models/time-entry/useTimeEntryQuery';
import useTimeOffQuery from 'hooks/models/time-off/useTimeOffQuery';
import useReactQueryBaseKey from 'hooks/react-query/useReactQueryBaseKey/useReactQueryBaseKey';
import { useReactQueryLazyLoading } from 'hooks/react-query/useReactQueryLazyLoading/useReactQueryLazyLoading';
import { t } from 'i18next';
import _, { groupBy, isEmpty, isNil, some, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IStoreTimeCardReportSettings } from 'store/TimeCardReportSettings/TimeCardReportSettings';
import { IReduxState } from 'store/types';
import ICostCode from 'types/CostCode';
import ICursorable from 'types/Cursorable';
import IEquipment from 'types/Equipment';
import IProject from 'types/Project';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import IJitLaborMetric from 'types/aggregate/JitLaborMetric';
import MemberPermission from 'types/enum/MemberPermission';
import { Nullable } from 'types/util/Nullable';
import { aggregateMemberLaborMetricsTime } from 'utils/aggregateUtils';
import { getApolloArchivedTimestampComparison } from 'utils/archivedUtils';
import { mapNotNil } from 'utils/collectionUtils';
import { dateTimeFromISOWithoutZone, getDateTimesBetween } from 'utils/dateUtils';
import { getRegularMetricTimeTotals } from 'utils/jitMetricUtils';
import { logError } from 'utils/testUtils';
import {
  ExpandedTimeCardReportDailySummaryInfo,
  ExpandedTimeCardReportMember,
  ExpandedTimeCardReportMemberData,
} from '../../types/types';

export interface IUseExpandedTimeCardReportDataProps {
  scroller: HTMLElement | null;
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  memberId?: string | null;
  memberGroupId?: string | null;
  positionId?: string | null;
  children: ReactNode | ReactNode[];
  archivedStatus: ArchivedStatus;
  permission: MemberPermissions.TimeEvents | MemberPermissions.ManageTimeEntries;
}

export interface IUseExpandedTimeCardReportDataPayload {
  data: ExpandedTimeCardReportMemberData[];
  loadedAll: boolean;
  refreshMembersData: (memberIds: string[]) => Promise<void>;
  forceLoadAll: (showAllMembers: boolean) => Promise<ExpandedTimeCardReportMemberData[]>;
  clearData: () => void;
  signaturesLoaded: PayPeriodSignaturesLoaded[];
  onSignaturesLoaded?: (memberId: string, isLoaded: boolean) => void;
  areAllSignaturesLoaded?: () => boolean;
  loading: boolean;
}

export function useExpandedTimeCardReportData({
  scroller,
  memberId,
  timeRangeType,
  memberGroupId,
  positionId,
  timeRange,
  archivedStatus,
  permission,
}: Omit<IUseExpandedTimeCardReportDataProps, 'children'>) {
  const client = useApolloClient();
  const toast = useToastOpen();

  const archivedOn = useMemo(() => getApolloArchivedTimestampComparison(archivedStatus), [archivedStatus]);
  const { getAll } = useApolloPaging();
  const getSortWithNameFormat = useApolloMemberNameSort();
  const timeCardReportSettings = useSelector<IReduxState, IStoreTimeCardReportSettings>(
    (state) => state.timeCardReportSettings
  );
  const getTimeEntries = useTimeEntryQuery();
  const getTimeOffs = useTimeOffQuery();
  const convertTimeEntryToRow = useTimeEntryToTimeEntryRowMap();
  const convertTimeOffToRow = useTimeOffToTimeOffRowMap();

  const signaturesLoaded = useRef<PayPeriodSignaturesLoaded[]>([]);
  const organization = useOrganization();
  const timeRangeIsPayPeriod = timeRangeType === TimeRangeType.PAY_PERIOD;
  const showSignaturesFooter =
    timeRangeIsPayPeriod &&
    organization.signatureDate &&
    timeRange.endTime < DateTime.local() &&
    timeCardReportSettings.showSignatures;

  const getMemberVariables = useCallback(
    (first: number, after?: string | null, requireHasTime: boolean = false) => {
      const sort = getSortWithNameFormat('asc');
      const variables: any = {
        first,
        after: after ?? undefined,
        filter: {
          permissions: {
            permissions: permission,
            operationType: OperationType.And,
          },
          memberGroupId: memberGroupId ? { equal: memberGroupId } : undefined,
          id: memberId ? { equal: memberId } : undefined,
          archivedOn,
          positionId: positionId ? { equal: positionId } : undefined,
        },
        sort,
      };

      // optimize report by only loading the members that will show up
      // no need to optimize if we are scoped to certain members
      if (requireHasTime) {
        variables.filter.hasTime = {
          startTime: timeRange.startTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
          endTime: timeRange.endTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
          includeOpenEntry: false,
          hasTimeOff: MemberHasTimeOff.All, // show both paid and unpaid time off
        };
      }

      variables.metricsInterval = LaborMetricsInterval.Day;
      variables.metricsStartDate = timeRange.startTime.toUTC().toISODate();
      variables.metricsEndDate = timeRange.endTime.toUTC().toISODate();

      variables.safetySignaturesFilter = {
        startTime: { greaterThanOrEqual: timeRange.startTime.toISO() },
        endTime: { lessThanOrEqual: timeRange.endTime.toISO() },
        deletedOn: { isNull: true },
        member: {
          permissions: {
            permissions: MemberPermission.MANAGE_TIME_ENTRIES,
            operationType: OperationType.And,
          },
        },
      };
      variables.safetySignaturesSort = [{ startTime: 'asc' }, { createdOn: 'asc' }];

      variables.memberTimeDocumentFilter = {
        startTime: { lessThanOrEqual: timeRange.endTime.toISO() },
        endTime: { greaterThanOrEqual: timeRange.startTime.toISO() },
        deletedOn: { isNull: true },
      };
      variables.memberTimeDocumentSort = [{ submittedOn: 'desc' }];

      return variables;
    },
    [
      getSortWithNameFormat,
      permission,
      memberGroupId,
      memberId,
      archivedOn,
      positionId,
      timeRange.startTime,
      timeRange.endTime,
    ]
  );

  async function populateData(
    members: ExpandedTimeCardReportMember[],
    range: ITimeRange<DateTime>
  ): Promise<ExpandedTimeCardReportMemberData[]> {
    const days = getDateTimesBetween(range.startTime, range.endTime);
    return populateMembersData(members, range, days);
  }

  async function populateMembersData(
    members: ExpandedTimeCardReportMember[],
    range: ITimeRange<DateTime>,
    days: DateTime[]
  ): Promise<Array<ExpandedTimeCardReportMemberData>> {
    let groupedTimeEntryData: Nullable<Record<string, ITimeEntryDataTableRow[] | null>> = null;
    if (timeCardReportSettings.showTimeEntries) {
      const memberIds = members.map(({ id }) => id);
      const timeData = await getTimeData(range, memberIds);
      groupedTimeEntryData = groupBy(timeData, ({ member }) => member.id);
    }

    return await Promise.all(
      members.map(async (member) => {
        // Can't extract these model into grouped calls because the `withTime` is on the server and we can't
        // remap the returning data back to the correct member
        // TODO: if possible join all these stuff in the initial member call?
        let projectSummaryData: (IProject & ICursorable)[] | null = null;
        if (timeCardReportSettings.showProjectSummary) {
          projectSummaryData = await getProjectSummaryData(range, member.id);
        }

        let costCodeSummaryData: (ICostCode & ICursorable)[] | null = null;
        if (timeCardReportSettings.showCostCodeSummary) {
          costCodeSummaryData = await getCostCodeSummaryData(range, member.id);
        }

        let equipmentSummaryData: (IEquipment & ICursorable)[] | null = null;
        if (timeCardReportSettings.showEquipmentSummary) {
          equipmentSummaryData = await getEquipmentSummaryData(range, member.id);
        }

        let dailySummaryData: ExpandedTimeCardReportDailySummaryInfo[] | null = null;
        if (timeCardReportSettings.showDailySummary) {
          dailySummaryData = getDailySummaryData(member, days);
        }

        const timeEntryDataForMember = groupedTimeEntryData ? groupedTimeEntryData[member.id] : null;
        const metric = aggregateMemberLaborMetricsTime(member.memberLaborMetrics ?? []);
        return {
          ...member,
          cursor: member.cursor!,
          totalSeconds: metric.total,
          totalRegularSeconds: metric.regularTime + metric.pto,
          totalDoubleTimeSeconds: metric.doubleTime,
          totalOvertimeSeconds: metric.overtime,
          timeEntryData: timeEntryDataForMember,
          projectSummaryData: projectSummaryData,
          costCodeSummaryData: costCodeSummaryData,
          equipmentSummaryData: equipmentSummaryData,
          dailySummaryData: dailySummaryData,
        };
      })
    );
  }

  async function getTimeData(range: ITimeRange<DateTime>, memberIds: string[]): Promise<ITimeEntryDataTableRow[]> {
    const entries = await getTimeEntries(
      range,
      false,
      memberIds,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      200
    );
    const timeOffs = await getTimeOffs(range, memberIds, undefined, undefined);

    let rows: ITimeEntryDataTableRow[] = entries.flatMap((e) => convertTimeEntryToRow(e, timeRange));
    if (!isEmpty(timeOffs)) {
      rows = rows.concat(timeOffs.map(convertTimeOffToRow));
    }

    return sortBy(rows, 'startDate');
  }

  async function getProjectSummaryData(
    range: ITimeRange<DateTime>,
    memberId: string
  ): Promise<(IProject & ICursorable)[]> {
    return await getAll<IProject & ICursorable>('projectsWithTime', {
      query: PROJECT_WITH_TIME_AND_MEMBER_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY_AND_ANCESTORS,
      fetchPolicy: 'network-only',
      variables: {
        first: 200,
        sort: [{ title: 'asc' }],
        metricsStartDate: range.startTime.toISODate(),
        metricsEndDate: range.endTime.toISODate(),
        startTime: range.startTime.toISO({
          suppressMilliseconds: true,
          includeOffset: false,
        }),
        endTime: range.endTime.toISO({
          suppressMilliseconds: true,
          includeOffset: false,
        }),
        includeOpenEntry: false,
        includeAncestors: false,
        metricsInterval: LaborMetricsInterval.Custom,
        memberId: { equal: memberId },
        memberIds: [memberId],
      },
    });
  }

  async function getCostCodeSummaryData(
    range: ITimeRange<DateTime>,
    memberId: string
  ): Promise<(ICostCode & ICursorable)[]> {
    return await getAll<ICostCode & ICursorable>('costCodes', {
      query: COST_CODES_WITH_MEMBER_METRICS_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 200,
        sort: [{ costCode: 'asc' }, { title: 'asc' }],
        filter: {
          costCodesWithTime: {
            startTime: range.startTime.toISO({
              suppressMilliseconds: true,
              includeOffset: false,
            }),
            endTime: range.endTime.toISO({
              suppressMilliseconds: true,
              includeOffset: false,
            }),
            includeOpenEntry: false,
            memberId: { equal: memberId },
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: range.startTime.toISODate(),
        metricsEndDate: range.endTime.toISODate(),
        memberIds: [memberId],
      },
    });
  }

  async function getEquipmentSummaryData(
    range: ITimeRange<DateTime>,
    memberId: string
  ): Promise<(IEquipment & ICursorable)[]> {
    return await getAll<IEquipment & ICursorable>('equipment', {
      query: SIMPLE_EQUIPMENT_WITH_MEMBER_TIME_ONLY_METRIC_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 200,
        sort: [{ equipmentName: 'asc' }],
        filter: {
          equipmentWithTime: {
            startTime: range.startTime.toISO({
              suppressMilliseconds: true,
              includeOffset: false,
            }),
            endTime: range.endTime.toISO({
              suppressMilliseconds: true,
              includeOffset: false,
            }),
            includeOpenEntry: false,
            memberId: { equal: memberId },
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: range.startTime.toISODate(),
        metricsEndDate: range.endTime.toISODate(),
        memberIds: [memberId],
      },
    });
  }

  function getDailySummaryData(
    member: ExpandedTimeCardReportMember,
    days: DateTime[]
  ): ExpandedTimeCardReportDailySummaryInfo[] {
    const signOffs = member.safetySignatures ?? [];
    const metricsDictionary = _.keyBy(member.memberLaborMetrics, (daySecond) => daySecond.start);
    const dataRows = mapNotNil(days, (day) => {
      const dayStart = day.startOf('day');
      const dayEnd = day.endOf('day');

      const filteredSignatures = signOffs.filter((signature) => {
        return (
          dateTimeFromISOWithoutZone(signature.startTime) <= dayEnd &&
          dateTimeFromISOWithoutZone(signature.endTime) >= dayStart
        );
      });

      const memberDayMetrics = getRegularMetricTimeTotals(
        metricsDictionary[dayStart.toISODate()] as IJitLaborMetric | undefined
      );
      const orderedSignatures = _.orderBy(filteredSignatures, (item) => item.endTime, ['desc']);

      const hasTimeEntryTime = memberDayMetrics.totalSeconds > 0;

      let regularSeconds = memberDayMetrics.regularSeconds ?? 0;
      if (memberDayMetrics) {
        const updatedRegularTotal = memberDayMetrics.regularSeconds + memberDayMetrics.paidTimeOffSeconds;
        regularSeconds = updatedRegularTotal > 0 ? updatedRegularTotal : 0;
      }

      if (hasTimeEntryTime === false) {
        return null;
      }

      return {
        date: day,
        timeAccurate: hasTimeEntryTime
          ? orderedSignatures.length > 0
            ? (orderedSignatures[0]?.timeAccurate ?? null)
            : null
          : null,
        injured: hasTimeEntryTime
          ? orderedSignatures.length > 0
            ? orderedSignatures.filter((signature: any) => signature.injured).length > 0
            : null
          : null,
        breakCompliance: hasTimeEntryTime
          ? orderedSignatures.length > 0
            ? (orderedSignatures[0]?.breakPolicyFollowed ?? null)
            : null
          : null,
        regularHours: regularSeconds,
        overtimeHours: memberDayMetrics.overtimeSeconds,
        doubleTimeHours: memberDayMetrics.doubleTimeSeconds,
        paidTimeOff: memberDayMetrics.paidTimeOffSeconds,
        totalHours: memberDayMetrics.totalSeconds,
      };
    });

    return dataRows;
  }

  const getMemberData = useCallback(
    async (cursor: string | null, first: number) => {
      const results = await client.query<
        QueryMembersForTimeMetericsWithMoreQuery,
        QueryMembersForTimeMetericsWithMoreQueryVariables
      >({
        query: MEMBERS_WITH_ALL_JOINED_DATA_QUERY,
        fetchPolicy: 'network-only',
        variables: getMemberVariables(first, cursor, isNil(memberId)),
      });

      return results.data.members ?? [];
    },
    [client, getMemberVariables, memberId]
  );

  const getData = useCallback(
    async (cursor: string | null, first: number) => {
      try {
        const members = await getMemberData(cursor, first);
        return await populateData(members, timeRange);
      } catch (error) {
        logError(error as Error);
        toast({ label: t('Something went wrong. Please try again later.') });
      }
      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getMemberData, populateData, toast, timeRange, timeCardReportSettings]
  );

  const baseQueryKey = useReactQueryBaseKey();
  const queryKey = useMemo(
    () => [
      baseQueryKey,
      'expanded-time-card-report',
      timeRange.startTime.toSeconds(),
      timeRange.endTime.toSeconds(),
      memberGroupId,
      memberId,
      positionId,
      archivedOn,
      timeCardReportSettings,
    ],
    [
      archivedOn,
      baseQueryKey,
      memberGroupId,
      memberId,
      positionId,
      timeCardReportSettings,
      timeRange.endTime,
      timeRange.startTime,
    ]
  );

  const {
    data,
    refetch: clearData,
    isLoading: loading,
    loadedAll,
  } = useReactQueryLazyLoading(scroller, queryKey, getData, 4);

  async function refreshMembersData(ids: string[]) {
    clearData();
    updateSignaturesLoaded(ids);
  }

  const queryClient = useQueryClient();
  const pageAll = useGraphQLPaging();

  async function forceLoadAll(showAllMembers: boolean): Promise<ExpandedTimeCardReportMemberData[]> {
    try {
      // TODO Set members and refactor. Might cause performance problems to have all
      // the rows loaded especially with a line for each member for each day
      const allMembers = await pageAll<
        QueryMembersForTimeMetericsWithMoreQuery,
        QueryMembersForTimeMetericsWithMoreQueryVariables
      >(
        {
          document: MEMBERS_WITH_ALL_JOINED_DATA_QUERY,
          variables: getMemberVariables(500, null, !(!isNil(memberId) || showAllMembers)),
        },
        'members'
      );

      const populatedData = await populateData(allMembers, timeRange);

      queryClient.setQueryData(queryKey, {
        pages: populatedData,
        pageParams: [null],
      });

      updateSignaturesLoaded(populatedData.map((item) => item.id));
      return populatedData;
    } catch (error) {
      return [];
    }
  }

  function areAllSignaturesLoaded(): boolean {
    if (showSignaturesFooter) {
      return !some(signaturesLoaded.current, (item) => item.signaturesLoaded === false);
    }
    return true;
  }

  function onSignaturesLoaded(memberId: string, isLoaded: boolean) {
    const index = signaturesLoaded.current.findIndex((item) => item.memberId === memberId);
    if (index > -1) {
      signaturesLoaded.current[index].signaturesLoaded = isLoaded;
    } else {
      signaturesLoaded.current.push({ memberId, signaturesLoaded: isLoaded });
    }
  }

  function updateSignaturesLoaded(memberIds: string[]) {
    memberIds.forEach((memberId) => {
      const index = signaturesLoaded.current.findIndex((item) => item.memberId === memberId);
      if (index > -1) {
        // If the member is already in the list, do nothing
      } else {
        signaturesLoaded.current.push({ memberId, signaturesLoaded: null });
      }
    });
  }

  return useMemo(
    () => ({
      data: data ?? [],
      loadedAll,
      refreshMembersData,
      forceLoadAll,
      clearData,
      signaturesLoaded: signaturesLoaded.current,
      onSignaturesLoaded,
      areAllSignaturesLoaded,
      loading,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, loadedAll, memberId, memberGroupId, signaturesLoaded.current, loading]
  );
}
