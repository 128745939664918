import { useMutation } from '@apollo/client';
import { Theme } from '@busybusy/webapp-react-ui';
import { CREATE_TIME_ENTRY_LOG, CREATE_TIME_ENTRY_LOGS } from 'apollo/mutations/client-time-entry-log-mutations';
import { CREATE_TIME_ENTRIES, UPDATE_TIME_ENTRIES, UPDATE_TIME_ENTRY } from 'apollo/mutations/time-entry-mutations';
import { useToastOpen } from 'contexts/ToastContext';
import { useActiveMember, useApolloPaging, useTimeRounding, useTimesheetsGraylog } from 'hooks';
import _, { isNil } from 'lodash';
import { DateTime } from 'luxon';
import IClientTimeEntryLog, { IClientTimeEntryLogCreateInput } from 'types/ClientTimeEntryLog';
import ICursorable from 'types/Cursorable';
import ClockAction from 'types/enum/ClockAction';
import ClockActionType from 'types/enum/ClockActionType';
import ClockType from 'types/enum/ClockType';
import MemberPermission from 'types/enum/MemberPermission';
import ITimeEntry, { ITimeEntryCreateInput, ITimeEntryUpdateInput } from 'types/TimeEntry';
import { dateUtils } from 'utils';
import { isNilOrEmpty } from 'utils/collectionUtils';
import { TimesheetsTypes } from 'utils/constants/graylogActionTypes';
import { combineDateAndTime, dateTimeFromISOKeepZone, setZone } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { getEndTimeForSubmission } from 'utils/timeEntryUtils';
import { uuid } from 'utils/uuidUtils';

export interface IEntryAndLog {
  entry: ITimeEntry;
  log: IClientTimeEntryLog;
}

export default function useTimeEntry() {
  const [updateTimeEntry] = useMutation<{ updateTimeEntry: ITimeEntry }>(UPDATE_TIME_ENTRY);
  const [createTimeEntries] = useMutation<{ createTimeEntries: [ITimeEntry] }>(CREATE_TIME_ENTRIES);
  const [updateTimeEntries] = useMutation<{ updateTimeEntries: [ITimeEntry] }>(UPDATE_TIME_ENTRIES);

  const [createTimeEntryLog] = useMutation<{
    createClientTimeEntryLog: IClientTimeEntryLog;
  }>(CREATE_TIME_ENTRY_LOG);
  const [createTimeEntryLogs] = useMutation<{
    createClientTimeEntryLogs: [IClientTimeEntryLog];
  }>(CREATE_TIME_ENTRY_LOGS);

  const member = useActiveMember();
  const userEvents = useTimesheetsGraylog();
  const { getAll } = useApolloPaging();
  const { roundTime } = useTimeRounding();
  const errorToast = useToastOpen();

  async function getOpenTimeEntriesQuery(
    query: any,
    maxStartDate?: DateTime,
    memberIds?: string[] | null,
    positionIds?: string[] | null,
    excludeArchived?: boolean | null,
    employeeGroupId?: string | null
  ) {
    return getAll<ITimeEntry & ICursorable>('timeEntries', {
      query,
      variables: {
        first: 500,
        filter: {
          startTime: maxStartDate
            ? {
                lessThanOrEqual: maxStartDate
                  .endOf('day')
                  .toUTC(0, { keepLocalTime: true })
                  .toISO({ suppressMilliseconds: true, includeOffset: false }),
              }
            : undefined,
          endTime: { isNull: true },
          member: {
            permissions: {
              permissions: MemberPermission.TIME_EVENTS,
              operationType: 'and',
              includeSelf: true,
            },
            archivedOn: !isNil(excludeArchived) ? { isNull: excludeArchived } : undefined,
            positionId: !isNil(positionIds) ? { contains: positionIds } : undefined,
            memberGroupId: employeeGroupId ? { equal: employeeGroupId } : undefined,
          },
          memberId: !isNilOrEmpty(memberIds) ? { contains: memberIds } : undefined,
          deletedOn: { isNull: true },
        },
      },
      fetchPolicy: 'network-only',
    });
  }

  async function createEntry(
    startTime: DateTime,
    endTime: DateTime,
    memberIds: string[],
    timezone: string,
    description?: string | null | undefined,
    projectId?: string | null | undefined,
    costCodeId?: string | null | undefined,
    equipmentId?: string | null | undefined,
    forceStartDst?: boolean,
    forceEndDst?: boolean | null
  ): Promise<IEntryAndLog[]> {
    const start = setZone(startTime, timezone, false);
    const end = setZone(endTime, timezone, false);
    const currentUTC = DateTime.utc().toISO();
    const createdOn = currentUTC;

    const promises = _.chunk(memberIds, 50).map(async (chunkedMemberIds) => {
      const entryData: ITimeEntryCreateInput[] = [];
      const logData: IClientTimeEntryLogCreateInput[] = [];

      for (const memberId of chunkedMemberIds) {
        const entry: ITimeEntryCreateInput = {
          id: uuid(),
          memberId,
          startTime: start.toISO(),
          startDeviceTime: currentUTC,
          startTimeTrusted: ClockType.USER_CUSTOM,
          endTime: start.toSeconds() > end.toSeconds() ? end.plus({ day: 1 }).toISO() : end.toISO(),
          endDeviceTime: currentUTC,
          endTimeTrusted: ClockType.USER_CUSTOM,
          daylightSavingTime: !isNil(forceStartDst) ? forceStartDst : startTime.isInDST,
          metaDaylightSavingTime: !isNil(forceEndDst) ? forceEndDst : endTime.isInDST,
          description: description === undefined ? null : description,
          projectId: projectId === undefined ? null : projectId,
          costCodeId: costCodeId === undefined ? null : costCodeId,
          equipmentId: equipmentId === undefined ? null : equipmentId,
          startLocationId: null,
          endLocationId: null,
          actionType: ClockActionType.MANUAL,
          createdOn,
        };

        const log: IClientTimeEntryLogCreateInput = {
          id: uuid(),
          updaterMemberId: member.id!,
          timeEntryId: entry.id!,
          originalTimeEntryId: entry.id,
          memberId,
          startTime: entry.startTime,
          startDeviceTime: entry.startDeviceTime,
          startTimeTrusted: entry.startTimeTrusted,
          endTime: entry.endTime,
          endDeviceTime: entry.endDeviceTime,
          endTimeTrusted: entry.endTimeTrusted,
          daylightSavingTime: entry.daylightSavingTime,
          metaDaylightSavingTime: entry.metaDaylightSavingTime,
          description: entry.description,
          projectId: entry.projectId,
          costCodeId: entry.costCodeId,
          equipmentId: entry.equipmentId,
          actionType: ClockActionType.MANUAL,
          deviceType: t('Web Browser'),
          timeEntryCreatedOn: entry.createdOn,
          timeEntryDeletedOn: entry.deletedOn === undefined || entry.deletedOn === null ? null : entry.deletedOn!,
          startLocationId: null,
          endLocationId: null,
          createdOn,
        };
        entryData.push(entry);
        logData.push(log);
      }
      const result: Promise<IEntryAndLog[]> = new Promise(async (resolve, reject) => {
        const timeEntryResults = await createTimeEntries({ variables: { entries: entryData } });
        if (timeEntryResults && _.isEmpty(timeEntryResults.errors)) {
          const logResults = await createTimeEntryLogs({ variables: { logs: logData } });

          if (logResults && _.isEmpty(logResults.errors)) {
            const logMap = _.groupBy(logResults.data?.createClientTimeEntryLogs, 'timeEntryId');
            const entryWithLogs = timeEntryResults.data?.createTimeEntries.map((timeEntry) => {
              const log = _.first(logMap[timeEntry.id]);
              if (_.isNil(log)) return null;

              const entryAndLog: IEntryAndLog = {
                entry: timeEntry,
                log,
              };
              return entryAndLog;
            });

            const [failed, successful] = _.partition(entryWithLogs, _.isNull);
            if (failed.length === 0) {
              resolve(successful);
            } else {
              reject(failed);
            }
          } else {
            errorToast({ label: t('Something went wrong'), theme: Theme.DANGER }); // log failure
            reject([]);
          }
        } else {
          errorToast({ label: t('Something went wrong'), theme: Theme.DANGER }); // time entry
          reject([]);
        }
      });

      return result;
    });

    return (await Promise.all(promises)).flat();
  }

  async function clockIn(
    startTime: DateTime,
    memberIds: string[],
    clockInType: ClockActionType.CLOCK_IN | ClockActionType.CLOCK_IN_AT,
    description?: string | null | undefined,
    projectId?: string | null | undefined,
    costCodeId?: string | null | undefined,
    equipmentId?: string | null | undefined,
    clockAction: ClockAction = ClockAction.CLOCK_IN
  ): Promise<IEntryAndLog[] | null> {
    let start = dateUtils.dateTimeFromISOKeepZone(startTime.toISO());
    start = roundTime(start, clockAction);

    const currentUTC = DateTime.utc().toISO();
    const createdOn = currentUTC;

    const promises = _.chunk(memberIds, 50).map(async (chunkedMemberIds) => {
      const entryData: ITimeEntryCreateInput[] = [];
      const logData: IClientTimeEntryLogCreateInput[] = [];

      for (const memberId of chunkedMemberIds) {
        const entry: ITimeEntryCreateInput = {
          id: uuid(),
          memberId,
          startTime: start.toISO(),
          startDeviceTime: currentUTC,
          startTimeTrusted: clockInType === ClockActionType.CLOCK_IN ? ClockType.BUSY_CLOCK : ClockType.USER_CUSTOM,
          daylightSavingTime: startTime.isInDST,
          description: description === undefined ? null : description,
          projectId: projectId === undefined ? null : projectId,
          costCodeId: costCodeId === undefined ? null : costCodeId,
          equipmentId: equipmentId === undefined ? null : equipmentId,
          startLocationId: null,
          endLocationId: null,
          actionType: clockInType,
          createdOn,
        };

        const log: IClientTimeEntryLogCreateInput = {
          id: uuid(),
          updaterMemberId: member.id!,
          timeEntryId: entry.id!,
          originalTimeEntryId: entry.id,
          memberId,
          startTime: entry.startTime,
          startDeviceTime: entry.startDeviceTime,
          startTimeTrusted: entry.startTimeTrusted,
          daylightSavingTime: entry.daylightSavingTime,
          description: entry.description,
          projectId: entry.projectId,
          costCodeId: entry.costCodeId,
          equipmentId: entry.equipmentId,
          actionType: clockInType,
          deviceType: t('Web Browser'),
          timeEntryCreatedOn: entry.createdOn,
          timeEntryDeletedOn: entry.deletedOn === undefined || entry.deletedOn === null ? null : entry.deletedOn!,
          startLocationId: null,
          endLocationId: null,
          createdOn,
        };

        entryData.push(entry);
        logData.push(log);
      }

      const result: Promise<IEntryAndLog[]> = new Promise(async (resolve, reject) => {
        const timeEntryResults = await createTimeEntries({ variables: { entries: entryData } });
        if (timeEntryResults && _.isEmpty(timeEntryResults.errors)) {
          const logResults = await createTimeEntryLogs({ variables: { logs: logData } });

          if (logResults && _.isEmpty(logResults.errors)) {
            const logMap = _.groupBy(logResults.data?.createClientTimeEntryLogs, 'timeEntryId');
            const entryWithLogs = timeEntryResults.data?.createTimeEntries.map((timeEntry) => {
              const log = _.first(logMap[timeEntry.id]);
              if (_.isNil(log)) return null;

              const entryAndLog: IEntryAndLog = {
                entry: timeEntry,
                log,
              };
              return entryAndLog;
            });

            const [failed, successful] = _.partition(entryWithLogs, _.isNull);
            if (failed.length === 0) {
              resolve(successful);
            } else {
              reject(failed);
            }
          } else {
            errorToast({ label: t('Something went wrong'), theme: Theme.DANGER }); // log failure
            reject([]);
          }
        } else {
          errorToast({ label: t('Something went wrong'), theme: Theme.DANGER }); // time entry
          reject([]);
        }
      });

      return result;
    });

    return (await Promise.all(promises)).flat();
  }

  async function deleteEntry(entry: ITimeEntry) {
    userEvents.events(TimesheetsTypes.events.action_type.DELETE_ENTRY);
    const currentUTC = DateTime.utc().toISO();

    const updateEntry = updateTimeEntry({
      variables: {
        entry: {
          id: entry.id,
          deletedOn: currentUTC,
          actionType: ClockActionType.DELETE,
        },
      },
    });

    const log: IClientTimeEntryLogCreateInput = {
      id: uuid(),
      updaterMemberId: member.id!,
      timeEntryId: entry.id!,
      originalTimeEntryId: entry.id,
      memberId: entry.member && entry.member.id ? entry.member.id : entry.memberId,
      startTime: entry.startTime,
      startDeviceTime: !_.isNil(entry.startDeviceTime) ? entry.startDeviceTime : currentUTC,
      startTimeTrusted: !_.isNil(entry.startTimeTrusted) ? entry.startTimeTrusted : ClockType.USER_CUSTOM,
      endTime: !_.isNil(entry.endTime) ? entry.endTime : DateTime.local().toISO(),
      endDeviceTime: entry.endDeviceTime,
      endTimeTrusted: entry.endTimeTrusted,
      daylightSavingTime: entry.daylightSavingTime,
      metaDaylightSavingTime: entry.metaDaylightSavingTime,
      description: entry.description,
      projectId: entry.project?.id,
      costCodeId: entry.costCode?.id,
      equipmentId: entry.equipment?.id,
      actionType: ClockActionType.DELETE,
      deviceType: t('Web Browser'),
      timeEntryCreatedOn: entry.createdOn,
      timeEntryDeletedOn: currentUTC,
      startLocationId: null,
      endLocationId: null,
      createdOn: currentUTC,
    };

    return new Promise<IEntryAndLog>(async (resolve, reject) => {
      const timeEntryResult = await updateEntry;
      const timeEntry = timeEntryResult.data?.updateTimeEntry;
      const timeEntryLogResult = await createTimeEntryLog({
        variables: { log },
      });
      const timeEntryLog = timeEntryLogResult.data?.createClientTimeEntryLog;

      if (timeEntryLog && timeEntry) {
        const entryAndLog: IEntryAndLog = {
          entry: timeEntry,
          log: timeEntryLog,
        };
        resolve(entryAndLog);
      } else {
        // TODO resolve between different failures
        reject();
      }
    });
  }

  async function clockOut(
    entries: ITimeEntry[],
    endTime: DateTime,
    clockOutType: ClockActionType.CLOCK_OUT | ClockActionType.CLOCK_OUT_AT,
    description?: string | null | undefined,
    projectId?: string | null | undefined,
    costCodeId?: string | null | undefined,
    equipmentId?: string | null | undefined,
    clockAction: ClockAction = ClockAction.CLOCK_OUT
  ) {
    const currentUTC = DateTime.utc().toISO();

    const promises = _.chunk(entries, 50).map(async (chunkedEntries) => {
      const entryData: ITimeEntryUpdateInput[] = [];
      const logData: IClientTimeEntryLogCreateInput[] = [];
      for (const entry of chunkedEntries) {
        const actualEntryStartTime = dateTimeFromISOKeepZone(entry.startTime).set({ second: 0, millisecond: 0 });
        const newEndTime = endTime < actualEntryStartTime ? actualEntryStartTime : endTime;

        let end = getEndTimeForSubmission(entry, newEndTime).set({
          second: 0,
          millisecond: 0,
        });
        end = roundTime(end, clockAction).set({ second: 0, millisecond: 0 });
        const trimmedDescription = !isNil(description) ? description.trim() : description; // need to maintain null/undefined

        const updateEntry: ITimeEntryUpdateInput = {
          id: entry.id,
          startTime: actualEntryStartTime.toISO(),
          endTime: end.toISO(),
          endDeviceTime: currentUTC,
          endTimeTrusted: clockOutType === ClockActionType.CLOCK_OUT ? ClockType.BUSY_CLOCK : ClockType.USER_CUSTOM,
          metaDaylightSavingTime: newEndTime.isInDST,
          description: trimmedDescription === '' ? null : trimmedDescription,
          projectId,
          startLocationId: entry.startLocation?.id,
          endLocationId: entry.endLocation?.id,
          costCodeId,
          equipmentId,
          actionType: clockOutType,
        };

        const log: IClientTimeEntryLogCreateInput = {
          id: uuid(),
          updaterMemberId: member.id!,
          timeEntryId: entry.id!,
          originalTimeEntryId: entry.id,
          memberId: entry.memberId,
          startTime: actualEntryStartTime.toISO(),
          startDeviceTime: entry.startDeviceTime!,
          startTimeTrusted: entry.startTimeTrusted ? entry.startTimeTrusted : ClockType.USER_CUSTOM,
          endTime: end.toISO(),
          endDeviceTime: currentUTC,
          endTimeTrusted: clockOutType === ClockActionType.CLOCK_OUT ? ClockType.BUSY_CLOCK : ClockType.USER_CUSTOM,
          daylightSavingTime: entry.daylightSavingTime,
          metaDaylightSavingTime: newEndTime.isInDST,
          description: description === undefined ? entry.description : description,
          projectId: projectId === undefined ? entry.project?.id : projectId,
          costCodeId: costCodeId === undefined ? entry.costCode?.id : costCodeId,
          equipmentId: equipmentId === undefined ? entry.equipment?.id : equipmentId,
          actionType: clockOutType,
          deviceType: t('Web Browser'),
          timeEntryCreatedOn: entry.createdOn,
          timeEntryDeletedOn: entry.deletedOn === undefined || entry.deletedOn === null ? null : entry.deletedOn!,
          startLocationId: entry.startLocation?.id,
          endLocationId: entry.endLocation?.id,
          createdOn: currentUTC,
        };
        entryData.push(updateEntry);
        logData.push(log);
      }
      const result: Promise<IEntryAndLog[]> = new Promise(async (resolve, reject) => {
        const timeEntryResults = await updateTimeEntries({ variables: { entries: entryData } });
        if (timeEntryResults && _.isEmpty(timeEntryResults.errors)) {
          const logResults = await createTimeEntryLogs({ variables: { logs: logData } });

          if (logResults && _.isEmpty(logResults.errors)) {
            const logMap = _.groupBy(logResults.data?.createClientTimeEntryLogs, 'timeEntryId');
            const entryWithLogs = timeEntryResults.data?.updateTimeEntries.map((timeEntry) => {
              const log = _.first(logMap[timeEntry.id]);
              if (_.isNil(log)) return null;

              const entryAndLog: IEntryAndLog = {
                entry: timeEntry,
                log,
              };
              return entryAndLog;
            });

            const [failed, successful] = _.partition(entryWithLogs, _.isNull);
            if (failed.length === 0) {
              resolve(successful);
            } else {
              reject(failed);
            }
          } else {
            errorToast({ label: t('Something went wrong'), theme: Theme.DANGER }); // log failure
            reject([]);
          }
        } else {
          errorToast({ label: t('Something went wrong'), theme: Theme.DANGER }); // time entry
          reject([]);
        }
      });

      return result;
    });

    return (await Promise.all(promises)).flat();
  }

  async function switchAction(
    entries: ITimeEntry[],
    startTime: DateTime,
    switchType: 'switch' | 'switch-at',
    description?: string | null | undefined,
    projectId?: string | null | undefined,
    costCodeId?: string | null | undefined,
    equipmentId?: string | null | undefined
  ) {
    if (switchType === 'switch') {
      await clockOut(
        entries,
        startTime,
        ClockActionType.CLOCK_OUT,
        undefined,
        undefined,
        undefined,
        undefined,
        ClockAction.SWITCH
      );
      return await clockIn(
        startTime,
        entries.map((entry) => entry.memberId),
        ClockActionType.CLOCK_IN,
        description,
        projectId,
        costCodeId,
        equipmentId,
        ClockAction.SWITCH
      );
    } else if (switchType === 'switch-at') {
      await clockOut(
        entries,
        startTime,
        ClockActionType.CLOCK_OUT_AT,
        undefined,
        undefined,
        undefined,
        undefined,
        ClockAction.SWITCH
      );
      return await clockIn(
        startTime,
        entries.map((entry) => entry.memberId),
        ClockActionType.CLOCK_IN_AT,
        description,
        projectId,
        costCodeId,
        equipmentId,
        ClockAction.SWITCH
      );
    }
  }

  async function editEntry(
    entry: ITimeEntry,
    startTime: DateTime,
    endTime?: DateTime | null,
    description?: string | null | undefined,
    projectId?: string | null | undefined,
    costCodeId?: string | null | undefined,
    equipmentId?: string | null | undefined,
    newTimezone?: string
  ) {
    const entryStarTime = dateUtils.dateTimeFromISOKeepZone(entry.startTime);

    const start = !isNil(newTimezone)
      ? setZone(startTime, newTimezone, false)
      : setZone(startTime, entryStarTime.zoneName, true);
    const startWithoutZone = dateUtils.dateTimeFromISOWithoutZone(start.toISO());
    const entryStartHasChanged =
      dateUtils.dateTimeFromISOWithoutZone(entry.startTime).toISO() !== startWithoutZone.toISO();

    const end = getEndTimeForSubmission(entry, endTime!, newTimezone);

    const newEndTime = !isNil(endTime)
      ? startTime.toSeconds() > endTime.toSeconds()
        ? end.plus({ day: 1 }).toISO()
        : end.toISO()
      : null;

    const endWithoutZone = dateUtils.dateTimeFromISOWithoutZone(newEndTime ?? end.toISO());

    const entryEndHasChanged =
      entry.endTime !== null
        ? dateUtils.dateTimeFromISOWithoutZone(entry.endTime!).toISO() !== endWithoutZone.toISO()
        : endTime !== null;

    const currentUTC = DateTime.utc().toISO();

    const updateEntry = updateTimeEntry({
      variables: {
        entry: {
          id: entry.id,
          startTime: entryStartHasChanged ? start.toISO() : entry.startTime,
          startDeviceTime: entryStartHasChanged ? start.toISO() : entry.startDeviceTime,
          startTimeTrusted: entryStartHasChanged ? ClockType.USER_CUSTOM : entry.startTimeTrusted,
          endTime: entryEndHasChanged ? newEndTime : entry.endTime,
          endDeviceTime: entryEndHasChanged ? newEndTime : entry.endTime,
          endTimeTrusted: entryEndHasChanged ? ClockType.USER_CUSTOM : entry.endTimeTrusted,
          description,
          projectId,
          startLocationId: entry.startLocation?.id,
          endLocationId: entry.endLocation?.id,
          costCodeId,
          equipmentId,
          actionType: ClockActionType.EDIT,
        },
      },
    });

    const log: IClientTimeEntryLogCreateInput = {
      id: uuid(),
      updaterMemberId: member.id!,
      timeEntryId: entry.id!,
      originalTimeEntryId: entry.id,
      memberId: entry.member && entry.member.id ? entry.member.id : entry.memberId,
      startTime: entryStartHasChanged ? start.toISO() : entry.startTime,
      startDeviceTime: entryStartHasChanged ? start.toISO() : entry.startTime,
      startTimeTrusted: entryStartHasChanged
        ? ClockType.USER_CUSTOM
        : entry.startTimeTrusted
          ? entry.startTimeTrusted
          : ClockType.USER_CUSTOM,
      endTime: entryEndHasChanged ? newEndTime : entry.endTime,
      endDeviceTime: entryEndHasChanged ? newEndTime : entry.endTime,
      endTimeTrusted: entryEndHasChanged ? ClockType.USER_CUSTOM : entry.endTimeTrusted,
      daylightSavingTime: entry.daylightSavingTime,
      metaDaylightSavingTime: entry.metaDaylightSavingTime,
      description: description === undefined ? entry.description : description,
      projectId: projectId === undefined ? entry.project?.id : projectId,
      costCodeId: costCodeId === undefined ? entry.costCode?.id : costCodeId,
      equipmentId: equipmentId === undefined ? entry.equipment?.id : equipmentId,
      actionType: ClockActionType.EDIT,
      deviceType: t('Web Browser'),
      timeEntryCreatedOn: entry.createdOn,
      timeEntryDeletedOn: entry.deletedOn === undefined || entry.deletedOn === null ? null : entry.deletedOn!,
      startLocationId: entry.startLocation?.id,
      endLocationId: entry.endLocation?.id,
      createdOn: currentUTC,
    };

    return new Promise<IEntryAndLog>(async (resolve, reject) => {
      const timeEntryResult = await updateEntry;
      const timeEntry = timeEntryResult.data?.updateTimeEntry;
      const timeEntryLogResult = await createTimeEntryLog({
        variables: { log },
      });
      const timeEntryLog = timeEntryLogResult.data?.createClientTimeEntryLog;

      if (timeEntry && timeEntryLog) {
        const entryAndLog: IEntryAndLog = {
          entry: timeEntry,
          log: timeEntryLog,
        };
        resolve(entryAndLog);
      } else {
        // TODO resolve between different failures
        reject();
      }
    });
  }

  async function editOpenEntry(
    entry: ITimeEntry,
    startTime: DateTime,
    description?: string | null | undefined,
    projectId?: string | null | undefined,
    costCodeId?: string | null | undefined,
    equipmentId?: string | null | undefined
  ) {
    const entryStarTime = dateUtils.dateTimeFromISOKeepZone(entry.startTime);
    const start = setZone(startTime, entryStarTime.zoneName, true);
    const startWithoutZone = dateUtils.dateTimeFromISOWithoutZone(start.toISO());
    const entryStartHasChanged =
      dateUtils.dateTimeFromISOWithoutZone(entry.startTime).toISO() !== startWithoutZone.toISO();
    const currentUTC = DateTime.utc().toISO();

    const updateEntry = updateTimeEntry({
      variables: {
        entry: {
          id: entry.id,
          startTime: entryStartHasChanged ? start.toISO() : entry.startTime,
          startDeviceTime: entryStartHasChanged ? start.toISO() : entry.startTime,
          startTimeTrusted: entryStartHasChanged ? ClockType.USER_CUSTOM : entry.startTimeTrusted,
          description,
          projectId,
          startLocationId: entry.startLocation?.id,
          endLocationId: entry.endLocation?.id,
          costCodeId,
          equipmentId,
          actionType: ClockActionType.EDIT,
        },
      },
    });

    const log: IClientTimeEntryLogCreateInput = {
      id: uuid(),
      updaterMemberId: member.id!,
      timeEntryId: entry.id!,
      originalTimeEntryId: entry.id,
      memberId: entry.member && entry.member.id ? entry.member.id : entry.memberId,
      startTime: entryStartHasChanged ? start.toISO() : entry.startTime,
      startDeviceTime: entryStartHasChanged ? start.toISO() : entry.startTime!,
      startTimeTrusted: entry.startTimeTrusted ? entry.startTimeTrusted : ClockType.USER_CUSTOM,
      endTime: entry.endTime,
      endDeviceTime: entry.endDeviceTime,
      endTimeTrusted: entry.endTimeTrusted,
      daylightSavingTime: entryStartHasChanged ? startTime.isInDST : entry.daylightSavingTime,
      metaDaylightSavingTime: entry.metaDaylightSavingTime,
      description: description === undefined ? entry.description : description,
      projectId: projectId === undefined ? entry.project?.id : projectId,
      costCodeId: costCodeId === undefined ? entry.costCode?.id : costCodeId,
      equipmentId: equipmentId === undefined ? entry.equipment?.id : equipmentId,
      actionType: ClockActionType.EDIT,
      deviceType: t('Web Browser'),
      timeEntryCreatedOn: entry.createdOn,
      timeEntryDeletedOn: entry.deletedOn === undefined || entry.deletedOn === null ? null : entry.deletedOn!,
      startLocationId: entry.startLocation?.id,
      endLocationId: entry.endLocation?.id,
      createdOn: currentUTC,
    };

    return new Promise<IEntryAndLog>(async (resolve, reject) => {
      const timeEntryResult = await updateEntry;
      const timeEntry = timeEntryResult.data?.updateTimeEntry;
      const timeEntryLogResult = await createTimeEntryLog({
        variables: { log },
      });
      const timeEntryLog = timeEntryLogResult.data?.createClientTimeEntryLog;

      if (timeEntry && timeEntryLog) {
        const entryAndLog: IEntryAndLog = {
          entry: timeEntry,
          log: timeEntryLog,
        };
        resolve(entryAndLog);
      } else {
        // TODO resolve between different failures
        reject();
      }
    });
  }

  async function bulkEditEntries(
    entries: ITimeEntry[],
    // undefined uses the time start time
    startTime: DateTime | undefined,
    // undefined uses the time end time
    endTime: DateTime | undefined,
    description?: string | undefined,
    projectId?: string | undefined,
    costCodeId?: string | undefined,
    equipmentId?: string | undefined
  ) {
    return await Promise.all(
      entries.flatMap((entry) => {
        const entryStart = dateTimeFromISOKeepZone(entry.startTime);
        let newStart = entryStart;
        if (startTime) {
          newStart = combineDateAndTime(entryStart, startTime);
        }

        if (isNil(endTime) && isNil(entry.endTime)) {
          return editOpenEntry(entry, newStart, description, projectId, costCodeId, equipmentId);
        } else {
          const entryEnd = dateTimeFromISOKeepZone(entry.endTime!);
          let newEnd = entryEnd;
          if (endTime) {
            newEnd = combineDateAndTime(entryEnd, endTime);
          }
          return editEntry(entry, newStart, newEnd, description, projectId, costCodeId, equipmentId);
        }
      })
    );
  }

  return {
    getOpenTimeEntriesQuery,
    createEntry,
    clockIn,
    deleteEntry,
    clockOut,
    switchAction,
    editEntry,
    editOpenEntry,
    bulkEditEntries,
  };
}
