import { Button, ButtonList, Position, Size, Theme, Tray } from '@busybusy/webapp-react-ui';
import { AddCircleIcon, ChevronLeftIcon, ChevronRightIcon, ColumnIcon, TableRowHeightIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { Header, IconButton, MoreButton } from 'components';
import { useActiveMember } from 'hooks';
import useIsPro from 'hooks/store/useIsPro';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { formatTimeRange } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { canManageTimeOffForAnyone } from 'utils/positionUtils';
import './TimesheetsActionHeader.scss';

export interface ITimesheetsActionHeaderProps {
  actionBar?: ReactNode;
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  forwardDisabled: boolean;
  backwardDisabled: boolean;
  onTimeRangeForward: () => void;
  onTimeRangeBackward: () => void;
  onTimeEntryAdd: () => void;
  onColumnsClick?: () => void;
  onTimeOffAdd?: () => void;
  onExport: () => void;
  onPrint: () => void;
  className?: ClassName;
  onRowHeightClick?: () => void;
}

const TimesheetsActionHeader = (props: ITimesheetsActionHeaderProps) => {
  const {
    actionBar,
    timeRange,
    timeRangeType,
    forwardDisabled,
    backwardDisabled,
    onTimeRangeBackward,
    onTimeRangeForward,
    onTimeEntryAdd,
    onColumnsClick,
    onTimeOffAdd,
    onExport,
    onPrint,
    onRowHeightClick,
    className,
  } = props;

  const isPro = useIsPro();
  const member = useActiveMember();
  const showProContent = isPro || member.position?.manageCompanySettings === true;
  const canManageTimeOff = member.position ? !!canManageTimeOffForAnyone(member.position) : false;

  function renderMoreContent(close: () => void) {
    const executeWithClose = (fn: () => void) => () => {
      fn();
      close();
    };

    return (
      <ButtonList>
        {canManageTimeOff && onTimeOffAdd && (
          <Button onClick={executeWithClose(onTimeOffAdd)}>{t('Add Time Off')}</Button>
        )}
        {showProContent && <Button onClick={executeWithClose(onExport)}>{t('Export')}</Button>}
        <Button onClick={executeWithClose(onPrint)}>{t('Print')}</Button>
      </ButtonList>
    );
  }

  function getTimeRangeFormat() {
    const today = DateTime.local();
    const sameYears = today.hasSame(timeRange.startTime, 'year') && today.hasSame(timeRange.endTime, 'year');
    if (timeRangeType === TimeRangeType.MONTHLY) {
      return sameYears ? 'MMMM' : 'MMMM, yyyy';
    } else {
      return sameYears ? 'EEE, MMM d' : 'EEE, MMM d, yyyy';
    }
  }

  const classes = classNames('timesheets-action-header', 'no-print', className);

  return (
    <Header className={classes} actionBar={actionBar}>
      <Tray spacing={1}>
        {timeRangeType !== TimeRangeType.CUSTOM && (
          <>
            <IconButton
              svg={ChevronLeftIcon}
              onClick={onTimeRangeBackward}
              buttonSize={Size.SMALL}
              disabled={backwardDisabled}
            />
            <IconButton
              svg={ChevronRightIcon}
              onClick={onTimeRangeForward}
              buttonSize={Size.SMALL}
              disabled={forwardDisabled}
            />
          </>
        )}
        <h2 className="timesheets-action-header-time-range fw-regular px-2">
          {formatTimeRange(timeRange, timeRangeType, getTimeRangeFormat())}
        </h2>
      </Tray>
      <Tray align="right" spacing={1}>
        <>
          {onColumnsClick && (
            <IconButton
              onClick={onColumnsClick}
              svg={ColumnIcon}
              tooltipLabel={t('Columns')}
              buttonSize={Size.MEDIUM}
            />
          )}
          {onRowHeightClick && (
            <IconButton
              onClick={onRowHeightClick}
              svg={TableRowHeightIcon}
              tooltipLabel={t('Table Row Height')}
              buttonSize={Size.MEDIUM}
            />
          )}
        </>

        <IconButton
          onClick={onTimeEntryAdd}
          svg={AddCircleIcon}
          tooltipLabel={t('Create Time Entry')}
          buttonSize={Size.MEDIUM}
          theme={Theme.LIGHT}
        />
        <MoreButton position={Position.BOTTOM_END} size={Size.MEDIUM} renderContent={renderMoreContent} />
      </Tray>
    </Header>
  );
};

export default TimesheetsActionHeader;
