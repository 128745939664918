import { FetchResult, useMutation } from '@apollo/client';
import IIdable from 'types/Idable';

export type UpdateKey<T extends IIdable<string>, MutationObject> = <Key extends keyof T>(
  id: string,
  key: Key,
  value: T[Key]
) => Promise<FetchResult<MutationObject>>;

type UpdateTuple<T extends IIdable<string>, MutationObject> = [
  UpdateKey<T, MutationObject>,
  (value: T) => Promise<FetchResult<MutationObject>>,
];

export default function useApolloUpdate<T extends IIdable<string>, MutationObject>(
  mutation: any,
  // TODO: tie this to the correct key
  variableKey: string
): UpdateTuple<T, MutationObject> {
  const [performMutation] = useMutation<MutationObject>(mutation);
  const updateKey = async <Key extends keyof T>(id: string, key: Key, value: T[Key]) => {
    return await performMutation({
      variables: {
        [variableKey]: { id, [key]: value },
      },
    });
  };

  const update = async (value: T) => {
    return await performMutation({ variables: { [variableKey]: value } });
  };

  return [updateKey, update];
}
