import classNames from 'classnames';
import { Panel } from 'components';
import TimeEntryReport from 'components/domain/time-entry/TimeEntryReport/TimeEntryReport';
import { DateTime } from 'luxon';
import { TimesheetView } from 'store/timesheets/Timesheets';
import { ClassName } from 'types/ClassName';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import ExpandedTimeCardReport from '../ExpandedTimeCardsReport/ExpandedTimeCardReport';
import {
  TimeCardReportExpandedBreakFormat,
  TimeCardReportExpandedTimeFormat,
} from '../TimesheetsSidePanel/hooks/useTimeCardSettings';
import WeeklyGridReport from '../WeeklyGridReport/WeeklyGridReport';
import './TimesheetsContent.scss';
import TimeCardReportContainer from '../TimeCardReport/TimeCardReportContainer';
import { MemberPermissions } from '__generated__/graphql';
import TimesheetSummary from '../summary/TimesheetSummary/TimesheetSummary';

interface ITimesheetsContentProps {
  timesheetView: TimesheetView;
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  forward: () => void;
  forwardEnabled: () => boolean;
  back: () => void;
  backEnabled: () => boolean;
  className?: ClassName;
  expandedTimeFormatSettings: TimeCardReportExpandedTimeFormat;
  breakFormat: TimeCardReportExpandedBreakFormat;
}

function TimesheetsContent({
  timesheetView,
  className,
  expandedTimeFormatSettings,
  breakFormat,
  ...timeRangeDetails
}: ITimesheetsContentProps) {
  const classes = classNames('timesheets-content', className);

  return (
    <Panel className={classes}>
      {timesheetView === TimesheetView.SUMMARY && (
        <TimesheetSummary permission={MemberPermissions.ManageTimeEntries} {...timeRangeDetails} />
      )}
      {timesheetView === TimesheetView.TIME_CARDS && (
        <TimeCardReportContainer permission={MemberPermissions.ManageTimeEntries} {...timeRangeDetails} />
      )}
      {timesheetView === TimesheetView.EXPANDED_TIME_CARDS && (
        <ExpandedTimeCardReport
          permission={MemberPermissions.ManageTimeEntries}
          showDecimalFormat={expandedTimeFormatSettings === TimeCardReportExpandedTimeFormat.DECIMAL}
          breakFormat={breakFormat}
          {...timeRangeDetails}
        />
      )}
      {timesheetView === TimesheetView.TIME_ENTRIES && (
        <TimeEntryReport permission={MemberPermissions.ManageTimeEntries} {...timeRangeDetails} />
      )}
      {timesheetView === TimesheetView.WEEKLY_GRID && (
        <WeeklyGridReport permission={MemberPermissions.ManageTimeEntries} {...timeRangeDetails} />
      )}
    </Panel>
  );
}

export default TimesheetsContent;
