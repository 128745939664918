import { Loader } from '@busybusy/webapp-react-ui';
import type { CostCode, Equipment, JobSafetyAnalysis, Project } from '__generated__/graphql';
import { ErrorState } from 'components';
import { LazyImportWrapper } from 'components/foundation/LazyImportWrapper/LazyImportWrapper';
import FullContentHeaderDialog from 'components/foundation/dialogs/FullContentHeaderDialog/FullContentHeaderDialog';
import type { IOpenableHook } from 'hooks/utils/useOpenable';
import { isNil } from 'lodash';
import { lazy } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import type { Nullable } from 'types/util/Nullable';
import { t } from 'utils/localize';
import { useManageJsaReportDataQuery } from './hooks/useManageJsaReportData';

const ManageJsaReportForm = lazy(() => import('./ManageJsaReportForm'));

export const JSA_FORM_MODE_MANAGE = 'manage';
export const JSA_FORM_MODE_REVIEW = 'review';
export const JSA_FORM_MODES = [JSA_FORM_MODE_MANAGE, JSA_FORM_MODE_REVIEW] as const;
export type TJsaFormMode = (typeof JSA_FORM_MODES)[number];

interface IManageJsaReportFormProps {
  jobSafetyAnalysisId?: Nullable<JobSafetyAnalysis['id']>;
  openable: IOpenableHook;
  jsaFormMode?: TJsaFormMode;
  projectId?: Project['id'];
  costCodeId?: CostCode['id'];
  equipmentId?: Equipment['id'];
}

const defaultManageJsaReportFormDialogProps: Required<Pick<IManageJsaReportFormProps, 'jsaFormMode'>> = {
  jsaFormMode: JSA_FORM_MODE_MANAGE,
};

export const ManageJsaReportFormDialog: React.FC<IManageJsaReportFormProps> = (props) => {
  const { jobSafetyAnalysisId, openable, jsaFormMode, projectId, costCodeId, equipmentId } = {
    ...defaultManageJsaReportFormDialogProps,
    ...props,
  };

  const jobSafetyAnalysisQuery = useManageJsaReportDataQuery(jobSafetyAnalysisId);
  if (!jobSafetyAnalysisId || !openable.isOpen) {
    return null;
  }

  return (
    <FullContentHeaderDialog
      className={`jsa-report-manage`}
      title={t(`JSA Report`)}
      isOpen={true}
      onClose={openable.close}
      size="full"
      hasDismiss={jsaFormMode === JSA_FORM_MODE_MANAGE}
      showBackArrowAsClose
      divider
    >
      <ErrorBoundary fallbackRender={() => <ErrorState title={t(`Something went wrong`)} />}>
        <LazyImportWrapper triggerLoad={openable.isOpen}>
          {jobSafetyAnalysisQuery.isLoading ? (
            <Loader isOpen />
          ) : isNil(jobSafetyAnalysisQuery.data) ? (
            <ErrorState title={t(`JSA Report not found`)} />
          ) : (
            <ManageJsaReportForm
              openable={openable}
              jobSafetyAnalysisQuery={jobSafetyAnalysisQuery}
              jsaFormMode={jsaFormMode}
              projectId={projectId}
              costCodeId={costCodeId}
              equipmentId={equipmentId}
            />
          )}
        </LazyImportWrapper>
      </ErrorBoundary>
    </FullContentHeaderDialog>
  );
};

export default ManageJsaReportFormDialog;
