enum KeyboardKey {
  Backspace = 'Backspace',
  Tab = 'Tab',
  Enter = 'Enter',
  Shift = 'Shift',
  Ctrl = 'Control',
  Alt = 'Alt',
  Pause = 'Pause',
  CapsLock = 'CapsLock',
  Escape = 'Escape',
  Space = ' ',
  PageUp = 'PageUp',
  PageDown = 'PageDown',
  End = 'End',
  Home = 'Home',
  ArrowLeft = 'ArrowLeft',
  ArrowUp = 'ArrowUp',
  ArrowRight = 'ArrowRight',
  ArrowDown = 'ArrowDown',
  Insert = 'Insert',
  Delete = 'Delete',
  Key0 = '0',
  Key1 = '1',
  Key2 = '2',
  Key3 = '3',
  Key4 = '4',
  Key5 = '5',
  Key6 = '6',
  Key7 = '7',
  Key8 = '8',
  Key9 = '9',
  A = 'a',
  B = 'b',
  C = 'c',
  D = 'd',
  E = 'e',
  F = 'f',
  G = 'g',
  H = 'h',
  I = 'i',
  J = 'j',
  K = 'k',
  L = 'l',
  M = 'm',
  N = 'n',
  O = 'o',
  P = 'p',
  Q = 'q',
  R = 'r',
  S = 's',
  T = 't',
  U = 'u',
  V = 'v',
  W = 'w',
  X = 'x',
  Y = 'y',
  Z = 'z',
}

export default KeyboardKey;
