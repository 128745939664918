import { TableCellHeight } from '@busybusy/webapp-react-ui/dist/components/Table/types/types';
import classNames from 'classnames';
import MemberTimeEntryDialog from 'components/domain/time-entry/dialog/MemberTimeEntryDialog/MemberTimeEntryDialog';
import TimeActionsFormDialog from 'components/domain/time-entry/time-actions-form/TimeActionsFormDialog/TimeActionsFormDialog';
import { ITimeActionsFormData } from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import { ITimeOffFormData } from 'components/domain/time-off/form/CreateTimeOffForm/CreateTimeOffForm';
import TimeOffFormDialog from 'components/domain/time-off/form/TimeOffFormDialog/TimeOffFormDialog';
import TimeCardReportMemberHeader from 'containers/timesheets/TimeCardReportMemberHeader/TimeCardReportMemberHeader';
import TimeCardReportTable from 'containers/timesheets/TimeCardReportTable/TimeCardReportTable';
import TimesheetPrintHeader from 'containers/timesheets/TimesheetPrintHeader/TimesheetPrintHeader';
import { useOpenable } from 'hooks';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useState } from 'react';
import { IMember } from 'types';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { t } from 'utils/localize';
import SignOffReportDialog from '../SignOffReportDialog/SignOffReportDialog';
import TimesheetPrintSignature from '../TimesheetPrintSignature/TimesheetPrintSignature';
import './TimeCardReport.scss';
import TemplatedRender from 'components/foundation/state-templates/TemplatedRender/TemplatedRender';
import { isEmpty } from 'lodash';
import { TimeCardReportMemberData } from './types/types';
import FlexContainer from '@busybusy/webapp-react-ui/dist/components/FlexContainer/FlexContainer';

export interface ITimeCardReportProps {
  members: TimeCardReportMemberData[];
  isLoading?: boolean;
  error?: string | undefined;
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  scroller?: HTMLElement | 'self';
  onSignaturesLoaded?: (memberId: string, isLoaded: boolean) => void;
  onSignatureComplete?: (memberId: string) => void;
  onMemberDataChange: (memberIds: string[]) => void;
  rowHeight?: TableCellHeight;
}

const TimeCardReport = ({
  members,
  isLoading,
  error,
  scroller,
  timeRange,
  timeRangeType,
  onSignatureComplete,
  onSignaturesLoaded,
  onMemberDataChange,
  rowHeight,
}: ITimeCardReportProps) => {
  const memberSettings = useMemberSettings();

  const [dialogMember, setDialogMember] = useState<IMember | null>(null);
  const [selectedDate, setSelectedDate] = useState<DateTime | null>(null);

  const viewEntriesDialog = useOpenable({ onClose: clearDialogSettings });
  const timeEntryFormDialog = useOpenable({ onClose: clearDialogSettings });
  const signOffReportDialog = useOpenable({ onClose: clearDialogSettings });
  const addTimeOffDialog = useOpenable({ onClose: clearDialogSettings });

  const memberIds = useMemo(() => (dialogMember ? [dialogMember.id] : []), [dialogMember]);

  const printOptions = memberSettings?.web?.features?.timeCards?.printOptions;

  function clearDialogSettings() {
    setDialogMember(null);
    setSelectedDate(null);
  }

  const onAddTimeEntry = useCallback((date: DateTime, member: IMember) => {
    setDialogMember(member);
    setSelectedDate(date);
    timeEntryFormDialog.open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddTimeOff = useCallback((date: DateTime, member: IMember) => {
    setDialogMember(member);
    setSelectedDate(date);
    addTimeOffDialog.open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onViewSignOff = useCallback((date: DateTime, member: IMember) => {
    setDialogMember(member);
    setSelectedDate(date);
    signOffReportDialog.open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onViewTimeEntries = useCallback((date: DateTime, member: IMember) => {
    setDialogMember(member);
    setSelectedDate(date);
    viewEntriesDialog.open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onViewMemberEntries(member: IMember) {
    setDialogMember(member);
    viewEntriesDialog.open();
  }

  function onViewSignOffs(member: IMember) {
    setDialogMember(member);
    signOffReportDialog.open();
  }

  const onSignatureCurried = (memberId: string) => () => onSignatureComplete?.(memberId);

  const renderRow = useCallback(
    (member: TimeCardReportMemberData, index: number) => {
      return (
        <div
          key={member.id}
          className={classNames(
            index !== 0 ? 'mt-8' : '',
            'timecard-report-container',
            printOptions?.pageBreaks ? 'page-break' : ''
          )}
          data-testid={member.id}
        >
          {printOptions?.pageBreaks && index !== 0 && (
            <TimesheetPrintHeader title={t('Time Cards')} timeRange={timeRange} />
          )}
          <TimeCardReportMemberHeader
            member={member}
            memberSeconds={member?.totalSeconds ?? null}
            onViewEntries={onViewMemberEntries}
            onViewSignOff={onViewSignOffs}
            isExpanded={false}
          />

          <TimeCardReportTable
            member={member}
            timeRange={timeRange}
            scroller={scroller !== 'self' ? scroller : undefined}
            timeRangeIsPayPeriod={timeRangeType === TimeRangeType.PAY_PERIOD}
            onAddTimeEntry={onAddTimeEntry}
            onAddTimeOff={onAddTimeOff}
            onViewSignOff={onViewSignOff}
            onViewTimeEntries={onViewTimeEntries}
            onSignatureComplete={onSignatureCurried(member.id)}
            onSignaturesLoaded={onSignaturesLoaded}
            rowHeight={rowHeight}
          />
          {printOptions?.position === 'at-end-of-document' ? (
            members.length - 1 === index && <TimesheetPrintSignature />
          ) : (
            <TimesheetPrintSignature />
          )}
          {printOptions?.pageBreaks && members.length - 1 !== index && <div className="time-card-page-break" />}
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scroller, timeRangeType, timeRange, printOptions, rowHeight]
  );

  function addTimeEntry(entryData: ITimeActionsFormData) {
    onMemberDataChange(entryData.members);
    timeEntryFormDialog.close();
  }

  function onTimeOffSubmit(data?: ITimeOffFormData | undefined) {
    if (data) {
      onMemberDataChange(data.members);
    }
    addTimeOffDialog.close();
  }

  const onSignOffUpdate = () => {
    onMemberDataChange(memberIds);
  };

  const range = useMemo(() => {
    return selectedDate ? { startTime: selectedDate.startOf('day'), endTime: selectedDate.endOf('day') } : timeRange;
  }, [selectedDate, timeRange]);

  return (
    <>
      <TimesheetPrintHeader title={t('Time Cards')} timeRange={timeRange} />
      <div className="timecard-report">
        <TemplatedRender error={error} isLoading={isLoading} isEmpty={isEmpty(members)}>
          <FlexContainer flexDirection="column">{members.map(renderRow)}</FlexContainer>
        </TemplatedRender>

        {viewEntriesDialog.isOpen && (
          <MemberTimeEntryDialog
            isOpen={viewEntriesDialog.isOpen}
            onClose={viewEntriesDialog.close}
            timeRange={range}
            memberIds={memberIds}
            timeRangeType={selectedDate ? TimeRangeType.DAILY : timeRangeType}
            onDataChange={onMemberDataChange}
          />
        )}
        <TimeActionsFormDialog
          type={'add'}
          isOpen={timeEntryFormDialog.isOpen}
          onClose={timeEntryFormDialog.close}
          onSubmit={addTimeEntry}
          memberIds={memberIds}
          currentDate={selectedDate ? selectedDate.toLocal() : timeRange.endTime}
          onDelete={addTimeEntry}
        />
        <SignOffReportDialog
          isOpen={signOffReportDialog.isOpen}
          onClose={signOffReportDialog.close}
          timeRange={range}
          memberIds={memberIds}
          timeRangeType={selectedDate ? TimeRangeType.DAILY : timeRangeType}
          onDataChange={onSignOffUpdate}
        />
        <TimeOffFormDialog
          memberIds={memberIds}
          currentDate={selectedDate ? selectedDate.toLocal().startOf('day') : timeRange.endTime}
          isOpen={addTimeOffDialog.isOpen}
          onClose={addTimeOffDialog.close}
          onSubmit={onTimeOffSubmit}
        />
      </div>
    </>
  );
};

export default TimeCardReport;
