import { useMutation } from '@apollo/client';
import { COST_CODE_UPDATE_MUTATION } from 'apollo/mutations/cost-code-mutations';
import { costCodesQuery } from 'containers/manage-cost-codes/cost-codes-queries';
import { useApolloPaging } from 'hooks';
import _ from 'lodash';
import { useCallback } from 'react';
import { ICostCodeUpdateInput } from 'types/CostCode';
import ICursorable from 'types/Cursorable';
import IIdable from 'types/Idable';

export default function useCostCode() {
  const { getAll } = useApolloPaging();
  const [updateCostCodeMutation] = useMutation(COST_CODE_UPDATE_MUTATION);

  const getCostCodes = useCallback(
    async <T extends ICursorable & IIdable<string>>(groupId: string, query: any = costCodesQuery) => {
      const results = await getAll<T>('costCodes', {
        query,
        variables: {
          first: 500,
          filter: {
            costCodeGroupId: { equal: groupId },
            archivedOn: { isNull: true },
          },
        },
        fetchPolicy: 'network-only',
      });
      return results;
    },
    [getAll]
  );

  async function assignCostCodesToGroup(groupId: string, costCodeIds: string[]) {
    const inputs: ICostCodeUpdateInput[] = costCodeIds.map((id) => {
      const item: ICostCodeUpdateInput = {
        id,
        costCodeGroupId: groupId,
      };
      return item;
    });

    return await new Promise(async (resolve, reject) => {
      const promises = inputs.map(async (costCode) => {
        const result = await updateCostCodeMutation({ variables: { costCode } });
        const newCostCode = result.data?.updateCostCode;

        if (newCostCode) {
          return newCostCode;
        } else {
          return null;
        }
      });

      const resolved = await Promise.all(promises);
      const [failed, successful] = _.partition(resolved, _.isNull);
      if (failed.length === 0) {
        resolve(successful);
      } else {
        reject(failed);
      }
    });
  }

  async function unassignCostCodesFromGroup(costCodeIds: string[]) {
    const inputs: ICostCodeUpdateInput[] = costCodeIds.map((id) => {
      const item: ICostCodeUpdateInput = {
        id,
        costCodeGroupId: null,
      };
      return item;
    });

    return await new Promise(async (resolve, reject) => {
      const promises = inputs.map(async (costCode) => {
        const result = await updateCostCodeMutation({ variables: { costCode } });
        const newCostCode = result.data?.updateCostCode;

        if (newCostCode) {
          return newCostCode;
        } else {
          return null;
        }
      });

      const resolved = await Promise.all(promises);
      const [failed, successful] = _.partition(resolved, _.isNull);
      if (failed.length === 0) {
        resolve(successful);
      } else {
        reject(failed);
      }
    });
  }

  async function unassignAllCostCodesFromGroup(groupId: string) {
    const costCodes = await getCostCodes(groupId);
    return unassignCostCodesFromGroup(costCodes.flatMap((item) => item.id));
  }

  return { getCostCodes, assignCostCodesToGroup, unassignCostCodesFromGroup, unassignAllCostCodesFromGroup };
}
