import { useQuery } from '@apollo/client';
import {
  Align,
  Button,
  Form,
  IFormRender,
  Justify,
  Label,
  Loader,
  Row,
  Size,
  TextareaFormField,
  Theme,
} from '@busybusy/webapp-react-ui';
import { MEMBER_LOCKS_QUERY } from 'apollo/queries/member-lock-queries';
import { TIME_ENTRIES_WITH_MEMBER_POSITION } from 'apollo/queries/time-entry-queries';
import { DeleteIcon } from 'assets/icons';
import classNames from 'classnames';
import { IBreakMultiPickerItem } from 'components/domain/time-entry-break/BreakMultiPicker/BreakMultiPicker';
import BreakMultiPickerFormField from 'components/domain/time-entry-break/BreakMultiPickerFormField/BreakMultiPickerFormField';
import FeatureTimeFormField from 'components/domain/time/FeatureTimeFormField/FeatureTimeFormField';
import FeatureTimeRangeFormField from 'components/domain/time/FeatureTimeRangeFormField/FeatureTimeRangeFormField';
import Well from 'components/foundation/Well/Well';
import IconButton from 'components/foundation/buttons/IconButton/IconButton';
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import ErrorState from 'components/foundation/state-templates/ErrorState/ErrorState';
import Typography from 'components/foundation/text/Typography/Typography';
import {
  useApolloPaging,
  useDefaultTimes,
  useMemberLock,
  useOpenable,
  usePermissions,
  useTimeEntry,
  useTimeRounding,
  useTimesheetsGraylog,
} from 'hooks';
import useOnMount from 'hooks/utils/useOnMount/useOnMount';
import { max, maxBy, minBy } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import * as React from 'react';
import { FunctionComponent, useEffect, useReducer, useRef, useState } from 'react';
import { ClassName } from 'types/ClassName';
import ICursorable from 'types/Cursorable';
import IMemberLock from 'types/MemberLock';
import ITimeEntry from 'types/TimeEntry';
import ITimeEntryBreak from 'types/TimeEntryBreak';
import ITimeRange from 'types/TimeRange';
import ClockAction from 'types/enum/ClockAction';
import { TimesheetsTypes } from 'utils/constants/graylogActionTypes';
import {
  combineDateAndTime,
  dateTimeFromISOKeepZone,
  dateTimeFromUtcISO,
  getDateString,
  nightShiftAdjustment,
} from 'utils/dateUtils';
import { t } from 'utils/localize';
import { inhibitsActions } from 'utils/memberLockUtils';
import { boldText } from 'utils/stringUtils';
import EditTimePermissionWell from '../../EditTimePermissionWell/EditTimePermissionWell';
import CostCodeTrashRow from '../CostCodeTrashRow/CostCodeTrashRow';
import EquipmentTrashRow from '../EquipmentTrashRow/EquipmentTrashRow';
import ProjectTrashRow from '../ProjectTrashRow/ProjectTrashRow';
import useBulkBreakSubmit from '../hooks/useBulkBreakSubmit';
import { BulkEditActionType, bulkEditReducer, reducerBulkEditInitialState } from '../reducers/reducers';
import { coalesceBulkTimeEntryFields, fieldIsSet, fieldIsSetValue } from '../util/utils';
import './BulkEditEntryForm.scss';

export interface IBulkEditEntryFormProps {
  timeEntryIds: string[];
  onSubmit: (entries: ITimeEntry[]) => void;
  className?: ClassName;
  isEntriesOpen?: boolean;
}

export const MULTIPLE = 'MULTIPLE';

export type TMultipleField<T> = T | 'MULTIPLE' | null;
export type TCompositeIdType<T> = T & { compositeId: string };
export type TCompositeBreak = TCompositeIdType<ITimeEntryBreak>;

export interface IBulkEntryInitialState {
  project: TMultipleField<string>;
  costCode: TMultipleField<string>;
  equipment: TMultipleField<string>;
  startTime: TMultipleField<DateTime>;
  endTime: TMultipleField<DateTime> | null;
  description: TMultipleField<string>;
  breaks: TMultipleField<TCompositeBreak[]>;
}

export interface IBulkEditEntryFormData {
  timeRange: ITimeRange<DateTime | null>;
  startTime?: DateTime | null;
  project?: string | null;
  costCode?: string | null;
  equipment?: string | null;
  description?: string | null;
  breaks: IBreakMultiPickerItem[];
}

export const compositeIdSeperator = ',';

const BulkEditEntryForm: FunctionComponent<IBulkEditEntryFormProps> = (props) => {
  const { timeEntryIds, onSubmit, className, isEntriesOpen } = props;

  const { data, error } = useQuery<{ timeEntries: ITimeEntry[] }>(TIME_ENTRIES_WITH_MEMBER_POSITION, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        id: { contains: timeEntryIds },
        deletedOn: { isNull: true },
      },
    },
  });

  const userEvents = useTimesheetsGraylog();
  const [formData, setFormData] = useState<
    Omit<IBulkEditEntryFormData, 'project' | 'costCode' | 'equipment' | 'description'>
  >({
    timeRange: { startTime: null, endTime: null },
    startTime: null,
    breaks: [],
  });

  const [descriptionState, descriptionDispatch] = useReducer(bulkEditReducer, reducerBulkEditInitialState);
  const [projectState, projectDispatch] = useReducer(bulkEditReducer, reducerBulkEditInitialState);
  const [costCodeState, costCodeDispatch] = useReducer(bulkEditReducer, reducerBulkEditInitialState);
  const [equipmentState, equipmentDispatch] = useReducer(bulkEditReducer, reducerBulkEditInitialState);
  const initialData = useRef<IBulkEntryInitialState>();
  const { bulkEditEntries } = useTimeEntry();
  const { defaultStartTime, defaultEndTime } = useDefaultTimes();
  const [breaksCleared, setBreaksCleared] = useState(false);
  const { roundTime } = useTimeRounding();
  const handleBulkBreakSubmit = useBulkBreakSubmit();
  const loaderDetails = useOpenable();
  const { hasPermissionToManage } = usePermissions();
  const { inhibitsActionsForMembers, editLock } = useMemberLock();
  const [memberLockDate, setMemberLockDate] = useState<DateTime | null>(null);
  const lockDateDialog = useOpenable();
  const preValidationFormData = useRef<IBulkEditEntryFormData>();
  const { getAll } = useApolloPaging();

  useOnMount(() => {
    userEvents.events(TimesheetsTypes.events.action_type.BULK_EDIT);
  });

  useEffect(() => {
    if (data) {
      const manageableEntries = data.timeEntries.filter((entry) =>
        hasPermissionToManage(entry.member, 'manageTimeEntries')
      );
      const parsedData = coalesceBulkTimeEntryFields(manageableEntries);
      initialData.current = parsedData;
      const breaks = fieldIsSet(parsedData, 'breaks')
        ? (parsedData.breaks as TCompositeBreak[]).map<IBreakMultiPickerItem>((entryBreak) => {
            return {
              id: entryBreak.compositeId,
              timeRange: {
                startTime: dateTimeFromISOKeepZone(entryBreak.startTime),
                endTime: dateTimeFromISOKeepZone(entryBreak.endTime!),
              },
            };
          })
        : [];

      setFormData({
        timeRange: {
          startTime: fieldIsSet(parsedData, 'startTime') ? (parsedData.startTime as DateTime) : null,
          endTime: fieldIsSet(parsedData, 'endTime') ? (parsedData.endTime as DateTime) : null,
        },
        startTime: fieldIsSet(parsedData, 'startTime') ? (parsedData.startTime as DateTime) : null,
        breaks,
      });

      descriptionDispatch({ type: BulkEditActionType.SET_ORIGINAL, payload: { value: parsedData.description } });
      projectDispatch({ type: BulkEditActionType.SET_ORIGINAL, payload: { value: parsedData.project } });
      costCodeDispatch({ type: BulkEditActionType.SET_ORIGINAL, payload: { value: parsedData.costCode } });
      equipmentDispatch({ type: BulkEditActionType.SET_ORIGINAL, payload: { value: parsedData.equipment } });

      checkLockDate(manageableEntries);
    }
  }, [data]);

  async function checkLockDate(timeEntries: ITimeEntry[]) {
    const earliestTimeEntry = minBy(timeEntries, (entry) => entry.startTime);
    if (earliestTimeEntry) {
      const hasLockDate = await inhibitsActionsForMembers(
        data?.timeEntries.map((entry) => entry.member.id) ?? [],
        dateTimeFromISOKeepZone(earliestTimeEntry.startTime)
      );
      if (hasLockDate) {
        setMemberLockDate(dateTimeFromISOKeepZone(earliestTimeEntry.startTime).startOf('day').minus({ day: 1 }));
      }
    }
  }

  function clearBreaks() {
    setBreaksCleared(true);
  }

  function getMaxTimeEntryStart() {
    if (data && data.timeEntries && data.timeEntries.length) {
      const entryStarts = data.timeEntries.map((entry) => dateTimeFromISOKeepZone(entry.startTime));
      return (maxBy(entryStarts, (start) => start.hour * 60 + start.minute) as DateTime).set({
        second: 0,
        millisecond: 0,
      });
    } else {
      return defaultStartTime;
    }
  }

  function renderFormFields(form: IFormRender<IBulkEditEntryFormData>) {
    const clearDescription = () => {
      descriptionDispatch({ type: BulkEditActionType.CLEAR_CURRENT });
    };

    const onDescriptionChange = (description: string | null) => {
      descriptionDispatch({ type: BulkEditActionType.SET_CURRENT, payload: { value: description } });
    };

    const renderDispatchUndo = () => {
      const onUndo = async (event: React.MouseEvent) => {
        event.stopPropagation();
        descriptionDispatch({ type: BulkEditActionType.UNDO_CURRENT });
      };

      return (
        <Typography className={'mx-2'} onClick={onUndo} color={'primary'} tag={'span'}>
          {t('Undo')}
        </Typography>
      );
    };

    function inRange(start: DateTime, end: DateTime, time: DateTime, key: keyof DateTime) {
      const keyedStart = start[key];
      const keyedEnd = end[key];
      const keyedTime = time[key];
      if (keyedStart && keyedEnd && keyedTime) {
        return keyedStart <= keyedTime && keyedTime <= keyedEnd;
      } else {
        return false;
      }
    }

    function validateBreakItem(item: IBreakMultiPickerItem): boolean {
      // Check that the item matches all the entries
      const itemStart = item.timeRange.startTime;
      const itemEnd = item.timeRange.endTime;
      if (formData.timeRange !== null && formData.timeRange.startTime !== null && formData.timeRange.endTime !== null) {
        const formStart = formData.timeRange.startTime;
        const formEnd = formData.timeRange.endTime;

        return (
          inRange(formStart, formEnd, itemStart, 'hour') &&
          inRange(formStart, formEnd, itemStart, 'minute') &&
          inRange(formStart, formEnd, itemEnd, 'hour') &&
          inRange(formStart, formEnd, itemEnd, 'minute')
        );
      } else {
        // Since the form data is not set we need to check each entry
        return (
          data?.timeEntries.every((entry) => {
            const entryStart = dateTimeFromISOKeepZone(entry.startTime);
            const entryEnd = dateTimeFromISOKeepZone(entry.endTime!);
            return (
              inRange(entryStart, entryEnd, itemStart, 'hour') &&
              inRange(entryStart, entryEnd, itemStart, 'minute') &&
              inRange(entryStart, entryEnd, itemEnd, 'hour') &&
              inRange(entryStart, entryEnd, itemEnd, 'minute')
            );
          }) ?? false
        );
      }
    }

    function getMinTimeEntryEnd() {
      if (data && data.timeEntries && data.timeEntries.length) {
        const entryEnds = data.timeEntries
          .map((entry) => dateTimeFromISOKeepZone(entry.endTime!))
          .filter((date) => date.isValid);
        if (isEmpty(entryEnds)) {
          return roundTime(DateTime.local(), ClockAction.CLOCK_OUT).set({ second: 0, millisecond: 0 });
        }
        const maxEnd = (max(entryEnds) as DateTime).set({
          second: 0,
          millisecond: 0,
        });
        const minEnd = (minBy(entryEnds, (end) => end.hour * 60 + end.minute) as DateTime).set({
          second: 0,
          millisecond: 0,
        });
        return combineDateAndTime(maxEnd, minEnd);
      } else {
        return defaultEndTime;
      }
    }

    const anyBreaksSet = !form.state.data.breaks || form.state.data.breaks.length === 0;
    const shouldShowDeleteAllBreaks = anyBreaksSet && initialData.current?.breaks === MULTIPLE && !breaksCleared;

    const { startTime, endTime } = nightShiftAdjustment({
      startTime: getMaxTimeEntryStart(),
      endTime: getMinTimeEntryEnd(),
    });

    return (
      <>
        {data && isEmpty(data.timeEntries.filter((entry) => !isNil(entry.endTime))) ? (
          <>
            <Label>{t('Start Time')}</Label>
            <div className="date-pickers-container mr-4">
              <div className="mr-4">
                <FeatureTimeFormField name="startTime" clockAction={ClockAction.CLOCK_IN} form={form} />
              </div>
            </div>
          </>
        ) : (
          <>
            <Label>{t('Time')}</Label>
            <FeatureTimeRangeFormField name="timeRange" form={form} placeholder={''} />
          </>
        )}

        <Label
          secondaryLabel={
            initialData.current?.breaks === MULTIPLE
              ? t('Adding breaks will remove all existing breaks and replace them.')
              : null
          }
        >
          {t('Breaks')}
        </Label>
        <Row justify={Justify.SPACE_BETWEEN} align={Align.CENTER}>
          <BreakMultiPickerFormField
            name="breaks"
            className="pb-0 flex-grow"
            form={form}
            timeEntryStart={startTime}
            timeEntryEnd={endTime}
            displayDateOnEntryDifference={false}
            displayError={validateBreakItem}
            hideDateOnBreakForm={true}
          />
          {shouldShowDeleteAllBreaks ? (
            <Button type="secondary" size={Size.SMALL} onClick={clearBreaks}>
              {t('Delete All')}
            </Button>
          ) : null}
        </Row>

        <Label
          className="pt-5"
          secondaryLabel={
            projectState.clearable || costCodeState.clearable || equipmentState.clearable
              ? t('To clear a field, press the trash icon next to the field.')
              : null
          }
        >
          {t('Details')}
        </Label>

        <ProjectTrashRow
          form={form}
          bulkState={projectState}
          dispatch={projectDispatch}
          costCodeDispatch={costCodeDispatch}
          costCodeState={costCodeState}
        />

        <CostCodeTrashRow
          form={form}
          bulkState={costCodeState}
          dispatch={costCodeDispatch}
          projectState={projectState}
          projectDispatch={projectDispatch}
          projectId={form.state.data.project}
        />

        <EquipmentTrashRow form={form} bulkState={equipmentState} dispatch={equipmentDispatch} />

        <Label
          secondaryLabel={descriptionState.clearable ? t('Press the trash icon to clear all descriptions.') : null}
        >
          {t('Description')}
          {descriptionState.undoable ? renderDispatchUndo() : undefined}
        </Label>
        <Row className="trash-row">
          <TextareaFormField
            name="description"
            placeholder={
              descriptionState.original === MULTIPLE && descriptionState.current === null
                ? t('All descriptions will be deleted.')
                : undefined
            }
            form={form}
            restrictTo={{ maxLength: 5000 }}
            onChange={onDescriptionChange}
          />
          {descriptionState.clearable && (
            <IconButton className="trash-icon" onClick={clearDescription} svg={DeleteIcon} />
          )}
        </Row>

        <Button type="primary" onClick={form.handleSubmit}>
          {t('Save')}
        </Button>
      </>
    );
  }

  async function onFormSubmit(submitted: IBulkEditEntryFormData) {
    preValidationFormData.current = submitted;

    if (!isNil(memberLockDate)) {
      lockDateDialog.open();
      return;
    }

    await handleSubmit(preValidationFormData.current);
  }

  async function handleSubmit(submitted: IBulkEditEntryFormData) {
    loaderDetails.open();

    const initial = initialData.current as IBulkEntryInitialState;
    const timeEntries = (data?.timeEntries ?? []).filter((entry) =>
      hasPermissionToManage(entry.member, 'manageTimeEntries')
    );
    // If the time range is set, use the time range, otherwise use that it was initially otherwise don't change it
    let start: DateTime | undefined =
      submitted.timeRange?.startTime ?? (initial.startTime !== MULTIPLE ? (initial.startTime as DateTime) : undefined);

    if (isEntriesOpen && !isNil(submitted.startTime) && isNil(submitted.timeRange?.endTime)) {
      start = submitted.startTime;
    }

    const end: DateTime | undefined =
      submitted.timeRange?.endTime ?? (initial.endTime !== MULTIPLE ? (initial.endTime as DateTime) : undefined);

    const entryLogs = await bulkEditEntries(
      timeEntries,
      start,
      end,
      descriptionState.current !== MULTIPLE ? (descriptionState.current as string) : undefined,
      projectState.current !== MULTIPLE ? (projectState.current as string) : undefined,
      costCodeState.current !== MULTIPLE ? (costCodeState.current as string) : undefined,
      equipmentState.current !== MULTIPLE ? (equipmentState.current as string) : undefined
    );

    await handleBulkBreakSubmit(submitted.breaks, breaksCleared, initial.breaks, entryLogs, timeEntries);
    onSubmit(entryLogs.map(({ entry }) => entry));
    loaderDetails.close();
  }

  async function onMoveLockDate() {
    if (memberLockDate) {
      loaderDetails.open();

      const timeEntries = (data?.timeEntries ?? []).filter((entry) =>
        hasPermissionToManage(entry.member, 'manageTimeEntries')
      );

      const locks = await getAll<IMemberLock & ICursorable>('memberLocks', {
        query: MEMBER_LOCKS_QUERY,
        variables: {
          first: 500,
          filter: {
            memberId: { contains: timeEntries.map((entry) => entry.member.id) },
          },
        },
        fetchPolicy: 'network-only',
      });

      const filteredLocks = locks.filter((l) => inhibitsActions(dateTimeFromUtcISO(l.effectiveDate!), memberLockDate));

      const locksPromises = filteredLocks.map(async (element) => {
        return editLock(element, memberLockDate);
      });
      await Promise.all(locksPromises);
    }
    lockDateDialog.close();
    await handleSubmit(preValidationFormData.current!);
  }

  function onFormChange(changedData: IBulkEditEntryFormData | undefined) {
    if (changedData) {
      setFormData(changedData);
    }
  }

  return (
    <div className={classNames('bulk-edit-entry-form', className)}>
      {!isNil(error) ? (
        <ErrorState className="pb-3" title={t('There was trouble retrieving your time entry information')} />
      ) : (
        <>
          <EditTimePermissionWell timeEntries={data ? data.timeEntries : []} />
          <Well theme={Theme.PRIMARY} className="mb-5">
            {t(
              'If a field is left blank on the form below, that field will not be changed and will retain whatever value it had before the edit.'
            )}
          </Well>
          <Form
            data={
              {
                ...formData,
                description: fieldIsSetValue(descriptionState.current) ? descriptionState.current : null,
                project: fieldIsSetValue(projectState.current) ? projectState.current : null,
                costCode: fieldIsSetValue(costCodeState.current) ? costCodeState.current : null,
                equipment: fieldIsSetValue(equipmentState.current) ? equipmentState.current : null,
              } as IBulkEditEntryFormData
            }
            onSubmit={onFormSubmit}
            onChange={onFormChange}
            render={renderFormFields}
          />
        </>
      )}
      <Loader isOpen={loaderDetails.isOpen} />
      <HeaderDialog
        isOpen={lockDateDialog.isOpen}
        title={t('Move lock date?')}
        onClose={lockDateDialog.close}
        className={classNames}
        divider={false}
      >
        <div className="m-6">
          {memberLockDate &&
            boldText(
              t(
                `One or more of the selected entries are restricted by the employee's lock date. Would you like us to move the lock date to ${getDateString(memberLockDate, 'DDDD', true)} so you can edit these entries?`
              ),
              getDateString(memberLockDate, 'DDDD', true)
            )}
          <Row align={Align.CENTER} justify={Justify.FLEX_END} className="mt-6">
            <Button className="mx-4" type="secondary" onClick={lockDateDialog.close}>
              {t('Cancel')}
            </Button>
            <Button type="primary" onClick={onMoveLockDate}>
              {t('Move Lock Date')}
            </Button>
          </Row>
        </div>
      </HeaderDialog>
    </div>
  );
};

export default BulkEditEntryForm;
