import { IStoreEnvironmentTemplate } from '../index';
import production from './production';

export const localhost: IStoreEnvironmentTemplate = Object.assign({}, production, {
  REACT_APP_APP_URL: 'http://localhost:3000/',
  REACT_APP_GRAPHQL_URL: 'http://localhost:8004/',
  REACT_APP_LEGACY_URL: 'http://localhost:8006/',
  REACT_APP_RESTAPI_URL: 'http://localhost:8001/',
  REACT_APP_PLATFORM_REPORT_URL: `http://localhost:8003`,
  REACT_APP_CUSTOM_REPORT_URL: `http://localhost:8002`,
  REACT_APP_GUSTO_URL: 'http://localhost:8111',
  REACT_APP_LEGACY_QBRPC_URL: `/`, // no localhost for legacy qb rpc
  REACT_APP_COMPANY_CAM_URL: 'http://localhost:8008',
  REACT_APP_COMPANY_CAM_SOCKET_URL: 'ws://localhost:8008',
  REACT_APP_QBD_API_URL: `/`, // no localhost for qbd-api
  REACT_APP_AGAVE_URL: `/`,
  REACT_APP_ADP_API_URL: `/`,
});

export default localhost;
