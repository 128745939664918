import { Dialog, Divider, Justify, Size } from '@busybusy/webapp-react-ui';
import FlexContainer from '@busybusy/webapp-react-ui/dist/components/FlexContainer/FlexContainer';
import { IHeaderDialogProps } from '../HeaderDialog/HeaderDialog';
import HeaderDialogHeader from '../HeaderDialogHeader/HeaderDialogHeader';
import './FullContentHeaderDialog.scss';

export type IFullContentHeaderDialogProps = IHeaderDialogProps;

export default function FullContentHeaderDialog({
  title,
  onClose,
  subtitle,
  showBackArrowAsClose,
  rightContent,
  headerClass,
  headerSize,
  divider,
  children,
  hasDismiss,
  ...dialogProps
}: IFullContentHeaderDialogProps) {
  const showBackArrow = !!showBackArrowAsClose && !!hasDismiss;

  return (
    <Dialog {...dialogProps} onClose={onClose} hasDismiss={!showBackArrowAsClose && hasDismiss}>
      <FlexContainer flexDirection="column" className="full-height">
        <HeaderDialogHeader
          title={title}
          subtitle={subtitle}
          showBackArrow={showBackArrow}
          rightContent={rightContent}
          onClose={onClose}
          justify={Justify.SPACE_BETWEEN}
          className={headerClass}
          size={headerSize}
        />
        {divider === undefined || (divider === true && <Divider className="full-content-header-dialog-divider" />)}
        <FlexContainer.Item flexGrow={1}>{children}</FlexContainer.Item>
      </FlexContainer>
    </Dialog>
  );
}

FullContentHeaderDialog.defaultProps = {
  headerSize: Size.LARGE,
  hasDismiss: true,
  showBackArrowAsClose: false,
};
