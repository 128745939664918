import { ClassName, Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { MemberPermissions } from '__generated__/graphql';
import TimesheetsActionHeader from '../TimesheetsActionHeader/TimesheetsActionHeader';
import { useOpenable, useOrganization, useReduxSelector, useTimesheetsGraylog } from 'hooks';
import TableColumnsDialog from 'components/foundation/table/TableColumnsDialog/TableColumnsDialog';
import TimeOffFormDialog from 'components/domain/time-off/form/TimeOffFormDialog/TimeOffFormDialog';
import TimeActionsFormDialog from 'components/domain/time-entry/time-actions-form/TimeActionsFormDialog/TimeActionsFormDialog';
import RowSettingsDialog from 'components/foundation/dialogs/RowSettingsDialog/RowSettingsDialog';
import TimesheetsColumnsForm from '../TimesheetsColumnsForm/TimesheetsColumnsForm';
import ProFeatureDialog from 'components/domain/account/ProFeatureDialog/ProFeatureDialog';
import { t } from 'i18next';
import { TimesheetsTypes } from 'utils/constants/graylogActionTypes';
import { downloadText } from 'utils/fileUtils';
import { convertTimeCardReportRowsToCsv } from './utils/utils';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import { isoTimeStampLocal } from 'utils/dateUtils';
import { useLoader } from 'contexts/LoaderContext';
import { useToastOpen } from 'contexts/ToastContext';
import TimesheetsMemberActionBar from '../action-bar/TimesheetsMemberActionBar/TimesheetsMemberActionBar';
import TimeCardReport, { ITimeCardReportProps } from './TimeCardReport';
import useTimesheetsRowHeight from '../hooks/useTimesheetsRowHeight/useTimesheetsRowHeight';
import { ITimeOffFormData } from 'components/domain/time-off/form/CreateTimeOffForm/CreateTimeOffForm';
import { ITimeActionsFormData } from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import { Nullable } from 'types/util/Nullable';
import { IUseTimeRangePayload } from 'hooks/utils/useTimeRange';
import { useMemo, useRef } from 'react';
import TimesheetsHeader from '../TimesheetsHeader/TimesheetsHeader';
import useMemberGraphAggregates from 'hooks/aggregates/useMemberGraphAggregates';
import useTimeCardReportData from './hooks/useTimeCardReportData';
import useIsPro from 'hooks/store/useIsPro';
import TimesheetsPrintDialog from '../TimesheetsPrintDialog/TimesheetsPrintDialog';
import { TimesheetView } from 'store/timesheets/Timesheets';
import useTimesheetsQueryParams from '../hooks/useTimesheetsQueryParams';
import { Panel } from 'components';

export interface ITimeCardReportContainerProps extends IUseTimeRangePayload {
  permission: MemberPermissions.ManageTimeEntries | MemberPermissions.TimeEvents;
  onSignatureComplete?: ITimeCardReportProps['onSignatureComplete'];
  className?: ClassName;
}

export default function TimeCardReportContainer({
  timeRange,
  timeRangeType,
  forward,
  back,
  forwardEnabled,
  backEnabled,
  onSignatureComplete,
  permission,
  className,
}: ITimeCardReportContainerProps) {
  const scroller = useRef<Nullable<HTMLDivElement>>(null);
  const { memberId, memberGroupId, positionId, archivedStatus } = useTimesheetsQueryParams();

  const userEvents = useTimesheetsGraylog();
  const memberIds = useMemo(() => (memberId ? [memberId] : null), [memberId]);

  const {
    data: members,
    isLoading,
    isError,
    refreshMembersData,
    forceLoadAll,
    areAllSignaturesLoaded,
    onSignaturesLoaded,
  } = useTimeCardReportData({
    memberIds,
    memberGroupId,
    positionId,
    timeRange,
    timeRangeType,
    archivedStatus,
    permission,
    scroller: scroller.current,
  });

  const organization = useOrganization();
  const brand = useBrandTitle();

  const { open: openLoader, close: closeLoader } = useLoader();
  const openToast = useToastOpen();

  const timeEntryDialogDetails = useOpenable();
  const timeOffDialogDetails = useOpenable();
  const columnDialogDetails = useOpenable();
  const proUpgradeDialogDetails = useOpenable();
  const printDetails = useOpenable();
  const rowSettingsDialog = useOpenable();

  const [rowHeight, setRowHeight] = useTimesheetsRowHeight();
  const checkedMemberIds = useReduxSelector((state) => state.timesheet.checkedMemberIds);

  const isPro = useIsPro();

  async function onExport() {
    if (!isPro) {
      proUpgradeDialogDetails.open();
      return;
    }

    userEvents.events(TimesheetsTypes.events.action_type.EXPORT);
    openLoader(t('Loading...'));
    try {
      const timeCardData = await forceLoadAll();

      if (timeCardData.length !== 0) {
        const csv = convertTimeCardReportRowsToCsv(timeCardData, organization);
        downloadText(csv, `${brand}-${t('timesheets-time-cards')}-${isoTimeStampLocal()}.csv`);
      } else {
        openToast({ theme: Theme.DANGER, label: t('There is no data to export.') });
      }
    } catch (e) {
      openToast({ theme: Theme.DANGER, label: t('Something went wrong.') });
    } finally {
      closeLoader();
    }
  }

  function onPrint() {
    printDetails.open();
    userEvents.events(TimesheetsTypes.events.action_type.PRINT);
  }

  async function handlePrint(loadAllData: boolean = true) {
    printDetails.close();
    openLoader(t('Loading...'));

    try {
      if (loadAllData) {
        await forceLoadAll();
      }

      setTimeout(() => {
        const loaded = areAllSignaturesLoaded?.();
        if (loaded) {
          setTimeout(() => {
            window.print();
            closeLoader();
          }, 1000);
        } else {
          handlePrint(false);
        }
      }, 2000);
    } catch (e) {
      openToast({ theme: Theme.DANGER, label: t('Something went wrong.') });
    } finally {
      closeLoader();
    }
  }

  function onTimeEntryFormDataSubmit(formData: ITimeActionsFormData) {
    onMemberDataChange(formData.members);
    timeEntryDialogDetails.close();
  }

  function onTimeOffFormDataSubmit(formData: ITimeOffFormData | undefined) {
    if (formData) {
      onMemberDataChange(formData.members);
    }
    timeOffDialogDetails.close();
  }

  const {
    data: graphAggregates,
    error: graphAggregateError,
    refetchData: refetchAggregates,
  } = useMemberGraphAggregates(timeRange, memberIds, null, null, null, memberGroupId, 2, archivedStatus);

  function onMemberDataChange(members: string[]) {
    refetchAggregates();
    refreshMembersData(members);
  }

  const classes = classNames('time-card-report-container', className);

  return (
    <Panel className={classes}>
      <TimesheetsActionHeader
        timeRange={timeRange}
        timeRangeType={timeRangeType}
        onTimeRangeForward={forward}
        onTimeRangeBackward={back}
        forwardDisabled={!forwardEnabled()}
        backwardDisabled={!backEnabled()}
        onTimeEntryAdd={timeEntryDialogDetails.open}
        onTimeOffAdd={timeOffDialogDetails.open}
        onColumnsClick={columnDialogDetails.open}
        onExport={onExport}
        onPrint={onPrint}
        onRowHeightClick={rowSettingsDialog.open}
        actionBar={
          <TimesheetsMemberActionBar
            onTimeEntryFormSubmit={onTimeEntryFormDataSubmit}
            onTimeOffFormSubmit={onTimeOffFormDataSubmit}
          />
        }
      />

      <div ref={scroller} className="overflow-y-auto full-height">
        <TimesheetsHeader
          className="m-6 no-print"
          timeRange={timeRange}
          graphData={graphAggregates}
          aggregateError={graphAggregateError?.name ?? null}
          showDecimalFormat={false}
        />

        <TimeCardReport
          members={members ?? []}
          timeRange={timeRange}
          timeRangeType={timeRangeType}
          isLoading={isLoading}
          error={isError ? t('Something went wrong') : undefined}
          onSignatureComplete={onSignatureComplete}
          onMemberDataChange={onMemberDataChange}
          onSignaturesLoaded={onSignaturesLoaded}
          rowHeight={rowHeight}
        />
      </div>

      <TimeActionsFormDialog
        type={'add'}
        isOpen={timeEntryDialogDetails.isOpen}
        onClose={timeEntryDialogDetails.close}
        onSubmit={onTimeEntryFormDataSubmit}
        onDelete={onTimeEntryFormDataSubmit}
      />

      <TimeOffFormDialog
        isOpen={timeOffDialogDetails.isOpen}
        onClose={timeOffDialogDetails.close}
        memberIds={checkedMemberIds}
        onSubmit={onTimeOffFormDataSubmit}
      />

      <TableColumnsDialog
        isOpen={columnDialogDetails.isOpen}
        onClose={columnDialogDetails.close}
        columnsForm={
          <TimesheetsColumnsForm timesheetView={TimesheetView.TIME_CARDS} onClose={columnDialogDetails.close} />
        }
      />

      <RowSettingsDialog
        isOpen={rowSettingsDialog.isOpen}
        onClose={rowSettingsDialog.close}
        initialValue={rowHeight}
        onSubmit={setRowHeight}
      />

      <ProFeatureDialog isOpen={proUpgradeDialogDetails.isOpen} onClose={proUpgradeDialogDetails.close} />

      <TimesheetsPrintDialog
        isOpen={printDetails.isOpen}
        onClose={printDetails.close}
        onPrint={() => handlePrint(true)}
      />
    </Panel>
  );
}
