import { ClassName, TextField } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import useOnMount from 'hooks/utils/useOnMount/useOnMount';
import { useRef } from 'react';

export interface IPickerListSearchHeaderProps {
  searchValue: string;
  onSearch: (value: string, e: React.ChangeEvent) => void;
  onKeyDown: (event: React.KeyboardEvent) => void;
  setFocusIndex: (n: number) => void;
  autofocus: boolean;
  className?: ClassName;
}

export default function PickerListSearchHeader({
  searchValue,
  autofocus,
  onSearch,
  onKeyDown,
  setFocusIndex,
  className,
}: IPickerListSearchHeaderProps) {
  const searchFieldRef = useRef<HTMLInputElement | null>(null);

  useOnMount(() => {
    if (autofocus) {
      searchFieldRef.current?.focus();
    }
  });

  function handleSearchChange(value: string, event: React.ChangeEvent) {
    onSearch(value, event);
    setFocusIndex(0);
  }

  function handleKeyDown(_value: string, event: React.KeyboardEvent) {
    onKeyDown(event);
  }

  const classes = classNames('picker-list-search-header', className);

  return (
    <div className={classes}>
      <TextField
        value={searchValue}
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown}
        inputRef={(ref) => (searchFieldRef.current = ref)}
        autofocus={true}
      />
    </div>
  );
}
