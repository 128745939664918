import {
  BASIC_MEMBER_LABOR_METRICS_FRAGMENT,
  BASIC_MEMBER_TIME_METRICS_FRAGMENT,
} from 'apollo/queries/jit-member-queries';
import {
  BASIC_PROJECT_LABOR_METRICS_FRAGMENT,
  BASIC_PROJECT_TIME_METRICS_FRAGMENT,
} from 'apollo/queries/jit-project-queries';
import { BASIC_LABOR_METRICS_FRAGMENT, BASIC_TIME_METRICS_FRAGMENT } from 'apollo/queries/jit-queries';
import gql from 'graphql-tag';

export const MEMBERS_WITH_TIME_ONLY_METRIC_QUERY = gql`
  query QueryMembersWithTimeMetricOnly(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      memberNumber
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...BasicMemberLaborMetrics
      }
    }
  }
  ${BASIC_MEMBER_TIME_METRICS_FRAGMENT}
`;

export const MEMBERS_WITH_METRIC_QUERY = gql`
  query QueryMembersWithMetric(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      memberNumber
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...MemberLaborMetrics
      }
    }
  }
  ${BASIC_MEMBER_LABOR_METRICS_FRAGMENT}
`;

export const MEMBERS_WITH_COST_CODE_METRIC_QUERY = gql`
  query QueryMembersWithCostCodeMetric(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      memberNumber
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
      ) {
        ...LaborMetrics
        costCodeId
      }
    }
  }
  ${BASIC_LABOR_METRICS_FRAGMENT}
`;

export const MEMBERS_WITH_COST_CODE_TIME_ONLY_METRIC_QUERY = gql`
  query QueryMembersWithCostCodeTimeMetricOnly(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      memberNumber
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
      ) {
        ...BasicLaborMetrics
        costCodeId
      }
    }
  }
  ${BASIC_TIME_METRICS_FRAGMENT}
`;

export const MEMBERS_WITH_COST_CODE_EQUIPMENT_METRIC_QUERY = gql`
  query QueryMembersWithCostCodeEquipmentMetric(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
    $equipmentIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      memberNumber
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberCostCodeEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
        equipmentIds: $equipmentIds
      ) {
        ...LaborMetrics
        costCodeId
        equipmentId
        equipmentCents
      }
    }
  }
  ${BASIC_LABOR_METRICS_FRAGMENT}
`;

export const MEMBERS_WITH_COST_CODE_EQUIPMENT_TIME_ONLY_METRIC_QUERY = gql`
  query QueryMembersWithCostCodeEquipmentTimeMetricOnly(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
    $equipmentIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      memberNumber
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberCostCodeEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
        equipmentIds: $equipmentIds
      ) {
        ...BasicLaborMetrics
        costCodeId
        equipmentId
      }
    }
  }
  ${BASIC_TIME_METRICS_FRAGMENT}
`;

export const MEMBERS_WITH_EQUIPMENT_METRIC_QUERY = gql`
  query QueryMembersWithEquipmentMetric(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $equipmentIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      memberNumber
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        equipmentIds: $equipmentIds
      ) {
        ...LaborMetrics
        equipmentId
        equipmentCents
      }
    }
  }
  ${BASIC_LABOR_METRICS_FRAGMENT}
`;

export const MEMBERS_WITH_EQUIPMENT_TIME_ONLY_METRIC_QUERY = gql`
  query QueryMembersWithEquipmentTimeMetricOnly(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $equipmentIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      memberNumber
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        equipmentIds: $equipmentIds
      ) {
        ...BasicLaborMetrics
        equipmentId
      }
    }
  }
  ${BASIC_TIME_METRICS_FRAGMENT}
`;

export const MEMBERS_WITH_PROJECT_METRIC_QUERY = gql`
  query QueryMembersWithProjectMetric(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      memberNumber
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
      ) {
        ...ProjectLaborMetrics
        projectId
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const MEMBERS_WITH_PROJECT_TIME_ONLY_METRIC_QUERY = gql`
  query QueryMembersWithProjectTimeMetric(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      memberNumber
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
      ) {
        ...BasicProjectLaborMetrics
        projectId
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;

export const MEMBERS_WITH_PROJECT_COST_CODE_METRIC_QUERY = gql`
  query QueryMembersWithProjectCostCodeMetric(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
    $costCodeIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      memberNumber
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberProjectCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
        costCodeIds: $costCodeIds
      ) {
        ...ProjectLaborMetrics
        projectId
        costCodeId
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const MEMBERS_WITH_PROJECT_COST_CODE_TIME_ONLY_METRIC_QUERY = gql`
  query QueryMembersWithProjectCostCodeTimeMetric(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
    $costCodeIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      memberNumber
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberProjectCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
        costCodeIds: $costCodeIds
      ) {
        ...BasicProjectLaborMetrics
        projectId
        costCodeId
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;

export const MEMBERS_WITH_PROJECT_EQUIPMENT_METRIC_QUERY = gql`
  query QueryMembersWithProjectEquipmentMetric(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
    $equipmentIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      memberNumber
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberProjectEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
        equipmentIds: $equipmentIds
      ) {
        ...ProjectLaborMetrics
        projectId
        equipmentId
        equipmentCents
        descEquipmentCents
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const MEMBERS_WITH_PROJECT_EQUIPMENT_TIME_ONLY_METRIC_QUERY = gql`
  query QueryMembersWithProjectEquipmentTimeMetric(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
    $equipmentIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      memberNumber
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberProjectEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
        equipmentIds: $equipmentIds
      ) {
        ...BasicProjectLaborMetrics
        projectId
        equipmentId
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;
