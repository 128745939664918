import gql from 'graphql-tag';

// Get all root projects that match the search string
export const projectsPickerQuery = gql`
  query ProjectPicker($filter: ProjectFilter, $first: Int, $after: String) {
    projects(first: $first, after: $after, sort: [{ title: asc }], filter: $filter) {
      id
      title
      cursor
      archivedOn
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
    }
  }
`;

export const projectFlatQuery = gql`
  query ProjectPickerFlat($filter: ProjectFilter, $first: Int, $after: String, $sort: [ProjectSort!]) {
    projects(filter: $filter, first: $first, after: $after, sort: $sort) {
      id
      title
      cursor
      archivedOn
    }
  }
`;

// Get any subprojects that match the search string
export const projectPickerDetails = gql`
  query ProjectPickerDetails($filter: ProjectFilter, $first: Int) {
    projects(first: $first, filter: $filter) {
      id
      title
      depth
      cursor
      archivedOn
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      projectInfo {
        id
        number
      }
    }
  }
`;

export const subProjectSearchQuery = gql`
  query SubprojectSearch($filter: ProjectFilter) {
    projects(first: 10, sort: [{ title: asc }], filter: $filter) {
      id
      title
      depth
      cursor
      archivedOn
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
    }
  }
`;

export const projectQuery = gql`
  query ProjectWithChildrenAncestors($filter: ProjectFilter) {
    projects(filter: $filter) {
      id
      title
      depth
      projectGroupId
      parentProjectId
      archivedOn
      children {
        title
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        projectGroupId
        archivedOn
      }
    }
  }
`;

// Get all of the subprojects for a project
// TODO - There is currently no way to remove archived subprojects from this call.
export const subProjectQuery = gql`
  query GetProjectWithSubProjects($projectId: Uuid, $first: Int, $after: String) {
    projects(filter: { id: { equal: $projectId }, archivedOn: { isNull: true } }, first: $first, after: $after) {
      id
      title
      archivedOn
      ancestors {
        id
      }
      children {
        id
        title
        archivedOn
        ancestors {
          id
          title
        }
        children {
          id
          title
          archivedOn
        }
      }
    }
  }
`;
