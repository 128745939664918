import { Bar, Size } from '@busybusy/webapp-react-ui';
import { stringUtils } from 'utils';
import './SimplePickerRow.scss';
import classNames from 'classnames';

export interface ISimplePickerRowProps {
  value: string;
  searchValue?: string;
}

export default function SimplePickerRow({ value, searchValue }: ISimplePickerRowProps) {
  const classes = classNames('simple-picker-row', 'px-3');

  return (
    <Bar size={Size.SMALL} className={classes}>
      <div className="ellipsis">{searchValue ? stringUtils.highlight(value, searchValue) : value}</div>
    </Bar>
  );
}
