import { useTimeEntryDataTableRow } from 'components/domain/time-entry/TimeEntryDataTable/hooks/useTimeEntryDataTableRow';
import { usePermissions } from 'hooks';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { first, isNil, keyBy } from 'lodash';
import { useCallback } from 'react';
import { useMemberTimeData } from './useMemberTimeData';
import { MemberTimeEntryDataMember } from '../types/types';
import { IPermissionCheckableMember } from 'utils/positionUtils';
import IMemberTimeDocument from 'types/MemberTimeDocument';
import { IMemberTimeEntryReportDataFilter } from './useMemberTimeEntryDataReportData';

export default function useMemberTimeEntryDataRows(filter: IMemberTimeEntryReportDataFilter) {
  const { hasPermissionToManage } = usePermissions();
  const getMemberTimeData = useMemberTimeData(filter);
  const getRows = useTimeEntryDataTableRow();
  const nameFormatter = useEmployeeNameFormatter();

  const memberNameFormatter = useCallback(
    (member: MemberTimeEntryDataMember) => nameFormatter(member.firstName ?? '', member.lastName ?? ''),
    [nameFormatter]
  );

  // TODO: rework `getRows` this seems like an odd requirement vs. just changing the generic type to have member time documents?
  const getSignature = useCallback(
    (member: MemberTimeEntryDataMember) =>
      member.memberTimeDocuments ? (first(member.memberTimeDocuments) as unknown as IMemberTimeDocument) : null,
    []
  );

  return useCallback(
    async (after: string | null, pageSize: number) => {
      const memberData = await getMemberTimeData(after, pageSize);
      const rows = await getRows(filter.timeRange, memberData ?? [], memberNameFormatter, getSignature);

      const keyedMemberData = keyBy(memberData, (memberDatum) => memberDatum.item.id);

      return rows.map((row) => {
        const memberData = keyedMemberData[row.id];

        return {
          ...row,
          // TODO: these permission functions are annoying type wise
          canManage: hasPermissionToManage(
            memberData.item as unknown as IPermissionCheckableMember,
            'manageTimeEntries'
          ),
          canManageTimeOff: hasPermissionToManage(
            memberData.item as unknown as IPermissionCheckableMember,
            'manageTimeOff'
          ),
          isArchived: !isNil(memberData.item.archivedOn),
          cursor: keyedMemberData[row.id]!.cursor!,
        };
      });
    },
    [filter.timeRange, getMemberTimeData, getRows, getSignature, hasPermissionToManage, memberNameFormatter]
  );
}
