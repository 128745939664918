import { useQuery } from '@tanstack/react-query';
import {
  type JobSafetyAnalysis,
  type JsaReportsManageJsaReportDataQuery,
  type JsaReportsManageJsaReportDataQueryVariables,
} from '__generated__/graphql';
import { COST_CODE_DISPLAY_FRAGMENT } from 'apollo/fragments/cost-code-fragments';
import { MEMBER_DISPLAY_FRAGMENT } from 'apollo/fragments/member-fragments';
import { PROJECT_DISPLAY_FRAGMENT } from 'apollo/fragments/project-fragments';
import gql from 'graphql-tag';
import useGraphQLPaging from 'hooks/graphql/useGraphQLPaging/useGraphQLPaging';
import useReactQueryBaseKey from 'hooks/react-query/useReactQueryBaseKey/useReactQueryBaseKey';
import type { NonNullArrayField } from 'types/util/NonNullArrayField';
import { Nullable } from 'types/util/Nullable';
import { getGraphQlEqualComparison } from 'utils/apolloUtils';
import { getJsaReportsManagementQueryConfig } from '../../hooks/getJsaReportsManagementQueryConfig';

export const JSA_REPORTS_MANAGE_JSA_REPORT_DATA = gql`
  query JsaReportsManageJsaReportData($filter: JobSafetyAnalysisFilter) {
    jobSafetyAnalyses(filter: $filter) {
      id
      type
      frequency
      scopeToProject
      clockInReviewEnabled
      taskName
      project {
        id
        ...ProjectDisplay
      }
      costCode {
        id
        costCode
        title
        ...CostCodeDisplay
      }
      finalizedOn
      finalizedByMember {
        id
        ...MemberDisplay
      }
      createdBy
      archivedOn
      deletedOn
      cursor
    }
  }

  ${PROJECT_DISPLAY_FRAGMENT}
  ${COST_CODE_DISPLAY_FRAGMENT}
  ${MEMBER_DISPLAY_FRAGMENT}
`;

export type JsaReportsManageJsaReportData = NonNullArrayField<JsaReportsManageJsaReportDataQuery['jobSafetyAnalyses']>;

export function useManageJsaReportDataRequest(id?: Nullable<JobSafetyAnalysis['id']>) {
  const pageAll = useGraphQLPaging();
  const dataKey = 'jobSafetyAnalyses';

  return () => {
    return pageAll<JsaReportsManageJsaReportDataQuery, JsaReportsManageJsaReportDataQueryVariables>(
      {
        document: JSA_REPORTS_MANAGE_JSA_REPORT_DATA,
        variables: {
          filter: { id: getGraphQlEqualComparison(id) },
        },
      },
      dataKey
    ).then((data) => {
      const result = data?.[0];

      if (!result || !result?.id) {
        throw Error(`${dataKey} request unsuccessful`, { cause: data });
      }

      return result;
    });
  };
}

export const useManageJsaReportDataQuery = (id?: Nullable<JobSafetyAnalysis['id']>) => {
  const queryKey = [...useReactQueryBaseKey(), 'jsa-reports', 'manage-jsa', id];
  const queryFn = useManageJsaReportDataRequest(id);

  return useQuery<JsaReportsManageJsaReportData>({
    ...getJsaReportsManagementQueryConfig(),
    queryKey,
    queryFn,
    enabled: !!id,
  });
};
