import {
  JobSafetyAnalysisFrequency,
  type JobSafetyAnalysis,
  type JobSafetyAnalysisLatestReviewdOnQuery,
  type JobSafetyAnalysisLatestReviewdOnQueryVariables,
  type JobSafetyAnalysisReviewFilter,
} from '__generated__/graphql';
import gql from 'graphql-tag';
import { useActiveMember } from 'hooks';
import useGraphQLClient from 'hooks/graphql/useGraphQLClient/useGraphQLClient';
import useOrganizationOvertimeCurrentDayStart from 'hooks/models/organization-overtime-period/useOrganizationOvertimeCurrentDayStart/useOrganizationOvertimeCurrentDayStart';
import { first, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { DayOfWeekValue } from 'types/enum/DayOfWeek';
import type { NonNullArrayField } from 'types/util/NonNullArrayField';
import { convertDateTimeToIsoForServer, dateTimeFromUtcISO, getStartOfWeek } from 'utils/dateUtils';
import type { TJsaReviewCandidate } from './useDetermineJsaToReview';

const JSA_LATEST_REVIEWED_ON = gql`
  query JobSafetyAnalysisLatestReviewdOn($filter: JobSafetyAnalysisReviewFilter) {
    jobSafetyAnalysisReviews(filter: $filter) {
      id
      reviewedOn
    }
  }
`;

export type TJsaLatestReviewedOnData = JobSafetyAnalysisLatestReviewdOnQuery['jobSafetyAnalysisReviews'];
export type TJsaLatestReviewedOn = NonNullArrayField<TJsaLatestReviewedOnData>;

export type TUseRequestJsaLatestReview = () => (props: {
  id: JobSafetyAnalysis['id'];
  frequency: JobSafetyAnalysisFrequency;
}) => Promise<TJsaLatestReviewedOn | undefined>;

export const useRequestJsaValidReview: TUseRequestJsaLatestReview = () => {
  const client = useGraphQLClient();
  const getLookbackDateBoundary = useGetLookbackDateBoundary();
  const activeMember = useActiveMember();

  return ({ id, frequency }) => {
    const filter: JobSafetyAnalysisReviewFilter = {
      jobSafetyAnalysisId: { equal: id },
      memberId: { equal: activeMember.id },
      isLatest: { equal: true },
      deletedOn: { isNull: true },
    };

    const dateBoundary = getLookbackDateBoundary(frequency);
    if (dateBoundary) {
      filter.reviewedOn = { greaterThanOrEqual: convertDateTimeToIsoForServer(dateBoundary.toUTC()) };
    }

    return client
      .request<JobSafetyAnalysisLatestReviewdOnQuery, JobSafetyAnalysisLatestReviewdOnQueryVariables>({
        document: JSA_LATEST_REVIEWED_ON,
        variables: { filter },
      })
      .then(({ jobSafetyAnalysisReviews }) => first(jobSafetyAnalysisReviews));
  };
};

export const isValidJsaFrequency = (frequency: JobSafetyAnalysisFrequency) =>
  Object.values(JobSafetyAnalysisFrequency).includes(frequency);

export const useDetermineJsaReviewNeeded = () => {
  const checkForValidReview = useRequestJsaValidReview();

  return ({ id, frequency, updatedOn }: TJsaReviewCandidate) => {
    if (isNil(frequency) || !isValidJsaFrequency(frequency)) {
      // invalid frequency, interpret as "never"
      return Promise.resolve(false);
    } else if (frequency === JobSafetyAnalysisFrequency.EveryTime) {
      return Promise.resolve(true);
    }

    return checkForValidReview({ id, frequency }).then((latestReview) => {
      // if there are no valid reviews, the JSA is due for review
      if (!latestReview?.reviewedOn) {
        return true;
      }

      // re-trigger if the JSA has been modified since the last time it was reviewed
      return dateTimeFromUtcISO(updatedOn) > DateTime.fromISO(latestReview.reviewedOn);
    });
  };
};

export const useGetLookbackDateBoundary = (): ((frequency: JobSafetyAnalysisFrequency) => DateTime | null) => {
  const overtimeDayStart = useOrganizationOvertimeCurrentDayStart() ?? DayOfWeekValue.SUNDAY;
  const now = DateTime.local();

  return (frequency) => {
    switch (frequency) {
      case JobSafetyAnalysisFrequency.Daily:
        return now.startOf('day');
      case JobSafetyAnalysisFrequency.Weekly:
        return getStartOfWeek(now, overtimeDayStart);
      case JobSafetyAnalysisFrequency.Monthly:
        return now.startOf('month');
      case JobSafetyAnalysisFrequency.Yearly:
        return now.startOf('year');
      case JobSafetyAnalysisFrequency.OneTime:
        return null;
      default:
        throw new Error(`Invalid frequency`, { cause: { frequency } });
    }
  };
};
