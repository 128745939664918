import { t } from 'i18next';
import { convertDurationToHoursDecimal, secondsToHmmString } from 'utils/dateUtils';
import { isNull } from 'lodash';
import { Organization } from '__generated__/graphql';
import Papa from 'papaparse';
import { Duration } from 'luxon';
import { TimeCardReportMemberData } from '../types/types';

export function convertTimeCardReportRowsToCsv(
  timeCardReportData: TimeCardReportMemberData[],
  organization: Pick<Organization, 'breakPolicy' | 'timeAccuracy' | 'safetySignature'>
): string {
  return Papa.unparse(
    timeCardReportData.flatMap((datum) => {
      return datum.rows.map((row) => {
        const result: any = {
          [t('Date')]: row.date.toFormat('ccc, LLL d'),
          [t('First Name')]: datum.firstName,
          [t('Last Name')]: datum.lastName,
        };

        if (organization.breakPolicy) {
          result[t('Break Policy')] = !isNull(row.breakCompliance) ? (row.breakCompliance ? t('Yes') : t('No')) : '';
        }

        if (organization.timeAccuracy) {
          result[t('Time Accurate')] = !isNull(row.timeAccurate) ? (row.timeAccurate ? t('Yes') : t('No')) : '';
        }

        if (organization.safetySignature) {
          result[t('Injured')] = !isNull(row.injured) ? (row.injured ? t('Yes') : t('No')) : '';
        }

        // If we decide to allow import/reimport we should get rid of the translation
        // due to having to untranslate on reimporting the file.
        return {
          ...result,
          [t('Regular Time')]: secondsToHmmString(row.regularHours ?? 0),
          [t('Regular Time (Decimal)')]: convertDurationToHoursDecimal(
            Duration.fromMillis((row.regularHours ?? 0) * 1000),
            2
          ),
          [t('Overtime')]: secondsToHmmString(row.overtimeHours ?? 0),
          [t('Overtime (Decimal)')]: convertDurationToHoursDecimal(
            Duration.fromMillis((row.overtimeHours ?? 0) * 1000),
            2
          ),
          [t('Double Time')]: secondsToHmmString(row.doubleTimeHours ?? 0),
          [t('Double Time (Decimal)')]: convertDurationToHoursDecimal(
            Duration.fromMillis((row.doubleTimeHours ?? 0) * 1000),
            2
          ),
          [t('Paid Time Off')]: secondsToHmmString(row.paidTimeOff ?? 0),
          [t('Total')]: secondsToHmmString(row.totalHours ?? 0),
          [t('Total (Decimal)')]: convertDurationToHoursDecimal(Duration.fromMillis((row.totalHours ?? 0) * 1000), 2),
        };
      });
    })
  );
}
