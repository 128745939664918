import { Position } from '@busybusy/webapp-react-ui';
import { COST_CODES_QUERY } from 'apollo/queries/cost-code-queries';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { useDebounce, useOrganization } from 'hooks';
import _, { first } from 'lodash';
import { ReactNode, useState } from 'react';
import * as React from 'react';
import { getSafeBoolean } from 'utils/optionalUtils';
import { getCostCodeDisplay } from 'utils/stringUtils';
import SimplePickerRow from 'components/foundation/pickers/SimplePickerRow/SimplePickerRow';
import useReactQueryBaseKey from 'hooks/react-query/useReactQueryBaseKey/useReactQueryBaseKey';
import { useQuery } from '@tanstack/react-query';
import {
  CostCodeSearchFields,
  QueryCostCodesQuery,
  QueryCostCodesQueryVariables,
  SearchType,
} from '__generated__/graphql';
import useGraphQLClient from 'hooks/graphql/useGraphQLClient/useGraphQLClient';
import DropDownPicker from 'components/foundation/pickers/DropDownPicker/DropDownPicker';
import { useReactQueryLazyLoading } from 'hooks/react-query/useReactQueryLazyLoading/useReactQueryLazyLoading';
import { NonNullArrayField } from 'types/util/NonNullArrayField';

export interface ICostCodePickerProps {
  value: string | null;
  onSelect: (costCodeId: string | null) => void;
  considerProjectSpecificCostCodes?: boolean;
  projectId?: string | null;
  minWidth?: string;
  placeholder?: string;
  error?: boolean;
  position?: Position;
  searchArchived?: boolean;
  className?: ClassName;
  dropDownButtonRender?: ReactNode;
  closeButtonRender?: (handleClear: (event: React.KeyboardEvent | React.MouseEvent) => void) => ReactNode;
}

type CostCodePickerCostCode = NonNullArrayField<QueryCostCodesQuery['costCodes']>;

const CostCodePicker = (props: ICostCodePickerProps) => {
  const {
    value,
    onSelect,
    minWidth = '350px',
    error,
    placeholder,
    position,
    projectId,
    searchArchived,
    considerProjectSpecificCostCodes = true,
    closeButtonRender,
    dropDownButtonRender,
    className,
  } = props;

  const organization = useOrganization();

  const [searchValue, setSearchValue] = useState('');
  const searchDebounce = useDebounce(searchValue, 250);

  const graphqlClient = useGraphQLClient();

  const [isOpen, setIsOpen] = useState(false);

  function onClose() {
    setIsOpen(false);
    setSearchValue('');
  }

  function handleSelect(model: CostCodePickerCostCode | null) {
    onSelect(model ? model.id : null);
  }

  const baseQueryKey = useReactQueryBaseKey();

  const { data: selectedCostCode } = useQuery({
    queryKey: [...baseQueryKey, 'cost-code-picker-by-id', value],
    queryFn: async () => {
      if (!value) {
        return null;
      }

      const costCode = _.find(data, (model) => model.id === value);

      if (costCode) {
        return getCostCodeDisplay(costCode);
      } else {
        const costCodeResult = await graphqlClient.request<QueryCostCodesQuery, QueryCostCodesQueryVariables>({
          document: COST_CODES_QUERY,
          variables: {
            filter: { id: { equal: value } },
          },
        });

        const target = first(costCodeResult?.costCodes);
        return getCostCodeDisplay(target) ?? null;
      }
    },
    initialData: null,
    enabled: !!value,
  });

  const variables: QueryCostCodesQueryVariables = {
    filter: {
      archivedOn: searchArchived ? { isNull: false } : { isNull: true },
      search: searchDebounce
        ? {
            type: SearchType.Contains,
            fields: [CostCodeSearchFields.CostCode, CostCodeSearchFields.Title],
            value: searchDebounce,
          }
        : undefined,
      projectCostCodeLink:
        considerProjectSpecificCostCodes && organization.useProjectCostCodeScoping
          ? { projectId: { equal: projectId }, deletedOn: { isNull: true } }
          : undefined,
    },
  };

  async function getCostCodes(after: string | null, first: number): Promise<CostCodePickerCostCode[]> {
    const pagedVariables: QueryCostCodesQueryVariables = {
      after,
      first,
      ...variables,
    };

    if (considerProjectSpecificCostCodes && organization.useProjectCostCodeScoping && !projectId) {
      return [];
    }

    const result = await graphqlClient.request<QueryCostCodesQuery, QueryCostCodesQueryVariables>({
      document: COST_CODES_QUERY,
      variables: pagedVariables,
    });

    return result.costCodes ?? [];
  }

  function renderValueTemplate() {
    if (selectedCostCode) {
      return <>{selectedCostCode}</>;
    }

    return <></>;
  }

  function renderRow(costCode: CostCodePickerCostCode) {
    return <SimplePickerRow value={getCostCodeDisplay(costCode)} searchValue={searchValue} />;
  }

  const scroller = React.useRef<HTMLElement>();

  const {
    data,
    isLoading,
    isError: isDataError,
    loadedAll,
  } = useReactQueryLazyLoading(scroller.current, [...baseQueryKey, 'cost-code-picker', variables], getCostCodes, 10, {
    enabled: isOpen,
  });

  const classes = classNames('cost-code-picker', className);

  if (!organization.trackCostCode) {
    return null;
  }

  return (
    <DropDownPicker
      value={value}
      dataError={isDataError}
      error={error}
      data={data ?? []}
      loadedAll={loadedAll}
      onSelect={handleSelect}
      onClose={onClose}
      onOpen={() => setIsOpen(true)}
      onSearch={setSearchValue}
      searchValue={searchValue}
      valueTemplate={renderValueTemplate()}
      renderRow={renderRow}
      minWidth={minWidth}
      position={position}
      placeholder={placeholder}
      className={classes}
      closeButtonRender={closeButtonRender}
      dropDownButtonRender={dropDownButtonRender}
      setScrollerRef={(scrollerRef) => (scroller.current = scrollerRef)}
      isLoading={isLoading}
      disabled={
        !projectId &&
        getSafeBoolean(organization.useProjectCostCodeScoping) &&
        getSafeBoolean(considerProjectSpecificCostCodes)
      }
    />
  );
};

export default CostCodePicker;
