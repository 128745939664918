import { gql } from '@apollo/client';

export const BASIC_TIME_ENTRIES_QUERY = gql`
  query BasicTimeEntries($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      memberId
      startTime
      endTime
      daylightSavingTime
      metaDaylightSavingTime
      actionType
      description
      createdOn
      deletedOn
      submittedOn
      updatedOn
      cursor
    }
  }
`;

export const SIMPLE_TIME_ENTRIES_QUERY = gql`
  query GetSimpleTimeEntries($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      startTime
      endTime
      daylightSavingTime
      metaDaylightSavingTime
      actionType
      description
      createdOn
      deletedOn
      submittedOn
      updatedOn
      breaks {
        id
        startTime
        startTimeDst
        endTime
        endTimeDst
        createdOn
        deletedOn
        submittedOn
        updatedOn
      }
      cursor
    }
  }
`;

export const TIME_ENTRY_BY_ID_QUERY = gql`
  query GetTimeEntryById($timeEntryId: Uuid!) {
    timeEntries(filter: { id: { equal: $timeEntryId } }) {
      id
      memberId
      member {
        id
        firstName
        lastName
        memberGroupId
        archivedOn
        memberLock {
          id
          effectiveDate
        }
        position {
          id
          level
          timeEvents
          manageTimeEntries
        }
      }
      project {
        id
        title
        depth
        projectInfo {
          id
          latitude
          locationRadius
          longitude
        }
        ancestors {
          id
          title
          depth
          archivedOn
          projectInfo {
            id
            latitude
            locationRadius
            longitude
          }
        }
      }
      costCode {
        id
        costCode
        title
      }
      equipment {
        id
        equipmentName
        model {
          id
          title
          make {
            id
            title
          }
          category {
            id
            title
          }
        }
      }
      startTime
      startDeviceTime
      endTime
      endDeviceTime
      daylightSavingTime
      metaDaylightSavingTime
      actionType
      description
      createdOn
      deletedOn
      submittedOn
      updatedOn
      breaks {
        id
        timeEntry {
          id
          startTime
          daylightSavingTime
          endTime
          metaDaylightSavingTime
        }
        clientLogs {
          id
        }
        startTime
        startDeviceTime
        startTimeTrusted
        startTimeDst
        endTime
        endDeviceTime
        endTimeTrusted
        endTimeDst
        createdOn
        deletedOn
        submittedOn
        updatedOn
        startLocation {
          id
        }
        endLocation {
          id
        }
      }
      hasLocation
      startLocation {
        id
      }
      endLocation {
        id
      }
      clientLogs {
        id
      }
      flaggedTimeEntryLocation {
        id
        status
        locationData
      }
      memberGpsStatuses {
        id
        status
        localTime
      }
    }
  }
`;

export const TIME_ENTRY_LOG_QUERY = gql`
  query GetTimEntryWithLogsById($timeEntryId: Uuid!) {
    timeEntries(filter: { id: { equal: $timeEntryId } }) {
      id
      memberId
      member {
        id
        firstName
        lastName
        memberGroupId
        memberLock {
          id
          effectiveDate
        }
        position {
          id
          level
        }
      }
      startTime
      startDeviceTime
      endTime
      endDeviceTime
      daylightSavingTime
      metaDaylightSavingTime
      actionType
      createdOn
      deletedOn
      submittedOn
      updatedOn
      startLocation {
        id
      }
      endLocation {
        id
      }
      breaks {
        id
        clientLogs {
          id
          startTime
          endTime
          actionType
          deviceType
          createdOn
          startLocationId
          endLocationId
          clientTimeEntryLogId
        }
        startTime
        startDeviceTime
        startTimeTrusted
        startTimeDst
        endTime
        endDeviceTime
        endTimeTrusted
        endTimeDst
        createdOn
        deletedOn
        submittedOn
        updatedOn
        startLocation {
          id
        }
        endLocation {
          id
        }
      }
      clientLogs {
        id
        createdOn
        actionType
        deviceType
        startLocationId
        endLocationId
        startTime
        daylightSavingTime
        endTime
        metaDaylightSavingTime
        projectId
        costCodeId
        equipmentId
        description
        originalTimeEntryId
        updaterMember {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const TIME_ENTRIES_FOR_MEMBER = gql`
  query timeEntryWithMember($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      cursor
      startTime
      endTime
      member {
        id
        firstName
        lastName
        imageUrl
        archivedOn
        position {
          id
          level
        }
      }
    }
  }
`;

export const LOCATION_HISTORY_TIME_ENTRIES_QUERY = gql`
  query GetTimeEntriesWithLocationHistory(
    $after: String
    $filter: TimeEntryFilter
    $first: Int
    $sort: [TimeEntrySort!]
  ) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      memberId
      member {
        id
        firstName
        lastName
      }
      project {
        id
        title
        depth
        parentProject {
          id
          projectInfo {
            id
            latitude
            longitude
            locationRadius
          }
        }
        ancestors {
          id
          title
          depth
          archivedOn
          projectInfo {
            id
            latitude
            longitude
            locationRadius
          }
        }
        projectInfo {
          id
          latitude
          locationRadius
          longitude
          customer
          number
        }
      }
      costCode {
        id
        costCode
        title
      }
      equipment {
        id
        equipmentName
        model {
          id
          title
          make {
            id
            title
          }
          category {
            id
            title
          }
        }
      }
      startTime
      endTime
      startDeviceTime
      daylightSavingTime
      metaDaylightSavingTime
      actionType
      description
      createdOn
      deletedOn
      submittedOn
      updatedOn
      breaks {
        id
        startTime
        startTimeDst
        endTime
        endTimeDst
        createdOn
        deletedOn
        submittedOn
        updatedOn
        startLocation {
          id
        }
        endLocation {
          id
        }
      }
      startLocation {
        id
      }
      endLocation {
        id
      }
      flaggedTimeEntryLocation {
        id
        status
        locationData
      }
      memberGpsStatuses {
        id
        status
        localTime
      }
      cursor
    }
  }
`;

export const LOCATION_HISTORY_TIME_ENTRY_QUERY = gql`
  query GetTimeEntryWithLocationHistory($timeEntryId: Uuid!) {
    timeEntries(filter: { id: { equal: $timeEntryId } }) {
      id
      memberId
      member {
        id
        firstName
        lastName
        memberLock {
          id
          effectiveDate
        }
      }
      project {
        id
        title
        depth
        parentProject {
          id
          projectInfo {
            id
            latitude
            longitude
            locationRadius
          }
        }
        ancestors {
          id
          title
          depth
          archivedOn
          projectInfo {
            id
            latitude
            longitude
            locationRadius
          }
        }
        projectInfo {
          id
          latitude
          locationRadius
          longitude
        }
      }
      costCode {
        id
        costCode
        title
      }
      equipment {
        id
        equipmentName
        model {
          id
          title
          make {
            id
            title
          }
          category {
            id
            title
          }
        }
      }
      startTime
      startDeviceTime
      endTime
      endDeviceTime
      daylightSavingTime
      metaDaylightSavingTime
      actionType
      description
      createdOn
      deletedOn
      submittedOn
      updatedOn
      breaks {
        id
        timeEntry {
          id
          startTime
          daylightSavingTime
          endTime
          metaDaylightSavingTime
        }
        startTime
        startDeviceTime
        startTimeTrusted
        startTimeDst
        endTime
        endDeviceTime
        endTimeTrusted
        endTimeDst
        createdOn
        deletedOn
        submittedOn
        updatedOn
        startLocation {
          id
        }
        endLocation {
          id
        }
      }
      startLocation {
        id
      }
      endLocation {
        id
      }
      flaggedTimeEntryLocation {
        id
        status
        locationData
      }
      memberGpsStatuses {
        id
        status
        localTime
      }
    }
  }
`;

export const TIME_ENTRY_IDS = gql`
  query GetTimeEntriesIds($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      cursor
    }
  }
`;

export const TIME_ENTRIES_QUERY_FRAGMENT = gql`
  fragment TimeEntryDetails on TimeEntry {
    id
    memberId
    projectId
    costCodeId
    equipmentId
    project {
      id
      title
      depth
      rootProjectId
      parentProjectId
      ancestors {
        id
        title
        depth
        archivedOn
        projectInfo {
          id
          customer
          number
          latitude
          longitude
          locationRadius
        }
      }
      projectInfo {
        id
        customer
        number
        latitude
        longitude
        locationRadius
      }
    }
    costCode {
      id
      costCode
      title
      unitTitle
    }
    equipment {
      id
      equipmentName
      model {
        id
        title
        make {
          id
          title
        }
        category {
          id
          title
        }
      }
    }
    startTime
    endTime
    startDeviceTime
    daylightSavingTime
    metaDaylightSavingTime
    actionType
    description
    createdOn
    deletedOn
    submittedOn
    updatedOn
    breaks {
      id
      startTime
      startDeviceTime
      startTimeTrusted
      startTimeDst
      endTime
      endTimeDst
      endDeviceTime
      endTimeTrusted
      createdOn
      deletedOn
      submittedOn
      updatedOn
    }
    hasLocation
    startLocation {
      id
    }
    endLocation {
      id
    }
    clientLogs {
      id
    }
  }
`;

export const TIME_ENTRIES_QUERY = gql`
  query GetTimeEntries($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      member {
        id
        firstName
        lastName
      }
      cursor
      ...TimeEntryDetails
    }
  }
  ${TIME_ENTRIES_QUERY_FRAGMENT}
`;

export const TIME_ENTRIES_QUERY_WITH_GPS_STATUS = gql`
  query GetTimeEntriesWithGps($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      member {
        id
        firstName
        lastName
      }
      cursor
      flaggedTimeEntryLocation {
        id
        status
        locationData
      }
      memberGpsStatuses {
        id
        status
        localTime
      }
      ...TimeEntryDetails
    }
  }
  ${TIME_ENTRIES_QUERY_FRAGMENT}
`;

export const TIME_ENTRIES_WITH_MEMBER_POSITION = gql`
  query QueryTimeEntries($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      __typename
      member {
        id
        firstName
        lastName
        memberNumber
        memberGroupId
        archivedOn
        position {
          id
          level
          timeEvents
          manageTimeEntries
        }
        memberLock {
          id
          effectiveDate
        }
      }
      cursor
      ...TimeEntryDetails
      memberGpsStatuses {
        id
        status
        localTime
      }
    }
  }
  ${TIME_ENTRIES_QUERY_FRAGMENT}
`;

export const SUBPROJECT_QUERY_FRAGMENT = gql`
  fragment SubprojectDetails on TimeEntry {
    project {
      id
      title
      depth
      projectInfo {
        id
        latitude
        longitude
        locationRadius
      }
      ancestors {
        id
        title
        depth
        archivedOn
        projectInfo {
          id
          latitude
          longitude
          locationRadius
        }
        children {
          id
          title
          archivedOn
          depth
          projectInfo {
            id
            latitude
            longitude
            locationRadius
          }
          children {
            id
            title
            archivedOn
            depth
            projectInfo {
              id
              latitude
              longitude
              locationRadius
            }
            children {
              id
              title
              archivedOn
              depth
              projectInfo {
                id
                latitude
                longitude
                locationRadius
              }
              children {
                id
                title
                archivedOn
                depth
                projectInfo {
                  id
                  latitude
                  longitude
                  locationRadius
                }
                children {
                  id
                  title
                  archivedOn
                  depth
                  projectInfo {
                    id
                    latitude
                    longitude
                    locationRadius
                  }
                  children {
                    id
                    title
                    archivedOn
                    depth
                    projectInfo {
                      id
                      latitude
                      longitude
                      locationRadius
                    }
                  }
                }
              }
            }
          }
        }
      }
      children {
        id
        title
        archivedOn
        projectInfo {
          id
          latitude
          longitude
          locationRadius
        }
      }
    }
  }
`;

export const MAP_TIME_ENTRIES = gql`
  query QueryMapTimeEntries($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      memberId
      member {
        id
        firstName
        lastName
        imageUrl
      }
      costCode {
        id
        costCode
        title
      }
      equipment {
        id
        equipmentName
        imageUrl
        lastLocation {
          latitude
          longitude
          id
          utc
        }
        model {
          id
          title
          make {
            id
            title
          }
          category {
            id
            title
          }
        }
      }
      startTime
      breaks {
        id
        startTime
        endTime
        deletedOn
      }
      startLocation {
        id
        latitude
        longitude
        timeLocal
      }
      cursor
      ...SubprojectDetails
    }
  }
  ${SUBPROJECT_QUERY_FRAGMENT}
`;

export const TIME_ENTRY_MEMBER_IDS_QUERY = gql`
  query QueryTimeEntriesMemberIds($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      memberId
      cursor
    }
  }
`;

export const TIME_ENTRY_COST_CODE_IDS_QUERY = gql`
  query QueryTimeEntriesCostCodeIds($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      costCodeId
      cursor
    }
  }
`;

export const TIME_ENTRY_EQUIPMENT_IDS_QUERY = gql`
  query QueryTimeEntriesEquipmentIds($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      equipmentId
      cursor
    }
  }
`;

export const TIME_ENTRIES_WITH_MEMBER_POSITION_AND_GPS_STATUS = gql`
  query QueryTimeEntriesWithMemberPositionAndGPSStatus(
    $after: String
    $filter: TimeEntryFilter
    $first: Int
    $sort: [TimeEntrySort!]
  ) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      __typename
      member {
        id
        firstName
        lastName
        memberNumber
        memberGroupId
        archivedOn
        position {
          id
          level
          timeEvents
          manageTimeEntries
        }
        memberLock {
          id
          effectiveDate
        }
      }
      cursor
      flaggedTimeEntryLocation {
        id
        status
        locationData
      }
      memberGpsStatuses {
        id
        status
        localTime
      }
      ...TimeEntryDetails
    }
  }
  ${TIME_ENTRIES_QUERY_FRAGMENT}
`;

export const OUT_SIDE_PROJECT_TIME_ENTRIES_QUERY = gql`
  query GetTimeEntriesWithProjectLocationHistory(
    $after: String
    $filter: TimeEntryFilter
    $first: Int
    $sort: [TimeEntrySort!]
  ) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      startTime
      endTime
      projectId
      project {
        id
        title
        depth
        parentProject {
          id
          projectInfo {
            id
            latitude
            longitude
            locationRadius
          }
        }
        ancestors {
          id
          title
          depth
        }
        projectInfo {
          latitude
          longitude
          locationRadius
        }
      }
      flaggedTimeEntryLocation {
        id
        organizationId
        timeEntryId
        memberId
        status
        comment
        reviewedByMemberId
        locationData
        edited
        lastStatusUpdatedOn
        createdOn
        submittedOn
        updatedOn
        deletedOn
        member {
          id
          firstName
          lastName
          position {
            id
            level
          }
        }
        reviewedByMember {
          id
          firstName
          lastName
        }
      }
      cursor
    }
  }
`;
