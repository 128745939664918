import { IFeatureFlags } from 'types';

// flags set here override LaunchDarkly
// to initialize launchDarkly flags, see ./launchDarkly
export const production: Partial<IFeatureFlags> = {
  GRAPHQL_BATCH: true,
  KIOSK_REPORT: true,
  SCHEDULING: true,
  MANAGEMENT_LISTS: true,
  MANAGEMENT_LISTS_COST_CODES: true,
  MANAGEMENT_LISTS_EMPLOYEES: true,
  MANAGEMENT_LISTS_PROJECTS: true,
  MANAGEMENT_LISTS_EQUIPMENT: true,
  TIME_ROUNDING: true,
  GROUP_MANAGEMENT: true,
  BREAK_HOURS_REPORT: true,
  TIMESHEETS: true,
  EMPLOYEE_TIMERS: true,
  DASHBOARD: true,
  SETTINGS: true,
  MY_STATUS: true,
  LANDING_PAGES: true,
  SAFETY_SIGN_OFF: true,
  TIME_OFF_REPORT: true,
  MAP_SECTION: true,
  EQUIPMENT_IMPORT: true,
  BULK_PROJECT_COST_CODE_CREATE: true,
  MACHINE_VS_OPERATOR_REPORT: true,
  COMMAND_PALLET: true,
  MANAGEMENT_LISTS_SEARCH: true,
  REACT_BY_DATE_RANGE_ACTIVITY_REPORTS: true,
  SCHEDULE_PRINT: true,
  QUICK_TIME_TEMPLATES: true,
  TIME_LOG_FEED: true,
  PAYROLL_JOURNAL_ENTRIES: true,
  ACTIVITY_REPORTS_PHASE_3000: true,
  MANAGE_PROJECT_MAP: true,
  MANAGE_EQUIPMENT_MAP: true,
  LABOR_BUDGETS: true,
  HOUR_METER_REPORT: true,
  BUDGET_PERFORMANCE: true,
  RECENT_BUDGET_FILTER: true,
  SCHEDULING_DRAFTS: true,
  SCHEDULING_RECURRING: true,
  REQUIRE_EQUIPMENT_POSITION_SETTING: true,
  EMPTY_ROUTE: false,
  BLOCKING_POLICY_POPUP: true,
  AUTOINVITE_NEW_EMPLOYEES: true,
  PHOTOS_VIEW_COLLECTION_PERMISSION: true,
  SAFETY_INCIDENTS_OSHA_FORM_5020: true,
  IFRAME_FEATURES: true,
  USE_KIOSK_PERMISSION: true,
  JSA_REPORTS: true,

  // under development
  CONVERT_SCHEDULE_TO_TIME_ENTRY: false,
  PAYROLL_SUBCONTRACTORS: false,
  PAYROLL_SCAN_BREAK_COMPLIANCE: false,
  SAFETY_SIGN_OFF_EXPORT_FOLLOWUPS: false,
  INTEGRATION_AGAVE_INTEGRATIONS: false,
  INTEGRATION_AGAVE_INTEGRATIONS_QBO: false,
  INTEGRATION_ADP: false,
  INTEGRATION_ADP_LOGIN: false,
  SAFETY_OVERVIEW: false,
  JSA_REPORTS_PARTICIPANTS: false,
};

Object.freeze(production);
