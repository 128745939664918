import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import generatedIntrospection from '__generated__/graphql';
import { useReduxSelector } from 'hooks';
import pLimit from 'p-limit';
import { IStoreEnvironment } from 'store/store';
import { IChildrenProps } from 'types/ChildrenProps';
import { decodeAuthToken } from 'utils/authenticationUtils';
import { useFeatureFlags } from 'utils/features';
import { isType } from 'utils/typeguard';

const ApolloAuthentication = ({ children }: IChildrenProps) => {
  const authentication = useReduxSelector((state) => state.authentication);
  const environment = useReduxSelector((state) => state.environment);
  const GRAPHQL_BATCH = useFeatureFlags('GRAPHQL_BATCH');

  let keyAuthorization;

  if (authentication) {
    const { token } = authentication;

    if (token) {
      keyAuthorization = decodeAuthToken(token).keyAuthorization;
    }
  }

  const httpLink = getHttpLink(environment, GRAPHQL_BATCH);
  const client = createClient(httpLink, keyAuthorization);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

const getHttpLink = (environment: IStoreEnvironment, isBatchingEnabled: boolean) => {
  // Need to support people querying multiple data types hence the bigger than average batch size
  const limited = pLimit(isBatchingEnabled ? 5 : 20);
  const httpLinkOptions: BatchHttpLink.Options = {
    uri: environment.REACT_APP_GRAPHQL_URL,
    fetch: (uri: RequestInfo | URL, config?: RequestInit) => limited(() => fetch(uri, config)),
  };

  return isBatchingEnabled ? new BatchHttpLink({ ...httpLinkOptions, batchMax: 8 }) : new HttpLink(httpLinkOptions);
};

const createClient = (httpLink: HttpLink | BatchHttpLink, keyAuthorization?: string | null) => {
  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    if (keyAuthorization) {
      return {
        headers: {
          ...headers,
          'key-authorization': keyAuthorization,
        },
      };
    } else {
      return { headers };
    }
  });

  const errorLink = onError(({ networkError }) => {
    if (networkError) {
      if (isType(networkError, 'statusCode') && (networkError.statusCode === 401 || networkError.statusCode === 403)) {
        window.location.reload();
      }
    }
  });

  return new ApolloClient({
    cache: new InMemoryCache({ possibleTypes: generatedIntrospection.possibleTypes }),
    link: from([authLink, errorLink, httpLink]),
  });
};

export default ApolloAuthentication;
