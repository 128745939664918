import {
  BASIC_PROJECT_LABOR_METRICS_FRAGMENT,
  BASIC_PROJECT_TIME_METRICS_FRAGMENT,
} from 'apollo/queries/jit-project-queries';
import gql from 'graphql-tag';

export const PROJECT_WITH_TIME_METRIC_AND_SUB_CHECK_QUERY = gql`
  query ProjectWithTimeAndMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $memberId: UuidComparison
    $costCodeId: UuidComparison
    $equipmentId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      memberId: $memberId
      costCodeId: $costCodeId
      equipmentId: $equipmentId
    ) {
      id
      title
      projectInfo {
        number
      }
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...ProjectLaborMetrics
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const PROJECT_WITH_TIME_AND_TIME_METRIC_AND_SUB_CHECK_QUERY = gql`
  query ProjectWithTimeAndTimeMetricAndSubCheck2(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $memberId: UuidComparison
    $costCodeId: UuidComparison
    $equipmentId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      memberId: $memberId
      costCodeId: $costCodeId
      equipmentId: $equipmentId
    ) {
      id
      title
      projectInfo {
        number
      }
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...BasicProjectLaborMetrics
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;

export const PROJECT_WITH_TIME_AND_MEMBER_METRIC_AND_SUB_CHECK_QUERY = gql`
  query ProjectWithTimeAndMemberMetricAndSubCheck3(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $memberIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $memberId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      memberId: $memberId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectMemberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        memberIds: $memberIds
        filter: $metricFilter
      ) {
        ...ProjectLaborMetrics
        memberId
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const PROJECT_WITH_TIME_AND_MEMBER_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY = gql`
  query ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $memberIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $memberId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      memberId: $memberId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectMemberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        memberIds: $memberIds
        filter: $metricFilter
      ) {
        ...BasicProjectLaborMetrics
        memberId
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;

export const PROJECT_WITH_TIME_AND_MEMBER_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY_AND_ANCESTORS = gql`
  query ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $memberIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $memberId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      memberId: $memberId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      ancestors {
        id
        title
        depth
        archivedOn
      }
      children {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectMemberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        memberIds: $memberIds
        filter: $metricFilter
      ) {
        ...BasicProjectLaborMetrics
        memberId
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;

export const PROJECT_WITH_TIME_AND_COST_CODE_METRIC_AND_SUB_CHECK_QUERY = gql`
  query ProjectWithTimeAndCostCodeMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $costCodeIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $costCodeId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      costCodeId: $costCodeId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        costCodeIds: $costCodeIds
        filter: $metricFilter
      ) {
        ...ProjectLaborMetrics
        costCodeId
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const PROJECT_WITH_TIME_AND_COST_CODE_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY = gql`
  query ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $costCodeIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $costCodeId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      costCodeId: $costCodeId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        costCodeIds: $costCodeIds
        filter: $metricFilter
      ) {
        ...BasicProjectLaborMetrics
        costCodeId
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;

export const PROJECT_WITH_TIME_AND_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY = gql`
  query ProjectWithTimeOnlyAndEquipmentMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $equipmentIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $equipmentId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      equipmentId: $equipmentId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        equipmentIds: $equipmentIds
        filter: $metricFilter
      ) {
        ...ProjectLaborMetrics
        projectId
        equipmentId
        equipmentCents
        descEquipmentCents
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const PROJECT_WITH_TIME_AND_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY = gql`
  query ProjectWithTimeAndEquipmentMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $equipmentIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $equipmentId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      equipmentId: $equipmentId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        equipmentIds: $equipmentIds
        filter: $metricFilter
      ) {
        ...BasicProjectLaborMetrics
        equipmentId
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;

export const PROJECT_WITH_TIME_AND_MEMBER_COST_CODE_METRIC_AND_SUB_CHECK_QUERY = gql`
  query ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $memberIds: [Uuid!]
    $costCodeIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $memberId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      memberId: $memberId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectMemberCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        memberIds: $memberIds
        costCodeIds: $costCodeIds
        filter: $metricFilter
      ) {
        ...ProjectLaborMetrics
        memberId
        costCodeId
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const PROJECT_WITH_TIME_AND_MEMBER_COST_CODE_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY = gql`
  query ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $memberIds: [Uuid!]
    $costCodeIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $memberId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      memberId: $memberId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectMemberCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        memberIds: $memberIds
        costCodeIds: $costCodeIds
        filter: $metricFilter
      ) {
        ...BasicProjectLaborMetrics
        memberId
        costCodeId
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;

export const PROJECT_WITH_TIME_AND_MEMBER_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY = gql`
  query ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $memberIds: [Uuid!]
    $equipmentIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $memberId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      memberId: $memberId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectMemberEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        memberIds: $memberIds
        equipmentIds: $equipmentIds
        filter: $metricFilter
      ) {
        ...ProjectLaborMetrics
        memberId
        equipmentId
        equipmentCents
        descEquipmentCents
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const PROJECT_WITH_TIME_AND_MEMBER_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY = gql`
  query ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $memberIds: [Uuid!]
    $equipmentIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $memberId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      memberId: $memberId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectMemberEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        memberIds: $memberIds
        equipmentIds: $equipmentIds
        filter: $metricFilter
      ) {
        ...BasicProjectLaborMetrics
        memberId
        equipmentId
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;

export const PROJECT_WITH_TIME_AND_COST_CODE_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY = gql`
  query ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $equipmentIds: [Uuid!]
    $costCodeIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $costCodeId: UuidComparison
    $equipmentId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      costCodeId: $costCodeId
      equipmentId: $equipmentId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectCostCodeEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        equipmentIds: $equipmentIds
        costCodeIds: $costCodeIds
        filter: $metricFilter
      ) {
        ...ProjectLaborMetrics
        equipmentId
        costCodeId
        equipmentCents
        descEquipmentCents
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const PROJECT_WITH_TIME_AND_COST_CODE_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY = gql`
  query ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $equipmentIds: [Uuid!]
    $costCodeIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $costCodeId: UuidComparison
    $equipmentId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      costCodeId: $costCodeId
      equipmentId: $equipmentId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectCostCodeEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        equipmentIds: $equipmentIds
        costCodeIds: $costCodeIds
        filter: $metricFilter
      ) {
        ...BasicProjectLaborMetrics
        equipmentId
        costCodeId
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;
