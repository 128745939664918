import { FetchResult } from '@apollo/client';
import { OrganizationUpdateInput } from '__generated__/graphql';
import { ORGANIZATION_UPDATE_MUTATION } from 'apollo/mutations/organization-mutations';
import { useApolloUpdate } from 'hooks';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { organizationSetAction } from 'store/store';
import IOrganization from 'types/Organization';
import useOrganization from '../../store/useOrganization';

interface IMutation {
  updateOrganization: IOrganization;
}

type OrganizationSetSettingsTuple = [
  <Key extends keyof OrganizationUpdateInput>(
    key: Key,
    value: OrganizationUpdateInput[Key]
  ) => Promise<FetchResult<IMutation>>,
  (value: Omit<OrganizationUpdateInput, 'id'>) => Promise<FetchResult<IMutation>>,
];

export default function useOrganizationUpdate(): OrganizationSetSettingsTuple {
  const [updateKey, update] = useApolloUpdate<OrganizationUpdateInput, IMutation>(
    ORGANIZATION_UPDATE_MUTATION,
    'organization'
  );
  const organization = useOrganization();
  const dispatch = useDispatch();

  const updateOrganizationKey = useCallback(
    async <Key extends keyof OrganizationUpdateInput>(key: Key, value: OrganizationUpdateInput[Key]) => {
      const result = await updateKey(organization.id!, key, value);
      dispatch(organizationSetAction(result.data!.updateOrganization));
      return result;
    },
    [dispatch, organization.id, updateKey]
  );

  const updateOrganization = useCallback(
    async (value: Omit<OrganizationUpdateInput, 'id'>) => {
      const result = await update({ ...value, id: organization.id! });
      dispatch(organizationSetAction(result.data!.updateOrganization!));
      return result;
    },
    [dispatch, organization.id, update]
  );

  return [updateOrganizationKey, updateOrganization];
}
