import {
  OrganizationSignInQuestion,
  OrganizationSignInQuestionAudience,
  OrganizationSignOffQuestion,
} from '__generated__/graphql';
import { POSITION_TITLE_QUERY } from 'apollo/queries/position-queries';
import { ICreateDailySignInQuestionsFormData } from 'containers/daily-sign-in/CreateDailySignInQuestionsForm/CreateDailySignInQuestionsForm';
import { SignInQuestionResponseType } from 'containers/daily-sign-in/CreateDailySignInQuestionsForm/FormFields/SignInQuestionResponseFormField/SignInQuestionResponseSelect/SignInQuestionResponseSelect';
import {
  ICreateDailySignOffQuestionsFormData,
  SignOffQuestionResponseType,
} from 'containers/daily-sign-off/CreateDailySignOffQuestionForm/CreateDailySignOffQuestionForm';
import { isEmpty, isNil } from 'lodash';
import { IMember } from 'types';
import { IMemberSignInAnswer } from 'types/MemberSignInAnswer';
import IPosition from 'types/Position';
import { t } from './localize';

// enum for choice keys
export enum ChoiceKeys {
  NAME = 'choice',
  FLAG = 'flag',
  FOLLOWUP = 'followup',
  RESTRICT = 'restrict',
  EXPLANATION = 'explanation',
}

export interface IOrganizationSignInQuestionChoice {
  choice: string;
  flag: boolean;
  followup?: string | null;
  restrict: boolean;
  explanation?: string | null;
}

export function buildChoiceJSONString(
  formData: ICreateDailySignInQuestionsFormData | ICreateDailySignOffQuestionsFormData
) {
  const yesObject: { [k: string]: any } = {};

  yesObject[ChoiceKeys.NAME] = 'Yes';
  yesObject[ChoiceKeys.FLAG] =
    formData.yesResponses === SignInQuestionResponseType.FLAG_ALLOW_CLOCK_IN ||
    formData.yesResponses === SignInQuestionResponseType.FLAG_NO_CLOCK_IN ||
    formData.yesResponses === SignOffQuestionResponseType.FLAG_RESPONSE;
  if (formData.yesFollowUpQuestion && !isEmpty(formData.yesFollowUpQuestionText)) {
    yesObject[ChoiceKeys.FOLLOWUP] = formData.yesFollowUpQuestionText;
  }
  yesObject[ChoiceKeys.RESTRICT] = formData.yesResponses === SignInQuestionResponseType.FLAG_NO_CLOCK_IN;
  if (formData.yesExplanation && !isEmpty(formData.yesExplanationText)) {
    yesObject[ChoiceKeys.EXPLANATION] = formData.yesExplanationText;
  }

  const noObject: { [k: string]: any } = {};

  noObject[ChoiceKeys.NAME] = 'No';
  noObject[ChoiceKeys.FLAG] =
    formData.noResponses === SignInQuestionResponseType.FLAG_ALLOW_CLOCK_IN ||
    formData.noResponses === SignInQuestionResponseType.FLAG_NO_CLOCK_IN ||
    formData.noResponses === SignOffQuestionResponseType.FLAG_RESPONSE;
  if (formData.noFollowUpQuestion && !isEmpty(formData.noFollowUpQuestionText)) {
    noObject[ChoiceKeys.FOLLOWUP] = formData.noFollowUpQuestionText;
  }
  noObject[ChoiceKeys.RESTRICT] = formData.noResponses === SignInQuestionResponseType.FLAG_NO_CLOCK_IN;
  if (formData.noExplanation && !isEmpty(formData.noExplanationText)) {
    noObject[ChoiceKeys.EXPLANATION] = formData.noExplanationText;
  }

  const jsonObject = [yesObject, noObject];
  return JSON.stringify(jsonObject);
}

export function buildChoiceObjectFromJSON(jsonString?: string | null): IOrganizationSignInQuestionChoice[] {
  if (!isNil(jsonString)) {
    const jsonObject = JSON.parse(jsonString);
    return jsonObject as IOrganizationSignInQuestionChoice[];
  }
  return [];
}

export function getAudienceType(type: string): OrganizationSignInQuestionAudience {
  switch (type) {
    case 'everyone':
      return OrganizationSignInQuestionAudience.Everyone;
    case 'no_one':
      return OrganizationSignInQuestionAudience.NoOne;
    default:
      return OrganizationSignInQuestionAudience.Position;
  }
}

export async function getAudienceTitle(
  question: OrganizationSignInQuestion | OrganizationSignOffQuestion,
  getPosition: (id: string, query: any) => Promise<IPosition | undefined>
) {
  let audienceTitle: string = question.audienceType ?? 'everyone';
  if (audienceTitle === 'position' && !isNil(question.positionId)) {
    const position = await getPosition(question.positionId, POSITION_TITLE_QUERY);
    audienceTitle = position?.title ?? 'everyone';
  } else if (audienceTitle === 'no_one') {
    audienceTitle = t('No One');
  } else if (audienceTitle === 'everyone') {
    audienceTitle = t('Everyone');
  }
  return audienceTitle;
}

export function memberHasSignInQuestions(member: IMember, questions: OrganizationSignInQuestion[]) {
  if (isNil(member.positionId)) {
    throw Error('Position id nil. Make sure you request the position id when you query for the member');
  }

  const test = questions.some((question) => {
    return (
      question.audienceType === OrganizationSignInQuestionAudience.Everyone ||
      (question.audienceType === OrganizationSignInQuestionAudience.Position &&
        question.positionId === member.positionId)
    );
  });

  return test;
}

export function getAnswersForQuestion(
  answers: IMemberSignInAnswer[],
  question: OrganizationSignInQuestion,
  allQuestions: OrganizationSignInQuestion[]
): IMemberSignInAnswer[] {
  const newAnswers = answers.filter(
    (a) =>
      a.questionTitle === question.questionTitle &&
      a.questionDescription === question.questionDescription &&
      (!isNil(a.audienceType)
        ? (a.audienceType === 'position' && a.positionId === question.positionId) ||
          a.audienceType === question.audienceType
        : true)
  );

  if (isEmpty(newAnswers)) {
    return answers
      .filter((a) => a.questionTitle === question.questionTitle)
      .filter((a) => !hasValidAnswersForQuestion(a, allQuestions));
  } else {
    return newAnswers;
  }
}

export function hasValidAnswersForQuestion(answer: IMemberSignInAnswer, questions: OrganizationSignInQuestion[]) {
  return questions.some(
    (question) =>
      answer.questionTitle === question.questionTitle &&
      answer.questionDescription === question.questionDescription &&
      (!isNil(answer.audienceType)
        ? (answer.audienceType === 'position' && answer.positionId === question.positionId) ||
          answer.audienceType === question.audienceType
        : true)
  );
}

export interface IOrganizationQuestionTitleAndLink {
  title: string;
  links?: string[];
}

export function getQuestionTitleAndLinks(question: OrganizationSignInQuestion): IOrganizationQuestionTitleAndLink {
  const expression =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?/gi;
  const matches = question.questionTitle.match(expression);
  const newTitle = question.questionTitle.replace(expression, '');
  return {
    title: newTitle,
    links: matches?.toString()?.split(' '),
  };
}
