import employee_pin from 'assets/icons/employee_pin.svg';
import classNames from 'classnames';
import _, { groupBy, isEmpty, isEqual } from 'lodash';
import { Component, memo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { AnyObject } from 'types/util/Object';
import { t } from 'utils/localize';
import ILocation, { IClusterGroup } from '../../../../types/Location';
import './GoogleMap.scss';

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    resolveGoogleMapsPromise: (google: any) => void;
    google: any;
  }
}

export interface IGoogleMapProps {
  mapId: string;
  defaultZoom: number;
  options?: google.maps.MapOptions;
  pinLocations: ILocation[];
  className?: string | AnyObject;
  selectedId?: string;
  onWindowOpen?: (selectedId?: string) => void;
  onWindowClose?: () => void;
  onWindowClick?: () => void;
  onTypeChange?: (type: string | google.maps.MapTypeId) => void;
  onPinClick?: (location: ILocation) => void;
  shouldZoomToMarker?: boolean;
  showRoute?: boolean;
}

export const googleMapDefaultOptions = {
  center: { lat: -0.0, lng: 0.0 },
  gestureHandling: 'greedy',
  zoom: 16,
  maxZoom: 20,
} as const;
interface IGoogleMapClusterMarkers {
  byId: {
    [key: string]: google.maps.Marker[];
  };
}

interface IGoogleMapCluster {
  byId: {
    [key: string]: IClusterGroup;
  };
}

export class GoogleMap extends Component<IGoogleMapProps> {
  public static defaultProps = {
    mapId: 'map',
    defaultZoom: 10,
  };

  private googleMapsPromise: Promise<any> | null = null;
  private currentPinIndex: number = -1; // -1 indicates overhead view of all pins.
  private listeners: google.maps.MapsEventListener[] = [];
  private map: google.maps.Map | null = null;
  private markers: google.maps.Marker[] = [];
  private infoWindow: google.maps.InfoWindow | null = null;
  private circles: google.maps.Circle[] = [];
  private clusterMarkers: IGoogleMapClusterMarkers | null = null;
  private clusterGroups: IGoogleMapCluster | null = null;
  private clusters: MarkerClusterer[] = [];
  private firstRender = true;
  private routePaths: google.maps.Polyline[] = [];

  public componentDidMount() {
    const clusterScript = document.createElement('script');
    // tslint:disable-next-line:max-line-length
    clusterScript.src = `https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js`;
    clusterScript.id = 'google-maps-cluster-script';
    clusterScript.async = true;
    document.body.appendChild(clusterScript);
    if (window.google) {
      this.setupMap();
      this.firstRender = false;
    } else {
      this.getGoogleMapPromise().then((google) => {
        this.setupMap();
        this.firstRender = false;
      });
    }
  }

  public componentDidUpdate(props: any, state: any) {
    if (this.map) {
      this.markers.forEach((element) => {
        element.setMap(null);
      });
      this.clusters.forEach((cluster) => {
        cluster.clearMarkers();
        cluster.setMap(null);
      });
      this.clusterMarkers = null;
      this.clusterGroups = null;
      if (this.infoWindow) {
        this.infoWindow.close();
        this.infoWindow = null;
      }
      this.circles.forEach((element) => {
        element.setMap(null);
      });

      if (this.props.pinLocations.length > 1) {
        this.setupMultipleMarkerMap(this.map);
      } else if (this.props.pinLocations.length === 1) {
        this.setupSingleMarkerMap(this.map);
      }
      if (this.props.shouldZoomToMarker) {
        this.zoomToMarkers();
      }
    }
  }

  public componentWillUnmount() {
    _.each(this.listeners, (listener) => listener.remove());
  }

  public render() {
    const { className, mapId } = this.props;

    const classes = classNames('google-map', className);

    return <div className={classes} id={mapId} />;
  }

  public zoomToMarkers() {
    const bounds = new window.google.maps.LatLngBounds();
    if (this.map) {
      this.markers.forEach((element) => {
        bounds.extend(element.getPosition());
      });
      this.map.fitBounds(bounds);
      this.map.panToBounds(bounds);
    }
  }

  private getGoogleMapPromise = () => {
    if (!this.googleMapsPromise) {
      this.googleMapsPromise = new Promise((resolve) => {
        window.resolveGoogleMapsPromise = () => {
          resolve(window.google);
          // delete window.resolveGoogleMapsPromise; // TODO: does this need to be deleted?
        };

        const script = document.createElement('script');
        // tslint:disable-next-line:max-line-length
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&callback=resolveGoogleMapsPromise`;
        script.id = 'google-maps-script';
        script.async = true;
        document.body.appendChild(script);
      });
    }

    return this.googleMapsPromise;
  };

  private handleKeyDown = (map: google.maps.Map, bounds: google.maps.LatLngBounds, event: KeyboardEvent) => {
    // Tab key - 9
    if (event.keyCode === 9) {
      const locations = this.props.pinLocations;
      event.preventDefault();
      if (event.shiftKey) {
        if (this.currentPinIndex === -1) {
          this.currentPinIndex = locations.length - 1;
          this.zoomToPosition(map, locations[this.currentPinIndex]);
        } else {
          this.currentPinIndex--;
          if (this.currentPinIndex !== -1) {
            this.zoomToPosition(map, locations[this.currentPinIndex]);
          } else {
            setTimeout(function () {
              map.fitBounds(bounds);
              map.panToBounds(bounds);
            }, 1);
          }
        }
      } else {
        this.currentPinIndex++;
        if (this.currentPinIndex !== locations.length) {
          this.zoomToPosition(map, locations[this.currentPinIndex]);
        } else {
          this.currentPinIndex = -1;
          setTimeout(function () {
            map.fitBounds(bounds);
            map.panToBounds(bounds);
          }, 1);
        }
      }
    }
  };

  private setupMap() {
    this.map = new window.google.maps.Map(document.getElementById(this.props.mapId), this.props.options);
    if (this.map) {
      if (this.props.options) {
        this.map.setOptions(this.props.options);
      }
      if (this.props.pinLocations.length > 1) {
        this.setupMultipleMarkerMap(this.map);
      } else if (this.props.pinLocations.length === 1) {
        this.setupSingleMarkerMap(this.map);
      }
      this.map.addListener('click', () => {
        if (this.infoWindow) {
          this.infoWindow.close();
        }
        if (this.props.onWindowClose) {
          this.props.onWindowClose();
        }
      });

      this.map.addListener('maptypeid_changed', () => {
        this.props.onTypeChange?.(this.map?.getMapTypeId() ?? 'roadmap');
      });
    }
  }

  private setupMultipleMarkerMap = (map: google.maps.Map) => {
    const bounds = new window.google.maps.LatLngBounds();
    const shouldZoom = this.markers.length === 0;
    const keyDownListener = window.google.maps.event.addDomListener(document, 'keydown', (event: KeyboardEvent) => {
      this.handleKeyDown(map, bounds, event);
    });

    this.listeners.push(keyDownListener);
    this.props.pinLocations.forEach((location, locationIndex) => {
      const marker = this.createMarkerAtLocation(map, location, locationIndex);
      bounds.extend(marker.getPosition());
    });

    if (this.props.showRoute) {
      this.setupRoutes();
    } else {
      this.routePaths.forEach((routePath) => {
        routePath.setMap(null);
      });
    }

    if (this.clusterMarkers && this.clusterGroups) {
      Object.keys(this.clusterMarkers.byId).forEach((c) => {
        // tslint:disable-next-line
        const cluster = new MarkerClusterer(map, this.clusterMarkers?.byId[c], {
          styles: [
            {
              url: this.clusterGroups?.byId[c].icon ?? employee_pin,
              height: 50,
              width: 50,
              textColor: 'white',
              textSize: 13,
              anchorText: [17, 1],
            },
          ],
          imagePath: this.clusterGroups?.byId[c].icon ?? employee_pin,
          gridSize: 50,
        });
        this.clusters.push(cluster);
      });
    }

    if (shouldZoom) {
      setTimeout(function () {
        map.fitBounds(bounds);
        map.panToBounds(bounds);
      }, 1);
    }
  };

  private setupRoutes() {
    const groupedPins = groupBy(this.props.pinLocations, (location) => location.groupId);
    Object.keys(groupedPins).forEach((key) => {
      if (key !== 'undefined') {
        // only show routes for pins with a groupId
        const pins = groupedPins[key];

        const coordinates = pins.map((location) => {
          return { lat: location.lat, lng: location.lng };
        });

        const routePath = new google.maps.Polyline({
          path: coordinates,
          geodesic: true,
          strokeColor: '#7094B1',
          strokeOpacity: 1.0,
          strokeWeight: 5,
        });

        this.routePaths.push(routePath);
      }
    });
    this.routePaths.forEach((routePath) => {
      routePath.setMap(this.map);
    });
  }

  private setupSingleMarkerMap = (map: google.maps.Map) => {
    const bounds = new window.google.maps.LatLngBounds();
    const shouldZoom = this.markers.length === 0 && this.props.shouldZoomToMarker !== false;
    const marker = this.createMarkerAtLocation(map, this.props.pinLocations[0], 0);
    bounds.extend(marker.getPosition());
    map.setCenter(marker.getPosition());
    if (shouldZoom) {
      setTimeout(function () {
        map.fitBounds(bounds);
        map.panToBounds(bounds);
      }, 1);
    }
  };

  private createMarkerAtLocation = (map: google.maps.Map, location: ILocation, locationIndex: number) => {
    const icon = {
      url: location.icon,
      scaledSize: new window.google.maps.Size(49, 64),
      anchor: new window.google.maps.Point(16, 23),
    };
    const marker = new window.google.maps.Marker({
      map,
      position: location,
      icon,
    });
    this.markers.push(marker);

    if (location.clusterGroup?.id) {
      if (this.clusterGroups === null) {
        this.clusterGroups = {
          byId: { [location.clusterGroup.id]: location.clusterGroup },
        };
      } else {
        this.clusterGroups.byId[location.clusterGroup.id] = location.clusterGroup;
      }

      if (this.clusterMarkers?.byId[location.clusterGroup.id]) {
        this.clusterMarkers.byId[location.clusterGroup.id].push(marker);
      } else {
        if (this.clusterMarkers === null) {
          this.clusterMarkers = {
            byId: { [location.clusterGroup.id]: [marker] },
          };
        } else {
          this.clusterMarkers.byId[location.clusterGroup.id] = [marker];
        }
      }
    }

    if (location.infoWindowTitle || location.infoWindowBody) {
      const content = ReactDOMServer.renderToString(
        <div className="infoWindow">
          {location.infoWindowTitle && <h1 className="infoWindowTitle pl-5 pt-4">{location.infoWindowTitle}</h1>}
          {location.distance ? (
            <p className="location-distance pr-5">
              {location.distance} {t('mi')}
            </p>
          ) : (
            <></>
          )}
          <div className="infoWindowBody pt-8 pl-5">{location.infoWindowBody}</div>
        </div>
      );
      if (this.infoWindow === null) {
        this.infoWindow = new window.google.maps.InfoWindow();
      }
      if (
        (this.props.selectedId && location.id && this.props.selectedId === location.id) ||
        (this.props.pinLocations.length === 1 && this.firstRender)
      ) {
        this.firstRender = false;
        this.infoWindow?.setContent(content);
        this.infoWindow?.open(map, marker);
      }

      if (this.infoWindow) {
        window.google.maps.event.addDomListener(this.infoWindow, 'domready', () => {
          if (location.actions && !isEmpty(location.actions)) {
            location.actions.forEach((action) => {
              document.getElementsByClassName(action.className)[0]?.removeEventListener('click', action.action);
              document.getElementsByClassName(action.className)[0]?.addEventListener('click', action.action);
            });
          } else {
            document.getElementsByClassName('infoWindow')[0]?.removeEventListener('click', this.performClick);
            document.getElementsByClassName('infoWindow')[0]?.addEventListener('click', this.performClick);
          }
        });
      }
      marker.addListener('click', () => {
        this.infoWindow?.setContent(content);
        this.infoWindow?.open(map, marker);
        this.props.onWindowOpen?.(location.id);
        this.props.onPinClick?.(location);
      });
      this.infoWindow?.addListener('closeclick', () => {
        this.props.onWindowClose?.();
      });
    }

    if (location.radius) {
      const circle = new window.google.maps.Circle({
        strokeColor: '#7094B1',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#7094B1',
        fillOpacity: 0.35,
        map,
        center: location,
        radius: location.radius ?? 100,
      });
      circle.bindTo('center', marker, 'position');
      this.circles.push(circle);
    }

    const doubleClickListener = window.google.maps.event.addListener(marker, 'dblclick', () =>
      this.handleDoubleClickOnMarker(map, marker, locationIndex)
    );

    this.listeners.push(doubleClickListener);
    return marker;
  };

  private performClick = () => {
    this.props.onWindowClick?.();
  };

  private handleDoubleClickOnMarker = (map: google.maps.Map, marker: google.maps.Marker, locationIndex: number) => {
    this.zoomToPosition(map, {
      lat: marker!.getPosition()!.lat(),
      lng: marker!.getPosition()!.lng(),
    });
    this.currentPinIndex = locationIndex;
  };

  private zoomToPosition = (map: google.maps.Map, latLng: ILocation) => {
    map.panTo(latLng);
    if ((map.getZoom() ?? 0) < this.props.defaultZoom) {
      map.setZoom(this.props.defaultZoom);
    }
  };
}

export default memo(GoogleMap, isEqual);
