import _, { isNil } from 'lodash';
import { DateTime } from 'luxon';
import OvertimePayPeriodType from 'types/enum/OvertimePayPeriodType';
import OvertimeRuleType from 'types/enum/OvertimeRuleType';
import IOrganizationOvertimePeriod from 'types/OrganizationOvertimePeriod';
import { dateTimeFromUtcISO } from './dateUtils';
import { t } from './localize';
import { getTimeRangeForWeek, getWeekRangeForTime } from './timeRangeUtils';
import { getChangeDateString } from './wageUtils';

export function getEffectiveDate(overtime: IOrganizationOvertimePeriod): DateTime {
  const date = dateTimeFromUtcISO(overtime.startTime);
  const weekRange = getTimeRangeForWeek(overtime, date.toSeconds())!;
  return DateTime.fromSeconds(weekRange.startTime).toUTC();
}

export function getEffectiveDateString(overtime: IOrganizationOvertimePeriod): string {
  return getChangeDateString(getEffectiveDate(overtime).toISO()) ?? '';
}

export function getCurrentOrgOvertime(overtimes: IOrganizationOvertimePeriod[]) {
  return _.first(
    _.sortBy(
      overtimes.filter((overtime) => {
        return (
          _.isNil(overtime?.deletedOn) &&
          overtime?.startTime &&
          getEffectiveDate(overtime).startOf('day') <= DateTime.local().startOf('day')
        );
      }),
      ['startTime']
    ).reverse()
  );
}

export function getFutureOvertime(overtimes: IOrganizationOvertimePeriod[]) {
  return _.first(
    overtimes.filter((overtime) => {
      return (
        _.isNil(overtime?.deletedOn) && getEffectiveDate(overtime).startOf('day') > DateTime.local().startOf('day')
      );
    })
  );
}

export function getOrgOvertimePeriodString(overtimes: IOrganizationOvertimePeriod[]) {
  const weekRange = getWeekRangeForTime(overtimes, DateTime.local().toSeconds())!;
  return weekRange.startTime.weekdayLong + ' - ' + weekRange.endTime.weekdayLong;
}

export function getSingleOvertimePeriodString(overtime: IOrganizationOvertimePeriod) {
  const timeRange = getTimeRangeForWeek(overtime, DateTime.local().toSeconds());
  return (
    DateTime.fromSeconds(timeRange.startTime).toUTC().weekdayLong +
    ' - ' +
    DateTime.fromSeconds(timeRange.endTime).toUTC().weekdayLong
  );
}

export function getRuleValue(type: OvertimeRuleType, overtime: IOrganizationOvertimePeriod): string {
  switch (type) {
    case OvertimeRuleType.WEEKLY_OVERTIME:
      return getWeeklyOvertimeString(overtime);
    case OvertimeRuleType.DAILY_OVERTIME:
      return getDailyOvertimeString(overtime);
    case OvertimeRuleType.DAILY_DOUBLE_OVERTIME:
      return getDailyDoubleOString(overtime);
    case OvertimeRuleType.SEVENTH_DAY_OVERTIME:
      return getSeventhOvertimeDayString(overtime);
    case OvertimeRuleType.SEVENTH_DAY_DOUBLE_OVERTIME:
      return getSeventhDayDoubleString(overtime);
    case OvertimeRuleType.SATURDAY_MULTIPLE:
      return getSaturdayMultipleString(overtime);
    case OvertimeRuleType.SUNDAY_MULTIPLE:
      return getSundayMultipleString(overtime);
  }
}

export function getWeeklyOvertimeString(overtime: IOrganizationOvertimePeriod): string {
  if (!isNil(overtime.weeklyOvertime)) {
    return t('after') + ' ' + overtime.weeklyOvertime + ' ' + t('hours');
  }
  return '---';
}

export function getDailyOvertimeString(overtime: IOrganizationOvertimePeriod): string {
  if (!isNil(overtime.dailyOvertime)) {
    return t('after') + ' ' + overtime.dailyOvertime + ' ' + t('hours');
  }
  return '---';
}

export function getDailyDoubleOString(overtime: IOrganizationOvertimePeriod): string {
  if (!isNil(overtime.dailyDoubletime)) {
    return t('after') + ' ' + overtime.dailyDoubletime + ' ' + t('hours');
  }
  return '---';
}

export function getSeventhOvertimeDayString(overtime: IOrganizationOvertimePeriod): string {
  if (!_.isNil(overtime.seventhDayOvertime)) {
    return overtime.seventhDayOvertime ? t('Yes') : '---';
  }
  return '---';
}

export function getSeventhDayDoubleString(overtime: IOrganizationOvertimePeriod): string {
  if (!isNil(overtime.seventhDayDoubletime)) {
    return t('after') + ' ' + overtime.seventhDayDoubletime + ' ' + t('hours');
  }
  return '---';
}

export function getSaturdayMultipleString(overtime: IOrganizationOvertimePeriod): string {
  if (overtime.saturdayMultiplier) {
    return t('wage x') + ' ' + overtime.saturdayMultiplier;
  }
  return '---';
}

export function getSundayMultipleString(overtime: IOrganizationOvertimePeriod): string {
  if (overtime.sundayMultiplier) {
    return t('wage x') + ' ' + overtime.sundayMultiplier;
  }
  return '---';
}

export function getOvertimePreference(overtime: IOrganizationOvertimePeriod): string {
  if (
    overtime.weeklyOvertime === 40 &&
    overtime.dailyOvertime === 8 &&
    overtime.dailyDoubletime === 12 &&
    overtime.seventhDayOvertime &&
    overtime.seventhDayDoubletime === 8 &&
    _.isNil(overtime.saturdayMultiplier) &&
    _.isNil(overtime.sundayMultiplier)
  ) {
    return t('California Settings');
  } else if (
    overtime.weeklyOvertime === 40 &&
    _.isNil(overtime.dailyOvertime) &&
    _.isNil(overtime.dailyDoubletime) &&
    !overtime.seventhDayOvertime &&
    _.isNil(overtime.seventhDayDoubletime) &&
    _.isNil(overtime.saturdayMultiplier) &&
    _.isNil(overtime.sundayMultiplier)
  ) {
    return t('Standard Settings');
  }
  return t('Custom Settings');
}

export function getOvertimePayPeriodType(overtime: IOrganizationOvertimePeriod): OvertimePayPeriodType {
  if (
    overtime.weeklyOvertime === 40 &&
    overtime.dailyOvertime === 8 &&
    overtime.dailyDoubletime === 12 &&
    overtime.seventhDayOvertime &&
    overtime.seventhDayDoubletime === 8 &&
    _.isNil(overtime.saturdayMultiplier) &&
    _.isNil(overtime.sundayMultiplier)
  ) {
    return OvertimePayPeriodType.CALIFORNIA;
  } else if (
    overtime.weeklyOvertime === 40 &&
    _.isNil(overtime.dailyOvertime) &&
    _.isNil(overtime.dailyDoubletime) &&
    !overtime.seventhDayOvertime &&
    _.isNil(overtime.seventhDayDoubletime) &&
    _.isNil(overtime.saturdayMultiplier) &&
    _.isNil(overtime.sundayMultiplier)
  ) {
    return OvertimePayPeriodType.STANDARD;
  }
  return OvertimePayPeriodType.CUSTOM;
}
