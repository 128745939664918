import { MinLayout, PrimaryLayout } from 'components';
import Dashboard from 'containers/dashboard/Dashboard/Dashboard';
import ManageEquipment from 'containers/manage-euipment/ManageEquipment';
import { lazy } from 'react';
import { IFeatureRouteSetup, IRouteSetup } from './Routes';
const NotFound = lazy(() => import('containers/error/NotFound'));

// const Home = lazy(() => import('containers/home/HomeContainer'));
const Login = lazy(() => import('containers/login/Login'));
const Logout = lazy(() => import('containers/logout/Logout'));

const PlatformLogin = lazy(() => import('containers/platform-login/PlatformLogin'));
const Unauthorized = lazy(() => import('containers/unauthorized/Unauthorized'));

const Photos = lazy(() => import('containers/photos/PhotosContainer'));
const PhotoVerificationReport = lazy(() => import('containers/photo-verification-report/PhotoVerificationReport'));
const Timesheets = lazy(() => import('containers/timesheets/Timesheets'));
const ProjectLandingPage = lazy(() => import('containers/landing-pages/project-landing-page/ProjectLandingPage'));
const CostCodeLandingPage = lazy(() => import('containers/landing-pages/cost-code-landing-page/CostCodeLandingPage'));
const EquipmentLandingPage = lazy(() => import('containers/landing-pages/equipment-landing-page/EquipmentLandingPage'));
const EmployeeTimers = lazy(() => import('containers/employee-timers/EmployeeTimers'));
const EmployeeDashboard = lazy(() => import('containers/employee-dashboard/EmployeeDashboard/EmployeeDashboard'));
const Ticket = lazy(() => import('containers/ticket/Ticket'));
const OwnerOnboarding = lazy(() => import('containers/onboarding/owner-onboarding/OwnerOnboardingContainer'));
const Scheduling = lazy(() => import('containers/scheduling/Scheduling'));
const QboDetails = lazy(() => import('containers/qbo/QboDetails'));
const DailyProjectReport = lazy(() => import('containers/daily-project-report/DailyProjectReport'));
const DailyReportPreview = lazy(() => import('containers/daily-project-report/DailyReportPreview/DailyReportPreview'));
const ManageEmployees = lazy(() => import('containers/manage-employees/ManageEmployees'));
const ManageProjects = lazy(() => import('containers/manage-projects/ManageProjects'));
const ManageCostCodes = lazy(() => import('containers/manage-cost-codes/ManageCostCodes'));
const BreakHoursReport = lazy(() => import('containers/break-hours-report/BreakHoursReportRoute'));
const Empty = lazy(() => import('containers/empty/Empty'));
const Settings = lazy(() => import('containers/settings/Settings'));
const DailySignOffReport = lazy(() => import('containers/daily-sign-off/DailySignOffReport/DailySignOffReport'));
const MyStatus = lazy(() => import('containers/my-status/MyStatus'));
const Payroll = lazy(() => import('containers/payroll/Payroll/Payroll'));
const Map = lazy(() => import('containers/map/MapSection'));
const ProjectDashboard = lazy(() => import('containers/project-dashboard/ProjectDashboard/ProjectDashboard'));
const CostCodeDashboard = lazy(() => import('containers/cost-code-dashboard/CostCodeDashboard/CostCodeDashboard'));
const EquipmentDashboard = lazy(() => import('containers/equipment-dashboard/EquipmentDashboard/EquipmentDashboard'));
const ActivityReport = lazy(() => import('containers/activity-reports/ActivityReport'));
const TimeOffReport = lazy(() => import('containers/time-off-report/TimeOffReport/TimeOffReport'));
const Progress = lazy(() => import('containers/progress-report/Progress/Progress'));
const DailySignIn = lazy(() => import('containers/daily-sign-in/DailySignIn/DailySignIn'));
const BudgetsContainer = lazy(() => import('containers/budgets/BudgetsContainer/BudgetsContainer'));
const MachineVsOperatorReport = lazy(() => import('containers/machine-vs-operator-report/MachineVsOperatorReport'));
const BudgetReportDetails = lazy(() => import('containers/budgets/BudgetReportDetails/BudgetReportDetails'));
const HourMeterReport = lazy(() => import('containers/hour-meter-report/HourMeterReport'));
const DocumentsReport = lazy(() => import('containers/documents/DocumentsReport/DocumentsReport'));
const TimeLogFeed = lazy(() => import('containers/time-log-feed/TimeLogFeed/TimeLogFeed'));
const QuickBooksIntegration = lazy(() => import('containers/settings/integrations/vendors/quickbooks/QuickBooks'));
const CompanyCamLandingPage = lazy(
  () => import('containers/settings/integrations/vendors/company-cam/CompanyCamLandingPage/CompanyCamLandingPage')
);
const CompanyCamSetup = lazy(
  () => import('containers/settings/integrations/vendors/company-cam/CompanyCamSetup/CompanyCamSetup')
);
const Bulletin = lazy(() => import('containers/bulletin/Bulletin'));
const AgaveSettings = lazy(() => import('containers/settings/integrations/vendors/agave/AgaveSettings/AgaveSettings'));
const GpsOutsideProjectReport = lazy(() => import('containers/gps-outside-project-report/GpsOutsideProjectReport'));
const PerDiemReport = lazy(() => import('containers/per-diem-report/PerDiemReport'));
const SafetyIncidentsReport = lazy(() => import('containers/safety-incident-reports/SafetyIncidentsReport'));
const GpsDisabledReport = lazy(() => import('containers/gps-disabled/GpsDisabledReport/GpsDisabledReport'));
const SafetyOverview = lazy(() => import('containers/safety-overview/SafetyOverview/SafetyOverview'));
const SafetyInspectionsReport = lazy(() => import('containers/safety-inspection-reports/SafetyInspectionsReport'));
const SafetyObservationsReport = lazy(() => import('containers/safety-observations/SafetyObservationsReport'));
const AgaveIntegrations = lazy(
  () => import('containers/settings/integrations/vendors/agave-integrations/AgaveIntegrationsRoute')
);
const SubprocessorsList = lazy(() => import('containers/legal/subprocessors-list/SubprocessorsList'));
const SafetyTrainingsReport = lazy(() => import('containers/safety-trainings/SafetyTrainingsReport'));
const JsaReports = lazy(() => import('containers/jsa-reports/JsaReports/JsaReports'));
const AdpLandingPageRouting = lazy(
  () => import('containers/settings/integrations/vendors/adp/AdpLandingPageRouting/AdpLandingPageRouting')
);
const AdpCallback = lazy(() => import('containers/login/vendors/adp/AdpCallbackRoute'));

export const LoginRoute: IRouteSetup = {
  Component: Login,
  Layout: MinLayout,
  authenticate: false,
  path: '/login/*',
};

export const PlatformLoginRoute: IRouteSetup = {
  Component: PlatformLogin,
  Layout: MinLayout,
  authenticate: false,
  path: '/platform-login/*',
};

export const UnauthorizedRoute: IRouteSetup = {
  Component: Unauthorized,
  Layout: MinLayout,
  authenticate: false,
  path: '/unauthorized/*',
};

export const OwnerOnboardingRoute: IRouteSetup = {
  Component: OwnerOnboarding,
  Layout: MinLayout,
  authenticate: false,
  path: '/owner-onboarding/*',
};

export const DashboardRoute: IRouteSetup = {
  Component: Dashboard,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['DASHBOARD', 'REACT_MAIN_APP'],
  path: '/',
};

export const PhotosRoute: IRouteSetup = {
  Component: Photos,
  Layout: PrimaryLayout,
  authenticate: true,
  path: '/photos/*',
};

export const PhotoVerificationReportRoute: IFeatureRouteSetup = {
  Component: PhotoVerificationReport,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['KIOSK_REPORT', 'PHOTO_VERIFICATION'],
  path: '/photo-verification-report/*',
};

export const ManageCostCodesRoute: IFeatureRouteSetup = {
  Component: ManageCostCodes,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['MANAGEMENT_LISTS', 'MANAGEMENT_LISTS_COST_CODES'],
  path: '/manage/cost-codes',
};

export const ManageEmployeesRoute: IFeatureRouteSetup = {
  Component: ManageEmployees,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['MANAGEMENT_LISTS', 'MANAGEMENT_LISTS_EMPLOYEES'],
  path: '/manage/employees',
};

export const ManageProjectsRoute: IFeatureRouteSetup = {
  Component: ManageProjects,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['MANAGEMENT_LISTS', 'MANAGEMENT_LISTS_PROJECTS'],
  path: '/manage/projects',
};

export const ManageEquipmentRoute: IFeatureRouteSetup = {
  Component: ManageEquipment,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['MANAGEMENT_LISTS', 'MANAGEMENT_LISTS_EQUIPMENT'],
  path: '/manage/equipment',
};

export const EmployeesRoute: IFeatureRouteSetup = {
  Component: EmployeeTimers,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['EMPLOYEE_TIMERS', 'REACT_MAIN_APP'],
  path: '/employees',
};

export const ProjectsRoute: IFeatureRouteSetup = {
  Component: ProjectLandingPage,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['LANDING_PAGES'],
  path: '/projects',
};

export const ProjectDashboardRoute: IFeatureRouteSetup = {
  Component: ProjectDashboard,
  Layout: MinLayout,
  authenticate: true,
  featureFlags: ['LANDING_PAGES'],
  path: '/project-dashboard',
};

export const CostCodeDashboardRoute: IFeatureRouteSetup = {
  Component: CostCodeDashboard,
  Layout: MinLayout,
  authenticate: true,
  featureFlags: ['LANDING_PAGES'],
  path: '/cost-code-dashboard',
};

export const CostCodesRoute: IFeatureRouteSetup = {
  Component: CostCodeLandingPage,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['LANDING_PAGES'],
  path: '/cost-codes',
};

export const EquipmentRoute: IFeatureRouteSetup = {
  Component: EquipmentLandingPage,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['LANDING_PAGES'],
  path: '/equipment',
};

export const EquipmentDashboardRoute: IFeatureRouteSetup = {
  Component: EquipmentDashboard,
  Layout: MinLayout,
  authenticate: true,
  featureFlags: ['LANDING_PAGES'],
  path: '/equipment-dashboard',
};

export const ActivityReportRoute: IFeatureRouteSetup = {
  Component: ActivityReport,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['REACT_ACTIVITY_REPORTS'],
  path: '/activity-report',
};

export const EmployeesDashboardRoute: IFeatureRouteSetup = {
  Component: EmployeeDashboard,
  Layout: MinLayout,
  authenticate: true,
  featureFlags: ['EMPLOYEE_TIMERS', 'REACT_MAIN_APP'],
  path: '/employee-dashboard/*',
};

export const SchedulingRoute: IRouteSetup = {
  Component: Scheduling,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['SCHEDULING'],
  path: '/scheduling/*',
};

export const TimesheetsRoute: IRouteSetup = {
  Component: Timesheets,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['TIMESHEETS', 'REACT_MAIN_APP'],
  path: '/time-cards/*',
};

export const QboRoute: IRouteSetup = {
  Component: QboDetails,
  Layout: MinLayout,
  authenticate: false,
  path: '/qbo/*',
};

export const LogoutRoute: IRouteSetup = {
  Component: Logout,
  Layout: MinLayout,
  authenticate: false,
  path: '/logout/*',
};

export const TicketRoute: IRouteSetup = {
  Component: Ticket,
  Layout: MinLayout,
  authenticate: false,
  path: '/ticket/*',
};

export const DailyReportRoute: IRouteSetup = {
  Component: DailyProjectReport,
  Layout: PrimaryLayout,
  authenticate: true,
  path: '/daily-report',
};

export const DailyReportPreviewRoute: IRouteSetup = {
  Component: DailyReportPreview,
  Layout: PrimaryLayout,
  authenticate: true,
  path: '/daily-report-preview/*',
};

export const SettingsRoute: IFeatureRouteSetup = {
  Component: Settings,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['SETTINGS', 'REACT_MAIN_APP'],
  path: '/settings/*',
  permissions: 'manageCompanySettings',
};

export const BreakHoursReportRoute: IFeatureRouteSetup = {
  Component: BreakHoursReport,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: 'BREAK_HOURS_REPORT',
  path: '/break-hours-report',
};

export const MyStatusReport: IFeatureRouteSetup = {
  Component: MyStatus,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['EMPLOYEE_TIMERS', 'MY_STATUS', 'REACT_MAIN_APP'],
  path: '/my-status',
};

export const MapRoute: IRouteSetup = {
  Component: Map,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['REACT_MAIN_APP'],
  path: '/map',
};

export const NotFoundRoute: IRouteSetup = {
  Component: NotFound,
  Layout: PrimaryLayout,
  authenticate: true,
  path: '*',
};

export const EmptyRoute: IRouteSetup = {
  Component: Empty,
  Layout: PrimaryLayout,
  authenticate: false,
  featureFlags: 'EMPTY_ROUTE',
  path: '/empty',
};

export const SignOffRoute: IRouteSetup = {
  Component: DailySignOffReport,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['SAFETY_SIGN_OFF'],
  path: '/daily-sign-off-report/*',
};

export const PayrollRoute: IRouteSetup = {
  Component: Payroll,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['REACT_PAYROLL'],
  path: '/payroll/*',
};

export const TimeOffReportRoute: IRouteSetup = {
  Component: TimeOffReport,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['TIME_OFF_REPORT'],
  path: '/time-off-report/*',
};

export const ProgressRoute: IRouteSetup = {
  Component: Progress,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['WORK_ACCOMPLISHED'],
  path: '/progress-report/*',
};

export const DailySignInRoute: IRouteSetup = {
  Component: DailySignIn,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['DAILY_SIGN_IN_QUESTIONS'],
  path: '/daily-sign-in/*',
};

export const BudgetRoute: IRouteSetup = {
  Component: BudgetsContainer,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['LABOR_BUDGETS'],
  path: '/budgets/*',
};

export const MachineVsOperatorReportRoute: IRouteSetup = {
  Component: MachineVsOperatorReport,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['MACHINE_VS_OPERATOR_REPORT'],
  path: '/machine-vs-operator-report',
};

export const BudgetReportDetailsRoute: IFeatureRouteSetup = {
  Component: BudgetReportDetails,
  Layout: MinLayout,
  authenticate: true,
  featureFlags: ['LABOR_BUDGETS'],
  path: '/budget-report-details',
};

export const HourMeterReportRoute: IFeatureRouteSetup = {
  Component: HourMeterReport,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['HOUR_METER_REPORT'],
  path: '/hour-meter-report',
};

export const DocumentReportRoute: IRouteSetup = {
  Component: DocumentsReport,
  Layout: PrimaryLayout,
  authenticate: true,
  path: '/documents',
};

export const TimeLogFeedRoute: IFeatureRouteSetup = {
  Component: TimeLogFeed,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['TIME_LOG_FEED'],
  path: '/event-logs',
};

export const QuickBooksIntegrationRoute: IFeatureRouteSetup = {
  Component: QuickBooksIntegration,
  Layout: MinLayout,
  authenticate: true,
  featureFlags: ['REACT_SETTINGS_INTEGRATION_QUICKBOOKS'],
  path: '/settings/integrations/quickbooks/*',
};

export const CompanyCamLandingPageRoute: IFeatureRouteSetup = {
  Component: CompanyCamLandingPage,
  Layout: MinLayout,
  authenticate: true,
  featureFlags: ['COMPANY_CAM_INTEGRATION'],
  path: '/settings/integrations/company-cam',
};

export const CompanyCamSetupRoute: IFeatureRouteSetup = {
  Component: CompanyCamSetup,
  Layout: MinLayout,
  authenticate: true,
  featureFlags: ['COMPANY_CAM_INTEGRATION'],
  path: '/settings/integrations/company-cam/setup',
};

export const BulletinRoute: IFeatureRouteSetup = {
  Component: Bulletin,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['BULLETIN_FEATURE'],
  path: '/bulletin',
};

export const AgaveSettingsRoute: IFeatureRouteSetup = {
  Component: AgaveSettings,
  Layout: MinLayout,
  authenticate: true,
  featureFlags: ['AGAVE_INTEGRATIONS'],
  path: '/settings/integrations/agave',
};

export const AdpIntegrationRoute: IFeatureRouteSetup = {
  Component: AdpLandingPageRouting,
  Layout: MinLayout,
  authenticate: true,
  featureFlags: ['INTEGRATION_ADP'],
  path: '/settings/integrations/adp/*',
};

export const GpsOutsideProjectReportRoute: IRouteSetup = {
  Component: GpsOutsideProjectReport,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['LOCATION_FLAGGING'],
  path: '/flagged-location-report',
};

export const SafetyOverviewRoute: IFeatureRouteSetup = {
  Component: SafetyOverview,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['SAFETY_OVERVIEW'],
  path: 'safety/overview',
};

export const SafetyIncidentsReportRoute: IFeatureRouteSetup = {
  Component: SafetyIncidentsReport,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['SAFETY_INCIDENTS'],
  path: 'safety/incidents',
};

export const SafetyInspectionsReportRoute: IFeatureRouteSetup = {
  Component: SafetyInspectionsReport,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['SAFETY_INSPECTIONS'],
  path: 'safety/inspections',
};

export const SafetyTrainingsReportRoute: IFeatureRouteSetup = {
  Component: SafetyTrainingsReport,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['SAFETY_TRAINING'],
  path: 'safety/trainings',
};

export const SafetyObservationsReportRoute: IFeatureRouteSetup = {
  Component: SafetyObservationsReport,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['SAFETY_OBSERVATIONS'],
  path: 'safety/observations',
};

export const PerDiemReportRoute: IFeatureRouteSetup = {
  Component: PerDiemReport,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['PER_DIEM'],
  path: '/per-diem-report',
};

export const GpsDisabledReportRoute: IRouteSetup = {
  Component: GpsDisabledReport,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['GPS_STATUS_DISABLED'],
  path: '/gps-disabled-report',
};

export const AgaveIntegrationsRoute: IFeatureRouteSetup = {
  Component: AgaveIntegrations,
  Layout: MinLayout,
  authenticate: true,
  featureFlags: ['INTEGRATION_AGAVE_INTEGRATIONS'],
  path: '/settings/integrations/agave-integrations/*',
};

export const SubprocessorsListRoute: IRouteSetup = {
  Component: SubprocessorsList,
  Layout: MinLayout,
  authenticate: true,
  path: '/legal/subprocessors-list',
};

export const JsaReportsRoute: IRouteSetup = {
  Component: JsaReports,
  Layout: PrimaryLayout,
  authenticate: true,
  featureFlags: ['JSA_REPORTS', 'JOB_SAFETY_ANALYSIS'],
  permissions: 'createSafetyAnalysisReports',
  path: '/safety/jsa-reports',
};

export const AdpCallbackRoute: IRouteSetup = {
  Component: AdpCallback,
  Layout: MinLayout,
  authenticate: false,
  featureFlags: ['INTEGRATION_ADP', 'INTEGRATION_ADP_LOGIN'],
  path: '/adp/callback',
};

export const routeConfigs: IRouteSetup[] = [
  // HomeRoute,
  LoginRoute,
  PlatformLoginRoute,
  UnauthorizedRoute,
  OwnerOnboardingRoute,
  DashboardRoute,
  PhotosRoute,
  PhotoVerificationReportRoute,
  ManageEmployeesRoute,
  ManageProjectsRoute,
  ManageEquipmentRoute,
  ManageCostCodesRoute,
  SchedulingRoute,
  TimesheetsRoute,
  ProjectsRoute,
  CostCodesRoute,
  EquipmentRoute,
  EquipmentDashboardRoute,
  ProjectDashboardRoute,
  CostCodeDashboardRoute,
  EmployeesRoute,
  EmployeesDashboardRoute,
  LogoutRoute,
  TicketRoute,
  QboRoute,
  DailyReportRoute,
  DailyReportPreviewRoute,
  BreakHoursReportRoute,
  SignOffRoute,
  SettingsRoute,
  MyStatusReport,
  PayrollRoute,
  MapRoute,
  ActivityReportRoute,
  TimeOffReportRoute,
  ProgressRoute,
  DailySignInRoute,
  BudgetRoute,
  MachineVsOperatorReportRoute,
  BudgetReportDetailsRoute,
  HourMeterReportRoute,
  DocumentReportRoute,
  TimeLogFeedRoute,
  QuickBooksIntegrationRoute,
  CompanyCamLandingPageRoute,
  CompanyCamSetupRoute,
  BulletinRoute,
  AgaveSettingsRoute,
  GpsOutsideProjectReportRoute,
  PerDiemReportRoute,
  SafetyOverviewRoute,
  SafetyIncidentsReportRoute,
  GpsDisabledReportRoute,
  SafetyInspectionsReportRoute,
  SafetyTrainingsReportRoute,
  SafetyObservationsReportRoute,
  AgaveIntegrationsRoute,
  SubprocessorsListRoute,
  SubprocessorsListRoute,
  AgaveIntegrationsRoute,
  JsaReportsRoute,
  AdpCallbackRoute,
  AdpIntegrationRoute,

  EmptyRoute,

  // NotFound must be the last route since it uses the '*' catchall
  NotFoundRoute,
];
