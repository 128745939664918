import { Button, Col, Justify, Row } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { ClassName } from 'types/ClassName';
import { t } from 'utils/localize';

export interface IFilterSidePanelProps {
  onApply: () => void;
  onClear: () => void;
  appliable: boolean;
  clearable: boolean;
  children: ReactNode;
  buttonsClassName?: ClassName;
  className?: ClassName;
}

function SidePanelFilter({
  children,
  className,
  appliable,
  clearable,
  buttonsClassName,
  onApply,
  onClear,
}: IFilterSidePanelProps) {
  const classes = classNames('side-panel-filter', className);

  return (
    <div className={classes}>
      {children}
      <div className={classNames('side-panel-filter-buttons', buttonsClassName)}>
        <Row className="pt-1">
          <Col>
            <Button
              type={appliable ? 'primary' : 'secondary'}
              disabled={!appliable}
              fullWidth={true}
              className={classNames({ 'ac-pulse': appliable })}
              onClick={onApply}
            >
              {t('Apply')}
            </Button>
          </Col>
        </Row>
        {clearable && (
          <Row className="pt-2" justify={Justify.CENTER}>
            <Button type="link" onClick={onClear}>
              {t('Reset')}
            </Button>
          </Row>
        )}
      </div>
    </div>
  );
}

export default SidePanelFilter;
