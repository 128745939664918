enum OvertimeRuleType {
  WEEKLY_OVERTIME = 'weekly_overtime',
  DAILY_OVERTIME = 'daily_overtime',
  DAILY_DOUBLE_OVERTIME = 'daily_double_overtime',
  SEVENTH_DAY_OVERTIME = 'seventh_day_overtime',
  SEVENTH_DAY_DOUBLE_OVERTIME = 'seventh_day_double_overtime',
  SATURDAY_MULTIPLE = 'saturday_multiple',
  SUNDAY_MULTIPLE = 'sunday_multiple',
}

export default OvertimeRuleType;
