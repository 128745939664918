import { Button, Icon, ITableColumn, Label, Row, Size, SortDirection, Table, Tray } from '@busybusy/webapp-react-ui';
import { TableCellHeight } from '@busybusy/webapp-react-ui/dist/components/Table/types/types';
import { Project } from '__generated__/graphql';
import { ClockIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { EmptyState } from 'components';
import ContainerWithSpinner from 'components/foundation/ContainerWithSpinner/ContainerWithSpinner';
import { ActivityReportType } from 'containers/activity-reports/ActivityReportFilter/ActivityReportFilter';
import useActivityReportDetailColumn from 'containers/activity-reports/hooks/useActivityReportDetailColumn';
import useActivityReportDetailTable from 'containers/activity-reports/hooks/useActivityReportDetailTable';
import { IProjectActivityTableRowInfo } from 'containers/activity-reports/project-tables/hooks/useProjectActivity';
import { flattenProjects } from 'containers/activity-reports/utils/ActivityReportUtils';
import { isNil } from 'lodash';
import * as React from 'react';
import { FunctionComponent, useMemo } from 'react';
import TimeRangeType from 'types/TimeRangeType';
import { t } from 'utils/localize';
import { generatedHasActiveSubProjectsWithTime } from 'utils/projectUtils';
import './ProjectActivityTable.scss';
import FormattedProjectName from 'components/domain/project/FormattedProjectName/FormattedProjectName';

export interface IProjectActivityLoadingData<T extends IProjectActivityTableRowInfo> {
  loadData: () => Promise<void>;
  sortedData: T[];
  onSort: ({ sortDir, sortBy }: any) => void;
  sortedBy: keyof T | undefined;
  sortDirection: SortDirection | undefined;
  sortIsDirty: boolean;
  isLoading: boolean;
  isProjectOpen: (projectId: string) => boolean;
  toggleSubProject: (project: Project) => void;
  remainingData?: T;
  getExportDataWithSubprojects: () => Promise<T[]>;
}

export interface IProjectActivityTableProps {
  className?: ClassName;
  loadingData: IProjectActivityLoadingData<IProjectActivityTableRowInfo>;
  secondaryReportType?: ActivityReportType;
  dateRangeReportType?: TimeRangeType;
  scroller: HTMLElement | null;
  onRowSelected: (row: IProjectActivityTableRowInfo) => void;
  showTotalFooter: boolean;
  setExportFunction?: (exportFunction: () => object[]) => void;
  lazyLoadingEnabled: boolean;
  rowHeight?: TableCellHeight;
}

export const ProjectActivityTable: FunctionComponent<IProjectActivityTableProps> = (props) => {
  const {
    className,
    loadingData,
    secondaryReportType: optionalSecondaryReportType,
    dateRangeReportType,
    scroller,
    onRowSelected,
    showTotalFooter,
    lazyLoadingEnabled,
    rowHeight,
  } = props;
  const classes = classNames('project-activity mt-5', className);
  const secondaryReportType = optionalSecondaryReportType ?? ActivityReportType.BASIC;

  const { sortedData, onSort, sortedBy, sortDirection, sortIsDirty, isLoading, isProjectOpen, toggleSubProject } =
    loadingData;

  const data = useMemo(() => {
    if (loadingData.remainingData) {
      return sortedData.concat(loadingData.remainingData);
    }
    return sortedData;
  }, [sortedData, loadingData.remainingData]);

  const { aggregateColumns } = useActivityReportDetailTable(
    data,
    secondaryReportType,
    ActivityReportType.BY_PROJECT,
    300,
    'report-cell'
  );

  const { detailColumn, isShowingPartialDateRange, isDetailRow } = useActivityReportDetailColumn(
    secondaryReportType,
    dateRangeReportType
  );

  const [showingPartialDateRange, setShowingPartialDateRange] = React.useState(false);
  const flattenedData = useMemo(() => flattenProjects(data), [data]);

  const partialMessage = useMemo<string | null>(() => {
    if (showingPartialDateRange) {
      switch (dateRangeReportType) {
        case TimeRangeType.MONTHLY:
          return t('* Indicates a partial month');
        case TimeRangeType.PAY_PERIOD:
          return t('* Indicates a partial pay period');
        default:
          return t('* Indicates a partial week');
      }
    }

    return null;
  }, [showingPartialDateRange, dateRangeReportType]);

  React.useEffect(() => {
    if (!isNil(isShowingPartialDateRange)) {
      setShowingPartialDateRange(isShowingPartialDateRange(flattenedData));
    }
  }, [flattenedData]);

  const renderProject = (row: IProjectActivityTableRowInfo) => {
    if (secondaryReportType !== ActivityReportType.BASIC && isDetailRow(row)) {
      return null;
    } else if (row.project) {
      if (generatedHasActiveSubProjectsWithTime(row.project)) {
        return (
          <>
            <Row className="no-print">
              <Button
                className="project-leaf-btn"
                onClick={(event) => onSubprojectToggleClicked(row, event)}
                type="secondary"
                size={Size.SMALL}
              >
                {isProjectOpen(row.project.id) ? '-' : '+'}
              </Button>
              {row.project.projectInfo?.number ? (
                <FormattedProjectName
                  className={'pl-2'}
                  title={row.project.title}
                  number={row.project.projectInfo?.number}
                />
              ) : (
                <Label className="pl-2 project-name">{row.project.title}</Label>
              )}
            </Row>

            <Row className="print">
              <Label className={projectTitleClassForPrinting(row.project)}>
                {row.project.projectInfo?.number ? (
                  <FormattedProjectName
                    className={'pl-2'}
                    title={row.project.title}
                    number={row.project.projectInfo?.number}
                  />
                ) : (
                  <span className="project-name">{row.project.title}</span>
                )}
              </Label>
            </Row>
          </>
        );
      }

      return (
        <>
          <Row className="report-cell no-print">
            <Tray>
              <Label className={'pl-3 project-name'}>{'- '}</Label>
              {row.project.projectInfo?.number ? (
                <FormattedProjectName
                  className={'pl-4'}
                  title={row.project.title}
                  number={row.project.projectInfo?.number}
                />
              ) : (
                <Label className="pl-2 project-name">{row.project.title}</Label>
              )}
            </Tray>
          </Row>

          <Row className="print">
            <Label className={projectTitleClassForPrinting(row.project)}>
              {row.project.projectInfo?.number ? (
                <FormattedProjectName
                  className={'pl-2'}
                  title={row.project.title}
                  number={row.project.projectInfo?.number}
                />
              ) : (
                <span className="project-name">{row.project.title}</span>
              )}
            </Label>
          </Row>
        </>
      );
    }

    return (
      <Row>
        <Label className="no-project project-name">{t('No Project')}</Label>
      </Row>
    );
  };

  function projectTitleClassForPrinting(project: Project) {
    switch (project.depth) {
      case 2:
        return 'pl-2';
      case 3:
        return 'pl-4';
      case 4:
        return 'pl-6';
      case 5:
        return 'pl-8';
      case 6:
        return 'pl-9';
      case 7:
        return 'pl-10';
      default:
        return '';
    }
  }

  const onSubprojectToggleClicked = (row: IProjectActivityTableRowInfo, event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    toggleSubProject(row.project!);
  };

  function getColumns(): Array<ITableColumn<IProjectActivityTableRowInfo>> {
    const allColumns: Array<ITableColumn<IProjectActivityTableRowInfo>> = [
      {
        key: 'project',
        title: t('Project'),
        sort: true,
        cell: renderProject,
        size: '400px',
        cellClassName: 'title-cell project-name-column',
        headerClassName: 'pl-6 project-name-column',
        footerClassName: 'pl-6 project-name-column',
        textWrap: true,
        footer: renderTotalColumnFooter,
      },
      ...detailColumn,
      ...aggregateColumns,
    ];

    return allColumns;
  }

  const renderTotalColumnFooter = () => <Label className="cell-title">{t('Totals')}</Label>;

  function getRowClassName(rowItem: IProjectActivityTableRowInfo) {
    switch (rowItem.project?.depth ?? 1) {
      case 1:
        return secondaryReportType === ActivityReportType.BASIC || isDetailRow(rowItem)
          ? 'level1Background'
          : 'level1SummaryBackground';
      case 2:
        return secondaryReportType === ActivityReportType.BASIC || isDetailRow(rowItem)
          ? 'level2Background'
          : 'level2SummaryBackground';
      case 3:
        return secondaryReportType === ActivityReportType.BASIC || isDetailRow(rowItem)
          ? 'level3Background'
          : 'level3SummaryBackground';
      case 4:
        return secondaryReportType === ActivityReportType.BASIC || isDetailRow(rowItem)
          ? 'level4Background'
          : 'level4SummaryBackground';
      case 5:
        return secondaryReportType === ActivityReportType.BASIC || isDetailRow(rowItem)
          ? 'level5Background'
          : 'level5SummaryBackground';
      case 6:
        return secondaryReportType === ActivityReportType.BASIC || isDetailRow(rowItem)
          ? 'level6Background'
          : 'level6SummaryBackground';
      case 7:
        return secondaryReportType === ActivityReportType.BASIC || isDetailRow(rowItem)
          ? 'level7Background'
          : 'level7SummaryBackground';
      default:
        return secondaryReportType === ActivityReportType.BASIC || isDetailRow(rowItem)
          ? 'level1Background'
          : 'level1SummaryBackground';
    }
  }

  const tableFooterClassName = useMemo(() => {
    return secondaryReportType === ActivityReportType.BASIC ? 'detailRowBackground' : 'detailTotalRowBackground';
  }, [secondaryReportType]);

  function renderEmptyState() {
    return <EmptyState graphic={<Icon svg={ClockIcon} size={180} />} title={t('No Project Activity')} />;
  }

  return (
    <ContainerWithSpinner className={classes} isLoading={isLoading}>
      {partialMessage && <div className="ml-6 mb-2">{partialMessage}</div>}
      <Table
        className={'project-activity-table'}
        cols={getColumns()}
        minWidth={`${getColumns().length * 140 + 260}px`}
        isLoading={isLoading}
        data={flattenedData}
        sortDir={sortDirection}
        sortBy={sortedBy}
        strokeCols={true}
        onSort={onSort}
        sortIsDirty={sortIsDirty}
        header="standard"
        footer={showTotalFooter ? 'standard' : 'none'}
        lazyLoad={lazyLoadingEnabled}
        lazyScrollSectionSize={50}
        lazyScrollTrigger={scroller ?? undefined}
        scroller={scroller ?? undefined}
        onRowClick={onRowSelected}
        emptyTemplate={renderEmptyState()}
        getCustomRowClassName={getRowClassName}
        footerClassName={tableFooterClassName}
        cellHeight={rowHeight ?? 'standard'}
      />
    </ContainerWithSpinner>
  );
};
