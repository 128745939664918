import { FetchResult } from '@apollo/client';
import { ORGANIZATION_INFO_UPDATE_MUTATION } from 'apollo/mutations/organization-info-mutations';
import { useApolloUpdate } from 'hooks';
import { useDispatch } from 'react-redux';
import { IOrganizationInfo, IOrganizationInfoUpdateInput } from 'types/OrganizationInfo';
import useOrganization from '../../store/useOrganization';

interface IMutationResult {
  updateOrganizationInfo: IOrganizationInfo;
}

type OrganizationSetSettingsTuple = [
  <Key extends keyof IOrganizationInfoUpdateInput>(
    key: Key,
    value: IOrganizationInfoUpdateInput[Key]
  ) => Promise<FetchResult<IMutationResult>>, // Changed to FetchResult
  (value: Omit<IOrganizationInfoUpdateInput, 'id'>) => Promise<FetchResult<IMutationResult>>, // Changed to FetchResult
];

export default function useOrganizationInfoUpdate(): OrganizationSetSettingsTuple {
  const [updateKey, update] = useApolloUpdate<IOrganizationInfoUpdateInput, IMutationResult>(
    ORGANIZATION_INFO_UPDATE_MUTATION,
    'organizationInfo'
  );

  const organization = useOrganization();
  const organizationInfo = organization.organizationInfo!;

  const dispatch = useDispatch();

  const updateOrganizationInfo = async (value: Omit<IOrganizationInfoUpdateInput, 'id'>) => {
    const result = await update({ ...value, id: organizationInfo.id });
    dispatch({
      type: 'ORGANIZATION_SET',
      payload: { ...organization, organizationInfo: result.data!.updateOrganizationInfo },
    });
    return result;
  };

  const updateOrganizationInfoKey = async <Key extends keyof IOrganizationInfoUpdateInput>(
    key: Key,
    value: IOrganizationInfoUpdateInput[Key]
  ) => {
    const result = await updateKey(organizationInfo.id, key, value);
    dispatch({
      type: 'ORGANIZATION_SET',
      payload: { ...organization, organizationInfo: result.data!.updateOrganizationInfo },
    });
    return result;
  };

  return [updateOrganizationInfoKey, updateOrganizationInfo];
}
