import {
  JobSafetyAnalysisType,
  type JobSafetyAnalysisFilter,
  type JsaReviewCandidatesQuery,
  type JsaReviewCandidatesQueryVariables,
} from '__generated__/graphql';
import gql from 'graphql-tag';
import useGraphQLClient from 'hooks/graphql/useGraphQLClient/useGraphQLClient';
import { compact, filter, flatten, last, map, maxBy, sortBy } from 'lodash';
import type ICostCode from 'types/CostCode';
import type IProject from 'types/Project';
import type { NonNullArrayField } from 'types/util/NonNullArrayField';
import type { Nullable } from 'types/util/Nullable';

const JSA_REVIEW_CANDIDATES = gql`
  query JsaReviewCandidates($filter: JobSafetyAnalysisFilter) {
    jobSafetyAnalyses(filter: $filter) {
      id
      frequency
      scopeToProject
      createdOn
      updatedOn
      project {
        id
        title
        depth
      }
    }
  }
`;

export type TJsaReviewCandidatesData = JsaReviewCandidatesQuery['jobSafetyAnalyses'];
export type TJsaReviewCandidate = NonNullArrayField<TJsaReviewCandidatesData>;
export type UseRequestJsaCandidates = () => (
  costCodeId: ICostCode['id'],
  projectId?: Nullable<IProject['id']>
) => Promise<TJsaReviewCandidatesData>;
export const useRequestJsaCandidates: UseRequestJsaCandidates = () => {
  const client = useGraphQLClient();
  return (costCodeId, projectId) => {
    const baseFilter: JobSafetyAnalysisFilter = {
      type: { equal: JobSafetyAnalysisType.CostCode },
      costCodeId: { equal: costCodeId },
      clockInReviewEnabled: { equal: true },
      finalizedOn: { isNull: false },
      archivedOn: { isNull: true },
      deletedOn: { isNull: true },
    };

    const unscopedFilter: JobSafetyAnalysisFilter = {
      ...baseFilter,
      scopeToProject: { equalOrNull: false },
    };

    const scopedFilter: JobSafetyAnalysisFilter = {
      ...baseFilter,
      project: { idWithAncestors: { equal: projectId } },
    };

    // always request JSA's not scoped to a project
    const requests = [
      () =>
        client.request<JsaReviewCandidatesQuery, JsaReviewCandidatesQueryVariables>({
          document: JSA_REVIEW_CANDIDATES,
          variables: { filter: unscopedFilter },
        }),
    ];

    if (projectId) {
      // get JSA's' scoped to this project or any ancestor project
      requests.push(() =>
        client.request<JsaReviewCandidatesQuery, JsaReviewCandidatesQueryVariables>({
          document: JSA_REVIEW_CANDIDATES,
          variables: { filter: scopedFilter },
        })
      );
    }

    return Promise.all(requests.map((fn) => fn())).then((results) => {
      const jobSafetyAnalyses = compact(flatten(map(results, 'jobSafetyAnalyses')));
      return jobSafetyAnalyses;
    });
  };
};

export interface IDetermineJsaToReviewProps {
  costCodeId?: Nullable<ICostCode['id']>;
  projectId?: Nullable<IProject['id']>;
}
export type TUseDetermineJsaToReview = () => (
  props: IDetermineJsaToReviewProps
) => Promise<TJsaReviewCandidate | undefined>;

export const useDetermineJsaToReview: TUseDetermineJsaToReview = () => {
  const requestRelatedJsaToReview = useRequestJsaCandidates();

  return ({ costCodeId, projectId }) => {
    if (!costCodeId) {
      return Promise.resolve(undefined);
    }

    return requestRelatedJsaToReview(costCodeId, projectId).then((jsaResults) => {
      if (!jsaResults?.length) {
        return undefined;
      }

      if (projectId) {
        const projectScopedJsa = findProjectScopedJsa(jsaResults);
        if (projectScopedJsa) {
          return projectScopedJsa;
        }
      }

      const unscopedJsa = findUnscopedJsa(jsaResults);
      if (unscopedJsa) {
        return unscopedJsa;
      }

      return undefined;
    });
  };
};

const findProjectScopedJsa = (jsaResults: TJsaReviewCandidate[]): TJsaReviewCandidate | undefined => {
  const projectScopedJsaResults = filter(jsaResults, { scopeToProject: true });

  if (projectScopedJsaResults.length) {
    // only self and ancestors are in the collection, so the nearest project is the one with the max depth
    // it is theoretically possible to have multiple matches so we match using a filter and get the most recent
    const maxDepth = maxBy(projectScopedJsaResults, 'project.depth')?.project?.depth;
    const deepestProjects = projectScopedJsaResults.filter((jsa) => jsa?.project?.depth === maxDepth);

    const sorted = sortBy(deepestProjects, 'createdOn');
    const result = last(sorted); // get the most recently created JSA

    return result;
  }

  return undefined;
};

const findUnscopedJsa = (jsaResults: TJsaReviewCandidate[]): TJsaReviewCandidate | undefined => {
  const unscopedJsaResults = filter(jsaResults, (jsa) => !jsa.scopeToProject);
  const sorted = sortBy(unscopedJsaResults, 'createdOn');
  const result = last(sorted); // get the most recently created JSA

  return result;
};
