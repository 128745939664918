import { Field, FieldFooter, IFormFieldDefaultProps } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import EmployeeMultiPicker, {
  IEmployeeMultiPickerProps,
} from 'components/domain/member/EmployeeMultiPicker/EmployeeMultiPicker';
import { AnyObject } from 'types/util/Object';

export interface IEmployeeMultiPickerFormFieldProps<FormType extends AnyObject>
  extends Omit<IEmployeeMultiPickerProps, 'values' | 'onChange'>,
    IFormFieldDefaultProps<string[], FormType> {
  onChange?: (values: string[]) => void;
}

function EmployeeMultiPickerFormField<FormType extends AnyObject>({
  form,
  name,
  validations,
  onChange,
  className,
  revalidateOnNewValidations,
  ...multiPickerProps
}: IEmployeeMultiPickerFormFieldProps<FormType>) {
  function renderField({ ...fieldProps }) {
    function handleOnChange(value: string[]) {
      fieldProps.handleChange(value, onChange);
    }

    const currentValue = form.state.data[name];

    return (
      <>
        <EmployeeMultiPicker
          {...multiPickerProps}
          values={currentValue}
          error={fieldProps.hasError}
          onChange={handleOnChange}
        />
        <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
      </>
    );
  }

  const classes = classNames('employee-multi-picker-form-field', className);

  return (
    <Field
      className={classes}
      form={form}
      name={name}
      validations={validations}
      render={renderField}
      revalidateOnNewValidations={revalidateOnNewValidations}
    />
  );
}

EmployeeMultiPickerFormField.defaultProps = {
  filterAlreadySelectedByBlacklist: true,
};

export default EmployeeMultiPickerFormField;
