import { Field, FieldFooter } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import EquipmentCategoryForTypePicker, {
  IEquipmentCategoryPickerProps,
} from 'components/domain/equipment/EquipmentCategoriesForTypePicker/EquipmentCategoriesForTypePicker';

interface IEquipmentCategoryFormFieldProps extends Omit<IEquipmentCategoryPickerProps, 'value' | 'onSelect'> {
  form: any;
  equipmentTypeId: string | null | undefined;
  name: string;
  onChange?: (value: string | null) => void;
  validations?: any;
}

function EquipmentCategoryFormField(props: IEquipmentCategoryFormFieldProps) {
  const { form, name, equipmentTypeId, validations, className, onChange, ...other } = props;

  const classes = classNames('equipment-category-picker-form-field', className);

  function renderField({ ...fieldProps }) {
    function handleOnSelect(val: string | null) {
      fieldProps.handleChange(val, onChange);
    }

    return (
      <>
        <EquipmentCategoryForTypePicker
          error={fieldProps.hasError}
          value={form.state.data[name]}
          onSelect={handleOnSelect}
          equipmentTypeId={equipmentTypeId}
          {...other}
        />
        <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
      </>
    );
  }

  return <Field form={form} name={name} validations={validations} className={classes} render={renderField} />;
}

export default EquipmentCategoryFormField;
