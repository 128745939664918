import { ClassName, ListItem } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ReactNode } from 'react';
import './PickerRow.scss';

export interface IPickerRow {
  focused: boolean;
  onClick: (event: React.MouseEvent) => void;
  children: ReactNode;
  className?: ClassName;
}

export default function PickerRow({ focused, children, onClick }: IPickerRow) {
  const classes = classNames('picker-row', { focused: focused });

  return (
    <ListItem className={classes} onClick={onClick}>
      {children}
    </ListItem>
  );
}
