import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { Align, Bar, Button, Divider, Icon, Position, Row, Size, Tooltip } from '@busybusy/webapp-react-ui';
import type { CostCode } from '__generated__/graphql';
import classNames from 'classnames';
import { t } from 'i18next';
import _, { isEmpty, isNil, keyBy, sortBy } from 'lodash';
import * as React from 'react';
import { Component, ReactNode } from 'react';
import { ClassName } from 'types/ClassName';
import SearchType from 'types/enum/SearchType';
import { Optional } from 'utility-types';
import { getGraphQlContainsComparison, getGraphQlDoesNotContainComparison } from 'utils/apolloUtils';
import { convertEmptyToNull, highlight } from 'utils/stringUtils';
import { logError } from 'utils/testUtils';
import { LazyLoadPickerList, LazyLoadSearchablePickerList } from '../../..';
import { ArrowBackIcon, ChevronRightIcon } from '../../../../assets/icons';
import IProject from '../../../../types/Project';
import { projectUtils } from '../../../../utils';
import Picker from '../../../foundation/pickers/Picker/Picker';
import { projectFlatQuery, projectPickerDetails, projectQuery, subProjectQuery } from './project-picker-queries';
import './ProjectPicker.scss';

export interface IProjectPickerProps {
  value: string | null;
  error?: boolean;
  onSelect: (projectId: string | null) => void;
  placeholder?: string;
  className?: ClassName;
  position?: Position;
  client?: any;
  dropDownButtonRender?: ReactNode; // Drop down button on the picker
  closeButtonRender?: (handleClear: (event: React.KeyboardEvent | React.MouseEvent) => void) => ReactNode; // Close button render on picker
  fetchPolicy?: string;
  searchArchivedProjects?: boolean;
  blacklistedIds?: string[];
  topLevelOnly?: boolean;
  scopeToCostCodeIds?: Array<CostCode['id']>;
  disabled?: boolean;
  onChange?: (value: string | null) => void;
}

interface IState {
  project: IProject | null;
  searchValue: string;
  layers: IProject[];
  projectData: IProject[];
  subProjectData: IProject[];
  projectError: boolean;
  loadedAllProjects: boolean;
  loadedAllSubProjects: boolean;
  focusStartId?: string | null;
}

export const PROJECT_PICKER_SECTION_SIZE = 500;

class ProjectPicker extends Component<WithApolloClient<IProjectPickerProps>, IState> {
  public state: IState = {
    focusStartId: null,
    layers: [],
    loadedAllProjects: false,
    loadedAllSubProjects: false,
    project: null, // The project object of the picker's value (projectId)
    projectData: [],
    projectError: false,
    searchValue: '',
    subProjectData: [],
  };
  public sectionSize = PROJECT_PICKER_SECTION_SIZE;
  public tooltipDelay = 750;
  public dividerIndex?: number | null;
  public picker?: HTMLElement; // ref
  public searchDebounce?: () => void; // used for debouncing api calls on search

  public componentDidMount() {
    this.setProject();
  }

  public componentDidUpdate(prevProps: IProjectPickerProps, prevState: IState) {
    if (prevState.searchValue !== this.state.searchValue) {
      // Load search results if searchValue has changed
      if (!this.searchDebounce) {
        this.searchDebounce = this.searchDebounce || _.debounce(this.clearProjectData, 200);
      }
      this.searchDebounce();
    }

    if (prevProps.value !== this.props.value) {
      this.setProject();
    }

    if (prevState.layers !== this.state.layers) {
      this.setState({
        loadedAllProjects: false,
        loadedAllSubProjects: false,
        projectData: [],
        subProjectData: [],
      });
    }

    if (prevProps.searchArchivedProjects !== this.props.searchArchivedProjects) {
      this.setState({
        loadedAllProjects: false,
        loadedAllSubProjects: false,
        projectData: [],
        subProjectData: [],
      });
    }
  }

  public clearProjectData() {
    this.setState({
      loadedAllProjects: false,
      projectData: [],
    });
  }

  private getProjectsWithSearch = async (after?: string) => {
    const searchedProjects = await this.props.client.query({
      query: projectFlatQuery,
      variables: {
        after,
        first: this.sectionSize,
        filter: {
          id: getGraphQlDoesNotContainComparison(this.props.blacklistedIds),
          archivedOn: this.props.searchArchivedProjects ? { isNull: false } : { isNull: true },
          search: { type: SearchType.CONTAINS, value: this.state.searchValue, fields: ['title', 'number'] },
          parentProjectId: this.props.topLevelOnly ? { isNull: true } : undefined,
          projectCostCodeLink: this.props.scopeToCostCodeIds
            ? { costCodeId: getGraphQlContainsComparison(this.props.scopeToCostCodeIds) }
            : undefined,
        },
      },
    });

    const projectIds = searchedProjects.data.projects.map((project: IProject) => project.id);

    if (isEmpty(projectIds)) {
      return [];
    }

    const projectDetails = await this.props.client.query({
      query: projectPickerDetails,
      fetchPolicy: this.props.fetchPolicy ?? 'network-only',
      variables: {
        filter: { id: getGraphQlContainsComparison(projectIds) },
        first: this.sectionSize,
      },
    });

    // Not lazy loading so we can sort client side for some performance gains
    // Something with having the joined data along with a sort and a search makes it slow on graphql
    return sortBy(projectDetails.data?.projects, ({ depth, title }) => [depth !== 1, title?.toLocaleLowerCase()]);
  };

  private getProjectsWithoutSearch = async (after?: string) => {
    const result = await this.props.client.query({
      query: projectFlatQuery,
      fetchPolicy: this.props.fetchPolicy ?? 'network-only',
      variables: {
        after,
        first: this.sectionSize,
        filter: {
          id: getGraphQlDoesNotContainComparison(this.props.blacklistedIds),
          archivedOn: this.props.searchArchivedProjects ? { isNull: false } : { isNull: true },
          parentProjectId: { isNull: true },
          projectCostCodeLink: this.props.scopeToCostCodeIds
            ? { costCodeId: getGraphQlContainsComparison(this.props.scopeToCostCodeIds) }
            : undefined,
        },
        sort: [{ title: 'asc' }],
      },
    });

    const groupedProjects = keyBy(result.data.projects, 'id');
    const projectIds = Object.keys(groupedProjects);

    if (isEmpty(projectIds)) {
      return [];
    }

    const projectDetails = await this.props.client.query({
      query: projectPickerDetails,
      fetchPolicy: this.props.fetchPolicy ?? 'network-only',
      variables: {
        first: this.sectionSize,
        filter: { id: getGraphQlContainsComparison(projectIds) },
      },
    });

    const withPreviousCursor = projectDetails.data?.projects.map((project: IProject) => {
      return { ...project, cursor: (groupedProjects[project.id] as Optional<IProject>)?.cursor };
    });

    return sortBy(withPreviousCursor, ({ title }) => title?.toLocaleLowerCase());
  };

  private calculateDividerIndex = (projects: IProject[]) => {
    const firstSubprojectIndex = projects.findIndex((project: IProject) => project.depth !== 1);
    return firstSubprojectIndex ? firstSubprojectIndex - 1 : null;
  };

  // Load the search screen
  public loadSearchSheet = async (after?: string): Promise<IProject[]> => {
    try {
      const projects = await (this.state.searchValue
        ? this.getProjectsWithSearch(after)
        : this.getProjectsWithoutSearch(after));

      this.dividerIndex = this.state.searchValue ? this.calculateDividerIndex(projects) : null;

      return projects;
    } catch (error) {
      logError(error);
      throw error;
    }
  };

  public loadSubProjectSheet = (after?: string): Promise<IProject[]> => {
    return new Promise((resolve, reject) => {
      return this.props.client
        .query({
          query: subProjectQuery,
          variables: {
            after,
            first: this.sectionSize,
            projectId: this.state.layers[this.state.layers.length - 1].id,
          },
        })
        .then((result: object) => {
          const subprojects = this.getSubProjectsFromApolloData(result);

          resolve(
            subprojects.filter((item: IProject) => {
              return item.archivedOn === null;
            })
          );
        })
        .catch((error: Error) => {
          logError(error);
          return reject(error);
        });
    });
  };

  public setProject = () => {
    const { value } = this.props;
    if (value) {
      return this.props.client
        .query({
          query: projectQuery,
          fetchPolicy: this.props.fetchPolicy || 'cache-first',
          variables: {
            filter: {
              id: { equal: value },
            },
          },
        })
        .then((result: any) => {
          const project = result?.data?.projects?.[0];
          if (project) {
            this.setState({
              focusStartId: project.id,
              project,
            });
          }
        })
        .catch((error: Error) => {
          logError(error);
          return Promise.reject(error);
        });
    } else {
      this.setState({
        focusStartId: null,
        project: null,
      });
    }
  };

  public renderRow = (row: IProject, index: number) => {
    if (
      this.state.searchValue &&
      row.ancestors &&
      row.ancestors.length &&
      !this.state.layers.length // only show on searchable list
    ) {
      return this.renderSubSearchRow(row);
    } else {
      return this.renderBasicRow(row, index);
    }
  };

  public hasActiveSubProjects = (row: IProject) => {
    return (
      row.children &&
      row.children.length > 0 &&
      !this.props.topLevelOnly &&
      row.children.some((item: IProject) => {
        return item.archivedOn === null;
      })
    );
  };

  // Row without project path
  public renderBasicRow = (row: IProject, index: number) => {
    const handleChevronClick = (e: React.MouseEvent) => {
      this.handleChevronClick(row, e);
    };

    return (
      <>
        <Bar size={Size.SMALL} className="px-3">
          <div className="picker-row-title ellipsis">
            {highlight(row.title, this.state.searchValue)}
            {row.projectInfo?.number &&
              !isNil(convertEmptyToNull(this.state.searchValue)) &&
              row.projectInfo.number.toLowerCase().includes(this.state.searchValue) && (
                <>
                  <br />
                  <div className="f-sm fc-2 ellipsis">
                    <b>{t('Proj #: ')}</b>
                    {highlight(row.projectInfo.number, this.state.searchValue)}
                  </div>
                </>
              )}
          </div>

          {this.hasActiveSubProjects(row) && (
            <div className="subproject-btn">
              <Button type="icon" onClick={handleChevronClick}>
                <Icon svg={ChevronRightIcon} />
              </Button>
            </div>
          )}
        </Bar>
        {this.state.layers.length === 0 && this.dividerIndex === index && <Divider className="my-3" />}
      </>
    );
  };

  // Render row with project path
  public renderSubSearchRow = (project: IProject) => {
    const handleChevronClick = (e: React.MouseEvent) => {
      this.handleChevronClick(project, e);
    };

    return (
      <Bar key={project.id} className="px-3 subproject-search-row" size={Size.LARGE}>
        <div>
          <div className="picker-row-title ellipsis">
            {highlight(project.title, this.state.searchValue)}{' '}
            {project.projectInfo?.number &&
              !isNil(convertEmptyToNull(this.state.searchValue)) &&
              project.projectInfo.number.toLowerCase().includes(this.state.searchValue) && (
                <>
                  <br />
                  <div className="f-sm fc-2 ellipsis">
                    <b>{t('Proj #: ')}</b>
                    {highlight(project.projectInfo.number, this.state.searchValue)}
                  </div>
                </>
              )}
          </div>
          <div className="f-sm fc-2 ellipsis">{projectUtils.getFormattedPathFromProject(project)}</div>
        </div>
        {this.hasActiveSubProjects(project) && (
          <div className="subproject-btn">
            <Button type="icon" ripple={false} onClick={handleChevronClick}>
              <Icon svg={ChevronRightIcon} />
            </Button>
          </div>
        )}
      </Bar>
    );
  };

  // Navigate to subprojects sheet
  public drillIn = (project: IProject, _e: React.KeyboardEvent | React.MouseEvent) => {
    if (project && project.children && project.children.length) {
      setTimeout(() => {
        this.setState((prevState: IState) => {
          return {
            focusStartId: null,
            layers: [...prevState.layers, project],
            loadedAllProjects: false,
            loadedAllSubProjects: false,
            projectData: [],
            subProjectData: [],
          };
        });
      });
    }
  };

  // Navigate back one level
  public goBack = () => {
    const layers = [...this.state.layers];
    const pop = layers.pop();

    if (pop) {
      setTimeout(() => {
        this.setState({
          focusStartId: pop.id,
          layers,
          loadedAllProjects: false,
          loadedAllSubProjects: false,
          projectData: [],
          subProjectData: [],
        });
      });
    }
  };

  public handleClear = () => {
    this.setState({
      focusStartId: null,
      layers: [],
      searchValue: '',
    });
  };

  public handleOnOpen = () => {
    const { project } = this.state;

    if (project) {
      this.setState(
        {
          // configure the picker using the existing selected project
          layers: _.sortBy(project.ancestors, 'depth'),
          searchValue: '',
        },
        () => {
          if (project.ancestors && project.ancestors.length) {
            this.loadSubProjectSheet();
          } else {
            this.loadSearchSheet();
          }
        }
      );
    } else {
      this.setState(
        {
          // Reset search and what screen is showing
          layers: [],
          searchValue: '',
        },
        () => {
          this.loadSearchSheet();
        }
      );
    }
  };

  public handleSearchInputChange = (value: string) => {
    if (value !== this.state.searchValue) {
      this.setState({ searchValue: value });
    }
  };

  public handleSelect = (project: IProject | null) => {
    this.props.onSelect(project ? project.id : null);

    setTimeout(() => {
      if (this.picker) {
        this.picker.focus();
      }
    });
  };

  public handleChevronClick = (project: IProject, e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();
    this.drillIn(project, e);
  };

  public buildValueTemplate = () => {
    const { project } = this.state;

    if (project) {
      const label = projectUtils.getFormattedPathFromProject(project, true);

      if (label) {
        return (
          <Tooltip label={label} delay={this.tooltipDelay}>
            <span>{label}</span>
          </Tooltip>
        );
      }
    }

    return <></>;
  };

  // Render the header for non-searchable lists
  public renderSecondaryHeader = () => (
    <Row className="picker-header px-3" align={Align.CENTER}>
      <Button size={Size.SMALL} type="icon" onClick={this.goBack} className="mr-2">
        <Icon svg={ArrowBackIcon} />
      </Button>
      <div>
        <h5 className="picker-title fw-bold">{this.state.layers[this.state.layers.length - 1].title}</h5>
        <div className="f-sm fc-2">
          {this.state.layers.map((layer, index) => (
            <React.Fragment key={index}>
              {index !== this.state.layers.length - 1 && (
                <span>
                  {layer.title} {index !== this.state.layers.length - 2 && '/'}{' '}
                </span>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </Row>
  );

  public setPickerRef = (el: HTMLElement) => {
    this.picker = el;
  };

  public getSubProjectsFromApolloData = (result: any) => {
    const data = result.data;
    if (data && data.projects && data.projects.length) {
      const subprojects = data.projects[0].children;
      return [...subprojects].sort((a: IProject, b: IProject) => a.title.localeCompare(b.title));
    }

    return [];
  };

  public handleSearchSheetDidLoad = (items: IProject[], error: boolean, loadedAll: boolean) => {
    if (!error) {
      this.setState({
        loadedAllProjects: this.state.searchValue ? true : loadedAll,
        projectData: items,
      });
    } else {
      this.setState({
        loadedAllProjects: true, // remove spinner
      });
    }
  };

  public handleSubProjectSheetDidLoad = (items: IProject[], error: boolean) => {
    if (!error) {
      this.setState({
        loadedAllSubProjects: true,
        subProjectData: items,
      });
    } else {
      this.setState({
        loadedAllSubProjects: true, // remove spinner
      });
    }
  };

  public renderPickerLists = (close: () => void) => {
    const handleCloseMenu = (shouldFocus: boolean) => {
      if (shouldFocus && this.picker) {
        this.picker.focus();
      }
      close();
    };

    return (
      <>
        {this.state.layers.length === 0 && (
          <LazyLoadSearchablePickerList
            getData={this.loadSearchSheet}
            didLoad={this.handleSearchSheetDidLoad}
            value={this.state.focusStartId}
            renderRow={this.renderRow}
            data={this.state.projectData}
            loadedAll={this.state.loadedAllProjects}
            closeMenu={handleCloseMenu}
            onSelect={this.handleSelect}
            searchValue={this.state.searchValue}
            onSearch={this.handleSearchInputChange}
            onRightKeyDown={this.handleChevronClick}
          />
        )}
        {this.state.layers.length > 0 && (
          <LazyLoadPickerList
            getData={this.loadSubProjectSheet}
            didLoad={this.handleSubProjectSheetDidLoad}
            value={this.state.focusStartId ?? null}
            loadedAll={this.state.loadedAllSubProjects}
            renderRow={this.renderRow}
            data={this.state.subProjectData}
            closeMenu={handleCloseMenu}
            onSelect={this.handleSelect}
            onRightKeyDown={this.drillIn}
            onLeftKeyDown={this.goBack}
            header={this.renderSecondaryHeader}
            autofocus={true}
          />
        )}
      </>
    );
  };

  public render() {
    const { value, placeholder, className, position, error, dropDownButtonRender, closeButtonRender, onChange } =
      this.props;

    const classes = classNames('project-picker', className);

    return (
      <Picker
        value={value}
        onSelect={this.handleSelect}
        onClear={this.handleClear}
        className={classes}
        error={error}
        dropDownButtonRender={dropDownButtonRender}
        closeButtonRender={closeButtonRender}
        position={position}
        placeholder={placeholder}
        valueTemplate={this.buildValueTemplate()}
        onOpen={this.handleOnOpen}
        forwardRef={this.setPickerRef}
        minWidth="350px"
        contentTemplate={this.renderPickerLists}
        onChange={onChange}
        disabled={this.props.disabled}
      />
    );
  }
}

// TODO: Add archived filtering
// TODO: Implement lazy loading once server-side paging is available

export default withApollo<IProjectPickerProps>(ProjectPicker);
