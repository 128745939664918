import { FetchPolicy, QueryResult, useApolloClient } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Position } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { useDebounce } from 'hooks';
import _ from 'lodash';
import * as React from 'react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import SearchType from 'types/enum/SearchType';
import IEquipmentCategory from 'types/EquipmentCategory';
import LazyLoadPicker from '../../../foundation/pickers/LazyLoadPicker/LazyLoadPicker';
import { equipmentCategoriesQuery, equipmentCategoryQuery } from './equipment-cateory-picker-queries';
import SimplePickerRow from 'components/foundation/pickers/SimplePickerRow/SimplePickerRow';

export interface IEquipmentCategoryPickerProps {
  value: string | null;
  equipmentTypeId: string | null | undefined;
  onSelect: (categoryId: string | null) => void;
  autofocus?: boolean;
  minWidth?: string;
  placeholder?: string;
  error?: boolean;
  position?: Position;
  fetchPolicy?: FetchPolicy;
  className?: ClassName;
  dropDownButtonRender?: ReactNode; // Drop down button on the picker
  closeButtonRender?: (handleClear: (event: React.KeyboardEvent | React.MouseEvent) => void) => ReactNode; // Close button render on picker
}

const EquipmentCategoryForTypePicker = (props: IEquipmentCategoryPickerProps) => {
  const {
    value,
    equipmentTypeId,
    onSelect,
    minWidth,
    error,
    placeholder,
    position,
    fetchPolicy,
    closeButtonRender,
    dropDownButtonRender,
    autofocus,
    className,
  } = props;

  const client = useApolloClient();

  const [pickerData, setPickerData] = useState<IEquipmentCategory[]>([]);
  const [loadedAll, setLoadedAll] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const searchDebounce = useDebounce(searchValue, 250);
  const [apolloError, setApolloError] = useState(false);
  const firstRender = useRef(true);

  // reset list when searching
  useEffect(() => {
    setPickerData([]);
    setLoadedAll(false);
  }, [searchDebounce]);

  // clear list and selected category when the type is changed
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    setPickerData([]);
    setLoadedAll(false);
    onSelect(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentTypeId]);

  function handleDidLoad(models: IEquipmentCategory[], err: boolean, allLoaded: boolean) {
    if (!err) {
      setLoadedAll(allLoaded);
      setPickerData(models);
    }

    setApolloError(err);
  }

  function onSearch(val: string) {
    setSearchValue(val);
  }

  function onClose() {
    setLoadedAll(false);
    setPickerData([]);
    setSearchValue('');
  }

  function handleSelect(model: IEquipmentCategory | null) {
    onSelect(model ? model.id : null);
  }

  async function getCategories(after?: string): Promise<IEquipmentCategory[]> {
    return new Promise((resolve, reject) => {
      client
        .query({
          fetchPolicy: fetchPolicy || 'cache-first',
          query: equipmentCategoriesQuery,
          variables: {
            after,
            filter: {
              equipmentTypeId: { equal: equipmentTypeId },
              deletedOn: { isNull: true },
              search: searchDebounce
                ? {
                    type: SearchType.CONTAINS,
                    fields: ['title'],
                    value: searchDebounce,
                  }
                : undefined,
            },
            first: 10,
          },
        })
        .then((result: { data: { equipmentCategories: IEquipmentCategory[] } }) => {
          resolve(result.data.equipmentCategories);
        })
        .catch(() => {
          reject();
        });
    });
  }

  function renderValueTemplate() {
    if (value) {
      const category = _.find(pickerData, (model) => model.id === value);
      if (category) {
        return <div className="ellipsis">{category.title}</div>;
      } else {
        return (
          <Query query={equipmentCategoryQuery} variables={{ categoryId: value }}>
            {({ data }: QueryResult<{ equipmentCategories: IEquipmentCategory[] }>) => {
              return <>{data && data.equipmentCategories.length ? data.equipmentCategories[0].title : <></>}</>;
            }}
          </Query>
        );
      }
    }

    return <></>;
  }

  function renderRow(category: IEquipmentCategory) {
    return <SimplePickerRow value={category.title} searchValue={searchValue} />;
  }

  const classes = classNames('equipment-category-picker', className);

  return (
    <LazyLoadPicker
      data-testid={'equipment-category-picker'}
      value={value}
      disabled={_.isNil(equipmentTypeId)}
      getData={getCategories}
      didLoad={handleDidLoad}
      error={error}
      dataError={apolloError}
      data={pickerData}
      loadedAll={loadedAll}
      onSelect={handleSelect}
      onClose={onClose}
      valueTemplate={renderValueTemplate()}
      renderRow={renderRow}
      minWidth={minWidth}
      position={position}
      searchValue={searchValue}
      onSearch={onSearch}
      placeholder={placeholder}
      closeButtonRender={closeButtonRender}
      dropDownButtonRender={dropDownButtonRender}
      autofocus={autofocus}
      className={classes}
    />
  );
};

EquipmentCategoryForTypePicker.defaultProps = {
  minWidth: '350px',
};

export default EquipmentCategoryForTypePicker;
