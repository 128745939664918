import { Avatar, Bar, Justify, Size } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import SearchText from 'components/foundation/text/SearchText/SearchText';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { ReactNode } from 'react';
import { AnyObject } from 'types/util/Object';
import './ImageRowItem.scss';

export interface IImageRowItemProps {
  firstName: string;
  lastName?: string;
  search?: string;
  numInitials?: 1 | 2;
  imageUrl?: string;
  rightContent?: ReactNode;
  className?: string | AnyObject;
}

const ImageRowItem = ({ firstName, lastName, search, numInitials, imageUrl, className }: IImageRowItemProps) => {
  const classes = classNames(
    {
      'image-list-item': true,
      'px-2': true,
    },
    className
  );

  const formatEmployeeName = useEmployeeNameFormatter();

  return (
    <Bar size={Size.MEDIUM} justify={Justify.FLEX_START} className={classes}>
      <Avatar firstName={firstName} lastName={lastName} image={imageUrl} size={Size.SMALL} numInitials={numInitials} />
      <span className="px-4 ellipsis">
        <SearchText text={formatEmployeeName(firstName, lastName ?? '')} search={search} wrap={false} />
      </span>
    </Bar>
  );
};

export default ImageRowItem;
