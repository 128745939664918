import { Justify, Row } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import _, { first, isNil } from 'lodash';
import { Component, ReactNode } from 'react';
import { shallowCompare } from 'utils/collectionUtils';
import { Spinner } from '../..';
import './LazyLoader.scss';

export interface ILazyLoaderProps<T extends { id: string; cursor?: string }> {
  data: T[];
  loadedAll: boolean;
  getData: (after?: string) => Promise<T[]>;
  didLoad: (items: T[], error: boolean, loadedAll: boolean) => void;
  renderRow: (row: T, index: number) => ReactNode;
  offset: number; // has default
  renderSize: number; // has default
  loadAll: boolean;
  scroller: HTMLElement | 'self';
  loadingTemplate?: ReactNode;
  // Used to check if loaded all.  Without this prop it nevers sets loaded all to true until the server returns no results.
  loadSize?: number;
  style?: object;
  didRender?: () => void;
  willLoad?: () => void;
  template?: (renderedRows: ReactNode, scroller?: ReactNode, data?: T[]) => ReactNode;
  forwardRef?: (ref: HTMLDivElement) => void;
  showLoaderWhenLoading?: boolean;
  className?: ClassName;
}

interface IState<T> {
  rows: T[]; // Rendered data items.
  loading: boolean;
}

// Prefer to use `useLazyLoading` or similar hooks instead of this component.
class LazyLoader<T extends { id: string; cursor?: string }> extends Component<ILazyLoaderProps<T>, IState<T>> {
  public static defaultProps: any;
  public scroller?: HTMLElement;
  public state: IState<T> = {
    rows: [],
    loading: false,
  };

  // TODO: using isMounted is considered an anti-pattern (https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html)
  private _isMounted: boolean = false;

  public componentDidMount() {
    this._isMounted = true;
    this.getScroller()!.addEventListener('scroll', this.onScroll);

    if (this.shouldLoadMore()) {
      this.loadMore();
    }

    this.parseRows();
  }

  public componentWillUnmount() {
    this._isMounted = false;
    this.getScroller()!.removeEventListener('scroll', this.onScroll);
  }

  public componentDidUpdate(prevProps: ILazyLoaderProps<T>, prevState: IState<T>) {
    const { data, loadedAll, loadAll, scroller } = this.props;

    if (prevProps.scroller !== scroller) {
      if (prevProps.scroller === 'self') {
        this.scroller?.removeEventListener('scroll', this.onScroll);
      } else {
        prevProps.scroller.removeEventListener('scroll', this.onScroll);
      }

      this.getScroller()!.addEventListener('scroll', this.onScroll);
    }

    if (this.shouldLoadMore()) {
      this.loadMore();
    }

    if (!shallowCompare(this.state.rows, prevState.rows)) {
      if (this.props.didRender) {
        this.props.didRender();
      }
    }

    if (!this.state.loading && loadAll && !loadedAll) {
      this.loadMore();
    }

    // The data was changed
    if (!shallowCompare(data, prevProps.data)) {
      this.parseRows();
    }
  }

  // TODO: this eats issues that could be memory leaks
  public safeSetState(newState: any) {
    if (this._isMounted) {
      return this.setState(newState);
    }
  }

  public parseRows() {
    const { rows } = this.state;
    const { data, renderSize, loadedAll } = this.props;
    const endIndex = loadedAll ? data.length : data.length >= rows.length ? rows.length || renderSize : data.length;
    const newRows = data.slice(0, endIndex);

    // Doesn't render new rows, just updates in case data has changed
    this.safeSetState({
      rows: newRows,
    });
  }

  public shouldLoadMore() {
    return !this.state.loading && this.reachedOffset() && !this.props.loadedAll;
  }

  // Load the next section of data, checking first to see if there are any unrendered rows in
  // the data prop. If all data has been rendered, fetch more data from the server.
  public loadMore = async () => {
    this.safeSetState({ loading: true });
    const nextSection = this.getNextSectionFromData();

    if (nextSection.length === 0) {
      if (!this.props.loadedAll) {
        await this.loadMoreFromNetwork();
        this.safeSetState({ loading: false });
      }
    } else {
      this.safeSetState((prevState: IState<T>) => {
        return {
          rows: [...prevState.rows, ...nextSection],
          loading: false,
        };
      });
    }
  };

  // Fetch the next section of data
  public loadMoreFromNetwork() {
    const { didLoad, getData, loadSize, data, willLoad } = this.props;

    if (willLoad) {
      willLoad();
    }

    return getData(this.getLastCursor(data))
      .then((results) => {
        if (!this._isMounted) {
          return;
        } else if (results) {
          if (results.length) {
            const loadedLessThanLoadSize = loadSize ? results.length < loadSize : false;
            didLoad(this.patchWithCursors([...data], [...results]), false, loadedLessThanLoadSize);
          } else {
            didLoad([...data], false, true);
          }
        } else {
          didLoad([...data], true, false);
        }
      })
      .catch(() => {
        didLoad([...data], true, false);
      });
  }

  // Get the next section of unrendered rows from the data prop
  public getNextSectionFromData() {
    let results: T[] = [];
    const { rows } = this.state;
    const { data, renderSize } = this.props;
    const remaining = data.length - rows.length;

    if (remaining > 0) {
      if (rows.length === 0) {
        results = data.slice(0, renderSize);
      } else if (remaining > renderSize) {
        results = data.slice(rows.length, rows.length + renderSize);
      } else {
        results = data.slice(rows.length);
      }
    }

    return results;
  }

  public onScroll = () => {
    if (this.shouldLoadMore()) {
      this.loadMore();
    }
  };

  public getScroller() {
    return this.props.scroller === 'self' ? this.scroller : this.props.scroller;
  }

  // Has the user scrolled enough to trigger a load more
  public reachedOffset() {
    const scroller = this.getScroller()!;
    return scroller.scrollTop + this.props.offset >= scroller.scrollHeight - scroller.offsetHeight;
  }

  // Find the last item in the data set that has a cursor
  public getLastCursor(items: T[]) {
    const lastItemWithCursor = [...items].reverse().find((item: T) => {
      return typeof item.cursor === 'string';
    });

    return lastItemWithCursor ? lastItemWithCursor.cursor : undefined;
  }

  // Remove duplicates from the existing data.
  // Items that are added/edited do not have cursors and will show up again in query results.
  // When we get new query results, which have cursors, we use them to replace any matching items that do not.
  public patchWithCursors(existing: T[], newQueryResults: T[]) {
    const newArray = _.map(existing, (e, index) => {
      let match;
      const newItem = first(
        newQueryResults.filter(
          (newResult) => newResult.id === e.id && newResult.cursor && e.cursor && newResult.cursor !== e.cursor
        )
      );
      if (!e.cursor || !isNil(newItem)) {
        const matchIndex = newQueryResults.findIndex((result) => {
          return result.id === e.id;
        });

        if (matchIndex > -1) {
          match = newQueryResults.splice(index, 1)[0];
        }
      }

      return match ? match : e;
    });

    return [...newArray, ...newQueryResults];
  }

  // Return the loading template
  public renderLoadingTemplate() {
    return (
      <>
        {this.props.loadingTemplate || (
          <Row justify={Justify.CENTER} className="py-5 no-print">
            <Spinner />
          </Row>
        )}
      </>
    );
  }

  public setRefs = (el: HTMLDivElement) => {
    if (this.props.scroller === 'self') {
      this.scroller = el;
      if (this.props.forwardRef) {
        this.props.forwardRef(el);
      }
    }
  };

  public render() {
    const { loadedAll, className, template, style, renderRow, showLoaderWhenLoading } = this.props;

    const classes = classNames('lazy-loader', className);

    const renderTemplate = template ? template : (renderedRows: ReactNode) => <>{renderedRows}</>;
    const rows = renderRow
      ? this.state.rows.map((item, index) => {
          return renderRow(item, index);
        })
      : null;

    return (
      <div ref={this.setRefs} style={style} className={classes}>
        {renderTemplate(rows)}
        {!loadedAll && showLoaderWhenLoading && this.renderLoadingTemplate()}
      </div>
    );
  }
}

LazyLoader.defaultProps = {
  offset: 100,
  renderSize: 20,
  loadAll: false,
  scroller: 'self',
};

export default LazyLoader;
