import { useApolloClient } from '@apollo/client';
import { useApolloPaging } from 'hooks';
import {
  costCodeLaborMetricQueryProvider,
  costCodeTimeMetricQueryProvider,
} from 'hooks/aggregates/metrics/CostCodeMetricQueryProviders';
import {
  equipmentLaborMetricQueryProvider,
  equipmentTimeMetricQueryProvider,
} from 'hooks/aggregates/metrics/EquipmentMetricQueryProviders';
import { memberLaborMetricQueryProvider } from 'hooks/aggregates/metrics/MemberMetricQueryProviders';
import {
  organizationLaborMetricQueryProvider,
  organizationTimeMetricQueryProvider,
} from 'hooks/aggregates/metrics/OrganizationMetricQueryProviders';
import {
  projectLaborMetricQueryProvider,
  projectLaborMetricWithTimeFilterQueryProvider,
  projectTimeMetricQueryProvider,
  projectTimeMetricWithTimeFilterQueryProvider,
} from 'hooks/aggregates/metrics/ProjectMetricQueryProviders';
import useMetricAggregatesAsync from 'hooks/aggregates/useMetricAggregates';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo } from 'react';
import MemberPermission from 'types/enum/MemberPermission';
import ITimeRange from 'types/TimeRange';
import { getDateTimesBetween, getMonthsBetween } from 'utils/dateUtils';
import { metricDataToLaborGraphData } from 'utils/laborGraphDataUtils';
import { ActivityReportType } from '../ActivityReportFilter/ActivityReportFilter';

export interface ActivityGraphAggregatesProps {
  timeRange: ITimeRange<DateTime>;
  reportType: ActivityReportType;
  memberId?: string | null;
  projectId?: string | null;
  projectGroupId?: string | null;
  costCodeId?: string | null;
  costCodeGroupId?: string | null;
  equipmentId?: string | null;
  equipmentCategoryId?: string | null;
  memberGroupId?: string | null;
  memberPermission?: MemberPermission;
  monthLimitToSwitch?: number;
}

export default function useActivityGraphAggregates(props: ActivityGraphAggregatesProps) {
  const {
    timeRange,
    reportType,
    memberId,
    projectId,
    projectGroupId,
    costCodeId,
    costCodeGroupId,
    equipmentId,
    equipmentCategoryId,
    memberGroupId,
    memberPermission,
    monthLimitToSwitch,
  } = props;
  const canViewCost = useHasCostPermission();

  const queryProvider = useCallback(() => {
    const memberIds = memberId ? [memberId] : undefined;
    const projectIds = projectId ? [projectId] : undefined;
    const costCodeIds = costCodeId ? [costCodeId] : undefined;
    const equipmentIds = equipmentId ? [equipmentId] : undefined;

    if (reportType === ActivityReportType.BY_PROJECT) {
      if (projectIds && !isEmpty(projectIds)) {
        // don't use the time filtered query providers when filtering by id
        return canViewCost
          ? projectLaborMetricQueryProvider(getAll, projectIds, projectGroupId ?? undefined, 'all', true)
          : projectTimeMetricQueryProvider(getAll, projectIds, projectGroupId ?? undefined, 'all');
      }
      return canViewCost
        ? projectLaborMetricWithTimeFilterQueryProvider(getAll, projectGroupId ?? undefined, 'all')
        : projectTimeMetricWithTimeFilterQueryProvider(getAll, projectGroupId ?? undefined, 'all');
    } else if (reportType === ActivityReportType.BY_COST_CODE) {
      return canViewCost
        ? costCodeLaborMetricQueryProvider(getAll, costCodeIds, costCodeGroupId ?? undefined, 'all')
        : costCodeTimeMetricQueryProvider(getAll, costCodeIds, costCodeGroupId ?? undefined, 'all');
    } else if (reportType === ActivityReportType.BY_EQUIPMENT) {
      return canViewCost
        ? equipmentLaborMetricQueryProvider(getAll, equipmentIds, equipmentCategoryId ?? undefined, 'all')
        : equipmentTimeMetricQueryProvider(getAll, equipmentIds, equipmentCategoryId ?? undefined, 'all');
    } else if (reportType === ActivityReportType.BY_DAY || reportType === ActivityReportType.BY_DATE_RANGE) {
      return canViewCost ? organizationLaborMetricQueryProvider(client) : organizationTimeMetricQueryProvider(client);
    }

    return memberLaborMetricQueryProvider(
      getAll,
      memberPermission,
      memberIds,
      memberGroupId ?? undefined,
      'all',
      !canViewCost
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reportType,
    memberId,
    projectId,
    costCodeId,
    equipmentId,
    memberGroupId,
    projectGroupId,
    costCodeGroupId,
    equipmentCategoryId,
    canViewCost,
  ]);

  const { getAll } = useApolloPaging();
  const client = useApolloClient();
  const {
    data: metricAggregates,
    error: metricAggregateError,
    execute: refetchData,
  } = useMetricAggregatesAsync(timeRange, queryProvider(), monthLimitToSwitch, false, false);

  const getRanges = useCallback(() => {
    const monthRanges = getMonthsBetween(timeRange.startTime, timeRange.endTime);

    return monthRanges.length > (monthLimitToSwitch ?? 2)
      ? monthRanges
      : getDateTimesBetween(timeRange.startTime, timeRange.endTime);
  }, [timeRange, monthLimitToSwitch]);

  // trigger sync to get aggregate data
  useEffect(() => {
    refetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reportType,
    timeRange,
    memberId,
    projectId,
    costCodeId,
    equipmentId,
    memberGroupId,
    projectGroupId,
    costCodeGroupId,
    equipmentCategoryId,
  ]);

  // once we get our aggregate data, then convert it to graph data
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(() => metricDataToLaborGraphData(metricAggregates ?? [], getRanges()), [metricAggregates]);

  return { data, error: metricAggregateError, refetchData };
}
