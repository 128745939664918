import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import SimplePickerRow from '../../SimplePickerRow/SimplePickerRow';
import { useMemo } from 'react';
import DropDownPicker, { IDropDownPickerProps } from '../../DropDownPicker/DropDownPicker';

export interface IBasicPickerValue<K extends string = string> {
  id: K;
  name: string;
}

export interface IBasicPickerProps<K extends string = string, T extends IBasicPickerValue<K> = IBasicPickerValue<K>>
  extends Omit<
    IDropDownPickerProps<T, K>,
    'onSelect' | 'loadedAll' | 'valueTemplate' | 'renderRow' | 'isLoading' | 'dataError'
  > {
  onSelect: (value: K | null) => void;
  className?: ClassName;
}

const BasicPicker = <K extends string, T extends IBasicPickerValue<K>>({
  value,
  data,
  onSelect,
  searchValue,
  className,
  ...pickerProps
}: IBasicPickerProps<K, T>) => {
  const item = useMemo(() => data.find((model) => model.id === value), [data, value]);

  function handleSelect(model: T | null) {
    onSelect(model?.id ?? null);
  }

  function renderValueTemplate() {
    if (item) {
      return <div className="ellipsis">{item.name}</div>;
    }

    return <></>;
  }

  function renderRow(item: T) {
    return <SimplePickerRow key={item.id} value={item.name} searchValue={searchValue} />;
  }

  const classes = classNames('basic-picker', className);

  return (
    <DropDownPicker
      {...pickerProps}
      className={classes}
      value={value}
      data={data}
      searchValue={searchValue}
      renderRow={renderRow}
      onSelect={handleSelect}
      valueTemplate={renderValueTemplate()}
      loadedAll={true}
      isLoading={false}
      dataError={false}
    />
  );
};

export default BasicPicker;
