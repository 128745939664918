import classNames from 'classnames';
import { ArchivedStatus } from 'components/domain/archived/ArchivedPicker/ArchivedPicker';
import CostCodeCapsules from 'components/domain/cost-code/CostCodeCapsules/CostCodeCapsules';
import EquipmentCapsules from 'components/domain/equipment/EquipmentCapsules/EquipmentCapsules';
import MemberCapsules from 'components/domain/member/MemberCapsules/MemberCapsules';
import ProjectCapsules from 'components/domain/project/ProjectCapsules/ProjectCapsules';
import TimeEntryActionBar from 'components/domain/time-entry/TimeEntryActionBar/TimeEntryActionBar';
import MemberTimeEntryDataReport from 'components/domain/time-entry/TimeEntryDataReport/MemberTimeEntryDataReport/MemberTimeEntryDataReport';
import useMemberTimeEntryDataReportData, {
  IMemberTimeEntryReportDataFilter,
} from 'components/domain/time-entry/TimeEntryDataReport/MemberTimeEntryDataReport/hooks/useMemberTimeEntryDataReportData';
import { ITimeEntryDataTableRow } from 'components/domain/time-entry/TimeEntryDataTable/TimeEntryDataTable';
import ManagedTimeRangeReportDialog from 'components/foundation/dialogs/ManagedTimeRangeReportDialog/ManagedTimeRangeReportDialog';
import { TimesheetReportType } from 'containers/timesheets/Timesheets';
import useTimesheetsColumns from 'containers/timesheets/hooks/useTimesheetsColumns';
import { useEmployeeNameFormat, useReduxSelector } from 'hooks';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import { first, isNil, map, partition, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateCheckedTimeEntryIds, updateCheckedTimeOffIds } from 'store/TimeEntryTable/TimeEntryTable';
import { ClassName } from 'types/ClassName';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { dateUtils } from 'utils';
import { isNilOrEmpty, onlyHasRemainingItem } from 'utils/collectionUtils';
import { areTimeRangesEqual } from 'utils/dateUtils';
import { t } from 'utils/localize';
import TimeEntryFilterBar from '../../TimeEntryFilterBar/TimeEntryFilterBar';
import './MemberTimeEntryDialog.scss';
import { MemberPermissions } from '__generated__/graphql';
import useReactQueryBaseKey from 'hooks/react-query/useReactQueryBaseKey/useReactQueryBaseKey';

export interface IMemberTimeEntryDialogProps {
  className?: ClassName;
  isOpen: boolean;
  onClose: () => void;
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  memberIds?: string[] | null;
  projectIds?: string[] | null;
  costCodeIds?: string[] | null;
  equipmentIds?: string[] | null;
  onDataChange?: (memberIds: string[]) => void;
  reportType?: TimesheetReportType;
  includeOpen?: boolean;
  projectIdWithDescendants?: boolean;
  archivedStatus?: ArchivedStatus;
  timeRangeBackPressed?: () => boolean; // returns boolean to indicate if the event consumed (true) or if it should perform the default behavior (false)
  timeRangeForwardPressed?: () => boolean; // returns boolean to indicate if the event consumed (true) or if it should perform the default behavior (false)
  excludeTypeColumn?: boolean;
}

const MemberTimeEntryDialogWrapper = ({ isOpen, ...rest }: IMemberTimeEntryDialogProps) => {
  if (!isOpen) {
    return null;
  }

  return <MemberTimeEntryDialog {...rest} isOpen={true} />;
};

const MemberTimeEntryDialog = ({
  className,
  isOpen,
  onClose,
  timeRange,
  timeRangeType,
  memberIds,
  projectIds,
  costCodeIds,
  equipmentIds,
  onDataChange,
  reportType,
  includeOpen,
  projectIdWithDescendants,
  archivedStatus,
  timeRangeBackPressed,
  timeRangeForwardPressed,
  excludeTypeColumn,
}: IMemberTimeEntryDialogProps) => {
  const filterMemberIds = useMemo(() => (memberIds ? memberIds : undefined), [memberIds]);
  const filterProjectIds = useMemo(() => (projectIds ? projectIds : undefined), [projectIds]);
  const filterCostCodeIds = useMemo(() => (costCodeIds ? costCodeIds : undefined), [costCodeIds]);
  const filterEquipmentIds = useMemo(() => (equipmentIds ? equipmentIds : undefined), [equipmentIds]);

  const dispatch = useDispatch();
  const selectedIds = useReduxSelector((state) =>
    state.timeEntryTable.checkedTimeEntryIds.concat(state.timeEntryTable.checkedTimeOffIds)
  );

  const onSelect = useCallback(
    (rows: ITimeEntryDataTableRow[]) => {
      const [timeEntryIds, timeOffIds] = partition(rows, (row) => isNil(row.timeOffType));
      dispatch(updateCheckedTimeEntryIds(map(timeEntryIds, 'id')));
      dispatch(updateCheckedTimeOffIds(map(timeOffIds, 'id')));
    },
    [dispatch]
  );

  const [scroller, setScroller] = useState<HTMLElement | null>(null);

  const { timeEntryColumns } = useTimesheetsColumns();

  const [filter, setFilter] = useState<IMemberTimeEntryReportDataFilter>({
    timeRange,
    includeOpenEntries: includeOpen,
    memberIds: filterMemberIds,
    memberGroupId: undefined,
    projectIds: filterProjectIds,
    costCodeIds: filterCostCodeIds,
    equipmentIds: filterEquipmentIds,
    positionId: undefined,
    reportType,
    projectIdWithDescendants,
    archivedStatus: archivedStatus ?? 'all',
    memberPermissions:
      !isNilOrEmpty(filterMemberIds) && filterMemberIds?.length === 1
        ? [MemberPermissions.TimeEvents]
        : [MemberPermissions.ManageTimeEntries],
  });

  const updatedFilter = {
    ...filter,
    memberIds: filter.memberIds !== filterMemberIds ? filterMemberIds : filter.memberIds,
  };

  const [employeeNameFormat] = useEmployeeNameFormat();

  const baseQueryKey = useReactQueryBaseKey();
  const queryKey = useMemo(
    () => [baseQueryKey, 'timesheets-member-time-data', employeeNameFormat, filter],
    [baseQueryKey, employeeNameFormat, filter]
  );

  const {
    data,
    onDataChange: onChange,
    loading,
    loadAll,
    loadedAll,
  } = useMemberTimeEntryDataReportData(scroller, updatedFilter, queryKey);

  const brand = useBrandTitle();

  function renderTimeEntryReport(range: ITimeRange<DateTime>) {
    if (!areTimeRangesEqual(filter.timeRange, range)) {
      setFilter((prev) => ({
        ...prev,
        timeRange: range,
      }));
    }

    return (
      <MemberTimeEntryDataReport
        className={className}
        columnSettings={
          excludeTypeColumn === true ? timeEntryColumns.filter((c) => c.key !== 'type') : timeEntryColumns
        }
        onSelectChange={data?.some((item) => item.canManage) ? onSelect : undefined}
        selectedIds={selectedIds}
        timeRange={range}
        onDataChange={handleUpdate}
        data={data ?? []}
        loading={loading}
        projectId={!onlyHasRemainingItem(projectIds) ? first(projectIds) : undefined}
        costCodeId={!onlyHasRemainingItem(costCodeIds) ? first(costCodeIds) : undefined}
        equipmentId={!onlyHasRemainingItem(equipmentIds) ? first(equipmentIds) : undefined}
        applyPrintOptions={false}
      />
    );
  }

  function handleUpdate(ids: string[]) {
    onChange();
    onDataChange?.(ids);
  }

  function renderActionBar() {
    return (
      <TimeEntryActionBar
        onBulkEditSubmit={(entries) => {
          const ids = uniq(entries.map((entry) => entry.member.id));
          handleUpdate(ids);
        }}
        onDelete={() => {
          handleUpdate((data ?? []).map((d) => d.id));
        }}
        onTimeEntryEdit={(data) => {
          const ids = data.members;
          handleUpdate(ids);
        }}
      />
    );
  }

  function renderFilterBar(range: ITimeRange<DateTime>) {
    const exportFileName = `${brand}-${t(
      'employee-activity-report-time-entries'
    )}-${dateUtils.isoTimeStampLocal()}.csv`;

    return (
      <>
        {(filterMemberIds || filterProjectIds || filterCostCodeIds || filterEquipmentIds) && (
          <TimeEntryFilterBar
            timeRange={range}
            data={data ?? []}
            loadAll={loadAll}
            loadedAll={loadedAll}
            exportFileName={exportFileName}
            renderFilters={(forPrint) => (
              <>
                {filterProjectIds && (
                  <ProjectCapsules
                    className="ml-2"
                    projectIds={filterProjectIds}
                    printVersion={forPrint}
                    isUnassinged={projectIdWithDescendants === false}
                  />
                )}
                {filterCostCodeIds && (
                  <CostCodeCapsules className="ml-2" printVersion={forPrint} costCodeIds={filterCostCodeIds} />
                )}
                {filterEquipmentIds && (
                  <EquipmentCapsules className="ml-2" printVersion={forPrint} equipmentIds={filterEquipmentIds} />
                )}
                {/* We're not printing the member capsules because the titles are already on the table headers */}
                {filterMemberIds && !forPrint && (
                  <MemberCapsules className="ml-2" printVersion={forPrint} memberIds={filterMemberIds} />
                )}
              </>
            )}
          />
        )}
      </>
    );
  }

  const classes = classNames('member-time-entry-dialog', className);

  return (
    <ManagedTimeRangeReportDialog
      className={classes}
      isOpen={isOpen}
      onClose={onClose}
      timeRange={timeRange}
      timeRangeType={timeRangeType}
      reportComponent={renderTimeEntryReport}
      title={t('Time Entries')}
      renderActionBar={renderActionBar}
      renderFilterBar={renderFilterBar}
      setRef={setScroller}
      timeRangeBackPressed={timeRangeBackPressed}
      timeRangeForwardPressed={timeRangeForwardPressed}
      headerClassName={'no-print'}
    />
  );
};

export default MemberTimeEntryDialogWrapper;
