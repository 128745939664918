import { Divider, Position } from '@busybusy/webapp-react-ui';
import { EQUIPMENT_QUERY } from 'apollo/queries/equipment-queries';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { IBasicPickerValue } from 'components/foundation/pickers/basic-picker/BasicPicker/BasicPicker';
import SimplePickerRow from 'components/foundation/pickers/SimplePickerRow/SimplePickerRow';
import { useDebounce, useOrganization } from 'hooks';
import _, { first, last } from 'lodash';
import * as React from 'react';
import { ReactNode, useState } from 'react';
import { getEquipmentDisplay } from 'utils/stringUtils';
import { logError } from 'utils/testUtils';
import { useQuery } from '@tanstack/react-query';
import useGraphQLClient from 'hooks/graphql/useGraphQLClient/useGraphQLClient';
import {
  EquipmentSearchFields,
  QueryEquipmentQuery,
  QueryEquipmentQueryVariables,
  SearchType,
} from '__generated__/graphql';
import useReactQueryBaseKey from 'hooks/react-query/useReactQueryBaseKey/useReactQueryBaseKey';
import { useReactQueryLazyLoading } from 'hooks/react-query/useReactQueryLazyLoading/useReactQueryLazyLoading';
import ICursorable from 'types/Cursorable';
import { Nullable } from 'types/util/Nullable';
import DropDownPicker from 'components/foundation/pickers/DropDownPicker/DropDownPicker';

export interface IEquipmentPickerProps {
  value: string | null;
  onSelect: (equipmentId: string | null) => void;
  minWidth?: string;
  placeholder?: string;
  error?: boolean;
  position?: Position;
  dropDownButtonRender?: ReactNode;
  pickerHeaderItems?: IBasicPickerValue[];
  closeButtonRender?: (handleClear: (event: React.KeyboardEvent | React.MouseEvent) => void) => ReactNode;
  className?: ClassName;
  filteredIds?: string[];
}

type CursoredPickerValue = IBasicPickerValue & { cursor: string | undefined };

const EquipmentPicker = ({
  value,
  onSelect,
  minWidth = '350px',
  error,
  placeholder,
  position,
  closeButtonRender,
  dropDownButtonRender,
  className,
  pickerHeaderItems,
  filteredIds,
}: IEquipmentPickerProps) => {
  const organization = useOrganization();
  const [searchValue, setSearchValue] = useState('');
  const searchDebounce = useDebounce(searchValue, 250);

  const graphqlClient = useGraphQLClient();

  const [isOpen, setIsOpen] = useState(false);

  function onClose() {
    setIsOpen(false);
    setSearchValue('');
  }

  function handleSelect(value: Nullable<IBasicPickerValue<string>>) {
    onSelect(value?.id ?? null);
  }

  const baseQueryKey = useReactQueryBaseKey();

  const variables: QueryEquipmentQueryVariables = {
    filter: {
      deletedOn: { isNull: true },
      search: searchDebounce
        ? {
            type: SearchType.Contains,
            fields: [
              EquipmentSearchFields.EquipmentName,
              EquipmentSearchFields.ModelName,
              EquipmentSearchFields.MakeName,
            ],
            value: searchDebounce,
          }
        : undefined,
      id: filteredIds ? { doesNotContain: filteredIds } : undefined,
    },
  } as const;

  async function getEquipment(after: string | null, first: number): Promise<Array<CursoredPickerValue & ICursorable>> {
    const pagedVariables: QueryEquipmentQueryVariables = {
      after,
      first,
      ...variables,
    };

    const result = await graphqlClient
      .request<QueryEquipmentQuery, QueryEquipmentQueryVariables>({
        document: EQUIPMENT_QUERY,
        variables: pagedVariables,
      })
      .catch(logError);

    return (
      result.equipment?.map((equipment) => ({
        id: equipment.id,
        name: getEquipmentDisplay(equipment),
        cursor: equipment.cursor,
      })) ?? []
    );
  }

  const { data: selectedEquipment } = useQuery({
    queryKey: [...baseQueryKey, 'equipment-picker-by-id', value],
    queryFn: async () => {
      if (!value) {
        return null;
      }

      const equipment = _.find(queriedData, (model) => model.id === value);

      if (equipment) {
        return equipment.name;
      } else {
        const equipmentResult = await graphqlClient.request<QueryEquipmentQuery, QueryEquipmentQueryVariables>({
          document: EQUIPMENT_QUERY,
          variables: {
            filter: { id: { equal: value } },
          },
        });

        const target = first(equipmentResult?.equipment);

        if (!target) {
          return null;
        }

        return getEquipmentDisplay(target);
      }
    },
    initialData: null,
    enabled: !!value,
  });

  const scroller = React.useRef<HTMLElement>();

  const {
    data: queriedData,
    isLoading,
    isError: isDataError,
    loadedAll,
  } = useReactQueryLazyLoading(scroller.current, [...baseQueryKey, 'equipment-picker', variables], getEquipment, 20, {
    enabled: isOpen,
  });

  function renderValueTemplate() {
    if (selectedEquipment) {
      return <div className="ellipsis">{selectedEquipment}</div>;
    }

    return <></>;
  }

  function renderRow(item: IBasicPickerValue<string>) {
    const isLastHeaderItem = item.id === last(pickerHeaderItems)?.id;

    if (isLastHeaderItem) {
      return (
        <>
          <SimplePickerRow value={item.name} searchValue={searchValue} />
          <Divider className="my-2" />
        </>
      );
    }

    return <SimplePickerRow value={item.name} searchValue={searchValue} />;
  }

  const data = React.useMemo((): Array<IBasicPickerValue<string>> => {
    const withHeaderItems: Array<IBasicPickerValue<string>> = [];

    if (pickerHeaderItems) {
      withHeaderItems.push(...pickerHeaderItems);
    }

    if (queriedData) {
      withHeaderItems.push(...queriedData);
    }

    return withHeaderItems;
  }, [pickerHeaderItems, queriedData]);

  if (!organization.trackEquipment) {
    return null;
  }

  const classes = classNames('equipment-picker', className);

  return (
    <DropDownPicker
      value={value}
      dataError={isDataError}
      error={error}
      data={data}
      loadedAll={loadedAll}
      onSelect={handleSelect}
      onClose={onClose}
      onOpen={() => setIsOpen(true)}
      onSearch={setSearchValue}
      searchValue={searchValue}
      valueTemplate={renderValueTemplate()}
      renderRow={renderRow}
      minWidth={minWidth}
      position={position}
      placeholder={placeholder}
      className={classes}
      closeButtonRender={closeButtonRender}
      dropDownButtonRender={dropDownButtonRender}
      defaultFocusIndex={pickerHeaderItems?.length ?? 0}
      setScrollerRef={(scrollerRef) => (scroller.current = scrollerRef)}
      isLoading={isLoading}
    />
  );
};

export default EquipmentPicker;
