import { FetchPolicy, QueryResult, useApolloClient } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Bar, Position, Size } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { useDebounce } from 'hooks';
import _ from 'lodash';
import * as React from 'react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import SearchType from 'types/enum/SearchType';
import IEquipmentMake from 'types/EquipmentMake';
import { stringUtils } from 'utils';
import LazyLoadPicker from '../../../foundation/pickers/LazyLoadPicker/LazyLoadPicker';
import { equipmentMakeQuery, equipmentMakesQuery } from './equipment-makes-picker-queries';
import SimplePickerRow from 'components/foundation/pickers/SimplePickerRow/SimplePickerRow';

export interface IEquipmentMakePickerProps {
  value: string | null;
  equipmentCategoryId: string | null | undefined;
  onSelect: (makeId: string | null) => void;
  minWidth?: string;
  placeholder?: string;
  error?: boolean;
  position?: Position;
  fetchPolicy?: FetchPolicy;
  className?: ClassName;
  dropDownButtonRender?: ReactNode; // Drop down button on the picker
  closeButtonRender?: (handleClear: (event: React.KeyboardEvent | React.MouseEvent) => void) => ReactNode; // Close button render on picker
  autofocus?: boolean;
}

const EquipmentMakeForCategoryPicker = (props: IEquipmentMakePickerProps) => {
  const { value, equipmentCategoryId, onSelect, minWidth, position, fetchPolicy, className, ...rest } = props;

  const client = useApolloClient();

  const [pickerData, setPickerData] = useState<IEquipmentMake[]>([]);
  const [loadedAll, setLoadedAll] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const searchDebounce = useDebounce(searchValue, 250);
  const [apolloError, setApolloError] = useState(false);
  const firstRender = useRef(true);

  // reset list when searching
  useEffect(() => {
    setPickerData([]);
    setLoadedAll(false);
  }, [searchDebounce]);

  // clear list and selected make when the category is changed
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    setPickerData([]);
    setLoadedAll(false);
    onSelect(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentCategoryId]);

  function handleDidLoad(models: IEquipmentMake[], err: boolean, allLoaded: boolean) {
    if (!err) {
      setLoadedAll(allLoaded);
      setPickerData(models);
    }

    setApolloError(err);
  }

  function onSearch(val: string) {
    setSearchValue(val);
  }

  function onClose() {
    setLoadedAll(false);
    setPickerData([]);
    setSearchValue('');
  }

  function handleSelect(model: IEquipmentMake | null) {
    onSelect(model ? model.id : null);
  }

  async function getMakes(after?: string): Promise<IEquipmentMake[]> {
    return new Promise((resolve, reject) => {
      client
        .query({
          fetchPolicy: fetchPolicy || 'cache-first',
          query: equipmentMakesQuery,
          variables: {
            after,
            filter: {
              model: {
                equipmentCategoryId: { equal: equipmentCategoryId },
                deletedOn: { isNull: true },
              },
              deletedOn: { isNull: true },
              unknown: { equal: false },
              search: searchDebounce
                ? {
                    type: SearchType.CONTAINS,
                    fields: ['title'],
                    value: searchDebounce,
                  }
                : undefined,
            },
            first: 10,
          },
        })
        .then((result: { data: { equipmentMakes: IEquipmentMake[] } }) => {
          resolve(result.data.equipmentMakes);
        })
        .catch(() => {
          reject();
        });
    });
  }

  function renderValueTemplate() {
    if (value) {
      const make = _.find(pickerData, (model) => model.id === value);
      if (make) {
        return <div className="ellipsis">{make.title}</div>;
      } else {
        return (
          <Query query={equipmentMakeQuery} variables={{ makeId: value }}>
            {({ data }: QueryResult<{ equipmentMakes: IEquipmentMake[] }>) => {
              return <>{data && data.equipmentMakes.length ? data.equipmentMakes[0].title : <></>}</>;
            }}
          </Query>
        );
      }
    }

    return <></>;
  }

  function renderRow(make: IEquipmentMake) {
    return <SimplePickerRow value={make.title} searchValue={searchValue} />;
  }

  const classes = classNames('equipment-make-picker', className);

  return (
    <LazyLoadPicker
      data-testid={'equipment-make-picker'}
      value={value}
      disabled={_.isNil(equipmentCategoryId)}
      getData={getMakes}
      didLoad={handleDidLoad}
      dataError={apolloError}
      data={pickerData}
      loadedAll={loadedAll}
      onSelect={handleSelect}
      onClose={onClose}
      valueTemplate={renderValueTemplate()}
      renderRow={renderRow}
      minWidth={minWidth}
      position={position}
      searchValue={searchValue}
      onSearch={onSearch}
      className={classes}
      {...rest}
    />
  );
};

EquipmentMakeForCategoryPicker.defaultProps = {
  minWidth: '350px',
};

export default EquipmentMakeForCategoryPicker;
