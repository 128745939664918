import classNames from 'classnames';
import * as React from 'react';
import { ReactNode } from 'react';
import { LazyLoadPickerList } from '../../..';
import PickerListSearchHeader from '../PickerListSearchHeader/PickerListSearchHeader';
import PickerListPicker, { IPickerListPickerProps } from '../PickerListPicker/PickerListPicker';

export interface ILazyLoadPickerProps<T extends { id: string; cursor?: string }>
  extends Omit<IPickerListPickerProps<T, string>, 'children'> {
  data: T[];
  loadedAll: boolean; // The lazy loader will continue to attempt network calls until loadedAll is set to false
  getData: (after?: string) => Promise<T[]>;
  didLoad: (items: T[], error: boolean, loadedAll: boolean) => void; // Needed to update the data and loadedAll props
  renderRow: (row: T, index: number, e?: React.KeyboardEvent) => ReactNode;
  renderSize?: number; // has default
  offset?: number; // has default
  loadingTemplate?: ReactNode;
  dataError?: boolean;
  searchValue?: string; // required for search
  header?: (
    onKeyDown: (event: React.KeyboardEvent) => void,
    setFocusIndex: (n: number) => void
  ) => JSX.Element | JSX.Element[] | HTMLElement;
  willLoad?: () => void;
  onSearch?: (value: string, e: React.ChangeEvent) => void; // required for search
  defaultFocusIndex?: number;
}

function LazyLoadPicker<T extends { id: string; cursor?: string }>({
  value,
  data,
  className,
  autofocus,
  searchValue,
  loadedAll,
  renderSize,
  offset,
  dataError,
  defaultFocusIndex,
  renderRow,
  getData,
  didLoad,
  onSearch,
  onSelect,
  header,
  willLoad,
  ...pickerListProps
}: ILazyLoadPickerProps<T>) {
  const isSearchable = onSearch && searchValue !== undefined;
  const classes = classNames('basic-picker', className);

  return (
    <PickerListPicker {...pickerListProps} value={value} onSelect={onSelect} className={classes}>
      {(handleCloseMenu) => (
        <LazyLoadPickerList
          getData={getData}
          didLoad={didLoad}
          renderRow={renderRow}
          value={value}
          data={data}
          error={dataError}
          loadedAll={loadedAll}
          closeMenu={handleCloseMenu}
          onSelect={onSelect}
          renderSize={renderSize}
          offset={offset}
          willLoad={willLoad}
          // If it's searchable we focus the search field rather than the list.
          autofocus={!isSearchable}
          header={(onKeyDown, setFocusIndex) => {
            return isSearchable ? (
              <PickerListSearchHeader
                className="p-3"
                searchValue={searchValue}
                autofocus={true}
                onSearch={onSearch}
                onKeyDown={onKeyDown}
                setFocusIndex={setFocusIndex}
              />
            ) : (
              header?.(onKeyDown, setFocusIndex)
            );
          }}
          defaultFocusIndex={defaultFocusIndex}
        />
      )}
    </PickerListPicker>
  );
}

LazyLoadPicker.defaultProps = {
  minWidth: '50px',
};

export default LazyLoadPicker;
