import { TableCellHeight } from '@busybusy/webapp-react-ui/dist/components/Table/types/types';
import useTimeCardsSettingsUpdate from 'hooks/models/member-settings/useTimeCardsSettingsUpdate';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import { useCallback, useState } from 'react';

export default function useTimesheetsRowHeight() {
  const memberSettings = useMemberSettings();
  const updateSettings = useTimeCardsSettingsUpdate();
  const [localRowHeight, setLocalRowHeight] = useState(
    memberSettings?.web?.features?.timeCards?.rowHeight ?? 'standard'
  );

  const setRowHeight = useCallback(
    (newRowHeight: TableCellHeight) => {
      setLocalRowHeight(newRowHeight);
      updateSettings([{ key: 'rowHeight', payload: newRowHeight }]);
    },
    [updateSettings]
  );

  return [localRowHeight, setRowHeight] as const;
}
