import { ClassName, Position } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import * as React from 'react';
import { ReactNode } from 'react';
import Picker from '../Picker/Picker';
import useOnMount from 'hooks/utils/useOnMount/useOnMount';
import { Nullable } from 'types/util/Nullable';
import IIdable from 'types/Idable';

export interface IPickerListPickerProps<T extends IIdable<K>, K extends string> {
  'value': K | null;
  'valueTemplate': JSX.Element;
  'onSelect': (row: T | null) => void;
  'dropDownButtonRender'?: ReactNode;
  'closeButtonRender'?: (handleClear: (event: React.KeyboardEvent | React.MouseEvent) => void) => ReactNode;
  'minWidth'?: string;
  'placeholder'?: string;
  'className'?: ClassName;
  'data-testid'?: string;
  'error'?: boolean;
  'position'?: Position;
  'onOpen'?: () => void;
  'onClose'?: () => void;
  'disabled'?: boolean;
  'autofocus'?: boolean;
  'onClear'?: () => void;
  'children': (closeMenu: (shouldFocus: boolean) => void) => React.ReactElement;
}

export default function PickerListPicker<T extends IIdable<K>, K extends string>({
  value,
  placeholder,
  valueTemplate,
  className,
  position,
  error,
  dropDownButtonRender,
  autofocus,
  disabled,
  minWidth = '100px',
  onOpen,
  closeButtonRender,
  onSelect,
  onClear,
  onClose,
  'data-testid': testId,
  children,
}: IPickerListPickerProps<T, K>) {
  const picker = React.useRef<Nullable<HTMLElement>>(null);

  useOnMount(() => {
    if (autofocus && picker.current) {
      picker.current.focus();
    }
  });

  const handleSelect = (row: T | null) => {
    onSelect(row);
    if (picker.current) {
      picker.current.focus();
    }
  };

  const setPickerRef = (ref: HTMLElement) => {
    picker.current = ref;
  };

  function renderContentTemplate(closeMenu: () => void) {
    const handleCloseMenu = (shouldFocus: boolean) => {
      if (picker.current && shouldFocus) {
        picker.current.focus();
      }
      closeMenu();
    };

    return children(handleCloseMenu);
  }

  const classes = classNames('picker-list-picker', className);

  return (
    <Picker
      value={value}
      error={error}
      onSelect={handleSelect}
      onClear={onClear}
      className={classes}
      data-testid={testId}
      position={position}
      placeholder={placeholder}
      valueTemplate={valueTemplate}
      onOpen={onOpen}
      onClose={onClose}
      dropDownButtonRender={dropDownButtonRender}
      closeButtonRender={closeButtonRender}
      forwardRef={setPickerRef}
      minWidth={minWidth}
      contentTemplate={renderContentTemplate}
      disabled={disabled}
    />
  );
}
