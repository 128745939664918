import { Size } from '@busybusy/webapp-react-ui';
import { FlaggedTimeEntryLocation, MemberGpsStatus } from '__generated__/graphql';
import { GpsIcon } from 'assets/icons';
import classNames from 'classnames';
import IconButton from 'components/foundation/buttons/IconButton/IconButton';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import React from 'react';
import { ClassName } from 'types/ClassName';
import IProject from 'types/Project';
import ITimeEntry from 'types/TimeEntry';
import { useFeatureFlags } from 'utils/features';
import { getDisabledMemberGpsStatuses, shouldShowFlaggedTimeEntryLocationIcon } from 'utils/locationUtils';
import { hasLocation } from 'utils/timeEntryUtils';
import './TimeEntryGPSIcon.scss';

export interface ITimeEntryGPSIconProps {
  className?: ClassName;
  timeEntry: Pick<ITimeEntry, 'startLocation' | 'endLocation' | 'breaks' | 'hasLocation' | 'startTime' | 'endTime'>;
  flaggedTimeEntryLocation?: FlaggedTimeEntryLocation | null;
  project?: IProject | null;
  memberGpsStatuses: Pick<MemberGpsStatus, 'status' | 'localTime'>[];
  onClick: () => void;
}

const TimeEntryGPSIcon = (props: ITimeEntryGPSIconProps) => {
  const { timeEntry, flaggedTimeEntryLocation, memberGpsStatuses, project, onClick, className } = props;

  const gpsStatusDisabledFeature = useFeatureFlags('GPS_STATUS_DISABLED');
  const locationFlaggingFeature = useFeatureFlags('LOCATION_FLAGGING');
  let iconClassName: string | undefined = undefined;
  let showIcon = false;

  if (hasLocation(timeEntry) || timeEntry.hasLocation) {
    showIcon = true;
  }

  if (
    locationFlaggingFeature &&
    flaggedTimeEntryLocation &&
    project &&
    project.projectInfo &&
    shouldShowFlaggedTimeEntryLocationIcon(timeEntry, flaggedTimeEntryLocation, project)
  ) {
    iconClassName = `gps-icon-warning`;
    showIcon = true;
  }

  if (
    gpsStatusDisabledFeature &&
    !isEmpty(memberGpsStatuses) &&
    !isEmpty(getDisabledMemberGpsStatuses(memberGpsStatuses, timeEntry))
  ) {
    iconClassName = `gps-icon-disabled`;
    showIcon = true;
  }

  return (
    showIcon && (
      <IconButton
        key="location"
        size={Size.MEDIUM}
        onClick={(event: React.MouseEvent) => {
          event.stopPropagation();
          event.preventDefault();
          onClick();
        }}
        svg={GpsIcon}
        iconTheme="light-gray"
        tooltipLabel={t('Locations')}
        className={classNames('time-entry-gps-icon', 'no-print', 'button', iconClassName, className)}
      />
    )
  );
};

export default TimeEntryGPSIcon;
