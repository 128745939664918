import { RequestOptions } from 'graphql-request';
import { useCallback } from 'react';
import ICursorable from 'types/Cursorable';
import { IDictionary } from 'types/Dictionary';
import { KeysOfType } from 'types/util/KeysOfType';
import { AnyObject } from 'types/util/Object';
import { Optional } from 'types/util/Optional';
import { graphqlPagingLoopShouldBreak, parseCursor } from 'utils/graphQlUtils';
import useGraphQLClient from '../useGraphQLClient/useGraphQLClient';
import { mapNotNil } from 'utils/collectionUtils';

export default function useGraphQLPaging() {
  const client = useGraphQLClient();

  return useCallback(
    async <
      Response extends AnyObject,
      Variables extends IDictionary<any> = AnyObject,
      ResponseData extends ICursorable = ICursorable,
    >(
      options: RequestOptions<Variables>,
      key: KeysOfType<Response, Optional<ResponseData[]>>,
      pageSize: number = 50
    ) => {
      const fn = async (after: string | null, first: number) => {
        return client.request<Response, Variables>({ ...options, variables: { ...options.variables, first, after } });
      };

      const pages: Response[] = [];
      let after: string | null = null;

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const page: Response = await fn(after, pageSize);
        const pageData = page[key];
        if (pageData && pageData.length) {
          pages.push(page);
        }

        if (graphqlPagingLoopShouldBreak(pageData, pageSize)) {
          break;
        }

        after = parseCursor(pageData, after);
      }

      return mapNotNil(pages, (page) => page[key] ?? null).flat();
    },
    [client]
  );
}
