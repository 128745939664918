import type { JobSafetyAnalysis } from '__generated__/graphql';
import type { ITimeActionsFormData } from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import useJsaReportsEnabled from 'containers/jsa-reports/hooks/useJsaReportsEnabled/useJsaReportsEnabled';
import { useJsaReportsInvalidQueryData } from 'containers/jsa-reports/hooks/useJsaReportsInvalidQueryData/useJsaReportsInvalidQueryData';
import { useLoader } from 'contexts/LoaderContext';
import { useToastOpen } from 'contexts/ToastContext';
import { useActiveMember, useOpenable } from 'hooks';
import type { IOpenableHook } from 'hooks/utils/useOpenable';
import type { TTimeActionsEntryDialogType } from 'hooks/utils/useTimeActions';
import { isNil } from 'lodash';
import { useState } from 'react';
import type ICostCode from 'types/CostCode';
import type IEquipment from 'types/Equipment';
import type IProject from 'types/Project';
import { t } from 'utils/localize';
import { useDetermineJsaReviewNeeded } from './useDetermineJsaScheduleStatus';
import { useDetermineJsaToReview } from './useDetermineJsaToReview';

export const JSA_REVIEW_TIME_ENTRY_DIALOG_TYPES: TTimeActionsEntryDialogType[] = [
  'clock-in',
  'clock-in-at',
  'switch',
  'switch-at',
] as const;

export const isJsaReviewEntryDialogType = (entryDialogType: TTimeActionsEntryDialogType) => {
  return entryDialogType && ['clock-in', 'clock-in-at', 'switch', 'switch-at'].includes(entryDialogType);
};

interface IUseJsaReviewPropsState {
  jobSafetyAnalysisId?: JobSafetyAnalysis['id'];
  costCodeId?: ICostCode['id'];
  projectId?: IProject['id'];
  equipmentId?: IEquipment['id'];
}
export interface IUseJsaReviewResults {
  jsaReviewDialog: IOpenableHook;
  props: IUseJsaReviewPropsState | undefined;
  launchJsaReview: (formData: ITimeActionsFormData) => void;
}
export const useJsaReview = (onComplete: (memberIds: string[]) => void): IUseJsaReviewResults => {
  const activeUser = useActiveMember();
  const isJsaReportsFeatureEnabled = useJsaReportsEnabled();
  const determineJsaToReview = useDetermineJsaToReview();
  const determineJsaReviewNeeded = useDetermineJsaReviewNeeded();
  const invalidateJsaReportsData = useJsaReportsInvalidQueryData();
  const [timeActionsData, setTimeActionsData] = useState<ITimeActionsFormData>();
  const [props, setProps] = useState<IUseJsaReviewPropsState>();
  const loader = useLoader();
  const toastOpen = useToastOpen();

  const handleClose = (members?: ITimeActionsFormData['members']) => {
    // invoke the onComplete callback set in useTimeActions when the dialog is closed
    onComplete(members ?? timeActionsData?.members ?? []);
    setTimeActionsData(undefined);
    setProps(undefined);
    return invalidateJsaReportsData();
  };

  const jsaReviewDialog = useOpenable({
    onClose: handleClose,
  });

  const launchJsaReview = (formData: ITimeActionsFormData) => {
    if (!isJsaReportsFeatureEnabled) {
      return handleClose(formData.members);
    }

    // JSA review is only triggered if the active user is part of the time action
    if (!formData.members.includes(activeUser.id)) {
      return handleClose(formData.members);
    }

    // hoist formData to the body of the hook so the dialog has access to it
    setTimeActionsData(formData);

    const costCodeId = formData.costCode ?? undefined;
    const projectId = formData.project ?? undefined;

    loader.open(t('Loading...'));
    return determineJsaToReview({ costCodeId, projectId })
      .then((jsaCandidate) => {
        // no associated JSA to review, allow the time action to close out
        if (isNil(jsaCandidate)) {
          return handleClose(formData.members);
        }

        return determineJsaReviewNeeded(jsaCandidate)
          .then((isReviewNeeded) => {
            if (!isReviewNeeded) {
              // the associated JSA is up to date, allow the time action to close out
              return handleClose(formData.members);
            } else {
              // set props needed by JsaReviewDialog
              setProps({ jobSafetyAnalysisId: jsaCandidate.id, costCodeId, projectId });
            }
          })
          .then(() => invalidateJsaReportsData())
          .then(() => {
            return jsaReviewDialog.open();
          });
      })
      .catch((launchJsaReviewError: unknown) => {
        // eslint-disable-next-line no-console
        console.error('Error determining JSA to review', { cause: { launchJsaReviewError } });
        // if something goes wrong, allow the time action to close out
        toastOpen({ label: t('Could not load JSA') });
        return handleClose(formData.members);
      })
      .finally(() => {
        loader.close();
      });
  };

  return { jsaReviewDialog, props, launchJsaReview };
};
