import {
  Align,
  Button,
  ButtonList,
  ClassName,
  ITableColumn,
  Justify,
  Position,
  Row,
  Table,
  TextAlign,
  Theme,
} from '@busybusy/webapp-react-ui';
import { MemberPermissions } from '__generated__/graphql';
import classNames from 'classnames';
import { EmptyState, MoreButton, Spinner } from 'components';
import ProFeatureDialog from 'components/domain/account/ProFeatureDialog/ProFeatureDialog';
import useMemberTimeEntryDataReportData, {
  IMemberTimeEntryReportDataFilter,
  ITimeEntryDataReportMemberData,
} from 'components/domain/time-entry/TimeEntryDataReport/MemberTimeEntryDataReport/hooks/useMemberTimeEntryDataReportData';
import { ITimeEntryDataTableRow } from 'components/domain/time-entry/TimeEntryDataTable/TimeEntryDataTable';
import TimeActionsFormDialog from 'components/domain/time-entry/time-actions-form/TimeActionsFormDialog/TimeActionsFormDialog';
import TimeOffFormDialog from 'components/domain/time-off/form/TimeOffFormDialog/TimeOffFormDialog';
import RowSettingsDialog from 'components/foundation/dialogs/RowSettingsDialog/RowSettingsDialog';
import TableColumnsDialog from 'components/foundation/table/TableColumnsDialog/TableColumnsDialog';
import { useLoader } from 'contexts/LoaderContext';
import { useToastOpen } from 'contexts/ToastContext';
import { useEmployeeNameFormat, useOpenable, useOrganization, useReduxSelector, useTimesheetsGraylog } from 'hooks';
import useMemberGraphAggregates from 'hooks/aggregates/useMemberGraphAggregates';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import useReactQueryBaseKey from 'hooks/react-query/useReactQueryBaseKey/useReactQueryBaseKey';
import { IUseTimeRangePayload } from 'hooks/utils/useTimeRange';
import { t } from 'i18next';
import { first } from 'lodash';
import React, { ReactNode, useMemo, useRef, useState } from 'react';
import { TimesheetView } from 'store/timesheets/Timesheets';
import TimeRangeType from 'types/TimeRangeType';
import { Nullable } from 'types/util/Nullable';
import { TimesheetsTypes } from 'utils/constants/graylogActionTypes';
import { isoTimeStampLocal } from 'utils/dateUtils';
import { useFeatureFlags } from 'utils/features';
import { downloadText } from 'utils/fileUtils';
import { executeFunctionsCurried } from 'utils/functionUtils';
import SignOffReportDialog from '../SignOffReportDialog/SignOffReportDialog';
import TimesheetsActionHeader from '../TimesheetsActionHeader/TimesheetsActionHeader';
import TimesheetsColumnsForm from '../TimesheetsColumnsForm/TimesheetsColumnsForm';
import TimesheetsHeader from '../TimesheetsHeader/TimesheetsHeader';
import TimesheetsPrintDialog from '../TimesheetsPrintDialog/TimesheetsPrintDialog';
import useTimesheetsQueryParams from '../hooks/useTimesheetsQueryParams';
import useTimesheetsRowHeight from '../hooks/useTimesheetsRowHeight/useTimesheetsRowHeight';
import { convertTimeEntryDataToCsv } from '../utils/utils';
import './WeeklyGridReport.scss';

export interface IWeeklyGridReportProps extends IUseTimeRangePayload {
  className?: ClassName;
  permission: MemberPermissions;
}

export interface WeeklyGridReportData {
  id: string;
  employeeName: string;
  weeklyTotal: string;
  weeklyOt: string;
  weeklyDt: string;
}

const WeeklyGridReport = (props: IWeeklyGridReportProps) => {
  const { className, timeRange, permission, forward, forwardEnabled, back, backEnabled, timeRangeType } = props;

  const scroller = useRef<Nullable<HTMLDivElement>>(null);
  const weeklyColumns = useReduxSelector((state) => state.timesheet.weeklyColumns);
  const dailyColumns = useReduxSelector((state) => state.timesheet.dailyColumns);
  const organization = useOrganization();

  const isPro = useFeatureFlags('PRO');

  const addTimeEntryDialog = useOpenable();
  const addTimeOffDialog = useOpenable();
  const dailySignOffDialog = useOpenable();
  const columnDialogDetails = useOpenable();
  const proUpgradeDialogDetails = useOpenable();
  const printDetails = useOpenable();
  const rowSettingsDialog = useOpenable();

  const isDailySignOffEnabled = organization.safetySignature || organization.timeAccuracy || organization.breakPolicy;

  const [employeeNameFormat] = useEmployeeNameFormat();

  const baseQueryKey = useReactQueryBaseKey();
  const { memberGroupId, memberId, positionId, archivedStatus } = useTimesheetsQueryParams();
  const [rowHeight, setRowHeight] = useTimesheetsRowHeight();

  const userEvents = useTimesheetsGraylog();
  const { open: openLoader, close: closeLoader } = useLoader();
  const openToast = useToastOpen();

  const brand = useBrandTitle();

  const filter = useMemo<IMemberTimeEntryReportDataFilter>(
    () => ({
      timeRange,
      memberGroupId,
      memberId,
      positionId,
      archivedStatus,
      memberPermissions: [permission],
    }),
    [archivedStatus, memberGroupId, memberId, permission, positionId, timeRange]
  );
  const queryKey = useMemo(
    () => [baseQueryKey, 'timesheets-member-time-data', employeeNameFormat, filter, weeklyColumns, dailyColumns],
    [baseQueryKey, dailyColumns, employeeNameFormat, filter, weeklyColumns]
  );

  const {
    data,
    onDataChange: handleDataChange,
    loadAll,
    loading,
  } = useMemberTimeEntryDataReportData(scroller.current ?? null, filter, queryKey);

  const [selectedMemberId, setSelectedMemberId] = useState<string>();

  const selectedMembers = useMemo(() => (selectedMemberId ? [selectedMemberId] : []), [selectedMemberId]);

  const handleAddTimeEntryDialogClose = () => {
    addTimeEntryDialog.close();
    setSelectedMemberId(undefined);
  };

  const handleAddTimeOffDialogClose = () => {
    addTimeOffDialog.close();
    setSelectedMemberId(undefined);
  };

  const handleDailySignOffDialogClose = () => {
    dailySignOffDialog.close();
    setSelectedMemberId(undefined);
  };

  const renderEmployeeCell = (row: ITimeEntryDataReportMemberData) => {
    const renderDropDown = (closeDropdown: () => void) => {
      const handleAddTimeEntryClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        closeDropdown();
        setSelectedMemberId(row.id);
        addTimeEntryDialog.open();
      };
      const handleAddTimeOffClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        closeDropdown();
        setSelectedMemberId(row.id);
        addTimeOffDialog.open();
      };
      const handleViewDailySignOffClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        closeDropdown();
        setSelectedMemberId(row.id);
        dailySignOffDialog.open();
      };

      return (
        <ButtonList>
          <Button onClick={handleAddTimeEntryClick}>{t('Add Time Entry')}</Button>
          <Button onClick={handleAddTimeOffClick}>{t('Add Time Off')}</Button>
          {isPro && isDailySignOffEnabled && (
            <Button onClick={handleViewDailySignOffClick}>{t('View Daily Sign-Off')}</Button>
          )}
        </ButtonList>
      );
    };

    return (
      <div className="employee-cell">
        <MoreButton className={`no-print button`} position={Position.BOTTOM_START} renderContent={renderDropDown} />
        <Row align={Align.CENTER} justify={Justify.SPACE_BETWEEN} className={'pl-2'}>
          {row.title}
        </Row>
      </div>
    );
  };

  const renderDateCell = (row: ITimeEntryDataReportMemberData, col: ITableColumn<ITimeEntryDataReportMemberData>) => {
    const currentDateData = first(row.rows.filter((data) => data.date === col.title));
    const cols = dailyColumns
      .filter((c) => c.visible)
      .map((dailyCol) => {
        const column: ITableColumn<ITimeEntryDataReportMemberData> = {
          ...dailyCol,
        };
        return column;
      });

    return (
      <div className="date-cell-table p-0">
        <div className="date-cell-row p-0">
          {cols.map((c) => {
            const key = c.key as keyof ITimeEntryDataTableRow;
            return <div className="date-cell-td" key={key}></div>;
          })}
        </div>
      </div>
    );
  };

  const renderEmptyState = (): ReactNode => <EmptyState title={t('No Data')} />;

  const getDaysOfWeekCols = () => {
    let currentDate = timeRange.startTime.startOf('day');
    const endDay = timeRange.endTime.endOf('day');
    const days = [];
    while (currentDate < endDay) {
      days.push(currentDate.toFormat('EEE, MMM d'));
      currentDate = currentDate.plus({ days: 1 });
    }

    const renderHeader = (col: ITableColumn<ITimeEntryDataReportMemberData>) => {
      const cols = dailyColumns
        .filter((c) => c.visible)
        .map((dailyCol) => {
          const column: ITableColumn<ITimeEntryDataReportMemberData> = {
            ...dailyCol,
          };
          return column;
        });
      return (
        <>
          <div className="px-4 py-1">{col.title}</div>
          {cols.length > 0 && (
            <div className="date-cell-row header p-0">
              {cols.map((c) => (
                <div className="date-cell-td pb-2 m-0" key={c.key}>
                  {c.title}
                </div>
              ))}
            </div>
          )}
        </>
      );
    };

    return days.map((day) => {
      const visibleColumns = dailyColumns.filter((col) => col.visible);
      const size = `${visibleColumns.length * 75}px`;
      const column: ITableColumn<ITimeEntryDataReportMemberData> = {
        key: day,
        title: day,
        align: TextAlign.CENTER,
        cellClassName: 'p-0 m-0 date-cell',
        cell: (row: ITimeEntryDataReportMemberData, col: ITableColumn<ITimeEntryDataReportMemberData>) => {
          return renderDateCell(row, col);
        },
        header: renderHeader,
        headerClassName: 'date-cell',
        size,
      };
      return column;
    });
  };

  const getColumns = (): Array<ITableColumn<ITimeEntryDataReportMemberData>> => {
    const cols = [
      {
        key: 'title',
        title: t('Employee'),
        cell: (row: ITimeEntryDataReportMemberData) => renderEmployeeCell(row),
        align: TextAlign.LEFT,
        size: '350px',
        cellClassName: 'p-0',
        headerClassName: 'employee-header',
      },
    ];

    const visibleColumns = weeklyColumns
      .filter((c) => c.visible)
      .map((col) => {
        const column: ITableColumn<ITimeEntryDataReportMemberData> = { ...col, align: TextAlign.CENTER, size: '75px' };
        return column;
      });

    return [...cols, ...visibleColumns, ...getDaysOfWeekCols()];
  };

  const calculateColumnsWidth = () => {
    const dayOverviewColumnsLength = getColumns().length - 2;
    const visibleDailyColumns = dailyColumns.filter((col) => col.visible);
    const dayDetailsColumnsLength = visibleDailyColumns.length * 75;
    const visibleWeeklyColumns = weeklyColumns.filter((col) => col.visible);
    const weeklyColumnsLength = visibleWeeklyColumns.length * 75;
    return dayOverviewColumnsLength * dayDetailsColumnsLength + 350 + weeklyColumnsLength;
  };

  const {
    data: graphAggregates,
    error: graphAggregateError,
    refetchData: refetchAggregates,
  } = useMemberGraphAggregates(
    timeRange,
    filter.memberIds,
    filter.projectIds,
    filter.costCodeIds,
    filter.equipmentIds,
    filter.memberGroupId,
    2,
    filter.archivedStatus
  );

  function onDataChange() {
    handleDataChange();
    refetchAggregates();
  }

  async function onExport() {
    if (!isPro) {
      proUpgradeDialogDetails.open();
      return;
    }

    userEvents.events(TimesheetsTypes.events.action_type.EXPORT);
    openLoader(t('Loading...'));

    try {
      const data = await loadAll();

      if (data.length !== 0) {
        const csv = convertTimeEntryDataToCsv(data, false);
        downloadText(csv, `${brand}-${t('timesheets-time-entries')}-${isoTimeStampLocal()}.csv`);
      } else {
        openToast({ theme: Theme.DANGER, label: t('There is no data to export.') });
      }
    } catch (e) {
      openToast({ theme: Theme.DANGER, label: t('Something went wrong exporting your data.') });
    } finally {
      closeLoader();
    }
  }

  function onPrint() {
    printDetails.open();
    userEvents.events(TimesheetsTypes.events.action_type.PRINT);
  }

  async function handlePrint() {
    printDetails.close();
    openLoader(t('Loading...'));
    try {
      await loadAll();

      setTimeout(() => {
        window.print();
      }, 500);
    } catch (e) {
      openToast({ theme: Theme.DANGER, label: t('Something went wrong loading your data.') });
    } finally {
      closeLoader();
    }
  }

  const classes = classNames('weekly-grid-container', 'overflow-y-auto', 'full-height', className);

  return (
    <>
      <TimesheetsActionHeader
        className="no-print"
        timeRange={timeRange}
        timeRangeType={timeRangeType}
        onTimeRangeForward={forward}
        onTimeRangeBackward={back}
        forwardDisabled={!forwardEnabled()}
        backwardDisabled={!backEnabled()}
        onTimeEntryAdd={addTimeEntryDialog.open}
        onTimeOffAdd={addTimeOffDialog.open}
        onColumnsClick={columnDialogDetails.open}
        onExport={onExport}
        onPrint={onPrint}
        onRowHeightClick={rowSettingsDialog.open}
        actionBar={null}
      />

      <div className={classes} ref={scroller}>
        <TimesheetsHeader
          className="m-6 no-print"
          timeRange={timeRange}
          graphData={graphAggregates}
          aggregateError={graphAggregateError?.name ?? null}
          showDecimalFormat={false}
        />

        {loading ? (
          <Row justify={Justify.CENTER} align={Align.CENTER} className="py-8">
            <Spinner />
          </Row>
        ) : (
          <Table
            cols={getColumns()}
            data={data ?? []}
            emptyTemplate={renderEmptyState()}
            className={'weekly-grid-report'}
            cellHeight={rowHeight}
            scroller={scroller.current ?? undefined}
            minWidth={`${calculateColumnsWidth()}px`}
          />
        )}
      </div>

      <TimeActionsFormDialog
        type={'add'}
        isOpen={addTimeEntryDialog.isOpen}
        onClose={handleAddTimeEntryDialogClose}
        memberIds={selectedMembers}
        onSubmit={executeFunctionsCurried(onDataChange, addTimeEntryDialog.close)}
        onDelete={executeFunctionsCurried(onDataChange, addTimeEntryDialog.close)}
      />
      <TimeOffFormDialog
        isOpen={addTimeOffDialog.isOpen}
        onClose={handleAddTimeOffDialogClose}
        memberIds={selectedMembers}
        onSubmit={executeFunctionsCurried(onDataChange, addTimeOffDialog.close)}
      />
      <SignOffReportDialog
        isOpen={dailySignOffDialog.isOpen}
        onClose={handleDailySignOffDialogClose}
        timeRange={timeRange}
        memberIds={selectedMembers}
        timeRangeType={TimeRangeType.WEEKLY}
        onDataChange={executeFunctionsCurried(onDataChange, dailySignOffDialog.close)}
      />

      <TableColumnsDialog
        isOpen={columnDialogDetails.isOpen}
        onClose={columnDialogDetails.close}
        columnsForm={
          <TimesheetsColumnsForm timesheetView={TimesheetView.WEEKLY_GRID} onClose={columnDialogDetails.close} />
        }
      />

      <RowSettingsDialog
        isOpen={rowSettingsDialog.isOpen}
        onClose={rowSettingsDialog.close}
        initialValue={rowHeight}
        onSubmit={setRowHeight}
      />

      <ProFeatureDialog isOpen={proUpgradeDialogDetails.isOpen} onClose={proUpgradeDialogDetails.close} />
      <TimesheetsPrintDialog isOpen={printDetails.isOpen} onClose={printDetails.close} onPrint={() => handlePrint()} />
    </>
  );
};

export default WeeklyGridReport;
