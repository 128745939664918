import { useApolloClient } from '@apollo/client';
import { Button, ButtonList, Divider, Label, Loader, Position, Theme, Toast, Tray } from '@busybusy/webapp-react-ui';
import { MEMBERS_QUERY_WITH_OPEN_ENTRY } from 'apollo/queries/member-queries';
import { SUBMISSIONS_REPORT_QUERY } from 'apollo/queries/member-sign-in-submission';
import { SAFETY_SIGNATURES_QUERY } from 'apollo/queries/safety-signature-query';
import { TIME_ENTRIES_WITH_MEMBER_POSITION_AND_GPS_STATUS } from 'apollo/queries/time-entry-queries';
import classNames from 'classnames';
import { HeaderDialog, MoreButton } from 'components';
import DailySignOffFormDialog from 'components/domain/daily-sign-off/DailySignOff/DailySignOffForm/DailySignOffFormDialog';
import SignInQuestionForm from 'components/domain/member-group/SignInQuestionForm/SignInQuestionForm';
import useSignInAnswerCheck from 'components/domain/time-entry/time-actions-form/ClockInForm/hooks/useSignInAnswerCheck';
import TimeActionsFormDialog from 'components/domain/time-entry/time-actions-form/TimeActionsFormDialog/TimeActionsFormDialog';
import EmployeeTimersActionToast from 'containers/employee-timers/EmployeeTimersActionToast/EmployeeTimersActionToast';
import JsaReviewDialog from 'containers/jsa-reports/JsaReportsManagement/JsaReview/JsaReviewDialog';
import { renderTimersColumn } from 'containers/landing-pages/landingPageUtils';
import { useActiveMember, useOpenable, useOrganization, usePermissions, useReduxSelector } from 'hooks';
import useOnMount from 'hooks/utils/useOnMount/useOnMount';
import useTimeActions from 'hooks/utils/useTimeActions';
import { cloneDeep, first, isEmpty } from 'lodash';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import { DateTime } from 'luxon';
import * as React from 'react';
import { ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setEmployeeTimersRowInfo } from 'store/employeeTimers/EmployeeTimers';
import { IMember } from 'types';
import { ClassName } from 'types/ClassName';
import { IMemberSignInSubmission } from 'types/MemberSignInSubmission';
import ISafetySignature from 'types/SafetySignature';
import ITimeEntry from 'types/TimeEntry';
import ITimeRange from 'types/TimeRange';
import { projectUtils, stringUtils } from 'utils';
import DateTimeFormat from 'utils/constants/dateTimeFormat';
import { dateTimeFromISOKeepZone } from 'utils/dateUtils';
import { useFeatureFlags } from 'utils/features';
import { breakIsUnderRequiredMinutes } from 'utils/timeEntryBreakUtils';
import './EmployeeDashboardTimeActionsCard.scss';

export interface IEmployeeDashboardTimeActionsCardProps {
  className?: ClassName;
  memberId: string;
  onActionComplete?: () => void;
}

export enum EmployeeDashboardClockState {
  CLOCKED_IN,
  CLOCKED_OUT,
  ON_BREAK,
}

const EmployeeDashboardTimeActionsCard = (props: IEmployeeDashboardTimeActionsCardProps) => {
  const { className, memberId, onActionComplete } = props;

  const client = useApolloClient();
  const { t } = useTranslation();
  const organization = useOrganization();
  const [member, setMember] = useState<IMember | null>(null);
  const [timeEntry, setTimeEntry] = useState<ITimeEntry | null>(null);
  const dialogEntry = useRef<ITimeEntry | null>(null);
  const [canBeManaged, setCanBeManaged] = useState<boolean>(false);
  const [canBeTracked, setCanBeTracked] = useState<boolean>(false);
  const { hasPermissionToManage } = usePermissions();
  const errorToastDetails = useOpenable();
  const errorToastMessage = useRef(t('There was an unexpected error.'));
  const [status, setStatus] = useState<EmployeeDashboardClockState>(EmployeeDashboardClockState.CLOCKED_OUT);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const timeActions = useTimeActions(handleTimeActionsCompletion);
  const activeMember = useActiveMember();
  const { answerCheck, setSubmissionId, setMemberIds } = useSignInAnswerCheck();
  const dailySignInDialog = useOpenable();
  const hasMinBreakSeconds = !isNil(organization.minBreakSeconds) && organization.minBreakSeconds > 0;
  const minimumBreakDialog = useOpenable();
  const defaultTimeRange: ITimeRange<DateTime> = {
    startTime: DateTime.now().startOf('day'),
    endTime: DateTime.now().endOf('day'),
  };
  const isPro = useFeatureFlags('PRO');

  const [hasSignature, setHasSignature] = useState(false);
  const [hasSignInSubmission, setHasSignInSubmission] = useState(false);

  const dispatch = useDispatch();
  const employeeTimersData = useReduxSelector((state) => state.employeeTimers.data);
  const breakDuration = hasMinBreakSeconds ? organization.minBreakSeconds! / 60 : 30;

  useOnMount(() => {
    getData();
    setMemberIds([memberId]);

    const timerId = setInterval(() => {
      getData();
    }, 60 * 1000); // refresh every minute

    return () => clearInterval(timerId);
  });

  async function getData() {
    setIsLoading(true);

    let timeEntry: ITimeEntry | null = null;

    const openEntryResult = await client.query<{ timeEntries: ITimeEntry[] }>({
      query: TIME_ENTRIES_WITH_MEMBER_POSITION_AND_GPS_STATUS,
      fetchPolicy: 'network-only',
      variables: {
        first: 1,
        filter: {
          memberId: { equal: memberId },
          endTime: { isNull: true },
          deletedOn: { isNull: true },
        },
        sort: [{ startTime: 'desc' }, { updatedOn: 'desc' }],
      },
    });

    if (openEntryResult.errors) {
      showToastError(t('Failed to get employee information.'));
      return;
    }

    if (isEmpty(openEntryResult.data.timeEntries)) {
      const closedEntryResult = await client.query<{ timeEntries: ITimeEntry[] }>({
        query: TIME_ENTRIES_WITH_MEMBER_POSITION_AND_GPS_STATUS,
        fetchPolicy: 'network-only',
        variables: {
          first: 1,
          filter: {
            memberId: { equal: memberId },
            deletedOn: { isNull: true },
          },
          sort: [{ startTime: 'desc' }, { updatedOn: 'desc' }],
        },
      });

      if (closedEntryResult.errors) {
        showToastError(t('Failed to get employee information.'));
        return;
      }

      timeEntry = closedEntryResult.data.timeEntries[0];
    } else {
      timeEntry = openEntryResult.data.timeEntries[0];
    }

    const signatures = await client.query<{ safetySignatures: ISafetySignature[] }>({
      query: SAFETY_SIGNATURES_QUERY,
      variables: {
        first: 500,
        filter: {
          startTime: {
            lessThanOrEqual: defaultTimeRange.endTime
              .toUTC()
              .toISO({ suppressMilliseconds: true, includeOffset: false }),
          },
          endTime: {
            greaterThanOrEqual: defaultTimeRange.startTime
              .toUTC()
              .toISO({ suppressMilliseconds: true, includeOffset: false }),
          },
          memberId: { equal: memberId },
          deletedOn: { isNull: true },
        },
        sort: [{ startTime: 'asc' }, { createdOn: 'asc' }],
      },
      fetchPolicy: 'network-only',
    });

    const submissions = await client.query<{ memberSignInSubmissions: IMemberSignInSubmission[] }>({
      query: SUBMISSIONS_REPORT_QUERY,
      variables: {
        first: 500,
        filter: {
          localTime: {
            lessThanOrEqual: defaultTimeRange.endTime
              .toUTC()
              .toISO({ suppressMilliseconds: true, includeOffset: false }),
            greaterThanOrEqual: defaultTimeRange.startTime
              .toUTC()
              .toISO({ suppressMilliseconds: true, includeOffset: false }),
          },
          memberId: { equal: activeMember.id },
          deletedOn: { isNull: true },
        },
        sort: [{ localTime: 'asc' }],
      },
      fetchPolicy: 'network-only',
    });

    if (!isNil(timeEntry)) {
      setTimeEntry(null); // force state refresh
      setMember(null);
      setTimeEntry(timeEntry);
      dialogEntry.current = timeEntry;
      setStatus(getStatus(timeEntry));
      setMember(timeEntry.member);
      setCanBeManaged(hasPermissionToManage(timeEntry.member, 'manageTimeEntries'));
      setCanBeTracked(hasPermissionToManage(timeEntry.member, 'timeEvents'));
      const staleData = cloneDeep(employeeTimersData);
      const rowToUpdate = first(staleData.filter((row) => row.member.id === timeEntry?.member.id));
      if (rowToUpdate) {
        if (isNil(timeEntry.endTime)) {
          rowToUpdate.openTimeEntry = timeEntry;
          rowToUpdate.member = {
            ...rowToUpdate.member,
            openTimeEntry: timeEntry,
          };
        } else {
          rowToUpdate.openTimeEntry = null;
          rowToUpdate.member = {
            ...rowToUpdate.member,
            openTimeEntry: null,
          };
        }
        dispatch(setEmployeeTimersRowInfo(staleData));
      }
    } else {
      const memberResult = await client.query<{ members: IMember[] }>({
        query: MEMBERS_QUERY_WITH_OPEN_ENTRY,
        fetchPolicy: 'network-only',
        variables: {
          first: 1,
          filter: {
            id: { equal: memberId },
            archivedOn: { isNull: true },
          },
        },
      });

      if (memberResult.errors) {
        showToastError(t('Failed to get employee information.'));
        return;
      }
      setTimeEntry(null); // force state refresh
      dialogEntry.current = null;
      setMember(null);
      setMember(memberResult.data.members[0]);
      setCanBeManaged(hasPermissionToManage(memberResult.data.members[0], 'manageTimeEntries'));
      setCanBeTracked(hasPermissionToManage(memberResult.data.members[0], 'timeEvents'));
    }

    setHasSignature(signatures.data.safetySignatures.length > 0);
    setHasSignInSubmission(submissions.data.memberSignInSubmissions.length > 0);
    setIsLoading(false);
  }

  function handleTimeActionsCompletion() {
    timeActions.loaderDetails.close();
    getData();
    onActionComplete?.();
  }

  function getStatus(entry: ITimeEntry | null | undefined): EmployeeDashboardClockState {
    if (!isNil(entry) && isNil(entry?.endTime)) {
      if (!isNil(entry?.breaks) && !isEmpty(entry?.breaks.filter((b) => isNil(b.endTime)))) {
        return EmployeeDashboardClockState.ON_BREAK;
      }
      return EmployeeDashboardClockState.CLOCKED_IN;
    } else {
      return EmployeeDashboardClockState.CLOCKED_OUT;
    }
  }

  function getTimerStatusTitle() {
    let statusTitle = t('Clocked Out');
    switch (status) {
      case EmployeeDashboardClockState.CLOCKED_IN:
        statusTitle = t('Clocked In');
        break;
      case EmployeeDashboardClockState.ON_BREAK:
        statusTitle = t('On Break');
        break;
    }
    return <div className="status-title">{statusTitle}</div>;
  }

  function getTimerStatusIcon() {
    let statusClass = 'clocked-out-circle';
    switch (status) {
      case EmployeeDashboardClockState.CLOCKED_IN:
        statusClass = 'clocked-in-circle';
        break;
      case EmployeeDashboardClockState.ON_BREAK:
        statusClass = 'on-break-circle';
        break;
    }
    return <div className={statusClass} />;
  }

  function getTimerTotal() {
    if (!isNull(timeEntry) && isNil(timeEntry.endTime)) {
      return <div className="timers-data ml-2 mt-1">{renderTimersColumn(timeEntry, organization, true)}</div>;
    }
    if (!isNull(timeEntry) && !isNil(timeEntry.endTime)) {
      const endDate = DateTime.fromISO(timeEntry.endTime);
      return (
        <div className="ml-2 mt-1">
          <Label>{endDate.toRelative()}</Label>
        </div>
      );
    }
    return (
      <div className="ml-2 mt-1">
        <Label>{'0:00'}</Label>
      </div>
    );
  }

  function getEntryDetails() {
    const details: ReactNode[] = [];
    if (!isNull(timeEntry)) {
      if (!isNil(timeEntry?.project)) {
        details.push(
          <Label className={!isNil(timeEntry.endTime) ? 'entry-details-disabled' : undefined} key="project-details">
            {projectUtils.getFormattedPathFromProject(timeEntry?.project, true)}
          </Label>
        );
      } else {
        details.push(
          <Label className={'entry-details-disabled'} key="project-details">
            {t('No Project')}
          </Label>
        );
      }

      if (organization && organization.trackCostCode) {
        if (!isNil(timeEntry?.costCode)) {
          details.push(
            <Label className={!isNil(timeEntry.endTime) ? 'entry-details-disabled' : undefined} key="cost-code-details">
              {stringUtils.getCostCodeDisplay(timeEntry?.costCode)}
            </Label>
          );
        } else {
          details.push(
            <Label className={'entry-details-disabled'} key="cost-code-details">
              {t('No Cost Code')}
            </Label>
          );
        }
      }

      if (organization && organization.trackEquipment) {
        if (!isNil(timeEntry?.equipment)) {
          details.push(
            <Label className={!isNil(timeEntry.endTime) ? 'entry-details-disabled' : undefined} key="equipment-details">
              {stringUtils.getEquipmentDisplay(timeEntry?.equipment)}
            </Label>
          );
        } else {
          details.push(
            <Label className={'entry-details-disabled'} key="equipment-details">
              {t('No Equipment')}
            </Label>
          );
        }
      }

      if (!isEmpty(timeEntry?.description)) {
        details.push(
          <Label className={!isNil(timeEntry.endTime) ? 'entry-details-disabled' : undefined} key="description-details">
            {timeEntry?.description}
          </Label>
        );
      } else {
        details.push(
          <Label className={'entry-details-disabled'} key="description-details">
            {t('No Description')}
          </Label>
        );
      }
    } else {
      details.push(
        <Label className={'entry-details-disabled'} key="project-details">
          -
        </Label>
      ); // project
      if (organization && organization.trackCostCode) {
        details.push(
          <Label className={'entry-details-disabled'} key="cost-code-details">
            -
          </Label>
        );
      }
      if (organization && organization.trackEquipment) {
        details.push(
          <Label className={'entry-details-disabled'} key="equipment-details">
            -
          </Label>
        );
      }
      details.push(
        <Label className={'entry-details-disabled'} key="description-details">
          -
        </Label>
      ); // description
    }
    return <div className="pt-5 pb-6">{details}</div>;
  }

  function handleDefaultAction(event: React.MouseEvent, closeDropdown?: () => void) {
    closeDropdown?.();
    event.stopPropagation();
    event.preventDefault();
  }

  async function handleClockOutAction(event: React.MouseEvent, closeDropdown?: () => void) {
    handleDefaultAction(event, closeDropdown);
    if (!isNull(timeEntry) && isNil(timeEntry?.endTime) && !isNil(member)) {
      timeActions.performClockOut([{ ...member, openTimeEntry: timeEntry }]);
    }
  }

  function handleClockInAction(event: React.MouseEvent, closeDropdown?: () => void) {
    handleDefaultAction(event, closeDropdown);
    if (!isNil(member)) {
      timeActions.performClockIn([member]);
    }
  }

  async function handleEndBreakAction(event: React.MouseEvent, closeDropdown?: () => void) {
    handleDefaultAction(event, closeDropdown);
    if (!isNull(timeEntry) && !isEmpty(timeEntry?.breaks.filter((b) => isNil(b.endTime))) && !isNil(member)) {
      timeActions.performEndBreak([{ ...member, openTimeEntry: timeEntry }]);
    }
  }

  function handleClockOutAtAction(event: React.MouseEvent, closeDropdown?: () => void) {
    handleDefaultAction(event, closeDropdown);
    if (!isNull(timeEntry) && isNil(timeEntry.endTime) && !isNil(member)) {
      timeActions.performClockOutAt([member], timeEntry);
    }
  }

  function handleEditTimeEntryAction(event: React.MouseEvent, closeDropdown?: () => void) {
    handleDefaultAction(event, closeDropdown);
    if (!isNull(timeEntry) && isNil(timeEntry.endTime) && !isNil(member)) {
      timeActions.performEdit([member], timeEntry);
    }
  }

  function handleSwitchAction(event: React.MouseEvent, closeDropdown?: () => void) {
    handleDefaultAction(event, closeDropdown);
    if (!isNull(timeEntry) && isNil(timeEntry.endTime) && !isNil(member)) {
      timeActions.performSwitch([member], timeEntry);
    }
  }

  function handleSwitchAtAction(event: React.MouseEvent, closeDropdown?: () => void) {
    handleDefaultAction(event, closeDropdown);
    if (!isNull(timeEntry) && isNil(timeEntry.endTime) && !isNil(member)) {
      timeActions.performSwitchAt([member], timeEntry);
    }
  }

  function handleGoOnBreakClick(event: React.MouseEvent, closeDropdown?: () => void) {
    handleDefaultAction(event, closeDropdown);
    if (hasMinBreakSeconds) {
      minimumBreakDialog.open();
    } else {
      handleGoOnBreakAction();
    }
  }

  const handleGoOnBreakAction = async () => {
    if (minimumBreakDialog.isOpen) {
      minimumBreakDialog.close();
    }
    if (!isNull(timeEntry) && isNil(timeEntry.endTime) && !isNil(member)) {
      timeActions.performBeginBreak([{ ...member, openTimeEntry: timeEntry }]);
    }
  };

  function handleClockInAtAction(event: React.MouseEvent, closeDropdown?: () => void) {
    handleDefaultAction(event, closeDropdown);
    if (!isNil(member)) {
      timeActions.performClockInAt([member]);
    }
  }

  function getClockActionDropdown(closeDropdown: () => void) {
    if (!isNull(timeEntry) && isNil(timeEntry?.endTime)) {
      if (!isEmpty(timeEntry?.breaks.filter((b) => isNil(b.endTime)))) {
        return (
          <ButtonList>
            <Button onClick={(event) => handleClockOutAction(event, closeDropdown)}>{t('Clock Out')}</Button>
            {canBeManaged && (
              <Button onClick={(event) => handleClockOutAtAction(event, closeDropdown)}>{t('Clock Out At')}</Button>
            )}
            {canBeManaged && (
              <>
                <Divider />
                <Button onClick={(event) => handleEditTimeEntryAction(event, closeDropdown)}>
                  {t('Edit Time Entry')}
                </Button>
              </>
            )}
          </ButtonList>
        );
      } else {
        return (
          <ButtonList>
            <Button onClick={(event) => handleSwitchAction(event, closeDropdown)}>{t('Switch')}</Button>
            {canBeManaged && (
              <Button onClick={(event) => handleSwitchAtAction(event, closeDropdown)}>{t('Switch At')}</Button>
            )}
            {canBeManaged && (
              <Button onClick={(event) => handleClockOutAtAction(event, closeDropdown)}>{t('Clock Out At')}</Button>
            )}
            <Button onClick={(event) => handleGoOnBreakClick(event, closeDropdown)}>{t('Go On Break')}</Button>
            {canBeManaged && (
              <>
                <Divider />
                <Button onClick={(event) => handleEditTimeEntryAction(event, closeDropdown)}>
                  {t('Edit Time Entry')}
                </Button>
              </>
            )}
          </ButtonList>
        );
      }
    } else {
      return (
        <ButtonList>
          {canBeManaged && (
            <Button onClick={(event) => handleClockInAtAction(event, closeDropdown)}>{t('Clock In At')}</Button>
          )}
        </ButtonList>
      );
    }
  }

  function getClockActionButtons() {
    let actionButton: ReactNode = (
      <Button className="pl-5 pr-5" type="secondary" onClick={(event) => handleClockInAction(event)}>
        {t('Clock In')}
      </Button>
    );
    const firstTimeEntryBreak = first(timeEntry?.breaks.filter((b) => isNil(b.endTime)));
    const breakZone = firstTimeEntryBreak ? dateTimeFromISOKeepZone(firstTimeEntryBreak?.startTime) : null;
    const endBreakDisabled = !isNil(firstTimeEntryBreak)
      ? !isNil(organization.minBreakSeconds) &&
        organization.minBreakSeconds !== 0 &&
        breakIsUnderRequiredMinutes(
          firstTimeEntryBreak.startDeviceTime!,
          breakZone ? breakZone.zoneName : 'UTC',
          organization.minBreakSeconds
        )
      : false;

    if (!isNull(timeEntry) && isNil(timeEntry?.endTime)) {
      if (!isEmpty(timeEntry?.breaks.filter((b) => isNil(b.endTime)))) {
        actionButton = (
          <Button
            className="pl-5 pr-5"
            type="secondary"
            onClick={(event) => handleEndBreakAction(event)}
            disabled={endBreakDisabled}
          >
            {t('End Break')}
          </Button>
        );
      } else {
        actionButton = (
          <Button className="pl-5 pr-5" type="secondary" onClick={(event) => handleClockOutAction(event)}>
            {t('Clock Out')}
          </Button>
        );
      }
    }

    return (
      <Tray align="right" spacing={1}>
        {actionButton}
        {((!isNull(timeEntry) && isNil(timeEntry?.endTime)) || canBeManaged) && !endBreakDisabled ? (
          <MoreButton
            position={Position.LEFT_START}
            renderContent={(closeDropdown) => getClockActionDropdown(closeDropdown)}
          />
        ) : null}
      </Tray>
    );
  }

  const getSignInSignOffButton = () => {
    if (isPro) {
      const timeEntryIsFromToday =
        !isNull(timeEntry) &&
        (DateTime.fromISO(timeEntry.startTime).startOf('day').toISO() === DateTime.now().startOf('day').toISO() ||
          (!isNil(timeEntry.endTime) &&
            DateTime.fromISO(timeEntry.endTime).startOf('day').toISO() === DateTime.now().startOf('day').toISO()));

      if (timeEntryIsFromToday) {
        if (
          !hasSignInSubmission &&
          (answerCheck?.complete === false || isNil(answerCheck?.complete)) &&
          isNil(timeEntry.endTime) &&
          organization.busySignInQuestion
        ) {
          return (
            <Button className={'px-5'} type="primary" onClick={dailySignInDialog.open}>
              {t('Complete Sign In')}
            </Button>
          );
        } else if (
          !hasSignature &&
          (organization.safetySignature || organization.breakPolicy || organization.timeAccuracy) &&
          !isNil(timeEntry.endTime)
        ) {
          return (
            <Button className={'px-5'} type="primary" onClick={timeActions.dailySignOffDetails.open}>
              {t('Complete Sign Off')}
            </Button>
          );
        }
      }
    } else {
      return <></>;
    }
  };

  function showToastError(message: string) {
    errorToastMessage.current = message;
    errorToastDetails.open();
  }

  const classes = classNames(
    {
      'employee-dashboard-time-actions-card': true,
      'is-loading': isLoading,
    },
    className
  );

  return (
    <>
      <div className="pt-4 pb-2 pl-8 pr-6">
        <div className={classes}>
          <Tray spacing={1}>
            <div className="justify-content pt-5">
              {getTimerStatusTitle()}
              <div className="timer-status-left-content">
                {getTimerStatusIcon()}
                {getTimerTotal()}
              </div>
            </div>
          </Tray>
          {canBeTracked ? getClockActionButtons() : null}
        </div>
        {getEntryDetails()}
        {canBeTracked && activeMember.id === memberId ? null : getSignInSignOffButton()}
      </div>
      {!isNull(timeActions.entryDialogType) && (
        <TimeActionsFormDialog
          type={timeActions.entryDialogType}
          timeEntryId={
            timeActions.entryDialogType === 'edit' ||
            timeActions.entryDialogType === 'switch' ||
            timeActions.entryDialogType === 'switch-at' ||
            timeActions.entryDialogType === 'clock-out-at'
              ? !isNull(dialogEntry.current)
                ? dialogEntry.current?.id
                : null
              : null
          }
          isOpen={timeActions.entryDialogDetails.isOpen}
          onClose={timeActions.closeEntryFormDialog}
          memberIds={!isNil(member) ? [member.id] : []}
          onSubmit={timeActions.onEntryFormDialogSubmit}
          onDelete={timeActions.onEntryFormDialogSubmit}
        />
      )}
      <EmployeeTimersActionToast
        isOpen={timeActions.timerActionsToastDetails.isOpen}
        onClose={timeActions.closeTimerActionsToast}
        type={timeActions.timerActionsToastState?.type ?? null}
        startTime={timeActions.timerActionsToastState?.dateTime ?? null}
        timeoutInSeconds={5}
      />
      <Loader isOpen={timeActions.loaderDetails.isOpen} />
      <Toast isOpen={errorToastDetails.isOpen} onClose={errorToastDetails.close} theme={Theme.DANGER}>
        {errorToastMessage.current}
      </Toast>
      <DailySignOffFormDialog
        member={timeActions.activeMember}
        timeRange={timeActions.timeEntryRange.current ?? defaultTimeRange}
        fromReport={false}
        currentOpenEntry={timeActions.currentOpenEntry.current}
        isOpen={timeActions.dailySignOffDetails.isOpen}
        onClose={timeActions.dailySignOffDetails.close}
        onComplete={timeActions.onSignOffComplete.current ?? timeActions.dailySignOffDetails.close}
      />
      <HeaderDialog
        isOpen={dailySignInDialog.isOpen}
        title={t('Daily Sign-In')}
        onClose={dailySignInDialog.close}
        divider={true}
      >
        <SignInQuestionForm member={activeMember} onSubmit={setSubmissionId} onClose={dailySignInDialog.close} />
      </HeaderDialog>
      <HeaderDialog
        isOpen={minimumBreakDialog.isOpen}
        onClose={minimumBreakDialog.close}
        divider={false}
        title={t(`${breakDuration} Minute Break`)}
      >
        <div className="mx-8">
          {t(
            `You will be able to end your break after ${breakDuration} minutes from the time it begins. You will not be able to end your break before ${DateTime.now()
              .plus({ minutes: breakDuration })
              .toFormat(DateTimeFormat.timeSimple)}.`
          )}
          <div className="my-5">
            <Button type="primary" onClick={handleGoOnBreakAction} className={'mr-5'}>
              {t('Start Break')}
            </Button>
            <Button type="secondary" onClick={minimumBreakDialog.close}>
              {t('Cancel')}
            </Button>
          </div>
        </div>
      </HeaderDialog>
      <JsaReviewDialog {...timeActions.jsaReview} />
    </>
  );
};

export default EmployeeDashboardTimeActionsCard;
