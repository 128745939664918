import { OrganizationSignOffQuestion } from '__generated__/graphql';
import { ITimeActionsFormData } from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import { IEmployeeTimersActionToastTypeAndDateTime } from 'containers/employee-timers/EmployeeTimers';
import {
  isJsaReviewEntryDialogType,
  useJsaReview,
} from 'containers/jsa-reports/JsaReportsManagement/JsaReview/hooks/useJsaReview';
import { useTimeEntry } from 'hooks';
import useGetOrganizationSignOffQuestions from 'hooks/models/organization-sign-off-questions/useGetOrganizationSignOffQuestions';
import { useCustomSignOffEnabled } from 'hooks/models/organization/useCustomSignOffEnabled';
import { IEntryAndBreak } from 'hooks/models/time-entry-break/useBreak';
import _, { isEmpty, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { IMember } from 'types';
import ITimeEntry from 'types/TimeEntry';
import ITimeRange from 'types/TimeRange';
import ClockActionType from 'types/enum/ClockActionType';
import { dateUtils } from 'utils';
import { mapNotNil } from 'utils/collectionUtils';
import { useFeatureFlags } from 'utils/features';
import { useBreak, useOrganization } from '..';
import useActiveMember from '../store/useActiveMember';
import useOpenable from './useOpenable';

export type TTimeActionsEntryDialogType =
  | 'add'
  | 'edit'
  | 'clock-in'
  | 'clock-in-at'
  | 'switch'
  | 'switch-at'
  | 'clock-out-at'
  | null;

export default function useTimeActions(completion: (memberIds: string[]) => void) {
  const [entryDialogType, setEntryDialogType] = useState<TTimeActionsEntryDialogType>(null);
  const entryDialogDetails = useOpenable();
  const { clockOut } = useTimeEntry();
  const { beginBreak, endBreak } = useBreak();
  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]);
  const [selectedTimeEntry, setSelectedTimeEntry] = useState<ITimeEntry | null>(null);
  const [selectedTimeEntries, setSelectedTimeEntries] = useState<ITimeEntry[]>([]);
  const timerActionsToastDetails = useOpenable();
  const [timerActionsToastState, setTimerActionsToastState] =
    useState<IEmployeeTimersActionToastTypeAndDateTime | null>(null);
  const today = DateTime.local().startOf('day');
  const loaderDetails = useOpenable();
  const activeMember = useActiveMember();
  const organization = useOrganization();
  const timeEntryRange = useRef<ITimeRange<DateTime> | undefined>();
  const currentOpenEntry = useRef<ITimeEntry | undefined>();
  const currentOpenEntryMember = useRef<IMember | undefined>();
  const onSignOffComplete = useRef<() => void | undefined>();
  const dailySignOffDetails = useOpenable();
  const addDescriptionDetails = useOpenable();
  const bulkEditDialogDetails = useOpenable();
  const isPro = useFeatureFlags('PRO');
  const isSafetySignOffEnabled = useFeatureFlags('SAFETY_SIGN_OFF');
  const getAllQuestions = useGetOrganizationSignOffQuestions();
  const [customQuestions, setCustomQuestions] = useState<OrganizationSignOffQuestion[]>([]);
  const isCustomSignOffEnabled = useCustomSignOffEnabled();
  const jsaReview = useJsaReview(completion);

  useEffect(() => {
    getQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQuestions = async () => {
    const questions = await getAllQuestions({ deletedOn: { isNull: true } });

    const filteredQuestions = questions.filter((q) => {
      return q.audienceType === 'everyone' || q.positionId === activeMember.positionId;
    });

    setCustomQuestions(filteredQuestions);
  };

  function closeEntryFormDialog() {
    setSelectedMemberIds([]);
    setSelectedTimeEntries([]);
    setEntryDialogType(null);
    entryDialogDetails.close();
  }

  function onEntryFormDialogSubmit(data: ITimeActionsFormData) {
    closeEntryFormDialog();
    loaderDetails.open();
    switch (entryDialogType) {
      case 'clock-in':
        setTimerActionsToastState({ type: 'clock-in', dateTime: null });
        break;
      case 'clock-in-at': {
        const clockInAt = data.startDate.value === null ? today : DateTime.fromJSDate(data.startDate.value!);
        setTimerActionsToastState({
          type: 'clock-in-at',
          dateTime: data.startTime!.set({ year: clockInAt.year, month: clockInAt.month, day: clockInAt.day }),
        });
        break;
      }
      case 'clock-out-at': {
        const clockOutAt = data.endDate.value === null ? today : DateTime.fromJSDate(data.endDate.value!);
        setTimerActionsToastState({
          type: 'clock-out-at',
          dateTime: data.endTime!.set({ year: clockOutAt.year, month: clockOutAt.month, day: clockOutAt.day }),
        });
        break;
      }
      case 'switch':
        setTimerActionsToastState({ type: 'switch', dateTime: null });
        break;
      case 'switch-at': {
        const switchAt = data.endDate.value === null ? today : DateTime.fromJSDate(data.endDate.value!);
        setTimerActionsToastState({
          type: 'switch-at',
          dateTime: data.endTime!.set({ year: switchAt.year, month: switchAt.month, day: switchAt.day }),
        });
        break;
      }
      default:
        break;
    }
    loaderDetails.close();
    if (entryDialogType !== 'edit' && entryDialogType !== 'add') {
      timerActionsToastDetails.open();
    }

    if (isJsaReviewEntryDialogType(entryDialogType)) {
      jsaReview.launchJsaReview(data);
    } else {
      completion(data.members);
    }
  }

  function closeTimerActionsToast() {
    timerActionsToastDetails.close();
    // Allow time for the toast to animate before resetting the state.
    setTimeout(() => {
      setTimerActionsToastState(null);
    }, 500);
  }

  async function launchDailySignOffOrSave(
    member: IMember,
    openEntry: ITimeEntry,
    endTime: DateTime = DateTime.local(),
    save: () => Promise<void>
  ) {
    // if logged in member is selected then launch daily sign off
    if (
      isPro &&
      ((isSafetySignOffEnabled &&
        (organization.timeAccuracy || organization.safetySignature || organization.breakPolicy) &&
        member.id === activeMember.id) ||
        (isCustomSignOffEnabled && customQuestions.length > 0))
    ) {
      timeEntryRange.current = {
        startTime: dateUtils.dateTimeFromISOWithoutZone(openEntry.startTime),
        endTime,
      };
      currentOpenEntry.current = openEntry;
      currentOpenEntryMember.current = member;

      // perform save after we complete sign off
      onSignOffComplete.current = () => {
        loaderDetails.open();
        save().then(() => {
          loaderDetails.close();
          dailySignOffDetails.close();
        });
      };
      dailySignOffDetails.open();
    } else {
      await save();
    }
  }

  const launchDailySignOffWithoutSave = (
    member: IMember,
    openEntry: ITimeEntry,
    endTime: DateTime = DateTime.local()
  ) => {
    if (
      isPro &&
      isSafetySignOffEnabled &&
      (organization.timeAccuracy || organization.safetySignature || organization.breakPolicy) &&
      member.id === activeMember.id
    ) {
      timeEntryRange.current = {
        startTime: dateUtils.dateTimeFromISOWithoutZone(openEntry.startTime),
        endTime,
      };
      currentOpenEntry.current = openEntry;

      // perform save after we complete sign off
      onSignOffComplete.current = () => {
        dailySignOffDetails.close();
      };
      dailySignOffDetails.open();
    }
  };

  function performClockIn(members: IMember[]) {
    setSelectedMemberIds(members.map((m) => m.id));
    setSelectedTimeEntries([]);
    setEntryDialogType('clock-in');
    entryDialogDetails.open();
  }

  function performClockInAt(members: IMember[]) {
    setSelectedMemberIds(members.map((m) => m.id));
    setSelectedTimeEntries([]);
    setEntryDialogType('clock-in-at');
    entryDialogDetails.open();
  }

  function performAdd(members: IMember[]) {
    setSelectedMemberIds(members.map((m) => m.id));
    setSelectedTimeEntries([]);
    setEntryDialogType('add');
    entryDialogDetails.open();
  }

  async function performSwitch(members: IMember[], entry: ITimeEntry | null) {
    setSelectedMemberIds(members.map((m) => m.id));

    const newEntries: ITimeEntry[] = [];
    if (members.length > 0) {
      members.forEach((m) => {
        if (!isNil(m.openTimeEntry)) {
          newEntries.push(m.openTimeEntry);
        }
      });
    } else {
      if (entry) {
        newEntries.push(entry);
      }
    }
    setSelectedTimeEntries(newEntries);
    setEntryDialogType('switch');
    entryDialogDetails.open();
  }

  async function performSwitchAt(members: IMember[], entry: ITimeEntry | null) {
    setSelectedMemberIds(members.map((m) => m.id));

    const newEntries: ITimeEntry[] = [];
    if (members.length > 0) {
      members.forEach((m) => {
        if (!isNil(m.openTimeEntry)) {
          newEntries.push(m.openTimeEntry);
        }
      });
    } else {
      if (entry) {
        newEntries.push(entry);
      }
    }
    setSelectedTimeEntries(newEntries);
    setEntryDialogType('switch-at');
    entryDialogDetails.open();
  }

  async function performClockOutAt(members: IMember[], entry: ITimeEntry | null) {
    setSelectedMemberIds(members.map((m) => m.id));

    const newEntries: ITimeEntry[] = [];
    if (members.length > 0) {
      members.forEach((m) => {
        if (!isNil(m.openTimeEntry)) {
          newEntries.push(m.openTimeEntry);
        }
      });
    } else {
      if (entry) {
        newEntries.push(entry);
      }
    }
    setSelectedTimeEntries(newEntries);
    setEntryDialogType('clock-out-at');
    entryDialogDetails.open();
  }

  async function performEdit(members: IMember[], entry: ITimeEntry | null) {
    setSelectedMemberIds(members.map((m) => m.id));

    const newEntries: ITimeEntry[] = [];
    if (members.length > 0) {
      members.forEach((m) => {
        if (!isNil(m.openTimeEntry)) {
          newEntries.push(m.openTimeEntry);
        }
      });
    } else {
      if (entry) {
        newEntries.push(entry);
      }
    }
    setSelectedTimeEntries(newEntries);
    setEntryDialogType('edit');
    entryDialogDetails.open();
  }

  async function performBulkEdit(entries: ITimeEntry[]) {
    setSelectedTimeEntries(entries);
    bulkEditDialogDetails.open();
  }

  async function performClockOut(members: IMember[]) {
    const openEntries = mapNotNil(members, (member) => member.openTimeEntry);
    const currentTime = DateTime.local();
    const save = async () => {
      const openEntryBreaks = mapNotNil(openEntries, (entry) => {
        const openBreak = _.findLast(entry.breaks, (brk) => _.isNil(brk.endTime));
        if (openBreak) {
          const item: IEntryAndBreak = {
            entry: entry,
            brk: openBreak,
          };
          return item;
        }
        return null;
      });

      if (!isEmpty(openEntryBreaks)) {
        await endBreak(openEntryBreaks, currentTime);
      }

      await clockOut(openEntries, currentTime, ClockActionType.CLOCK_OUT);
      setTimerActionsToastState({ type: 'clock-out', dateTime: null });
      timerActionsToastDetails.open();
      completion(members.map((m) => m.id));
    };

    try {
      loaderDetails.open();
      if (members.length === 1 && members[0].id === activeMember.id) {
        // Only show daily sign-off if clocking out self.
        await launchDailySignOffOrSave(members[0], openEntries[0], currentTime, save);
      } else {
        await save();
      }
      loaderDetails.close();
    } catch (error) {
      throw Error();
    }
  }

  async function performBeginBreak(members: IMember[]) {
    const openEntries = mapNotNil(members, (member) => member.openTimeEntry);

    try {
      loaderDetails.open();
      await beginBreak(openEntries, DateTime.local());
      setTimerActionsToastState({ type: 'begin-break', dateTime: null });
      timerActionsToastDetails.open();
      completion(members.map((m) => m.id));
    } catch (error) {
      throw Error();
    }
  }

  async function performEndBreak(members: IMember[]) {
    const openEntryBreaks = mapNotNil(members, (member) => {
      const openEntry = member.openTimeEntry;
      if (openEntry) {
        const openBreak = _.findLast(openEntry.breaks, (brk) => _.isNil(brk.endTime));
        if (openBreak) {
          const item: IEntryAndBreak = {
            entry: openEntry,
            brk: openBreak,
          };
          return item;
        }
      }
      return null;
    });
    try {
      loaderDetails.open();
      await endBreak(openEntryBreaks, DateTime.local());
      setTimerActionsToastState({ type: 'end-break', dateTime: null });
      timerActionsToastDetails.open();
      completion(members.map((m) => m.id));
    } catch (error) {
      throw Error();
    }
  }

  function performAddDescription(entry: ITimeEntry | null) {
    setSelectedTimeEntry(entry);
    addDescriptionDetails.open();
  }

  function handleAddDescriptionSubmit(timeEntry: ITimeEntry) {
    completion([timeEntry.member.id]);
    addDescriptionDetails.close();
  }

  function handleAddDescriptionClose() {
    setSelectedTimeEntry(null);
    addDescriptionDetails.close();
  }

  function onSubmitBulkEdit(entries: ITimeEntry[]) {
    bulkEditDialogDetails.close();
    completion(entries.map((entry) => entry.member.id));
  }

  return {
    loaderDetails,
    entryDialogDetails,
    entryDialogType,
    timerActionsToastState,
    timerActionsToastDetails,
    closeTimerActionsToast,
    closeEntryFormDialog,
    onEntryFormDialogSubmit,
    timeEntryRange,
    currentOpenEntry,
    currentOpenEntryMember,
    activeMember,
    onSignOffComplete,
    dailySignOffDetails,
    addDescriptionDetails,
    selectedMemberIds,
    selectedTimeEntry,
    selectedTimeEntries,
    performClockIn,
    performClockInAt,
    performAdd,
    performSwitch,
    performSwitchAt,
    performClockOutAt,
    performEdit,
    performClockOut,
    performBeginBreak,
    performEndBreak,
    performAddDescription,
    handleAddDescriptionSubmit,
    handleAddDescriptionClose,
    performBulkEdit,
    bulkEditDialogDetails,
    onSubmitBulkEdit,
    launchDailySignOffWithoutSave,
    jsaReview,
  };
}
