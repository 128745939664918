import {
  Button,
  CheckboxFormField,
  DatePickerFormField,
  Form,
  IDatePickerPayload,
  IFormRender,
  Label,
  Link,
  Loader,
  Radio,
  RadioGroupFormField,
  TextFormField,
  TextareaFormField,
  Theme,
  TimeFormField,
  Tray,
} from '@busybusy/webapp-react-ui';
import {
  IncidentEmployeeSexes,
  IncidentIllnessTypes,
  IncidentMedicalDetails,
  IncidentOutcomeDetailsTypes,
  IncidentTypes,
} from '__generated__/graphql';
import classNames from 'classnames';
import {
  CheckboxGroupFormField,
  DateTrayPickerFormField,
  FormAccordion,
  HeaderDialog,
  ProjectPickerFormField,
  Well,
} from 'components';
import EmployeePickerFormField from 'components/domain/member/EmployeePickerFormField/EmployeePickerFormField';
import DashboardRibbon from 'components/foundation/dashboard/DashboardRibbon/DashboardRibbon';
import { CheckboxFormItems } from 'components/foundation/form-fields/CheckboxGroup/CheckboxGroupFormField';
import HeaderText from 'containers/settings/components/HeaderText/HeaderText';
import { useToastOpen } from 'contexts/ToastContext';
import { useOpenable } from 'hooks';
import { t } from 'i18next';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useState } from 'react';
import { ClassName } from 'types/ClassName';
import ISafetySignature from 'types/SafetySignature';
import { v_numbers_only, v_require } from 'utils/validations';
import RecordableIncidentsForm from '../RecordableIncidentsForm/RecordableIncidentsForm';
import { ISafetyIncidentsOshaRowItem } from '../SafetyIncidentsOsha300Table/SafetyIncidentsOsha300Table';
import { ISafetyIncidentsReportedRowItem } from '../SafetyIncidentsReportedTable/SafetyIncidentsReportedTable';
import { IncidentAnswerQuestions } from '../types/types';
import './SubmitSafetyIncidentForm.scss';
import { useCreateSafetyIncident } from './utils/useCreateSafetyIncident';
import { useUpdateSafetyIncident } from './utils/useUpdateSafetyIncident';

export interface ISubmitSafetyIncidentFormProps {
  handleCreateIncidentForm: () => void;
  safetySignature?: ISafetySignature;
  incident?: ISafetyIncidentsReportedRowItem | ISafetyIncidentsOshaRowItem;
  className?: ClassName;
}

export interface ISubmitSafetyIncidentFormData {
  memberId?: string | null;
  projectId?: string | null;
  type?: IncidentTypes | null;
  illnessType?: IncidentIllnessTypes | null;
  recordable: boolean;
  occurredAtDate?: IDatePickerPayload | null;
  occurredAtTime?: DateTime | null;
  employeeStartTime?: DateTime | null;
  activityBefore?: string | null;
  whatHappened?: string | null;
  typeDescription?: string | null;
  whatHarmed?: string | null;
  location?: string | null;
  treatments?: CheckboxFormItems[] | null;
  facilityName?: string | null;
  facilityAddress?: string | null;
  facilityCity?: string | null;
  facilityState?: string | null;
  facilityPostalCode?: string | null;
  physicianName?: string | null;
  employeeJobTitle?: string | null;
  employeeBirthday: DateTime | null;
  employeeHireDate: DateTime | null;
  employeeSex?: IncidentEmployeeSexes;
  employeeAddress1?: string | null;
  employeeCity?: string | null;
  employeeState?: string | null;
  employeePostalCode?: string | null;
  outcomeType?: IncidentOutcomeDetailsTypes;
  daysAway?: number | null;
  daysTransfer?: number | null;
  outcomeDate?: IDatePickerPayload | null;
}

const SubmitSafetyIncidentForm = (props: ISubmitSafetyIncidentFormProps) => {
  const { handleCreateIncidentForm, safetySignature, incident, className } = props;

  const classes = classNames('submit-safety-incident-form px-6', className);

  const [formData, setFormData] = useState<ISubmitSafetyIncidentFormData>({
    memberId: safetySignature?.memberId,
    employeeJobTitle: safetySignature?.member.position?.title,
    recordable: false,
    occurredAtTime: null,
    employeeStartTime: null,
    employeeBirthday: null,
    employeeHireDate: null,
  });
  const caseInformationDateDetails = useOpenable();
  const incidentOutcomeDateDetails = useOpenable();
  const recordableIncidentsDialog = useOpenable();
  const createIncident = useCreateSafetyIncident();
  const updateIncident = useUpdateSafetyIncident();
  const loadingOpenable = useOpenable({ isOpen: false });
  const toast = useToastOpen();

  const generateTreatmentOptions = (): CheckboxFormItems[] => [
    {
      id: 'treatmentFirstAid',
      label: t('First Aid (On Site)'),
      checked: false,
    },
    {
      id: 'treatmentDoctor',
      label: t('Treatment by Doctor'),
      checked: false,
    },
    {
      id: 'treatmentER',
      label: t('Emergency Room'),
      checked: false,
    },
    {
      id: 'treatmentOvernight',
      label: t('Hospitalized Overnight'),
      checked: false,
    },
    {
      id: 'treatmentNone',
      label: t('None / Refused'),
      checked: false,
    },
    {
      id: 'treatmentOther',
      label: t('Other Treatment'),
      checked: false,
    },
  ];

  const treatmentOptions: CheckboxFormItems[] = generateTreatmentOptions().map((option) => ({
    ...option,
    checked: incident ? (incident.treatment?.[option.id as keyof IncidentMedicalDetails] as boolean) : false,
  }));

  useEffect(() => {
    if (!isNil(incident)) {
      const occurredAtDate = incident.occurredAt
        ? {
            value: DateTime.fromISO(incident.occurredAt).toJSDate(),
            inputValue: DateTime.fromISO(incident.occurredAt).toString(),
          }
        : null;

      const outcomeDate = incident.outcomeDetails?.outcomeDate
        ? {
            value: DateTime.fromISO(incident.outcomeDetails.outcomeDate).toJSDate(),
            inputValue: DateTime.fromISO(incident.outcomeDetails.outcomeDate).toString(),
          }
        : null;

      setFormData({
        ...formData,
        memberId: incident.member.id,
        employeeAddress1: incident.employeeAddress1,
        employeeBirthday: incident.employeeBirthday ? DateTime.fromISO(`${incident.employeeBirthday}T00:00:00`) : null,
        employeeCity: incident.employeeCity,
        employeeHireDate: incident.employeeHireDate ? DateTime.fromISO(`${incident.employeeHireDate}T00:00:00`) : null,
        employeeJobTitle: incident.employeeJobTitle,
        employeePostalCode: incident.employeePostalCode,
        employeeSex: incident.employeeSex,
        employeeStartTime: incident.employeeStartTime ? DateTime.fromISO(incident.employeeStartTime) : null,
        employeeState: incident.employeeState,
        facilityAddress: incident.facilityAddress,
        facilityCity: incident.facilityCity,
        facilityName: incident.facilityName,
        facilityPostalCode: incident.facilityPostalCode,
        facilityState: incident.facilityState,
        physicianName: incident.treatment?.physicianName,
        illnessType: incident.illnessType,
        whatHappened: incident.incidentDescription,
        location: incident.locationDetails,
        recordable: 'recordable' in incident ? incident.recordable : true,
        occurredAtTime:
          incident.occurrenceTimeSet && incident.occurredAt ? DateTime.fromISO(incident.occurredAt) : null,
        occurredAtDate,
        whatHarmed: incident.harmfulSubstance,
        treatments: treatmentOptions,
        activityBefore: incident.activityBefore,
        typeDescription: incident.injuryDescription,
        outcomeType: incident.outcomeDetails?.type,
        daysAway: incident.outcomeDetails?.daysAway,
        daysTransfer: incident.outcomeDetails?.daysTransfer,
        outcomeDate,
        type: incident.type,
        projectId: incident.project?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incident]);

  const onFormSubmit = async (formData: ISubmitSafetyIncidentFormData) => {
    const anyTreatmentSelected = formData.treatments?.some((treatment) => treatment.checked === true);

    if (anyTreatmentSelected) {
      loadingOpenable.open();
      const isSuccessful = !isNil(incident)
        ? await updateIncident(incident.id, formData)
        : await createIncident(formData, safetySignature);
      loadingOpenable.close();
      if (isSuccessful) {
        handleCreateIncidentForm();
      }
    } else {
      toast({ label: t('Treatment is required'), theme: Theme.DANGER });
    }
  };

  const submitButton = (form: IFormRender<ISubmitSafetyIncidentFormData>): ReactNode => {
    return (
      <div className={'pb-6'}>
        <Button onClick={form.handleSubmit} type={'primary'}>
          {t('Submit')}
        </Button>
      </div>
    );
  };

  const employeePicker = (form: IFormRender<ISubmitSafetyIncidentFormData>): ReactNode => (
    <>
      <Label required={true}>{t('Injured Employee')}</Label>
      <EmployeePickerFormField
        form={form}
        name={'memberId'}
        className={'pb-6'}
        validations={[{ validation: v_require }]}
      />
    </>
  );

  const typePicker = (form: IFormRender<ISubmitSafetyIncidentFormData>): ReactNode => (
    <>
      <Label required={true}>{t('Type')}</Label>
      <RadioGroupFormField name={'type'} form={form} validations={[{ validation: v_require }]}>
        <Radio value={IncidentTypes.Injury} label={t('Injury')} />
        <Radio value={IncidentTypes.Illness} label={t('Illness')} />
      </RadioGroupFormField>
    </>
  );

  const illnessTypePicker = (form: IFormRender<ISubmitSafetyIncidentFormData>): ReactNode => (
    <>
      <Label>{t('Illness Type:')}</Label>
      <RadioGroupFormField name={'illnessType'} form={form} validations={[{ validation: v_require }]}>
        <Radio value={IncidentIllnessTypes.Skin} label={t('Skin Disorder')} />
        <Radio value={IncidentIllnessTypes.Respiratory} label={t('Respiratory Condition')} />
        <Radio value={IncidentIllnessTypes.Poisoning} label={t('Poisoning')} />
        <Radio value={IncidentIllnessTypes.Hearing} label={t('Hearing Loss')} />
        <Radio value={IncidentIllnessTypes.Other} label={t('Other')} />
      </RadioGroupFormField>
    </>
  );

  const recordablePicker = (form: IFormRender<ISubmitSafetyIncidentFormData>): ReactNode => (
    <>
      <CheckboxFormField
        name={'recordable'}
        form={form}
        label={
          <div className={'pl-3h3'}>
            <HeaderText title={t('Recordable Incident')} className={'pb-2'} />
            <div className={'fw-light fc-2 pb-2'}>
              {t(
                'A recordable incident is one that is required to be recorded in an OSHA Form 301 and submitted in an OSHA Form 300.'
              )}
            </div>
            <Link className="section-link" onClick={recordableIncidentsDialog.open} href="#">
              {t('How do I know if this is a recordable incident?')}
            </Link>
          </div>
        }
        onChange={(value) => {
          if (value === false) {
            form.clearMultipleFieldErrors([
              'employeeJobTitle',
              'employeeBirthday',
              'employeeHireDate',
              'employeeSex',
              'employeeAddress1',
              'employeeCity',
              'employeeState',
              'employeePostalCode',
            ]);
            form.clearIsDirty();
          }
          form.setData('recordable', value);
        }}
      />
    </>
  );

  const caseInformationAccordion = (form: IFormRender<ISubmitSafetyIncidentFormData>): ReactNode => (
    <FormAccordion form={form} title={t('Case Information')}>
      <Label className={'pt-4'}>{t('Date of Injury or Illness')}</Label>
      <DatePickerFormField
        name="occurredAtDate"
        form={form}
        disabledAfter={DateTime.now().toJSDate()}
        validations={[{ validation: v_require }]}
        isOpen={caseInformationDateDetails.isOpen}
        onClose={caseInformationDateDetails.close}
        onOpen={caseInformationDateDetails.open}
      />
      <Label secondaryLabel={t('Leave blank if unknown')} className={'pt-4'}>
        {t('Time of Event:')}
      </Label>
      <TimeFormField name="occurredAtTime" form={form} meridiemEnabled={true} />
      <Label className={'pt-4'}>{t('Time the Employee Began Work:')}</Label>
      <TimeFormField
        name="employeeStartTime"
        form={form}
        meridiemEnabled={true}
        validations={[{ validation: v_require }]}
      />
      <Label className={'pt-4'}>{t(IncidentAnswerQuestions.ActivityBefore)}</Label>
      <div className={'fw-light fc-2 pb-4'}>
        {t(
          'Describe the activity, as well as the tools, equipment or material the employee was using. Be specific. Examples: "climbing a ladder while carrying roofing materials"; "spraying chlorine from hand sprayer"; "daily computer key-entry."'
        )}
      </div>
      <TextareaFormField
        name={'activityBefore'}
        form={form}
        restrictTo={{ maxLength: 2000 }}
        validations={[{ validation: v_require }]}
      />
      <Label className={'pt-4'}>{t(IncidentAnswerQuestions.WhatHappened)}</Label>
      <div className={'fw-light fc-2 pb-4'}>
        {t(
          `Tell us how the injury occurred. Examples: "When ladder slipped on wet floor, worker fell 20 feet"; "Worker was sprayed with chlorine when gasket broke during replacement"; "Worker developed soreness in wrist over time."`
        )}
      </div>
      <TextareaFormField
        name={'whatHappened'}
        form={form}
        restrictTo={{ maxLength: 2000 }}
        validations={[{ validation: v_require }]}
      />
      <Label className={'pt-4'}>{t(IncidentAnswerQuestions.TypeDescription)}</Label>
      <div className={'fw-light fc-2 pb-4'}>
        {t(
          `Tell us the part of the body that was affected and how it was affected. Examples: "strained back"; "chemical burn, hand"; "carpal tunnel syndrome."`
        )}
      </div>
      <TextareaFormField
        name={'typeDescription'}
        form={form}
        restrictTo={{ maxLength: 2000 }}
        validations={[{ validation: v_require }]}
      />
      <Label className={'pt-4'}>{t(IncidentAnswerQuestions.WhatHarmed)}</Label>
      <div className={'fw-light fc-2 pb-4'}>
        {t(
          `Examples: "concrete floor"; "chlorine"; "radial arm saw." If this question does not apply to the incident, leave it blank.`
        )}
      </div>
      <TextareaFormField name={'whatHarmed'} form={form} restrictTo={{ maxLength: 2000 }} />
      <Label className={'pt-4'}>{t(IncidentAnswerQuestions.Location)}</Label>
      <div className={'fw-light fc-2 pb-4'}>
        {t(`Describe where the event/incident occurred (e.g. Loading dock north end)`)}
      </div>
      <TextareaFormField
        name={'location'}
        form={form}
        restrictTo={{ maxLength: 255 }}
        validations={[{ validation: v_require }]}
      />
      <Label secondaryLabel={t('Optional')} className={'pt-4'}>
        {t('Project')}
      </Label>
      <div className={'fw-light fc-2 pb-4'}>{t(`Select a project if the injury occurred on a specific project.`)}</div>
      <ProjectPickerFormField name={'projectId'} form={form} />
    </FormAccordion>
  );

  const medicalCareAccordion = (form: IFormRender<ISubmitSafetyIncidentFormData>): ReactNode => (
    <FormAccordion form={form} title={t('Medical Care')}>
      <Label className={'py-4'} secondaryLabel={t('Select at least one')}>
        {t('What type of treatment was given?')}
      </Label>
      <CheckboxGroupFormField
        checkBoxItems={treatmentOptions}
        form={form}
        name={'treatments'}
        validations={[{ validation: v_require }]}
      />
      {!isNil(form.state.data.treatments) &&
        (form.state.data.treatments[
          form.state.data.treatments.map((item) => item.id).findIndex((id) => id === 'treatmentDoctor')
        ].checked ||
          form.state.data.treatments[
            form.state.data.treatments.map((item) => item.id).findIndex((id) => id === 'treatmentER')
          ].checked ||
          form.state.data.treatments[
            form.state.data.treatments.map((item) => item.id).findIndex((id) => id === 'treatmentOvernight')
          ].checked) && (
          <div className={'pb-6'}>
            <Label className={'py-4'}>{t('If treatment was given away from the worksite, where was it given?')}</Label>
            <DashboardRibbon theme={Theme.LIGHT} className={'mb-4'}>
              <Label>{t('Facility')}</Label>
              <TextFormField name={'facilityName'} form={form} />
              <Label>{t('Address')}</Label>
              <TextFormField name={'facilityAddress'} form={form} />
              <div className={'city-state-zip'}>
                <Label>{t('City')}</Label>
                <TextFormField name={'facilityCity'} form={form} />
                <Tray>
                  <div className={'state'}>
                    <Label>{t('State')}</Label>
                    <TextFormField name={'facilityState'} form={form} />
                  </div>
                  <div>
                    <Label>{t('Zip')}</Label>
                    <TextFormField name={'facilityPostalCode'} form={form} />
                  </div>
                </Tray>
              </div>
            </DashboardRibbon>
            <Label>{t('Physician/Provider')}</Label>
            <TextFormField name={'physicianName'} form={form} />
          </div>
        )}
    </FormAccordion>
  );

  const employeeDetailsAccordion = (form: IFormRender<ISubmitSafetyIncidentFormData>): ReactNode => (
    <FormAccordion form={form} title={t('Employee Details')}>
      <Label className={'pt-4'}>{t('Job Title')}</Label>
      <TextFormField
        name={'employeeJobTitle'}
        form={form}
        validations={form.state.data.recordable ? [{ validation: v_require }] : undefined}
      />
      <Label className={'pt-4'}>{t('Birth Date')}</Label>
      <DateTrayPickerFormField
        name={'employeeBirthday'}
        form={form}
        validations={form.state.data.recordable ? [{ validation: v_require }] : undefined}
      />
      <Label className={'pt-4'}>{t('Hire Date')}</Label>
      <DateTrayPickerFormField
        name={'employeeHireDate'}
        form={form}
        validations={form.state.data.recordable ? [{ validation: v_require }] : undefined}
      />
      <Label className={'pt-4'}>{t('Sex')}</Label>
      <RadioGroupFormField
        name={'employeeSex'}
        form={form}
        validations={form.state.data.recordable ? [{ validation: v_require }] : undefined}
      >
        <Radio value={IncidentEmployeeSexes.Male} label={t('Male')} />
        <Radio value={IncidentEmployeeSexes.Female} label={t('Female')} />
      </RadioGroupFormField>
      <h3 className={'mb-4'}>{t('Employee Address')}</h3>
      <DashboardRibbon theme={Theme.LIGHT} className={'mb-6'}>
        <Label>{t('Street')}</Label>
        <TextFormField
          name={'employeeAddress1'}
          form={form}
          validations={form.state.data.recordable ? [{ validation: v_require }] : undefined}
        />
        <div className={'city-state-zip'}>
          <Label>{t('City')}</Label>
          <TextFormField
            name={'employeeCity'}
            form={form}
            validations={form.state.data.recordable ? [{ validation: v_require }] : undefined}
          />
          <Tray>
            <div className={'state'}>
              <Label>{t('State')}</Label>
              <TextFormField
                name={'employeeState'}
                form={form}
                validations={form.state.data.recordable ? [{ validation: v_require }] : undefined}
              />
            </div>
            <div>
              <Label>{t('Zip')}</Label>
              <TextFormField
                name={'employeePostalCode'}
                form={form}
                validations={form.state.data.recordable ? [{ validation: v_require }] : undefined}
              />
            </div>
          </Tray>
        </div>
      </DashboardRibbon>
    </FormAccordion>
  );

  const incidentOutcomeAccordion = (form: IFormRender<ISubmitSafetyIncidentFormData>): ReactNode => (
    <FormAccordion form={form} title={t('Incident Outcome')}>
      <Well>
        <div>
          {t(
            `This information can be added later if the outcome is still unknown. The outcome of all recordable incidents will need to be documented before submitting a form 300 or 300A.`
          )}
        </div>
      </Well>
      <Label className={'pt-8'}>{t('What was the outcome of the case?')}</Label>
      <div className={'fw-light fc-2 pb-2'}>{t('Select one based on the most serious outcome of the case.')}</div>
      <RadioGroupFormField name={'outcomeType'} form={form}>
        <Radio value={IncidentOutcomeDetailsTypes.DaysAway} label={t('Days away from work')} />
        <Radio value={IncidentOutcomeDetailsTypes.Transfer} label={t('Job transfer or restriction')} />
        <Radio value={IncidentOutcomeDetailsTypes.Death} label={t('Death')} />
        <Radio value={IncidentOutcomeDetailsTypes.Other} label={t('Other')} />
      </RadioGroupFormField>
      {form.state.data.outcomeType === IncidentOutcomeDetailsTypes.DaysAway && (
        <div className={'mb-5'}>
          <Label>{t('How many days were spent away from work? *')}</Label>
          <TextFormField
            className={'number-of-days'}
            name={'daysAway'}
            form={form}
            validations={[{ validation: v_numbers_only }]}
          />
          <span style={{ fontWeight: 'lighter' }}>{t('* Calendar days should include weekends and holidays.')}</span>
        </div>
      )}
      {form.state.data.outcomeType === IncidentOutcomeDetailsTypes.Transfer && (
        <>
          <Label>{t('How many days on job transfer or restriction? *')}</Label>
          <TextFormField
            className={'number-of-days'}
            name={'daysTransfer'}
            form={form}
            validations={[{ validation: v_numbers_only }]}
          />
          <span style={{ fontWeight: 'lighter' }}>{t('* Calendar days should include weekends and holidays.')}</span>
        </>
      )}
      {form.state.data.outcomeType === IncidentOutcomeDetailsTypes.Death && (
        <>
          <Label>{t('Death Date')}</Label>
          <DatePickerFormField
            name="outcomeDate"
            form={form}
            isOpen={incidentOutcomeDateDetails.isOpen}
            onClose={incidentOutcomeDateDetails.close}
            onOpen={incidentOutcomeDateDetails.open}
          />
        </>
      )}
    </FormAccordion>
  );

  const renderFormFields = (form: IFormRender<ISubmitSafetyIncidentFormData>): ReactNode => {
    return (
      <>
        {employeePicker(form)}
        {typePicker(form)}
        {form.state.data.type === IncidentTypes.Illness && illnessTypePicker(form)}
        {recordablePicker(form)}
        {form.state.data.recordable && employeeDetailsAccordion(form)}
        {caseInformationAccordion(form)}
        {medicalCareAccordion(form)}
        {form.state.data.recordable && incidentOutcomeAccordion(form)}
        {submitButton(form)}
      </>
    );
  };

  return (
    <div className={classes}>
      <Form render={renderFormFields} onSubmit={onFormSubmit} data={formData} allowMultipleSubmissions={true} />
      <HeaderDialog
        isOpen={recordableIncidentsDialog.isOpen}
        onClose={recordableIncidentsDialog.close}
        title={t('Recordable Incidents')}
        divider={false}
      >
        <RecordableIncidentsForm />
      </HeaderDialog>
      <Loader isOpen={loadingOpenable.isOpen} />
    </div>
  );
};

export default SubmitSafetyIncidentForm;
