import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import CostCodeTimeEntryTableListeningContainer from 'components/domain/time-entry/CostCodeTimeEntryTableListeningContainer/CostCodeTimeEntryTableListeningContainer';
import DateRangeTimeEntryTableListeningContainer from 'components/domain/time-entry/DateRangeTimeEntryTableListeningContainer/DateRangeTimeEntryTableListeningContainer';
import DateTimeEntryTableListeningContainer from 'components/domain/time-entry/DateTimeEntryTableListeningContainer/DateTimeEntryTableListeningContainer';
import EquipmentTimeEntryTableListeningContainer from 'components/domain/time-entry/EquipmentTimeEntryTableListeningContainer/EquipmentTimeEntryTableListeningContainer';
import MemberTimeEntryTableListeningContainer from 'components/domain/time-entry/MemberTimeEntryTableListeningContainer/MemberTimeEntryTableListeningContainer';
import ProjectTimeEntryTableListeningContainer from 'components/domain/time-entry/ProjectTimeEntryTableListeningContainer/ProjectTimeEntryTableListeningContainer';
import { IMemberTimeEntryReportDataFilter } from 'components/domain/time-entry/TimeEntryDataReport/MemberTimeEntryDataReport/hooks/useMemberTimeEntryDataReportData';
import { ActivityReportType } from 'containers/activity-reports/ActivityReportFilter/ActivityReportFilter';
import { TimesheetReportType } from 'containers/timesheets/Timesheets';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { areTimeRangesEqual } from 'utils/dateUtils';
import { MemberPermissions } from '__generated__/graphql';

export interface IActivityReportTimeEntriesProps {
  className?: ClassName;
  reportType: ActivityReportType;
  timeRange: ITimeRange<DateTime>;
  dateRangeReportType: TimeRangeType;
  filterId?: string | null;
  filterType: ActivityReportType;
  filterByUnassignedId?: boolean;
  scroller: HTMLElement | null;
}

const ActivityReportTimeEntries = (props: IActivityReportTimeEntriesProps) => {
  const {
    className,
    reportType,
    timeRange,
    dateRangeReportType,
    filterId,
    filterType,
    scroller,
    filterByUnassignedId,
  } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterIds = useMemo(() => (filterId ? [filterId] : undefined), [filterId, filterType]);
  const [memberFilter, setMemberFilter] = useState<IMemberTimeEntryReportDataFilter>({
    timeRange,
    includeOpenEntries: false,
    memberIds: filterType === ActivityReportType.BY_EMPLOYEE ? filterIds : undefined,
    memberGroupId: undefined,
    projectIds: filterType === ActivityReportType.BY_PROJECT ? filterIds : undefined,
    costCodeIds: filterType === ActivityReportType.BY_COST_CODE ? filterIds : undefined,
    equipmentIds: filterType === ActivityReportType.BY_EQUIPMENT ? filterIds : undefined,
    positionId: undefined,
    reportType: shouldShowTimeOff() ? TimesheetReportType.ENTRY_AND_TIME_OFF_PAID : TimesheetReportType.ENTRY,
    projectIdWithDescendants: !filterByUnassignedId,
    archivedStatus: 'all',
    memberPermissions: [MemberPermissions.TimeEvents],
  });

  function shouldShowTimeOff(): boolean {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
      case ActivityReportType.BY_COST_CODE:
      case ActivityReportType.BY_EQUIPMENT:
        // only show time off if we aren't filtering by a project/costCode/equipment
        return isNil(filterId) || filterId === remainingDataItemId;
    }

    return true;
  }

  useEffect(() => {
    if (!areTimeRangesEqual(memberFilter.timeRange, timeRange)) {
      setMemberFilter((prev) => ({
        ...prev,
        timeRange,
      }));
    }
  }, [timeRange]);

  const classes = classNames('activity-report-time-entries', className);

  let reportTable: ReactNode;
  switch (reportType) {
    case ActivityReportType.BY_PROJECT:
      reportTable = (
        <>
          <ProjectTimeEntryTableListeningContainer
            className={className}
            scroller={scroller}
            timeRange={timeRange}
            equipmentIds={filterType === ActivityReportType.BY_EQUIPMENT ? filterIds : undefined}
            memberIds={filterType === ActivityReportType.BY_EMPLOYEE ? filterIds : undefined}
            projectIds={filterType === ActivityReportType.BY_PROJECT ? filterIds : undefined}
            costCodeIds={filterType === ActivityReportType.BY_COST_CODE ? filterIds : undefined}
            excludeArchived={false}
            rootProjectsOnly={true}
            applyPrintOptions={false}
          />
        </>
      );
      break;
    case ActivityReportType.BY_EMPLOYEE:
      reportTable = (
        <>
          <MemberTimeEntryTableListeningContainer
            className={className}
            scroller={scroller}
            filter={memberFilter}
            applyPrintOptions={false}
          />
        </>
      );
      break;
    case ActivityReportType.BY_COST_CODE:
      reportTable = (
        <>
          <CostCodeTimeEntryTableListeningContainer
            className={className}
            scroller={scroller}
            timeRange={timeRange}
            equipmentIds={filterType === ActivityReportType.BY_EQUIPMENT ? filterIds : undefined}
            memberIds={filterType === ActivityReportType.BY_EMPLOYEE ? filterIds : undefined}
            projectIds={filterType === ActivityReportType.BY_PROJECT ? filterIds : undefined}
            costCodeIds={filterType === ActivityReportType.BY_COST_CODE ? filterIds : undefined}
            projectIdWithDescendants={!filterByUnassignedId}
            excludeArchived={false}
            applyPrintOptions={false}
          />
        </>
      );
      break;
    case ActivityReportType.BY_EQUIPMENT:
      reportTable = (
        <>
          <EquipmentTimeEntryTableListeningContainer
            className={className}
            scroller={scroller}
            timeRange={timeRange}
            equipmentIds={filterType === ActivityReportType.BY_EQUIPMENT ? filterIds : undefined}
            memberIds={filterType === ActivityReportType.BY_EMPLOYEE ? filterIds : undefined}
            projectIds={filterType === ActivityReportType.BY_PROJECT ? filterIds : undefined}
            costCodeIds={filterType === ActivityReportType.BY_COST_CODE ? filterIds : undefined}
            projectIdWithDescendants={!filterByUnassignedId}
            excludeDeleted={false}
            applyPrintOptions={false}
          />
        </>
      );
      break;
    case ActivityReportType.BY_DAY:
      reportTable = (
        <>
          <DateTimeEntryTableListeningContainer
            className={className}
            timeRange={timeRange}
            equipmentIds={filterType === ActivityReportType.BY_EQUIPMENT ? filterIds : undefined}
            memberIds={filterType === ActivityReportType.BY_EMPLOYEE ? filterIds : undefined}
            projectIds={filterType === ActivityReportType.BY_PROJECT ? filterIds : undefined}
            costCodeIds={filterType === ActivityReportType.BY_COST_CODE ? filterIds : undefined}
            projectIdWithDescendants={!filterByUnassignedId}
            applyPrintOptions={false}
          />
        </>
      );
      break;
    case ActivityReportType.BY_DATE_RANGE:
      reportTable = (
        <>
          <DateRangeTimeEntryTableListeningContainer
            className={className}
            timeRange={timeRange}
            timeRangeType={dateRangeReportType}
            clipDatesToTimeRange={true}
            equipmentIds={filterType === ActivityReportType.BY_EQUIPMENT ? filterIds : undefined}
            memberIds={filterType === ActivityReportType.BY_EMPLOYEE ? filterIds : undefined}
            projectIds={filterType === ActivityReportType.BY_PROJECT ? filterIds : undefined}
            costCodeIds={filterType === ActivityReportType.BY_COST_CODE ? filterIds : undefined}
            projectIdWithDescendants={!filterByUnassignedId}
            applyPrintOptions={false}
          />
        </>
      );
      break;
  }

  return <div className={classes}>{reportTable}</div>;
};

export default ActivityReportTimeEntries;
