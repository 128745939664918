import { Align, Justify, Label, Row } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { HeaderDialog } from 'components';
import LockDateDialog, { LockDateDialogType } from 'components/domain/member-lock/LockDateDialog/LockDateDialog';
import PayPeriodSignatures from 'components/domain/pay-period-signature/PayPeriodSignatures/PayPeriodSignatures';
import TimeEntryView from 'components/domain/time-entry/TimeEntryView/TimeEntryView';
import TimeActionsFormDialog from 'components/domain/time-entry/time-actions-form/TimeActionsFormDialog/TimeActionsFormDialog';
import TimeOffView from 'components/domain/time-off/TimeOffView/TimeOffView';
import { ITimeOffFormData } from 'components/domain/time-off/form/CreateTimeOffForm/CreateTimeOffForm';
import TimeOffFormDialog from 'components/domain/time-off/form/TimeOffFormDialog/TimeOffFormDialog';
import { ExpandedTimeCardsPrintDensityType } from 'containers/timesheets/TimesheetPrintOptionsForm/TimesheetPrintOptions';
import { TimeCardReportExpandedBreakFormat } from 'containers/timesheets/TimesheetsSidePanel/hooks/useTimeCardSettings';
import { useOpenable, useOrganization } from 'hooks';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { t } from 'i18next';
import { isEmpty, isEqual, isNil, isNull } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IStoreTimeCardReportSettings } from 'store/TimeCardReportSettings/TimeCardReportSettings';
import { IReduxState } from 'store/types';
import { ClassName } from 'types/ClassName';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { numberOfDaysBetween } from 'utils/dateUtils';
import './ExpandedTimeCardsContents.scss';
import ExpandedTimeCardsSummaryTable from './ExpandedTimeCardsSummaryTable/ExpandedTimeCardsSummaryTable';
import ExpandedTimeCardsTimeEntriesTable, {
  IExpandedTimeCardRowInfo,
} from './ExpandedTimeCardsTimeEntriesTable/ExpandedTimeCardsTimeEntriesTable';
import { IMember } from 'types';
import { ExpandedTimeCardReportMemberData } from '../types/types';

export interface IExpandedTimeCardsContentsProps {
  className?: ClassName;
  data: ExpandedTimeCardReportMemberData;
  scroller?: HTMLElement;
  timeRange: ITimeRange<DateTime>;
  onDataChange: (memberIds: string[]) => void;
  timeRangeType: TimeRangeType;
  onSignaturesLoaded?: () => void;
  onSignatureComplete?: () => void;
  showDecimalFormat?: boolean;
  breakFormat: TimeCardReportExpandedBreakFormat;
  printDensity?: ExpandedTimeCardsPrintDensityType;
}

const ExpandedTimeCardsContents = (props: IExpandedTimeCardsContentsProps) => {
  const {
    className,
    data,
    scroller,
    onDataChange,
    timeRange,
    timeRangeType,
    onSignatureComplete,
    onSignaturesLoaded,
    showDecimalFormat,
    breakFormat,
    printDensity,
  } = props;

  const previousData = useRef<ExpandedTimeCardReportMemberData | null>(null);

  const forceUpdate = useOpenable();

  // TODO: Not sure the intention here
  useEffect(() => {
    if (isNull(previousData.current)) {
      previousData.current = data;
    }

    if (!isEqual(data, previousData.current)) {
      forceUpdate.toggle();
    }
  }, [data]);

  const [newLockDate, setNewLockDate] = useState<DateTime>(DateTime.local());
  const [rowData, setRowData] = useState<IExpandedTimeCardRowInfo | null>(null);
  const timeEntryDialogOpenable = useOpenable();
  const lockOpenable = useOpenable();
  const timeOffOpenable = useOpenable();
  const timeOffViewDialogDetails = useOpenable();
  const timeEntryViewOpenable = useOpenable();
  const nameFormatter = useEmployeeNameFormatter();

  const timeCardReportSettings = useSelector<IReduxState, IStoreTimeCardReportSettings>(
    (state) => state.timeCardReportSettings
  );
  const timeRangeIsPayPeriod = timeRangeType === TimeRangeType.PAY_PERIOD;

  const organization = useOrganization();
  const showSignaturesFooter =
    timeRangeIsPayPeriod && organization.signatureDate && timeCardReportSettings.showSignatures;
  const payPeriodIsOpen = timeRange.endTime > DateTime.local();
  const refreshData = useCallback(() => {
    onDataChange([data.id]);
  }, [data, onDataChange]);

  const onTimeEntryFormDialogOpen = useCallback(
    (row: IExpandedTimeCardRowInfo) => {
      setRowData(row);
      timeEntryDialogOpenable.open();
    },
    [setRowData, timeEntryDialogOpenable]
  );

  const handleTimeEntrySubmit = useCallback(() => {
    refreshData();
    timeEntryDialogOpenable.close();
  }, [timeEntryDialogOpenable, refreshData]);

  const onTimeEntryViewDialogOpen = useCallback(
    (row: IExpandedTimeCardRowInfo) => {
      setRowData(row);
      timeEntryViewOpenable.open();
    },
    [setRowData, timeEntryViewOpenable]
  );

  const onLockDialogOpen = useCallback(
    (row: IExpandedTimeCardRowInfo) => {
      setRowData(row);
      setNewLockDate(row.timeData?.endDate ?? DateTime.local());
      lockOpenable.open();
    },
    [lockOpenable, setRowData]
  );

  const handleMoveLockDate = useCallback(() => {
    lockOpenable.close();

    setNewLockDate(DateTime.local());

    if (isNil(rowData?.timeData?.timeOffType)) {
      timeEntryDialogOpenable.open();
    } else if (!isNil(rowData)) {
      handleOpenEditTimeOff(rowData);
    } else {
      throw new Error('Unexpected control flow in [ExpandedTimeCardsReport]. Should only be triggered when row exists');
    }

    refreshData();
  }, [lockOpenable, timeEntryDialogOpenable, rowData, refreshData]);

  const handleOpenEditTimeOff = useCallback(
    (row: IExpandedTimeCardRowInfo) => {
      setRowData(row);
      timeOffOpenable.open();
    },
    [timeOffOpenable, setRowData]
  );

  const onTimeOffViewDialogOpen = useCallback(
    (row: IExpandedTimeCardRowInfo) => {
      setRowData(row);
      timeOffViewDialogDetails.open();
    },
    [setRowData, timeOffViewDialogDetails]
  );

  function handleTimeOffEditSubmit(_data?: ITimeOffFormData | undefined) {
    refreshData();
    timeOffOpenable.close();
  }

  function onSignComplete(effectiveDate?: DateTime) {
    // if we have a new lock date then we need to update our state
    if (effectiveDate) {
      setNewLockDate(effectiveDate);
    }
    onSignatureComplete?.();
  }

  const areAllEmpty =
    !isNil(data) &&
    isEmpty(data?.timeEntryData) &&
    isEmpty(data?.projectSummaryData) &&
    isEmpty(data?.costCodeSummaryData) &&
    isEmpty(data?.equipmentSummaryData) &&
    isEmpty(data?.dailySummaryData);

  const classes = classNames('expanded-tme-cards-contents', printDensity === 'standard' ? 'standard' : '', className);

  return (
    <div className={classes}>
      {areAllEmpty ? (
        <Row align={Align.CENTER} justify={Justify.CENTER} className={'full-width'}>
          <Label className={'p-8 section-title'}>{t('No Time Worked')}</Label>
        </Row>
      ) : (
        <>
          {timeCardReportSettings.showTimeEntries && (
            <ExpandedTimeCardsTimeEntriesTable
              data={data}
              scroller={scroller}
              onTimeEntryFormDialogOpen={onTimeEntryFormDialogOpen}
              onLockDialogOpen={onLockDialogOpen}
              onTimeEntryViewDialogOpen={onTimeEntryViewDialogOpen}
              onTimeOffFormDialogOpen={handleOpenEditTimeOff}
              onTimeOffViewDialogOpen={onTimeOffViewDialogOpen}
              showDecimalFormat={showDecimalFormat}
              breakFormat={breakFormat}
              printDensity={printDensity}
            />
          )}
          {timeCardReportSettings.showProjectSummary && (
            <div className="summary-item">
              <Label className={'section-title'}>
                {!isNil(data?.projectSummaryData) && !isEmpty(data?.projectSummaryData) && t('PROJECT SUMMARY')}
              </Label>
              <ExpandedTimeCardsSummaryTable
                scroller={scroller}
                data={data}
                type="project"
                timeRange={timeRange}
                onDataChange={refreshData}
                timeRangeType={timeRangeType}
                showDecimalFormat={showDecimalFormat}
                printDensity={printDensity}
              />
            </div>
          )}
          {organization.trackCostCode === true && timeCardReportSettings.showCostCodeSummary && (
            <div className="summary-item">
              <Label className={'section-title'}>
                {!isNil(data?.costCodeSummaryData) && !isEmpty(data?.costCodeSummaryData) && t('COST CODE SUMMARY')}
              </Label>
              <ExpandedTimeCardsSummaryTable
                scroller={scroller}
                data={data}
                type="cost-code"
                timeRange={timeRange}
                onDataChange={refreshData}
                timeRangeType={timeRangeType}
                showDecimalFormat={showDecimalFormat}
                printDensity={printDensity}
              />
            </div>
          )}
          {organization.trackEquipment === true && timeCardReportSettings.showEquipmentSummary && (
            <div className="summary-item">
              <Label className={'section-title'}>
                {!isNil(data?.equipmentSummaryData) && !isEmpty(data?.equipmentSummaryData) && t('EQUIPMENT SUMMARY')}
              </Label>
              <ExpandedTimeCardsSummaryTable
                scroller={scroller}
                data={data}
                type="equipment"
                timeRange={timeRange}
                onDataChange={refreshData}
                timeRangeType={timeRangeType}
                showDecimalFormat={showDecimalFormat}
                printDensity={printDensity}
              />
            </div>
          )}
          {timeCardReportSettings.showDailySummary && (
            <div className="summary-item">
              <Label className={'section-title'}>
                {!isNil(data?.dailySummaryData) && !isEmpty(data?.dailySummaryData) && t('DAILY SUMMARY')}
              </Label>
              <ExpandedTimeCardsSummaryTable
                scroller={scroller}
                data={data}
                type="daily"
                timeRange={timeRange}
                onDataChange={refreshData}
                timeRangeType={timeRangeType}
                showDecimalFormat={showDecimalFormat}
                printDensity={printDensity}
              />
            </div>
          )}
        </>
      )}

      {showSignaturesFooter && (
        <div className="summary-item">
          <Label className={'section-title'}>{t('SIGNATURES')}</Label>
          <div className={'signature-disclaimer'}>{organization.disclaimer}</div>
          <PayPeriodSignatures
            // TODO: would be good to swap this to the correct type but it propogates IMember
            member={data as unknown as IMember}
            timeRange={timeRange}
            onComplete={onSignComplete}
            forceReload={forceUpdate.isOpen}
            onSignaturesLoaded={onSignaturesLoaded}
            payPeriodIsOpen={payPeriodIsOpen}
          />
        </div>
      )}

      {!isNull(rowData) && !isNil(rowData.timeData) && timeEntryDialogOpenable.isOpen && (
        <TimeActionsFormDialog
          type={'edit'}
          memberIds={[data.id]}
          currentDate={timeRange.endTime}
          timeEntryId={rowData.timeData.id}
          isOpen={timeEntryDialogOpenable.isOpen}
          onClose={timeEntryDialogOpenable.close}
          onSubmit={handleTimeEntrySubmit}
          onDelete={handleTimeEntrySubmit}
        />
      )}
      {lockOpenable.isOpen && (
        <LockDateDialog
          date={newLockDate}
          type={LockDateDialogType.SINGLE_EDIT}
          memberIds={[data.id]}
          isOpen={lockOpenable.isOpen}
          onClose={lockOpenable.close}
          onMoveLockDate={handleMoveLockDate}
        />
      )}
      {!isNull(rowData) && !isNil(rowData.timeData) && timeEntryViewOpenable.isOpen && (
        <TimeEntryView
          timeEntryId={rowData.timeData.id}
          isOpen={timeEntryViewOpenable.isOpen}
          onClose={timeEntryViewOpenable.close}
          onEdit={refreshData}
        />
      )}
      {!isNull(rowData) && !isNil(rowData.timeData) && timeOffViewDialogDetails.isOpen && (
        <HeaderDialog
          isOpen={timeOffViewDialogDetails.isOpen}
          title={t('Time Off')}
          subtitle={nameFormatter(rowData.timeData.member.firstName ?? '', rowData.timeData.member.lastName ?? '')}
          onClose={timeOffViewDialogDetails.close}
        >
          <TimeOffView timeOffId={rowData.timeData.id} />
        </HeaderDialog>
      )}
      {!isNull(rowData) && !isNil(rowData.timeData) && timeOffOpenable.isOpen && (
        <TimeOffFormDialog
          memberIds={[data.id]}
          currentDate={numberOfDaysBetween(timeRange.startTime, timeRange.endTime) === 1 ? timeRange.endTime : null}
          timeOffId={rowData.timeData.id}
          isOpen={timeOffOpenable.isOpen}
          onClose={timeOffOpenable.close}
          onSubmit={handleTimeOffEditSubmit}
          onEdit={() => {
            refreshData();
            timeOffOpenable.close();
          }}
        />
      )}
    </div>
  );
};

export default ExpandedTimeCardsContents;
