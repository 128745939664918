import { Align, ClassName, Dialog, Row } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { TimeRangeDialogHeader } from 'components/foundation/headers/TimeRangeDialogHeader/TimeRangeDialogHeader';
import Panel from 'components/layout/Panel/Panel';
import PanelContent from 'components/layout/PanelContent/PanelContent';
import { IUseTimeRangePayload } from 'hooks/utils/useTimeRange';
import { ReactNode } from 'react';
import './TimeRangeDialog.scss';

export interface ITimeRangeDialogProps extends IUseTimeRangePayload {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
  actionBar?: ReactNode;
  filterBar?: ReactNode;
  headerClassName?: ClassName;
  className?: ClassName;
  setRef?: (scroller: HTMLElement | null) => void;
  timeRangeBackPressed?: () => boolean; // returns boolean to indicate if the event consumed (true) or if it should perform the default behavior (false)
  timeRangeForwardPressed?: () => boolean; // returns boolean to indicate if the event consumed (true) or if it should perform the default behavior (false)
}

export default function TimeRangeDialog({
  timeRange,
  title,
  isOpen,
  timeRangeType,
  children,
  actionBar,
  filterBar,
  setRef,
  className,
  headerClassName,
  forward,
  back,
  onClose,
  forwardEnabled,
  backEnabled,
  timeRangeBackPressed,
  timeRangeForwardPressed,
}: ITimeRangeDialogProps) {
  // TODO: This should probably be handled by parents but there's a decent amount of propogation currently
  function timeActionForward() {
    const eventConsumed = timeRangeForwardPressed?.();

    if (eventConsumed !== true) {
      forward();
    }
  }

  function timeActionBack() {
    const eventConsumed = timeRangeBackPressed?.();

    if (eventConsumed !== true) {
      back();
    }
  }

  const forwardRef = (element: HTMLElement | null) => {
    setRef?.(element);
  };

  const classes = classNames('time-range-dialog', className);

  return (
    <Dialog hasDismiss={false} className={classes} size="full" isOpen={isOpen} onClose={onClose}>
      <Panel className="time-range-dialog-content">
        <div>
          <TimeRangeDialogHeader
            className={headerClassName ?? (filterBar ? undefined : 'mb-8')}
            actionBar={actionBar}
            onBackClick={onClose}
            title={title}
            forward={timeActionForward}
            back={timeActionBack}
            backEnabled={backEnabled}
            forwardEnabled={forwardEnabled}
            timeRange={timeRange}
            timeRangeType={timeRangeType}
          />
          {filterBar}
        </div>
        <PanelContent className="panel-content" forwardRef={forwardRef}>
          <Row align={Align.CENTER} className="middle-row">
            <div className="report-container">{children}</div>
          </Row>
        </PanelContent>
      </Panel>
    </Dialog>
  );
}
