import { Align, Justify, Row, Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { EmptyState, Spinner } from 'components';
import ProFeatureDialog from 'components/domain/account/ProFeatureDialog/ProFeatureDialog';
import MemberTimeEntryDialog from 'components/domain/time-entry/dialog/MemberTimeEntryDialog/MemberTimeEntryDialog';
import TimeActionsFormDialog from 'components/domain/time-entry/time-actions-form/TimeActionsFormDialog/TimeActionsFormDialog';
import { ITimeActionsFormData } from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import { ITimeOffFormData } from 'components/domain/time-off/form/CreateTimeOffForm/CreateTimeOffForm';
import TimeOffFormDialog from 'components/domain/time-off/form/TimeOffFormDialog/TimeOffFormDialog';
import TimeCardReportMemberHeader from 'containers/timesheets/TimeCardReportMemberHeader/TimeCardReportMemberHeader';
import { useLoader } from 'contexts/LoaderContext';
import { useToastOpen } from 'contexts/ToastContext';
import { useOpenable, useTimesheetsGraylog } from 'hooks';
import useMemberGraphAggregates from 'hooks/aggregates/useMemberGraphAggregates';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import useIsPro from 'hooks/store/useIsPro';
import { IUseTimeRangePayload } from 'hooks/utils/useTimeRange';
import { DateTime } from 'luxon';
import { useMemo, useRef, useState } from 'react';
import { ClassName } from 'types/ClassName';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { TimesheetsTypes } from 'utils/constants/graylogActionTypes';
import { t } from 'utils/localize';
import SignOffReportDialog from '../SignOffReportDialog/SignOffReportDialog';
import TimesheetPrintSignature from '../TimesheetPrintSignature/TimesheetPrintSignature';
import TimesheetsActionHeader from '../TimesheetsActionHeader/TimesheetsActionHeader';
import TimesheetsHeader from '../TimesheetsHeader/TimesheetsHeader';
import TimesheetsPrintDialog from '../TimesheetsPrintDialog/TimesheetsPrintDialog';
import { TimeCardReportExpandedBreakFormat } from '../TimesheetsSidePanel/hooks/useTimeCardSettings';
import TimesheetsMemberActionBar from '../action-bar/TimesheetsMemberActionBar/TimesheetsMemberActionBar';
import useTimesheetsQueryParams from '../hooks/useTimesheetsQueryParams';
import './ExpandedTimeCardReport.scss';
import ExpandedTimeCardsContents from './ExpandedTimeCardsContents/ExpandedTimeCardsContents';
import ExpandedTimeCardsPrintFooter from './ExpandedTimeCardsPrintFooter/ExpandedTimeCardsPrintFooter';
import ExpandedTimeCardsPrintHeader from './ExpandedTimeCardsPrintHeader/ExpandedTimeCardsPrintHeader';
import { MemberPermissions } from '__generated__/graphql';
import { ITimesheetPrintOptionsData } from '../TimesheetPrintOptionsForm/TimesheetPrintOptions';
import { ExpandedTimeCardReportMember, ExpandedTimeCardReportMemberData } from './types/types';
import { pageGraphQl } from 'utils/graphQlUtils';
import useMemberTimeEntryDataRows from 'components/domain/time-entry/TimeEntryDataReport/MemberTimeEntryDataReport/hooks/useMemberTimeEntryDataRows';
import { isoTimeStampLocal } from 'utils/dateUtils';
import { downloadText } from 'utils/fileUtils';
import { convertTimeEntryDataToCsv } from '../utils/utils';
import { useExpandedTimeCardReportData } from './hooks/useExpandedTimeCardReportData/useExpandedTimeCardReportData';
import { TimesheetReportType } from '../Timesheets';
import { Nullable } from 'types/util/Nullable';
import { IMemberTimeEntryReportDataFilter } from 'components/domain/time-entry/TimeEntryDataReport/MemberTimeEntryDataReport/hooks/useMemberTimeEntryDataReportData';

export interface IExpandedTimeCardReportProps extends IUseTimeRangePayload {
  permission: MemberPermissions;
  className?: ClassName;
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  onSignatureComplete?: (memberId: string) => void;
  showDecimalFormat?: boolean;
  breakFormat: TimeCardReportExpandedBreakFormat;
}

const ExpandedTimeCardReport = ({
  timeRange,
  timeRangeType,
  permission,
  breakFormat,
  showDecimalFormat,
  onSignatureComplete,
  forward,
  back,
  backEnabled,
  forwardEnabled,
  className,
}: IExpandedTimeCardReportProps) => {
  const scroller = useRef<Nullable<HTMLDivElement>>(null);
  const { memberId, memberGroupId, positionId, archivedStatus } = useTimesheetsQueryParams();

  const memberSettings = useMemberSettings();

  const printOptions = memberSettings?.web?.features?.timeCards?.printOptions;

  const [dialogMember, setDialogMember] = useState<ExpandedTimeCardReportMember | null>(null);
  const [selectedDate, setSelectedDate] = useState<DateTime | null>(null);

  const filterMemberIds = useMemo(() => (memberId ? [memberId] : null), [memberId]);
  const memberIds = useMemo(() => (dialogMember ? [dialogMember.id] : []), [dialogMember]);

  const viewEntriesDialog = useOpenable({ onClose: clearDialogSettings });
  const signOffReportDialog = useOpenable({ onClose: clearDialogSettings });

  const timeEntryDialogDetails = useOpenable();
  const timeOffDialogDetails = useOpenable();
  const proUpgradeDialogDetails = useOpenable();
  const printDetails = useOpenable();

  const userEvents = useTimesheetsGraylog();

  const brand = useBrandTitle();
  const isPro = useIsPro();

  const { open: openLoader, close: closeLoader } = useLoader();
  const openToast = useToastOpen();

  const exportFilter = useMemo(
    (): IMemberTimeEntryReportDataFilter => ({
      timeRange,
      memberIds: memberId ? [memberId] : null,
      memberGroupId,
      positionId,
      archivedStatus,
      memberPermissions: [permission],
      reportType: TimesheetReportType.ENTRY_AND_ALL_TIME_OFF,
    }),
    [archivedStatus, memberGroupId, memberId, permission, positionId, timeRange]
  );

  const getExportData = useMemberTimeEntryDataRows(exportFilter);

  const {
    data: members,
    loadedAll,
    refreshMembersData,
    loading,
    forceLoadAll,
    areAllSignaturesLoaded,
    onSignaturesLoaded,
    clearData,
  } = useExpandedTimeCardReportData({
    scroller: scroller.current,
    memberId,
    timeRangeType,
    memberGroupId,
    positionId,
    timeRange,
    archivedStatus,
    permission: MemberPermissions.ManageTimeEntries,
  });

  const {
    data: graphAggregates,
    error: graphAggregateError,
    refetchData: refetchAggregates,
  } = useMemberGraphAggregates(timeRange, filterMemberIds, null, null, null, memberGroupId, 2, archivedStatus);

  function clearDialogSettings() {
    setDialogMember(null);
    setSelectedDate(null);
  }

  function onViewMemberEntries(member: ExpandedTimeCardReportMember) {
    setDialogMember(member);
    viewEntriesDialog.open();
  }

  function onViewSignOffs(member: ExpandedTimeCardReportMember) {
    setDialogMember(member);
    signOffReportDialog.open();
  }

  const onSignOffUpdate = () => {
    refreshMembersData(memberIds);
  };

  const onSignatureCurried = (memberId: string) => () => onSignatureComplete?.(memberId);
  const onSignatureLoadedCurried = (memberId: string) => () => onSignaturesLoaded?.(memberId, true);

  const renderRow = (data: ExpandedTimeCardReportMemberData, index: number) => {
    return (
      <div
        key={data.id}
        className={classNames(
          index !== 0 ? 'report-gap' : '',
          'timecard-report-container',
          printOptions?.pageBreaks ? 'page-break' : ''
        )}
        data-testid={data.id}
      >
        <ExpandedTimeCardsPrintHeader member={data} printDensity={printOptions?.printDensity} timeRange={timeRange} />
        <div className="no-print">
          <TimeCardReportMemberHeader
            member={data}
            memberSeconds={data.totalSeconds ?? null}
            onViewEntries={onViewMemberEntries}
            onViewSignOff={onViewSignOffs}
            isExpanded={true}
            showDecimalFormat={showDecimalFormat}
          />
        </div>

        <ExpandedTimeCardsContents
          data={data}
          scroller={scroller.current ?? undefined}
          timeRange={timeRange}
          onDataChange={refreshMembersData}
          timeRangeType={timeRangeType}
          onSignatureComplete={onSignatureCurried(data.id)}
          onSignaturesLoaded={onSignatureLoadedCurried(data.id)}
          showDecimalFormat={showDecimalFormat}
          breakFormat={breakFormat}
          printDensity={printOptions?.printDensity}
        />

        {printOptions?.position === 'at-end-of-document' ? (
          (members ?? []).length - 1 === index && <TimesheetPrintSignature />
        ) : (
          <TimesheetPrintSignature />
        )}

        <ExpandedTimeCardsPrintFooter />

        {printOptions?.pageBreaks && (members ?? []).length - 1 !== index && <div className="time-card-page-break" />}
      </div>
    );
  };

  function onTimeEntryFormDataSubmit(_formData: ITimeActionsFormData) {
    onDataChange();
    timeEntryDialogDetails.close();
  }

  function onTimeOffFormDataSubmit(formData: ITimeOffFormData | undefined) {
    if (formData) {
      onDataChange();
    }
    timeOffDialogDetails.close();
  }

  function onDataChange() {
    clearData();
    refetchAggregates();
  }

  const dayRange = useMemo(() => {
    return selectedDate ? { startTime: selectedDate.startOf('day'), endTime: selectedDate.endOf('day') } : timeRange;
  }, [selectedDate, timeRange]);

  async function onExport() {
    if (!isPro) {
      proUpgradeDialogDetails.open();
      return;
    }

    userEvents.events(TimesheetsTypes.events.action_type.EXPORT);
    openLoader(t('Loading...'));

    try {
      const result = await pageGraphQl(getExportData, (r) => r, 500);

      if (result.length !== 0) {
        const csv = convertTimeEntryDataToCsv(result, showDecimalFormat);
        downloadText(csv, `${brand}-${t('timesheets-time-entries')}-${isoTimeStampLocal()}.csv`);
      } else {
        openToast({ theme: Theme.DANGER, label: t('There is no data to export.') });
      }
    } catch (e) {
      openToast({ theme: Theme.DANGER, label: t('Something went wrong exporting your data.') });
    } finally {
      closeLoader();
    }
  }

  function onPrint() {
    printDetails.open();
    userEvents.events(TimesheetsTypes.events.action_type.PRINT);
  }

  async function handlePrint(options: ITimesheetPrintOptionsData, loadAllData: boolean = true) {
    printDetails.close();
    openLoader(t('Loading...'));

    try {
      if (loadAllData) {
        await forceLoadAll(options.showAllEmployees);
      }

      setTimeout(() => {
        const loaded = areAllSignaturesLoaded?.();
        if (loaded) {
          setTimeout(() => {
            window.print();
            closeLoader();
          }, 1000);
        } else {
          handlePrint(options, false);
        }
      }, 2000);
    } catch (e) {
      openToast({ theme: Theme.DANGER, label: t('Something went wrong.') });
    } finally {
      closeLoader();
    }
  }

  const classes = classNames('expanded-time-card-report', className);

  return (
    <>
      <TimesheetsActionHeader
        timeRange={timeRange}
        timeRangeType={timeRangeType}
        onTimeRangeForward={forward}
        onTimeRangeBackward={back}
        forwardDisabled={!forwardEnabled()}
        backwardDisabled={!backEnabled()}
        onTimeEntryAdd={timeEntryDialogDetails.open}
        onTimeOffAdd={timeOffDialogDetails.open}
        onExport={onExport}
        onPrint={onPrint}
        actionBar={
          <TimesheetsMemberActionBar
            onTimeEntryFormSubmit={onTimeEntryFormDataSubmit}
            onTimeOffFormSubmit={onTimeOffFormDataSubmit}
          />
        }
      />

      <div className="overflow-y-auto max-height" ref={scroller}>
        <TimesheetsHeader
          className="m-6 no-print"
          timeRange={timeRange}
          graphData={graphAggregates}
          aggregateError={graphAggregateError?.name ?? null}
          showDecimalFormat={false}
        />

        <div className={classes}>
          {members?.map((member, index) => renderRow(member, index))}
          {loadedAll && members?.length === 0 && <EmptyState title={t('No employees')} />}
          {loading && (
            <Row justify={Justify.CENTER} align={Align.CENTER} className="py-8 no-print full-width">
              <Spinner />
            </Row>
          )}

          {viewEntriesDialog.isOpen && (
            <MemberTimeEntryDialog
              isOpen={viewEntriesDialog.isOpen}
              onClose={viewEntriesDialog.close}
              timeRange={dayRange}
              memberIds={memberIds}
              timeRangeType={selectedDate ? TimeRangeType.DAILY : timeRangeType}
              onDataChange={onDataChange}
            />
          )}

          <SignOffReportDialog
            isOpen={signOffReportDialog.isOpen}
            onClose={signOffReportDialog.close}
            timeRange={dayRange}
            memberIds={memberIds}
            timeRangeType={selectedDate ? TimeRangeType.DAILY : timeRangeType}
            onDataChange={onSignOffUpdate}
          />
        </div>
      </div>

      <TimeActionsFormDialog
        type={'add'}
        isOpen={timeEntryDialogDetails.isOpen}
        onClose={timeEntryDialogDetails.close}
        onSubmit={onTimeEntryFormDataSubmit}
        onDelete={onTimeEntryFormDataSubmit}
      />

      <TimeOffFormDialog
        isOpen={timeOffDialogDetails.isOpen}
        onClose={timeOffDialogDetails.close}
        onSubmit={onTimeOffFormDataSubmit}
      />

      <ProFeatureDialog isOpen={proUpgradeDialogDetails.isOpen} onClose={proUpgradeDialogDetails.close} />
      <TimesheetsPrintDialog
        isOpen={printDetails.isOpen}
        onClose={printDetails.close}
        onPrint={(options) => handlePrint(options, true)}
      />
    </>
  );
};

export default ExpandedTimeCardReport;
