import { Button, Checkbox, Form, IFormRender, Label, SelectFormField, Switch } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import DashboardTimeSelectFormField from 'components/domain/dashboard/DashboardTimeSelect/DashboardTimeSelectFormField';
import ProjectPickerFormField from 'components/domain/project/ProjectPickerFormField/ProjectPickerFormField';
import DraggableList, { IUpdatedDraggable } from 'components/foundation/DraggableList/draggable-list/DraggableList';
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import { useOrganization } from 'hooks';
import { compact, isNil, sortBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { ClassName } from 'types/ClassName';
import { t } from 'utils/localize';
import { v_require } from 'utils/validations';
import './DashboardCardSettingsDialog.scss';

export interface IDashboardCardSettingsDialogProps<Key extends string = string> {
  isOpen: boolean;
  title: string;
  settingsItem: IDashboardSettingsItem<Key>;
  onClose: () => void;
  onSave: (settingsItem: IDashboardSettingsItem<Key>) => void;
  className?: ClassName;
}

export interface IDashboardCardSettingsData {
  time: string;
  injuriesCheck?: boolean;
  missingSignOffsCheck?: boolean;
  inaccurateTimeCheck?: boolean;
  breakIssuesCheck?: boolean;
  customFlagsCheck?: boolean;
  projectsToDisplay?: string;
  customProjectOne?: string;
  customProjectTwo?: string;
  customProjectThree?: string;
  customProjectFour?: string;
  hideProcessedInjuries?: boolean;
  hideResolvedBreakIssues?: boolean;
  hideResolvedInaccurateTime?: boolean;
  hideResolvedCustomFlags?: boolean;
  injuriesPosition?: number;
  breakIssuesPosition?: number;
  inaccurateTimePosition?: number;
  customFlagsPosition?: number;
  missingSignOffsPosition?: number;
}

export interface ISafetySignatureDraggableItem {
  label: string;
  value: boolean;
}

type IDraggableListItem = ISafetySignatureDraggableItem & {
  position: number;
  draggable: boolean;
  id: string;
  visible: boolean;
  show: boolean;
};

function DashboardCardSettingsDialog<Key extends string = string>(props: IDashboardCardSettingsDialogProps<Key>) {
  const { isOpen, title, settingsItem, onSave, onClose, className } = props;
  const organization = useOrganization();
  const optionsKey = 'time';
  const injuryKey = 'injury';
  const inaccurateTimeKey = 'inaccurateTime';
  const breakHoursKey = 'breakHours';
  const projectsToDisplayKey = 'projectsToDisplay';
  const customProjectsKey = 'customProjects';
  const hideProcessedInjuriesKey = 'hideProcessedInjuries';
  const hideResolvedBreakIssuesKey = 'hideResolvedBreakIssues';
  const hideResolvedInaccurateTimeKey = 'hideResolvedInaccurateTime';
  const injuriesPositionKey = 'injuriesPosition';
  const breakIssuesPositionKey = 'breakIssuesPosition';
  const inaccurateTimePositionKey = 'inaccurateTimePosition';
  const customQuestionsKey = 'customQuestions';
  const customQuestionsPositionKey = 'customQuestionsPosition';
  const hideCustomQuestionsKey = 'hideResolvedCustomQuestions';
  const missingSignOffsKey = 'missingSignOffs';
  const missingSignOffsPositionKey = 'missingSignOffsPosition';

  const initialData = useCallback((): IDashboardCardSettingsData => {
    return {
      time: settingsItem.options![optionsKey],
      injuriesCheck: settingsItem.options![injuryKey],
      inaccurateTimeCheck: settingsItem.options![inaccurateTimeKey],
      breakIssuesCheck: settingsItem.options![breakHoursKey],
      projectsToDisplay: settingsItem.options![projectsToDisplayKey] ?? 'mostActive',
      customProjectOne: settingsItem.options![customProjectsKey] ? settingsItem.options![customProjectsKey][0] : null,
      customProjectTwo: settingsItem.options![customProjectsKey] ? settingsItem.options![customProjectsKey][1] : null,
      customProjectThree: settingsItem.options![customProjectsKey] ? settingsItem.options![customProjectsKey][2] : null,
      customProjectFour: settingsItem.options![customProjectsKey] ? settingsItem.options![customProjectsKey][3] : null,
      hideProcessedInjuries: settingsItem.options![hideProcessedInjuriesKey] ?? false,
      hideResolvedBreakIssues: settingsItem.options![hideResolvedBreakIssuesKey] ?? true,
      hideResolvedInaccurateTime: settingsItem.options![hideResolvedInaccurateTimeKey] ?? true,
      injuriesPosition: settingsItem.options![injuriesPositionKey] ?? 0,
      inaccurateTimePosition: settingsItem.options![inaccurateTimePositionKey] ?? 1,
      breakIssuesPosition: settingsItem.options![breakIssuesPositionKey] ?? 2,
      customFlagsCheck: settingsItem.options![customQuestionsKey],
      customFlagsPosition: settingsItem.options![customQuestionsPositionKey] ?? 3,
      hideResolvedCustomFlags: settingsItem.options![hideCustomQuestionsKey] ?? true,
      missingSignOffsCheck: settingsItem.options![missingSignOffsKey] ?? true,
      missingSignOffsPosition: settingsItem.options![missingSignOffsPositionKey] ?? 4,
    };
  }, [settingsItem]);

  const RenderDailySignatureAlertOptions = ({ ...form }: IFormRender<IDashboardCardSettingsData>) => {
    const updateCheck = (checked: boolean, key: string) => {
      switch (key) {
        case 'injuries':
          form.setFormData({
            ...form.state.data,
            injuriesCheck: checked,
          });
          break;
        case 'inaccurateTime':
          form.setFormData({
            ...form.state.data,
            inaccurateTimeCheck: checked,
          });
          break;
        case 'breakIssues':
          form.setFormData({
            ...form.state.data,
            breakIssuesCheck: checked,
          });
          break;
        case 'customFlags':
          form.setFormData({
            ...form.state.data,
            customFlagsCheck: checked,
          });
          break;
        case 'missingSignOffs':
          form.setFormData({
            ...form.state.data,
            missingSignOffsCheck: checked,
          });
          break;
      }
    };
    const updateToggle = (checked: boolean, key: string) => {
      switch (key) {
        case 'injuries':
          form.setFormData({
            ...form.state.data,
            hideProcessedInjuries: checked,
          });
          break;
        case 'inaccurateTime':
          form.setFormData({
            ...form.state.data,
            hideResolvedInaccurateTime: checked,
          });
          break;
        case 'breakIssues':
          form.setFormData({
            ...form.state.data,
            hideResolvedBreakIssues: checked,
          });
          break;
        case 'customFlags':
          form.setFormData({
            ...form.state.data,
            hideResolvedCustomFlags: checked,
          });
          break;
      }
    };

    const updatePositions = (items: Array<IUpdatedDraggable<IDraggableListItem>>) => {
      const positionsPayload = {
        injuriesPosition: 0,
        inaccurateTimePosition: 0,
        breakIssuesPosition: 0,
        customFlagsPosition: 0,
        missingSignOffsPosition: 0,
      };
      items.map((item) => {
        if (item.payload.id === 'injuries') {
          positionsPayload.injuriesPosition = item.index;
        }
        if (item.payload.id === 'inaccurateTime') {
          positionsPayload.inaccurateTimePosition = item.index;
        }
        if (item.payload.id === 'breakIssues') {
          positionsPayload.breakIssuesPosition = item.index;
        }
        if (item.payload.id === ' customFlags') {
          positionsPayload.customFlagsPosition = item.index;
        }
        if (item.payload.id === 'missingSignOffs') {
          positionsPayload.missingSignOffsPosition = item.index;
        }
      });

      form.setFormData({
        ...form.state.data,
        injuriesPosition: positionsPayload.injuriesPosition,
        inaccurateTimePosition: positionsPayload.inaccurateTimePosition,
        breakIssuesPosition: positionsPayload.breakIssuesPosition,
        customFlagsPosition: positionsPayload.customFlagsPosition,
        missingSignOffsPosition: positionsPayload.missingSignOffsPosition,
      });
    };

    const renderItem = (item: IDraggableListItem) => {
      return (
        <div className={'dashboard-card-settings-draggable-item mr-2'}>
          <Checkbox
            checked={item.value}
            value={item.value}
            onChange={(checked: boolean) => updateCheck(checked, item.id)}
            label={item.label}
            className={'my-2'}
          />
          {!(item.id === 'missingSignOffs') && (
            <div className={'my-2'}>
              <span>{item.id === 'injuries' ? t('Hide Processed') : t('Hide Resolved')}</span>
              <Switch
                onChange={(checked: boolean) => updateToggle(checked, item.id)}
                value={item.show}
                className={'ml-2'}
              />
            </div>
          )}
        </div>
      );
    };

    const items = useMemo(() => {
      const itemArray = [
        {
          id: 'injuries',
          label: 'Injuries',
          value: form.state.data.injuriesCheck!,
          position: form.state.data.injuriesPosition!,
          draggable: true,
          visible: organization.safetySignature,
          show: form.state.data.hideProcessedInjuries!,
        },
        {
          id: 'inaccurateTime',
          label: 'Inaccurate Time',
          value: form.state.data.inaccurateTimeCheck!,
          position: form.state.data.inaccurateTimePosition!,
          draggable: true,
          visible: organization.timeAccuracy,
          show: form.state.data.hideResolvedInaccurateTime!,
        },
        {
          id: 'breakIssues',
          label: 'Break Issues',
          value: form.state.data.breakIssuesCheck!,
          position: form.state.data.breakIssuesPosition!,
          draggable: true,
          visible: organization.breakPolicy,
          show: form.state.data.hideResolvedBreakIssues!,
        },
        {
          id: 'customFlags',
          label: 'Custom Flags',
          value: form.state.data.customFlagsCheck!,
          position: form.state.data.customFlagsPosition!,
          draggable: true,
          visible: !!organization.customSignOff,
          show: form.state.data.hideResolvedCustomFlags!,
        },
        {
          id: 'missingSignOffs',
          label: 'Missing Sign Offs',
          value: form.state.data.missingSignOffsCheck!,
          position: form.state.data.missingSignOffsPosition!,
          draggable: true,
          visible:
            !!organization.customSignOff ||
            !!organization.breakPolicy ||
            !!organization.timeAccuracy ||
            !!organization.safetySignature,
          show: true,
        },
      ];
      return sortBy(
        itemArray.filter((item) => item.visible),
        'position'
      );
    }, [
      form.state.data.breakIssuesCheck,
      form.state.data.breakIssuesPosition,
      form.state.data.customFlagsCheck,
      form.state.data.customFlagsPosition,
      form.state.data.hideProcessedInjuries,
      form.state.data.hideResolvedBreakIssues,
      form.state.data.hideResolvedCustomFlags,
      form.state.data.hideResolvedInaccurateTime,
      form.state.data.inaccurateTimeCheck,
      form.state.data.inaccurateTimePosition,
      form.state.data.injuriesCheck,
      form.state.data.injuriesPosition,
      form.state.data.missingSignOffsCheck,
      form.state.data.missingSignOffsPosition,
    ]);

    return <DraggableList items={items} renderItem={(item) => renderItem(item)} onUpdate={updatePositions} />;
  };

  function renderProjectPickers(form: IFormRender<IDashboardCardSettingsData>) {
    const first = form.state.data.customProjectOne;
    const second = form.state.data.customProjectTwo;
    const third = form.state.data.customProjectThree;
    const fourth = form.state.data.customProjectFour;

    const firstBlacklist = compact([second, third, fourth]);
    const secondBlacklist = compact([first, third, fourth]);
    const thirdBlacklist = compact([first, second, fourth]);
    const fourthBlacklist = compact([first, second, third]);

    return (
      <>
        <ProjectPickerFormField
          name="customProjectOne"
          form={form}
          placeholder={t('Project')}
          topLevelOnly={true}
          blacklistedIds={firstBlacklist}
        />
        <ProjectPickerFormField
          name="customProjectTwo"
          form={form}
          placeholder={t('Project')}
          topLevelOnly={true}
          blacklistedIds={secondBlacklist}
        />
        <ProjectPickerFormField
          name="customProjectThree"
          form={form}
          placeholder={t('Project')}
          topLevelOnly={true}
          blacklistedIds={thirdBlacklist}
        />
        <ProjectPickerFormField
          name="customProjectFour"
          form={form}
          placeholder={t('Project')}
          topLevelOnly={true}
          blacklistedIds={fourthBlacklist}
        />
      </>
    );
  }

  function renderBudgetProjectsToDisplayForm({ ...form }: IFormRender<IDashboardCardSettingsData>) {
    const options = [
      { value: 'mostActive', label: t('Most Active') },
      { value: 'custom', label: t('Custom') },
    ];

    return (
      <>
        <Label>{t('Projects to Display')} </Label>
        <SelectFormField
          name={'projectsToDisplay'}
          form={form}
          validations={[{ validation: v_require }]}
          options={options}
        />
        {form.state.data.projectsToDisplay === 'custom' && renderProjectPickers(form)}
      </>
    );
  }

  const renderFormFields = ({ ...form }: IFormRender<IDashboardCardSettingsData>) => {
    const buttonDisabled =
      settingsItem.key === 'dailySignatureAlerts' &&
      form.state.data.breakIssuesCheck === false &&
      form.state.data.inaccurateTimeCheck === false &&
      form.state.data.injuriesCheck === false &&
      form.state.data.customFlagsCheck === false;
    return (
      <div>
        {settingsItem.key === 'budgets' && renderBudgetProjectsToDisplayForm(form)}
        {(settingsItem.key !== 'budgets' ||
          (settingsItem.key === 'budgets' && form.state.data.projectsToDisplay !== 'custom')) && (
          <>
            <Label>{t('Time')}</Label>

            <DashboardTimeSelectFormField
              name="time"
              form={form}
              includeDaily={
                settingsItem.key === 'photosAndNotes' ||
                settingsItem.key === 'progressEntriesReport' ||
                settingsItem.key === 'photoVerification'
              }
            />
          </>
        )}
        {settingsItem.key === 'dailySignatureAlerts' && RenderDailySignatureAlertOptions(form)}
        <Button className="mt-4" type="primary" onClick={form.handleSubmit} disabled={buttonDisabled}>
          {t('Save')}
        </Button>
      </div>
    );
  };

  const handleSubmit = async (data: IDashboardCardSettingsData) => {
    const saveData: IDashboardSettingsItem<Key> = {
      ...settingsItem,
      options: {
        ...settingsItem.options,
        time: data.time,
      },
    };
    switch (settingsItem.key) {
      case 'dailySignatureAlerts':
        saveData.options = {
          ...saveData.options,
          injury: data.inaccurateTimeCheck,
          inaccurateTime: data.inaccurateTimeCheck,
          breakHours: data.breakIssuesCheck,
          injuriesPosition: data.injuriesPosition,
          breakIssuesPosition: data.breakIssuesPosition,
          inaccurateTimePosition: data.inaccurateTimePosition,
          hideProcessedInjuries: data.hideProcessedInjuries,
          hideResolvedBreakIssues: data.hideResolvedBreakIssues,
          hideResolvedInaccurateTime: data.hideResolvedInaccurateTime,
          customQuestions: data.customFlagsCheck,
          customQuestionsPosition: data.customFlagsPosition,
          hideResolvedCustomQuestions: data.hideResolvedCustomFlags,
          missingSignOffs: data.missingSignOffsCheck,
          missingSignOffsPosition: data.missingSignOffsPosition,
        };

        break;
      case 'budgets':
        saveData.options = {
          ...saveData.options,
          projectsToDisplay: data.projectsToDisplay,
          customProjects:
            data.projectsToDisplay === 'custom'
              ? [data.customProjectOne, data.customProjectTwo, data.customProjectThree, data.customProjectFour].filter(
                  (id) => !isNil(id)
                )
              : null,
        };
        break;
      default:
        break;
    }
    onSave(saveData);
    onClose();
  };

  const classes = classNames('dashboard-card-settings-dialog form-dialog-padding', className);

  return (
    <HeaderDialog isOpen={isOpen} title={title} onClose={onClose} divider={false} className={classes}>
      <Form data={initialData()} onSubmit={handleSubmit} render={renderFormFields} className={classes} />
    </HeaderDialog>
  );
}

export default DashboardCardSettingsDialog;
