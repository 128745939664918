import { Loader } from '@busybusy/webapp-react-ui';
import { Suspense, useEffect, useMemo, useState, type PropsWithChildren } from 'react';

export interface ILazyImportWrapperProps {
  /**
   * @description Once triggerLoad becomes true, the `LazyComponent` will be loaded lazily.
   */
  triggerLoad?: boolean;

  /**
   * @description optionally, control if the `LazyComponent` should be mounted.
   */
  condition?: boolean;

  /**
   * @description The content to render while the `LazyComponent` is being loaded.
   *
   * the default is `<Loader isOpen={true} />`
   */
  fallback?: React.ReactNode;
}

const defaultLazyImportWrapperProps = {
  triggerLoad: true,
  condition: true,
};

/**
 * @name LazyImportWrapper
 *
 * @description
 *  Helper component for preventing the network from loading a component until it is needed.
 *
 * Example Usage:
```tsx
  const MyLazyDialog = lazy(() => import('./MyLazyDialog'));
  ...
  <LazyImportWrapper triggerLoad={dialog.isOpen}>
    <MyLazyDialog isOpen={dialog.isOpen} onClose={dialog.close} />
  </LazyImportWrapper>
```
 *
 */
export const LazyImportWrapper: React.FC<PropsWithChildren<ILazyImportWrapperProps>> = (props) => {
  const { triggerLoad, fallback, children, condition } = { ...defaultLazyImportWrapperProps, ...props };

  const [isMounted, setIsMounted] = useState(false);
  const suspenseFallback = useMemo(() => fallback ?? <Loader isOpen={true} />, [fallback]);

  useEffect(() => {
    if (triggerLoad && !isMounted) {
      setIsMounted(true);
    }
  }, [triggerLoad, isMounted]);

  return isMounted && condition ? <Suspense fallback={suspenseFallback}>{children}</Suspense> : null;
};
