import { Field, FieldFooter, Position } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { t } from 'i18next';
import * as React from 'react';
import { ReactNode } from 'react';
import { ProjectPicker } from '../../..';
import type { CostCode } from '__generated__/graphql';

interface IProjectPickerFormFieldProps {
  className?: ClassName;
  form: any;
  name: string;
  dropDownButtonRender?: ReactNode; // Drop down button on the picker
  closeButtonRender?: (handleClear: (event: React.KeyboardEvent | React.MouseEvent) => void) => ReactNode; // Close button render on picker
  position?: Position;
  placeholder?: string;
  onChange?: (value: string | null) => void;
  validations?: any;
  showGpsError?: boolean;
  topLevelOnly?: boolean;
  blacklistedIds?: string[];
  scopeToCostCodeIds?: Array<CostCode['id']>;
  disabled?: boolean;
}

function ProjectPickerFormField({
  form,
  name,
  validations,
  className,
  onChange,
  showGpsError,
  ...other
}: IProjectPickerFormFieldProps) {
  const classes = classNames('project-picker-form-field', className);
  function renderField({ ...fieldProps }) {
    function handleOnSelect(val: string | null) {
      fieldProps.handleChange(val, onChange);
    }

    const showError: boolean = showGpsError || fieldProps.hasError;
    const errorMessage: string = showGpsError
      ? t('You must be on-site at this project to clock in.')
      : fieldProps.errorMessage;

    return (
      <>
        <ProjectPicker error={showError} value={form.state.data[name]} onSelect={handleOnSelect} {...other} />
        <FieldFooter errorMessage={errorMessage} hasError={showError} />
      </>
    );
  }

  return <Field form={form} name={name} validations={validations} className={classes} render={renderField} />;
}

export default ProjectPickerFormField;
