import { useApolloClient } from '@apollo/client';
import {
  ConditionFieldType,
  ConditionOperationType,
  FlaggedTimeEntryLocation,
  LaborMetricsInterval,
  MemberGpsStatus,
  MemberTimeMetricsQueryQuery,
  MemberTimeMetricsQueryQueryVariables,
  OperationType,
} from '__generated__/graphql';
import { MEMBER_TIME_METRICS_QUERY } from 'apollo/queries/member-metric-queries';
import { SAFETY_SIGNATURES_QUERY } from 'apollo/queries/safety-signature-query';
import { useApolloPaging } from 'hooks';
import { t } from 'i18next';
import { isEmpty, isNil, some, sumBy } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import ICursorable from 'types/Cursorable';
import IIdable from 'types/Idable';
import IMemberTimeDocument from 'types/MemberTimeDocument';
import ISafetySignature from 'types/SafetySignature';
import ITimeEntry from 'types/TimeEntry';
import ITimeOff from 'types/TimeOff';
import ITimeRange from 'types/TimeRange';
import IMemberTimeMetrics from 'types/aggregate/MemberLaborMetrics';
import MemberPermission from 'types/enum/MemberPermission';
import { getTotalAsHoursMinutesSeconds } from 'utils/stringUtils';
import ITimeEntryReportWithHeaderData from '../../TimeEntryDataReport/types/TimeEntryReportWithHeaderData';
import { ITimeEntryDataTableRow } from '../TimeEntryDataTable';
import useTimeEntryToTimeEntryRowMap from './useTimeEntryToTimeEntryRowMap';
import { useTimeOffToTimeOffRowMap } from './useTimeOffToTimeOffRowMap';

export interface ITimeEntryDataTableData<T extends IIdable<string>> {
  item: T;
  entries: ITimeEntry[];
  timeOffs: ITimeOff[];
  cursor?: string;
}

export interface ITimeEntryReportRowActions {
  logs: boolean;
  locations: boolean;
  conflicts: boolean;
  splitEntry: boolean;
  memberGpsStatuses: Pick<MemberGpsStatus, 'status' | 'localTime'>[];
  flaggedTimeEntryLocation?: FlaggedTimeEntryLocation | null;
}

export function useTimeEntryDataTableRow() {
  const convertTimeEntryToRow = useTimeEntryToTimeEntryRowMap();
  const convertTimeOffToRow = useTimeOffToTimeOffRowMap();
  const { getAll } = useApolloPaging();
  const client = useApolloClient();

  const getSafetySignatures = useCallback(
    async (timeRange: ITimeRange<DateTime>, memberId: string): Promise<ISafetySignature[]> => {
      const signatures = await getAll<ISafetySignature & ICursorable>('safetySignatures', {
        query: SAFETY_SIGNATURES_QUERY,
        variables: {
          first: 500,
          filter: {
            startTime: {
              lessThanOrEqual: timeRange.endTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
            },
            endTime: {
              greaterThanOrEqual: timeRange.startTime
                .toUTC()
                .toISO({ suppressMilliseconds: true, includeOffset: false }),
            },
            memberId: { equal: memberId },
            deletedOn: { isNull: true },
            member: { permissions: { permissions: MemberPermission.TIME_EVENTS, operationType: 'and' } },
          },
          sort: [{ startTime: 'asc' }, { createdOn: 'asc' }],
        },
        fetchPolicy: 'network-only',
      });

      return signatures;
    },
    [getAll]
  );

  const getLaborMetrics = useCallback(
    async (timeRange: ITimeRange<DateTime>, memberId: string): Promise<IMemberTimeMetrics[]> => {
      const metrics = await client.query<MemberTimeMetricsQueryQuery, MemberTimeMetricsQueryQueryVariables>({
        query: MEMBER_TIME_METRICS_QUERY,
        variables: {
          metricsInterval: LaborMetricsInterval.Custom,
          metricsStartDate: timeRange.startTime.toISODate(),
          metricsEndDate: timeRange.endTime.toISODate(),
          metricFilter: {
            operationType: OperationType.And,
            conditions: [
              {
                field: ConditionFieldType.MemberId,
                operator: ConditionOperationType.Equal,
                value: memberId,
              },
            ],
          },
        },
        fetchPolicy: 'network-only',
      });

      return metrics.data.memberLaborMetrics as IMemberTimeMetrics[];
    },
    [client]
  );

  // TODO: this function has jumped the shark
  async function getRows<T extends IIdable<string>>(
    timeRange: ITimeRange<DateTime>,
    items: Array<ITimeEntryDataTableData<T>>,
    getName: (item: T) => string,
    getSignature?: (item: T) => IMemberTimeDocument | null | undefined,
    shouldUseSafetySignatures?: boolean,
    shouldUseMetrics?: boolean,
    shouldIncludeEntry?: (row: ITimeEntryDataTableRow, item: T) => boolean
  ): Promise<ITimeEntryReportWithHeaderData[]> {
    const rowPromises = items.map(async (item): Promise<ITimeEntryReportWithHeaderData> => {
      const safetySignatures = shouldUseSafetySignatures
        ? await getSafetySignatures(timeRange, item.item.id)
        : undefined;

      let rows: ITimeEntryDataTableRow[] = item.entries.flatMap((e) => {
        const newRows = convertTimeEntryToRow(
          e,
          timeRange,
          safetySignatures,
          shouldUseMetrics ? getLaborMetrics : undefined
        );
        if (shouldIncludeEntry) {
          return newRows.filter((r) => shouldIncludeEntry(r, item.item));
        }
        return newRows;
      });
      if (!isEmpty(item.timeOffs)) {
        rows = rows.concat(item.timeOffs.map(convertTimeOffToRow));
      }
      const total = getTotalAsHoursMinutesSeconds(sumBy(rows, (r) => r.totalSeconds));
      const breaks = getTotalAsHoursMinutesSeconds(sumBy(rows, (r) => r.breakSeconds ?? 0));
      const title = getName(item.item);
      const hasSignature = getSignature && !isNil(getSignature(item.item)) ? !getSignature(item.item)?.canceled : false;
      const breakCompliance = some(rows, (r) => !isNil(r.breakCompliance))
        ? some(rows, (r) => r.breakCompliance === 'No')
          ? t('No')
          : t('Yes')
        : '---';
      const timeAccurate = some(rows, (r) => !isNil(r.timeAccurate))
        ? some(rows, (r) => r.timeAccurate === 'No')
          ? t('No')
          : t('Yes')
        : '---';
      const injured = some(rows, (r) => !isNil(r.injured))
        ? some(rows, (r) => r.injured === 'Yes')
          ? t('Yes')
          : t('No')
        : '---';

      const ot = getTotalAsHoursMinutesSeconds(sumBy(rows, (r) => r.otSeconds));
      const dt = getTotalAsHoursMinutesSeconds(sumBy(rows, (r) => r.dtSeconds));

      return {
        id: item.item.id,
        title,
        breaks,
        breakCompliance,
        timeAccurate,
        injured,
        total,
        rows,
        hasSignature,
        ot,
        dt,
      };
    });

    return Promise.all(rowPromises);
  }

  return getRows;
}
