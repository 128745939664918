import { gql } from '@apollo/client';

export const LOG_FEED_TIME_ENTRY_LOGS_QUERY = gql`
  query LogFeedTimeEntryLogs($after: String, $filter: ClientTimeEntryLogFilter, $first: Int) {
    clientTimeEntryLogs(after: $after, filter: $filter, first: $first, sort: [{ createdOn: desc }]) {
      id
      cursor
      createdOn
      actionType
      deviceType
      startLocationId
      endLocationId
      startTime
      daylightSavingTime
      endTime
      metaDaylightSavingTime
      projectId
      costCodeId
      equipmentId
      description
      originalTimeEntryId
      timeEntryId
      memberId
      member {
        id
        firstName
        lastName
        memberGroupId
        position {
          id
          level
        }
      }
      updaterMember {
        id
        firstName
        lastName
      }
      updaterMemberId
      project {
        id
        title
        projectGroupId
        depth
        ancestors {
          id
          title
          depth
          archivedOn
        }
      }
      costCode {
        id
        costCode
        costCodeGroupId
        title
      }
      equipment {
        id
        equipmentName
        model {
          id
          title
          equipmentCategoryId
          make {
            id
            title
          }
          category {
            id
            title
          }
        }
      }
      timeEntry {
        startTime
        startDeviceTime
        endTime
        endDeviceTime
        daylightSavingTime
        metaDaylightSavingTime
        actionType
        createdOn
        deletedOn
        submittedOn
        updatedOn
        startLocation {
          id
        }
        endLocation {
          id
        }
        breaks {
          id
          clientLogs {
            id
            timeEntryBreakId
            startTime
            endTime
            actionType
            deviceType
            createdOn
            startLocationId
            endLocationId
            clientTimeEntryLogId
          }
          startTime
          startDeviceTime
          startTimeTrusted
          startTimeDst
          endTime
          endDeviceTime
          endTimeTrusted
          endTimeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
          startLocation {
            id
          }
          endLocation {
            id
          }
        }
      }
    }
  }
`;

export const UNJOINED_MEMBER_LOCATIONS_QUERY = gql`
  query UnjoinedMemberLocations(
    $filter: MemberLocationFilter
    $sort: [MemberLocationSort!]
    $first: Int
    $after: String
  ) {
    memberLocations(filter: $filter, sort: $sort, first: $first, after: $after) {
      id
      memberId
      longitude
      latitude
      horizontalAccuracy
      altitude
      verticalAccuracy
      timeGmt
      timeLocal
      timeDst
      createdOn
      submittedOn
      updatedOn
      deletedOn
      cursor
    }
  }
`;
