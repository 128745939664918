import { Theme } from '@busybusy/webapp-react-ui';
import { ChannelIds } from 'contexts/ChannelContext/ChannelIds';
import { useChannelContext } from 'contexts/ChannelContext/useChannelContext';
import { useToastOpen } from 'contexts/ToastContext';
import { useCallback, useEffect, useRef } from 'react';
import { AnyObject } from 'types/util/Object';
import { downloadText } from 'utils/fileUtils';
import { t } from 'utils/localize';
import { ITimeEntryDataReportData } from '../TimeEntryDataReport/TimeEntryDataReport';
import { convertTimeEntryDataToCsv } from 'containers/timesheets/utils/utils';

export function useTimeEntryExportChannelListener(
  data: ITimeEntryDataReportData[],
  loadedAll: boolean,
  fileName: string,
  loadAll?: () => Promise<AnyObject | undefined> | undefined
) {
  const channel = useChannelContext();
  const errorToast = useToastOpen();
  const shouldExport = useRef(false);

  const exportData = () => {
    if (data.length !== 0) {
      const csv = convertTimeEntryDataToCsv(data);
      downloadText(csv, `${fileName}.csv`);
    } else {
      errorToast({ theme: Theme.DANGER, label: t('There is no data to export.') });
    }
  };

  const onExport = useCallback(async () => {
    if (!loadedAll && loadAll) {
      // load all of the data, and once the data variable changes then we'll export it
      shouldExport.current = true;
      await loadAll();
    } else {
      // all the data is loaded so we can export right away
      exportData();
    }
  }, [data, loadAll, loadedAll]);

  useEffect(() => {
    if (shouldExport.current) {
      shouldExport.current = false;
      exportData();
    }
  }, [data]);

  useEffect(() => {
    channel.registerListener(ChannelIds.Export, onExport);

    return () => {
      channel.unregisterListener(ChannelIds.Export);
    };
  }, [channel, onExport]);
}
