import { QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { Bar, Position, Size } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { t } from 'i18next';
import { uniqBy } from 'lodash';
import { Component } from 'react';
import { ClassName } from 'types/ClassName';
import { AnyObject } from 'types/util/Object';
import LazyLoadPicker from '../../../foundation/pickers/LazyLoadPicker/LazyLoadPicker';

export interface IGroupPickerProps<T> {
  value: string | null;
  query: any;
  singleQuery: any;
  filter?: AnyObject;
  onSelect: (groupId: string | null) => void;
  queryResolver: (results: QueryResult) => T[];
  singleQueryResolver: (results: QueryResult) => T;
  minWidth?: string; // has default
  className?: ClassName;
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
  position?: Position;
  client?: any;
  fetchPolicy?: string;
  searchArchived?: boolean;
  autofocus?: boolean;
  includeNoneOption?: boolean;
}

interface IState<T extends { id: string; groupName: string }> {
  groups: T[];
  loadedAll: boolean;
}

// TODO rework this component to have good types/function component
class GroupPicker<T extends { id: string; groupName: string }> extends Component<
  WithApolloClient<IGroupPickerProps<T>>,
  IState<T>
> {
  public static defaultProps: any;
  public sectionSize = 10;
  public state: IState<T> = {
    loadedAll: false,
    groups: [],
  };

  // Return the filter for the server call
  public getGroupFilter() {
    return { deletedOn: { isNull: true } };
  }

  // Fetch a page of groups
  public fetchGroups = (after?: string): Promise<T[]> => {
    const { query, fetchPolicy, client, queryResolver, filter } = this.props;

    return new Promise((resolve, reject) => {
      client
        .query({
          fetchPolicy,
          query,
          variables: {
            after,
            filter: {
              ...this.getGroupFilter(),
              ...filter,
            },
            first: this.sectionSize,
          },
        })
        .then((result: QueryResult) => {
          resolve(queryResolver(result));
        })
        .catch(() => {
          reject();
        });
    });
  };

  // Handle new results from the server
  public handleDidLoad = (groups: T[], error: boolean, loadedAll: boolean) => {
    const { includeNoneOption } = this.props;
    let updatedGroups = groups;

    if (includeNoneOption) {
      const noneOption = { id: 'none', groupName: 'No Group' } as T;
      updatedGroups = uniqBy([...groups, noneOption], 'id');
    }

    if (!error) {
      this.setState({
        loadedAll,
        groups: updatedGroups,
      });
    }
  };

  // Render the picker's value
  public renderValueTemplate = () => {
    const value = this.props.value;

    if (value === 'none') {
      return <>{t('No Group')}</>;
    }

    if (value) {
      return (
        <Query query={this.props.singleQuery} variables={{ groupId: value }}>
          {({ data }: QueryResult) => {
            return <>{this.props.singleQueryResolver(data)}</>;
          }}
        </Query>
      );
    }
    return <></>;
  };

  // Render a row within the picker
  public renderRow = (group: T) => (
    <Bar size={Size.SMALL} className="px-3">
      <div className="ellipsis">{group.groupName}</div>
    </Bar>
  );

  // Handle when a value is selected from the list
  public handleSelect = (group: T | null) => {
    this.props.onSelect(group ? group.id : null);
  };

  // Handle the picker closing
  public handleClose = () => {
    this.setState({
      loadedAll: false,
      groups: [],
    });
  };

  // Render
  public render() {
    const { className, placeholder, minWidth, position, disabled, value, autofocus } = this.props;

    const classes = classNames(
      {
        'group-picker': true,
      },
      className
    );

    return (
      <LazyLoadPicker
        value={value}
        getData={this.fetchGroups}
        didLoad={this.handleDidLoad}
        data={this.state.groups}
        loadedAll={this.state.loadedAll}
        onSelect={this.handleSelect}
        onClose={this.handleClose}
        valueTemplate={this.renderValueTemplate()}
        renderRow={this.renderRow}
        minWidth={minWidth}
        disabled={disabled}
        position={position}
        placeholder={placeholder}
        autofocus={autofocus}
        className={classes}
      />
    );
  }
}

GroupPicker.defaultProps = {
  fetchPolicy: 'cache-first',
  minWidth: '350px',
  includeNoneOption: false,
};

export default withApollo<IGroupPickerProps<any>>(GroupPicker);
