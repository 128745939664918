import { Field, FieldFooter, IFormFieldDefaultProps } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import CostCodePicker, { ICostCodePickerProps } from 'components/domain/cost-code/CostCodePicker/CostCodePicker';
import { useOrganization } from 'hooks';
import { AnyObject } from 'types/util/Object';

export interface ICostCodePickerFormFieldProps<FormType extends AnyObject>
  extends Omit<ICostCodePickerProps, 'value' | 'onSelect'>,
    IFormFieldDefaultProps<string | null, FormType> {
  onSelect?: (value: string | null) => void;
}

const CostCodePickerFormField = <FormType extends AnyObject>({
  form,
  name,
  validations,
  onSelect,
  className,
  revalidateOnNewValidations,
  ...other
}: ICostCodePickerFormFieldProps<FormType>) => {
  const organization = useOrganization();

  function renderField({ ...fieldProps }) {
    function handleOnSelect(value: string | null) {
      fieldProps.handleChange(value, onSelect);
    }

    return (
      <>
        <CostCodePicker
          error={fieldProps.hasError}
          value={form.state.data[name]}
          onSelect={handleOnSelect}
          {...other}
        />
        <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
      </>
    );
  }

  if (!organization.trackCostCode) {
    return null;
  }

  const classes = classNames('cost-code-picker-form-field', className);

  return (
    <Field
      className={classes}
      form={form}
      name={name}
      validations={validations}
      render={renderField}
      revalidateOnNewValidations={revalidateOnNewValidations}
    />
  );
};

export default CostCodePickerFormField;
