import { useMemo } from 'react';
import ITimeEntryReportWithHeaderData from '../../types/TimeEntryReportWithHeaderData';
import { IMemberTimeEntryFilter } from './useMemberTimeData';
import { useReactQueryLazyLoading } from 'hooks/react-query/useReactQueryLazyLoading/useReactQueryLazyLoading';
import useMemberTimeEntryDataRows from './useMemberTimeEntryDataRows';
import { QueryKey } from '@tanstack/react-query';

// TODO: probably consolidate this type to one type but they're both used in different parts of the app
export interface IMemberTimeEntryReportDataFilter extends IMemberTimeEntryFilter {}

export interface ITimeEntryDataReportMemberData extends ITimeEntryReportWithHeaderData {
  canManage: boolean;
  canManageTimeOff: boolean;
  isArchived: boolean;
}

export default function useMemberTimeEntryDataReportData(
  scroller: HTMLElement | null,
  filter: IMemberTimeEntryReportDataFilter,
  queryKey: QueryKey
) {
  const getData = useMemberTimeEntryDataRows(filter);

  const { data, loadAll, loadedAll, isLoading, isError, refetch } = useReactQueryLazyLoading(
    scroller,
    queryKey,
    getData,
    20
  );

  return useMemo(
    () => ({
      data,
      loading: isLoading,
      loadedAll,
      onDataChange: refetch,
      error: isError,
      loadAll,
      clearData: refetch,
    }),
    [data, isLoading, isError, loadedAll, loadAll, refetch]
  );
}
