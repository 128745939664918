import { Theme, Toast } from '@busybusy/webapp-react-ui';
import { MemberPermissions } from '__generated__/graphql';
import classNames from 'classnames';
import ProFeatureDialog from 'components/domain/account/ProFeatureDialog/ProFeatureDialog';
import MemberTimeEntryDataReport from 'components/domain/time-entry/TimeEntryDataReport/MemberTimeEntryDataReport/MemberTimeEntryDataReport';
import useMemberTimeEntryDataReportData, {
  IMemberTimeEntryReportDataFilter,
} from 'components/domain/time-entry/TimeEntryDataReport/MemberTimeEntryDataReport/hooks/useMemberTimeEntryDataReportData';
import { ITimeEntryDataTableRow } from 'components/domain/time-entry/TimeEntryDataTable/TimeEntryDataTable';
import { ITimeOffFormData } from 'components/domain/time-off/form/CreateTimeOffForm/CreateTimeOffForm';
import TimeOffFormDialog from 'components/domain/time-off/form/TimeOffFormDialog/TimeOffFormDialog';
import RowSettingsDialog from 'components/foundation/dialogs/RowSettingsDialog/RowSettingsDialog';
import EmptyState from 'components/foundation/state-templates/EmptyState/EmptyState';
import TableColumnsDialog from 'components/foundation/table/TableColumnsDialog/TableColumnsDialog';
import TimesheetPrintHeader from 'containers/timesheets/TimesheetPrintHeader/TimesheetPrintHeader';
import TimesheetsActionHeader from 'containers/timesheets/TimesheetsActionHeader/TimesheetsActionHeader';
import TimesheetsColumnsForm from 'containers/timesheets/TimesheetsColumnsForm/TimesheetsColumnsForm';
import TimesheetsHeader from 'containers/timesheets/TimesheetsHeader/TimesheetsHeader';
import TimesheetsPrintDialog from 'containers/timesheets/TimesheetsPrintDialog/TimesheetsPrintDialog';
import TimesheetsTimeEntryActionBar from 'containers/timesheets/action-bar/TimesheetsTimeEntryActionBar/TimesheetsTimeEntryActionBar';
import useTimesheetsQueryParams from 'containers/timesheets/hooks/useTimesheetsQueryParams';
import useTimesheetsRowHeight from 'containers/timesheets/hooks/useTimesheetsRowHeight/useTimesheetsRowHeight';
import { convertTimeEntryDataToCsv } from 'containers/timesheets/utils/utils';
import { useLoader } from 'contexts/LoaderContext';
import { useToastOpen } from 'contexts/ToastContext';
import { useEmployeeNameFormat, useOpenable, useReduxSelector, useTimesheetsGraylog } from 'hooks';
import useMemberGraphAggregates from 'hooks/aggregates/useMemberGraphAggregates';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import useReactQueryBaseKey from 'hooks/react-query/useReactQueryBaseKey/useReactQueryBaseKey';
import useIsPro from 'hooks/store/useIsPro';
import { IUseTimeRangePayload } from 'hooks/utils/useTimeRange';
import { t } from 'i18next';
import { isEmpty, isNil, map, partition } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { TimesheetView, updateCheckedTimeEntryIds, updateCheckedTimeOffIds } from 'store/timesheets/Timesheets';
import { ClassName } from 'types/ClassName';
import ITimeRange from 'types/TimeRange';
import { isNilOrEmpty } from 'utils/collectionUtils';
import { TimesheetsTypes } from 'utils/constants/graylogActionTypes';
import { isoTimeStampLocal } from 'utils/dateUtils';
import { downloadText } from 'utils/fileUtils';
import TimeActionsFormDialog from '../time-actions-form/TimeActionsFormDialog/TimeActionsFormDialog';
import { ITimeActionsFormData } from '../time-actions-form/hooks/useTimeActionsForm';
import './TimeEntryReport.scss';
import { Nullable } from 'types/util/Nullable';

export interface ITimeEntryReportProps extends IUseTimeRangePayload {
  timeRange: ITimeRange<DateTime>;
  permission: MemberPermissions;
  className?: ClassName;
}

const TimeEntryReport = ({
  timeRange,
  permission,
  timeRangeType,
  forward,
  back,
  forwardEnabled,
  backEnabled,
  className,
}: ITimeEntryReportProps) => {
  const scroller = useRef<Nullable<HTMLDivElement>>(null);

  const filter = useTimesheetTimeEntryReportFilter(timeRange, permission);
  const [employeeNameFormat] = useEmployeeNameFormat();

  const coumnSettings = useReduxSelector((state) => state.timesheet.dailyColumns);
  const baseQueryKey = useReactQueryBaseKey();
  const queryKey = useMemo(
    () => [baseQueryKey, 'timesheets-member-time-data', employeeNameFormat, filter, coumnSettings],
    [baseQueryKey, coumnSettings, employeeNameFormat, filter]
  );

  const {
    data,
    loading,
    onDataChange: handleDataChange,
    loadAll,
  } = useMemberTimeEntryDataReportData(scroller.current, filter, queryKey);

  const {
    data: graphAggregates,
    error: graphAggregateError,
    refetchData: refetchAggregates,
  } = useMemberGraphAggregates(
    timeRange,
    filter.memberIds,
    filter.projectIds,
    filter.costCodeIds,
    filter.equipmentIds,
    filter.memberGroupId,
    2,
    filter.archivedStatus
  );

  const userEvents = useTimesheetsGraylog();

  const brand = useBrandTitle();
  const isPro = useIsPro();

  const { open: openLoader, close: closeLoader } = useLoader();
  const openToast = useToastOpen();

  const timeEntryDialogDetails = useOpenable();
  const timeOffDialogDetails = useOpenable();
  const columnDialogDetails = useOpenable();
  const proUpgradeDialogDetails = useOpenable();
  const printDetails = useOpenable();
  const rowSettingsDialog = useOpenable();

  const columnSettings = useReduxSelector((state) => state.timesheet.timeEntryTableColumns);
  const selectedIds = useReduxSelector((state) =>
    state.timesheet.checkedTimeEntryIds.concat(state.timesheet.checkedTimeOffIds)
  );
  const dispatch = useDispatch();

  const [rowHeight, setRowHeight] = useTimesheetsRowHeight();

  const errorToastDetails = useOpenable();
  const errorToastMessage = useRef(t('There was an unexpected error.'));

  const onSelectChange = useCallback(
    (rows: ITimeEntryDataTableRow[]) => {
      const [timeEntryIds, timeOffIds] = partition(rows, (row) => isNil(row.timeOffType));
      dispatch(updateCheckedTimeEntryIds(map(timeEntryIds, 'id')));
      dispatch(updateCheckedTimeOffIds(map(timeOffIds, 'id')));
    },
    [dispatch]
  );

  async function onExport() {
    if (!isPro) {
      proUpgradeDialogDetails.open();
      return;
    }

    userEvents.events(TimesheetsTypes.events.action_type.EXPORT);
    openLoader(t('Loading...'));

    try {
      const data = await loadAll();

      if (data.length !== 0) {
        const csv = convertTimeEntryDataToCsv(data, false);
        downloadText(csv, `${brand}-${t('timesheets-time-entries')}-${isoTimeStampLocal()}.csv`);
      } else {
        openToast({ theme: Theme.DANGER, label: t('There is no data to export.') });
      }
    } catch (e) {
      openToast({ theme: Theme.DANGER, label: t('Something went wrong exporting your data.') });
    } finally {
      closeLoader();
    }
  }

  function onPrint() {
    printDetails.open();
    userEvents.events(TimesheetsTypes.events.action_type.PRINT);
  }

  async function handlePrint() {
    printDetails.close();
    openLoader(t('Loading...'));
    try {
      await loadAll();

      setTimeout(() => {
        window.print();
      }, 500);
    } catch (e) {
      openToast({ theme: Theme.DANGER, label: t('Something went wrong loading your data.') });
    } finally {
      closeLoader();
    }
  }

  function onTimeEntryFormDataSubmit(_formData: ITimeActionsFormData) {
    onDataChange();
    timeEntryDialogDetails.close();
  }

  function onTimeOffFormDataSubmit(formData: ITimeOffFormData | undefined) {
    if (formData) {
      onDataChange();
    }
    timeOffDialogDetails.close();
  }

  function onDataChange() {
    handleDataChange();
    refetchAggregates();
  }

  const dataIsEmpty = isNilOrEmpty(filter.memberIds) && isEmpty(data) && !loading;
  const classes = classNames('time-entry-report', 'overflow-y-auto', { 'full-height': dataIsEmpty }, className);

  return (
    <>
      <TimesheetsActionHeader
        className="no-print"
        timeRange={timeRange}
        timeRangeType={timeRangeType}
        onTimeRangeForward={forward}
        onTimeRangeBackward={back}
        forwardDisabled={!forwardEnabled()}
        backwardDisabled={!backEnabled()}
        onTimeEntryAdd={timeEntryDialogDetails.open}
        onTimeOffAdd={timeOffDialogDetails.open}
        onColumnsClick={columnDialogDetails.open}
        onExport={onExport}
        onPrint={onPrint}
        onRowHeightClick={rowSettingsDialog.open}
        actionBar={
          <TimesheetsTimeEntryActionBar
            onBulkEditSubmit={onDataChange}
            onDelete={onDataChange}
            onTimeEntryEdit={onDataChange}
          />
        }
      />

      <TimesheetPrintHeader title={t('Time Entries')} timeRange={timeRange} />
      <div className={classes} ref={scroller}>
        <TimesheetsHeader
          className="m-6 no-print"
          timeRange={timeRange}
          graphData={graphAggregates}
          aggregateError={graphAggregateError?.name ?? null}
          showDecimalFormat={false}
        />
        <MemberTimeEntryDataReport
          columnSettings={columnSettings}
          selectedIds={selectedIds}
          onSelectChange={onSelectChange}
          onDataChange={onDataChange}
          data={data ?? []}
          timeRange={timeRange}
          loading={loading}
          rowHeight={rowHeight}
        />
        <Toast isOpen={errorToastDetails.isOpen} onClose={errorToastDetails.close} theme={Theme.DANGER}>
          {errorToastMessage.current}
        </Toast>
      </div>
      {/** We show the empty state with the member header when they're filtered by members **/}
      {dataIsEmpty && <EmptyState className="empty-state" title={t('No time entries in the specified date range.')} />}

      <TimeActionsFormDialog
        type={'add'}
        isOpen={timeEntryDialogDetails.isOpen}
        onClose={timeEntryDialogDetails.close}
        onSubmit={onTimeEntryFormDataSubmit}
        onDelete={onTimeEntryFormDataSubmit}
      />

      <TimeOffFormDialog
        isOpen={timeOffDialogDetails.isOpen}
        onClose={timeOffDialogDetails.close}
        onSubmit={onTimeOffFormDataSubmit}
      />

      <RowSettingsDialog
        isOpen={rowSettingsDialog.isOpen}
        onClose={rowSettingsDialog.close}
        initialValue={rowHeight}
        onSubmit={setRowHeight}
      />

      <TableColumnsDialog
        isOpen={columnDialogDetails.isOpen}
        onClose={columnDialogDetails.close}
        columnsForm={
          <TimesheetsColumnsForm timesheetView={TimesheetView.TIME_ENTRIES} onClose={columnDialogDetails.close} />
        }
      />
      <ProFeatureDialog isOpen={proUpgradeDialogDetails.isOpen} onClose={proUpgradeDialogDetails.close} />
      <TimesheetsPrintDialog isOpen={printDetails.isOpen} onClose={printDetails.close} onPrint={handlePrint} />
    </>
  );
};

TimeEntryReport.defaultProps = {
  type: null,
};

function useTimesheetTimeEntryReportFilter(
  timeRange: ITimeRange<DateTime>,
  memberPermission: MemberPermissions
): IMemberTimeEntryReportDataFilter {
  const {
    timesheetReportType,
    memberGroupId,
    memberId,
    positionId,
    projectId,
    costCodeId,
    equipmentId,
    archivedStatus,
    projectGroupId,
    costCodeGroupId,
  } = useTimesheetsQueryParams();

  return useMemo<IMemberTimeEntryReportDataFilter>(
    () => ({
      archivedStatus,
      includeOpenEntries: false,
      memberIds: memberId ? [memberId] : null,
      memberGroupId,
      projectIds: projectId ? [projectId] : null,
      costCodeIds: costCodeId ? [costCodeId] : null,
      equipmentIds: equipmentId ? [equipmentId] : null,
      positionId,
      reportType: timesheetReportType,
      projectIdWithDescendants: !isNil(projectId) || !isNil(projectGroupId),
      memberPermissions: [memberPermission],
      costCodeGroupId,
      projectGroupId,
      timeRange,
    }),
    [
      archivedStatus,
      memberId,
      memberGroupId,
      projectId,
      costCodeId,
      equipmentId,
      positionId,
      timesheetReportType,
      memberPermission,
      costCodeGroupId,
      projectGroupId,
      timeRange,
    ]
  );
}

export default TimeEntryReport;
