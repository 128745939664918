import { Nullable } from 'types/util/Nullable';
import { v4 } from 'uuid';

export function uuid() {
  return v4();
}

export function validateUuid(test: string) {
  const regexMatchUuidV4 = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
  return regexMatchUuidV4.test(test);
}

export function uuidIfValid(uuid: string): Nullable<string> {
  return validateUuid(uuid) ? uuid : null;
}
