import { IFormRender, Label } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { CostCodePickerFormField, ProjectPickerFormField } from 'components';
import EquipmentClockFormPickerFormField from 'components/domain/equipment/EquipmentClockFormPickerFormField/EquipmentClockFormPickerFormField';
import { useOrganization } from 'hooks';
import useProjectCostCodeLinkCheck from 'hooks/models/project-cost-code-link/useProjectCostCodeLinkCheck';
import useTimeEntryCostCodeValidation from 'hooks/useTimeEntryCostCodeValidation';
import useTimeEntryEquipmentValidation from 'hooks/useTimeEntryEquipmentValidation';
import useTimeEntryProjectValidation from 'hooks/useTimeEntryProjectValidation';
import { ClassName } from 'types/ClassName';
import { t } from 'utils/localize';

interface IFormData {
  project: string | undefined | null;
  costCode: string | undefined | null;
  equipment: string | undefined | null;
}

export interface ITimeActionFormDetailsSectionProps<T extends IFormData> {
  form: IFormRender<T>;
  onFormChange: (data: T) => void;
  className?: ClassName;
  showGpsError?: boolean;
  considerEquipmentRequirements?: boolean;
}

function TimeActionFormDetailsSection<T extends IFormData>({
  form,
  onFormChange,
  className,
  showGpsError,
  considerEquipmentRequirements,
}: ITimeActionFormDetailsSectionProps<T>) {
  const organization = useOrganization();
  const projectValidations = useTimeEntryProjectValidation();
  const costCodeValidations = useTimeEntryCostCodeValidation();
  const equipmentValidations = useTimeEntryEquipmentValidation();

  const projectCostCodeLinkCheck = useProjectCostCodeLinkCheck();

  async function onProjectSelect(value: string | null) {
    if (organization.useProjectCostCodeScoping) {
      const currentCostCode = form.state.data.costCode;

      if (currentCostCode && value) {
        const { exists } = await projectCostCodeLinkCheck(value, currentCostCode);

        if (!exists) {
          form.setData('costCode', null as any);
          onFormChange({
            ...form.state.data,
            project: value,
            costCode: null,
          });
          return;
        }
      }
    }

    onFormChange({
      ...form.state.data,
      project: value,
    });
  }

  return (
    <div className={classNames('time-action-form-details-section', className)}>
      <Label>{t('Details')}</Label>
      <ProjectPickerFormField
        name="project"
        onChange={onProjectSelect}
        form={form}
        placeholder={organization.requireProject ? t('Project (Required)') : t('Project')}
        validations={projectValidations}
        showGpsError={showGpsError}
      />
      <CostCodePickerFormField
        name="costCode"
        projectId={form.state.data.project}
        placeholder={organization.requireCostCode ? t('Cost Code (Required)') : t('Cost Code')}
        form={form}
        validations={costCodeValidations}
        onSelect={(value) => onFormChange({ ...form.state.data, costCode: value })}
      />
      <EquipmentClockFormPickerFormField
        name="equipment"
        form={form}
        validations={equipmentValidations}
        onSelect={(value) => onFormChange({ ...form.state.data, equipment: value })}
        considerEquipmentRequirements={!!considerEquipmentRequirements}
      />
    </div>
  );
}

export default TimeActionFormDetailsSection;
