import classNames from 'classnames';
import { IUseTimeRangePayload } from 'hooks/utils/useTimeRange';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';
import ITimeRange from 'types/TimeRange';
import TimeRangeDialog, { ITimeRangeDialogProps } from '../TimeRangeDialog/TimeRangeDialog';

export interface ITimeRangeReportDialogProps extends IUseTimeRangePayload, Omit<ITimeRangeDialogProps, 'children'> {
  reportComponent: (timeRange: ITimeRange<DateTime>) => ReactNode;
  renderActionBar?: (timeRange: ITimeRange<DateTime>) => ReactNode;
  renderFilterBar?: (timeRange: ITimeRange<DateTime>) => ReactNode;
  headerClassName?: string;
}

export default function TimeRangeReportDialog({
  timeRange,
  className,
  reportComponent,
  renderActionBar,
  renderFilterBar,
  ...timeRangeDialogProps
}: ITimeRangeReportDialogProps) {
  const classes = classNames('time-range-report-dialog', className);

  return (
    <TimeRangeDialog
      timeRange={timeRange}
      className={classes}
      actionBar={renderActionBar?.(timeRange)}
      filterBar={renderFilterBar?.(timeRange)}
      {...timeRangeDialogProps}
    >
      {reportComponent(timeRange)}
    </TimeRangeDialog>
  );
}
