import { QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { Bar, Position, Size } from '@busybusy/webapp-react-ui';
import { type ProjectGroupFilter } from '__generated__/graphql';
import classNames from 'classnames';
import { t } from 'i18next';
import _, { uniqBy } from 'lodash';
import { Component } from 'react';
import { ClassName } from 'types/ClassName';
import { IProjectGroup } from '../../../../types/ProjectGroup';
import LazyLoadPicker from '../../../foundation/pickers/LazyLoadPicker/LazyLoadPicker';
import { projectGroupQuery, projectGroupsQuery } from './project-group-picker-queries';

export interface IProjectGroupPickerProps {
  value: string | null;
  onSelect: (projectGroupId: string | null) => void;
  minWidth?: string; // has default
  className?: ClassName;
  placeholder?: string;
  error?: boolean;
  position?: Position;
  client?: any;
  fetchPolicy?: string;
  searchArchived?: boolean;
  autofocus?: boolean;
  excludeIds?: string[];
  includeNoneOption?: boolean;
}

interface IState {
  projectGroups: IProjectGroup[];
  loadedAll: boolean;
}

class ProjectGroupPicker extends Component<WithApolloClient<IProjectGroupPickerProps>, IState> {
  public static defaultProps: any;
  public sectionSize = 10;
  public state: IState = {
    loadedAll: false,
    projectGroups: [],
  };

  // Return the filter for the server call
  public getProjectGroupFilter(): ProjectGroupFilter {
    const { excludeIds } = this.props;

    const filter: ProjectGroupFilter = {
      deletedOn: { isNull: true },
    };

    if (excludeIds && excludeIds.length > 0) {
      filter.id = { doesNotContain: excludeIds };
    }

    return filter;
  }

  // Fetch a page of project groups
  public fetchProjectGroups = (after?: string): Promise<IProjectGroup[]> => {
    return new Promise((resolve, reject) => {
      this.props.client
        .query({
          fetchPolicy: this.props.fetchPolicy,
          query: projectGroupsQuery,
          variables: {
            after,
            filter: this.getProjectGroupFilter(),
            first: this.sectionSize,
          },
        })
        .then((result: { data: { projectGroups: IProjectGroup[] } }) => {
          resolve(result.data.projectGroups);
        })
        .catch(() => {
          reject();
        });
    });
  };

  // Handle new results from the server
  public handleDidLoad = (projectGroups: IProjectGroup[], error: boolean, loadedAll: boolean) => {
    const { includeNoneOption } = this.props;
    let updatedProjectGroups = projectGroups;

    if (includeNoneOption) {
      const noneOption = { id: 'none', groupName: 'No Group' } as IProjectGroup; // Explicitly cast to IProjectGroup
      updatedProjectGroups = uniqBy([...projectGroups, noneOption], 'id');
    }

    if (!error) {
      this.setState({
        loadedAll,
        projectGroups: updatedProjectGroups,
      });
    }
  };

  // Render the picker's value
  public renderValueTemplate = () => {
    const value = this.props.value;

    if (value === 'none') {
      return <>{t('No Group')}</>;
    }

    if (value) {
      return (
        <Query query={projectGroupQuery} variables={{ groupId: value }}>
          {({ data }: QueryResult) => {
            return <>{_.get(data, 'projectGroups[0][groupName]')}</>;
          }}
        </Query>
      );
    }
    return <></>;
  };

  // Render a row within the picker
  public renderRow = (projectGroup: IProjectGroup) => (
    <Bar size={Size.SMALL} className="px-3">
      <div className="ellipsis">{projectGroup.groupName}</div>
    </Bar>
  );

  // Handle when a value is selected from the list
  public handleSelect = (project: IProjectGroup | null) => {
    this.props.onSelect(project ? project.id : null);
  };

  // Handle the picker closing
  public handleClose = () => {
    this.setState({
      loadedAll: false,
      projectGroups: [],
    });
  };

  // Render
  public render() {
    const { className, placeholder, minWidth, position, value, autofocus } = this.props;

    const classes = classNames(
      {
        'project-picker': true,
      },
      className
    );

    return (
      <LazyLoadPicker
        value={value}
        getData={this.fetchProjectGroups}
        didLoad={this.handleDidLoad}
        data={this.state.projectGroups}
        loadedAll={this.state.loadedAll}
        onSelect={this.handleSelect}
        onClose={this.handleClose}
        valueTemplate={this.renderValueTemplate()}
        renderRow={this.renderRow}
        minWidth={minWidth}
        position={position}
        placeholder={placeholder}
        autofocus={autofocus}
        className={classes}
      />
    );
  }
}

ProjectGroupPicker.defaultProps = {
  fetchPolicy: 'cache-first',
  minWidth: '350px',
  includeNoneOption: false,
};

export default withApollo<IProjectGroupPickerProps>(ProjectGroupPicker);
