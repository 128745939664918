import { useApolloClient } from '@apollo/client';
import { Bar, Justify, Label } from '@busybusy/webapp-react-ui';
import { MEMBERS_QUERY } from 'apollo/queries/member-queries';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { LazyLoader, PanelContent } from 'components';
import DailySignOffReportTable from 'components/domain/daily-sign-off/DailySignOff/DailySignOffReportTable/DailySignOffReportTable';
import MemberTimeEntryDialog from 'components/domain/time-entry/dialog/MemberTimeEntryDialog/MemberTimeEntryDialog';
import DailySignOffReportDetailsDialog from 'containers/daily-sign-off/DailySignOffDetailsDialog/DailySignOffReportDetailsDialog';
import { useOpenable, useQueryParams } from 'hooks';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { IMember } from 'types';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { QueryParam } from 'utils/constants/queryParam';
import { fullName } from 'utils/memberUtils';
import { TimesheetReportType } from '../Timesheets';
import './SignOffReport.scss';

export interface IDailySignOffReportProps {
  employeeIds?: string[] | null;
  timeRange: ITimeRange<DateTime>;
  onDataChange: (members: string[]) => void;
}

const SignOffReport = (props: IDailySignOffReportProps) => {
  const { employeeIds, timeRange, onDataChange } = props;

  const client = useApolloClient();
  const [members, setMembers] = useState<IMember[]>([]);
  const [loadedAll, setLoadedAll] = useState<boolean>(false);
  const [scroller, setScroller] = useState<HTMLElement | null>(null);
  const { getParam } = useQueryParams();
  const timeRangeType = getParam<TimeRangeType>(QueryParam.TIME_RANGE_TYPE) ?? TimeRangeType.PAY_PERIOD;
  const memberGroupId = getParam(QueryParam.EMPLOYEE_GROUP_ID);
  const memberId = getParam(QueryParam.EMPLOYEE_ID);
  const detailsDialog = useOpenable();
  const openEntries = useOpenable();
  const selectedDate = useRef<DateTime>();
  const selectedMember = useRef<IMember>();
  const today = useRef<DateTime>(DateTime.local().startOf('day'));
  const dialogMemberIdList = useRef<string[]>([]);
  const tableRefreshAction = useRef<(() => void) | undefined>(undefined);

  useEffect(() => {
    setLoadedAll(false);
    setMembers([]);
  }, [timeRange, memberId, memberGroupId]);

  const getMoreMembers = (cursor?: string): Promise<IMember[]> => {
    const variables: any = {
      first: 5,
      filter: {
        permissions: { permissions: 'timeEvents', operationType: 'and', includeSelf: true },
        memberGroupId: memberGroupId !== null ? { equal: memberGroupId } : undefined,
        id: memberId !== null ? { equal: memberId } : undefined,
      },
      sort: [{ firstName: 'asc' }, { lastName: 'asc' }],
    };

    if (employeeIds) {
      variables.filter.id = { contains: employeeIds };
    }

    if (cursor) {
      variables.after = cursor;
    }

    return new Promise((resolve, reject) => {
      client
        .query<{ members: IMember[] }>({
          query: MEMBERS_QUERY,
          variables,
        })
        .then((result: any) => {
          resolve(result.data.members);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const renderRow = (member: IMember) => {
    return (
      <div key={member.id} className="mt-8" data-testid={member.id}>
        <Bar justify={Justify.FLEX_START} className="p-4 employee-name-bar">
          <Label>{fullName(member)}</Label>
        </Bar>
        <DailySignOffReportTable
          member={member}
          timeRange={timeRange}
          scroller={scroller!}
          onOpenDetails={onOpenDetails}
          onOpenEntries={onOpenEntries}
        />
      </div>
    );
  };

  const didLoad = (updatedMembers: IMember[], err: boolean, updatedLoadedAll: boolean) => {
    setLoadedAll(updatedLoadedAll);
    setMembers(updatedMembers);
  };

  const onOpenEntries = (member: string, date: DateTime) => {
    selectedDate.current = date;
    dialogMemberIdList.current = [member];

    openEntries.open();
  };

  const onOpenDetails = (member: IMember, date: DateTime, refreshAction: () => void) => {
    selectedMember.current = member;
    selectedDate.current = date;
    dialogMemberIdList.current = [member.id];
    tableRefreshAction.current = refreshAction;

    detailsDialog.open();
  };

  const setLoaderRef = (el: HTMLElement) => {
    setScroller(el);
  };

  const classes = classNames('sign-off-report', { 'is-loading': !loadedAll });

  const shouldRefresh = () => {
    tableRefreshAction.current?.();
    onDataChange(dialogMemberIdList.current);
  };

  return (
    <PanelContent className={classes}>
      <LazyLoader
        data={members}
        sectionSize={5}
        offset={200}
        renderRow={renderRow}
        getData={getMoreMembers}
        loadedAll={loadedAll}
        didLoad={didLoad}
        forwardRef={setLoaderRef}
        showLoaderWhenLoading={true}
      />
      <DailySignOffReportDetailsDialog
        isOpen={detailsDialog.isOpen}
        onClose={detailsDialog.close}
        member={selectedMember.current!}
        currentDate={selectedDate.current ? selectedDate.current : today.current}
        onShouldRefresh={shouldRefresh}
      />
      {openEntries.isOpen && (
        <MemberTimeEntryDialog
          isOpen={openEntries.isOpen}
          onClose={openEntries.close}
          timeRange={
            selectedDate.current
              ? { startTime: selectedDate.current.startOf('day'), endTime: selectedDate.current.endOf('day') }
              : timeRange
          }
          reportType={TimesheetReportType.ENTRY}
          memberIds={dialogMemberIdList.current}
          timeRangeType={selectedDate.current ? TimeRangeType.DAILY : timeRangeType}
          onDataChange={onDataChange}
        />
      )}
    </PanelContent>
  );
};

export default SignOffReport;
