import { isNil } from 'lodash';
import { useEffect } from 'react';
import { Optional } from 'types/util/Optional';
import { isType } from 'utils/typeguard';
import { isScrollBreakpointReached } from 'utils/uiUtils';

export function getScrollerElement(scroller?: HTMLElement | Document | null) {
  if (isNil(scroller)) {
    return;
  }

  if (isType<HTMLElement>(scroller, 'scrollTop')) {
    return scroller;
  } else if (isType<Document>(scroller, 'body')) {
    return scroller.body;
  }
}

export default function useScrollBreakpoint(
  // Optional for first render
  scroller: Optional<HTMLElement | Document>,
  onBreakpointReached: () => void,
  scrollOffset: number = 200
) {
  useEffect(() => {
    function isBreakpointReached() {
      const element = getScrollerElement(scroller);

      if (!element) {
        return false;
      }

      return isScrollBreakpointReached(element, scrollOffset);
    }

    function onScroll() {
      if (isBreakpointReached()) {
        onBreakpointReached();
      }
    }

    if (scroller) {
      scroller.addEventListener('scroll', onScroll);
    }
    return () => {
      if (scroller) {
        scroller.removeEventListener('scroll', onScroll);
      }
    };
  }, [onBreakpointReached, scrollOffset, scroller]);
}
