import { Field, FieldFooter, IFormValidation, Position } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { EmployeePicker } from 'components';
import { IEmployeePickerContainerProps } from 'components/domain/member/EmployeePicker/EmployeePicker';
import { FunctionComponent } from 'react';

interface IEmployeePickerFormFieldProps extends Omit<IEmployeePickerContainerProps, 'value' | 'onSelect'> {
  form: any;
  name: string;
  validations?: Array<IFormValidation<string, any>>;
  position?: Position;
  onChange?: (value: string | null) => void;
}

const EmployeePickerFormField: FunctionComponent<IEmployeePickerFormFieldProps> = (props) => {
  const { form, name, validations, onChange, className, ...pickerProps } = props;

  function renderField({ ...fieldProps }) {
    function handleOnChange(value: string | null) {
      fieldProps.handleChange(value, onChange);
    }

    return (
      <>
        <EmployeePicker
          error={fieldProps.hasError}
          value={form.state.data[name]}
          onSelect={handleOnChange}
          {...pickerProps}
        />
        <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
      </>
    );
  }

  const classes = classNames(
    {
      'employee-picker-form-field': true,
    },
    className
  );

  return <Field className={classes} form={form} name={name} validations={validations} render={renderField} />;
};

export default EmployeePickerFormField;
