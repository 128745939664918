import { Button, ClassName, Label, Row, Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import ConditionalContainer from 'components/foundation/ConditionalContainer/ConditionalContainer';
import useSlackSafetyReportsAccess from 'containers/settings/integrations/vendors/slack/hooks/useSlackSafetyReportsAccess';
import { useToastDispatch } from 'contexts/ToastContext';
import useOrganizationUiSettingsUpdate from 'hooks/models/organization/settings/hooks/useOrganizationUiSettingsUpdate';
import { t } from 'i18next';
import { FunctionComponent, useState } from 'react';
import './SafetyReportsEnableForm.scss';

export interface ISafetyReportsEnableFormProps {
  onRequestSubmitted: () => void;
  className?: ClassName;
}

export const SafetyReportsEnableForm: FunctionComponent<ISafetyReportsEnableFormProps> = (props) => {
  const { className, onRequestSubmitted } = props;
  const toastDispatch = useToastDispatch();
  const [showErrorTip, setShowErrorTip] = useState(false);
  const safetyReportsAccess = useSlackSafetyReportsAccess();
  const updateUiSettings = useOrganizationUiSettingsUpdate();

  const classes = classNames('safety-reports-enable-form', className);

  const onLinkClicked = () => {
    window.open('https://fast.wistia.com/embed/channel/kd6foal20s');
  };

  const requestAccess = async () => {
    let requestSuccessful = false;

    try {
      const response = await safetyReportsAccess();
      requestSuccessful = response.status === 200;
    } catch (e) {
      requestSuccessful = false;
    }

    if (requestSuccessful) {
      updateUiSettings('requestedSafetyReportsAccess', true);
      onRequestSubmitted();
    } else {
      toastDispatch({
        type: 'OPEN',
        payload: { label: t('Failed to send request. Try again later.'), theme: Theme.DANGER },
      });
      setShowErrorTip(true);
    }
  };

  return (
    <div className={classes}>
      <Label className="mt-3 mb-2">{t('-Now Includes-')}</Label>
      <ul className="pl-4">
        <li>{t('Safety Trainings (Spanish Available)')}</li>
        <li>{t('Inspections')}</li>
        <li>{t('Observations')}</li>
        <li>{t('JSA Reports')}</li>
        <li>{t('Incident Reporting')}</li>
        <li>{t('Attach Tool Box Talks to Field Reports')}</li>
      </ul>

      <Label className="mt-7 mb-2">{t('How to Get Started')}</Label>
      <div className="content">
        {t(
          `Click the button below and a friendly busybusy team member will reach out to get your free trial turned on and answer any questions you have. \n\n`
        )}
        {t(`Want to learn more?`)}
        <a className="link ml-1" onClick={() => onLinkClicked()}>
          {t('Click here')}
        </a>
        {t(' to explore all our Safety Feature Videos.')}
      </div>

      <Row className="mt-9">
        <Button type="primary" onClick={requestAccess}>
          {t('Contact')}
        </Button>
      </Row>

      <ConditionalContainer condition={showErrorTip}>
        <br />
        {t('* If you have an ad blocker, disable it and try again.')}
      </ConditionalContainer>
    </div>
  );
};

export default SafetyReportsEnableForm;
