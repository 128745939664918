import { useApolloClient } from '@apollo/client';
import { SortDirection } from '@busybusy/webapp-react-ui';
import {
  ConditionFieldType,
  ConditionNullableFieldType,
  ConditionOperationNullType,
  ConditionOperationType,
  CostCode,
  Equipment,
  LaborMetricsInterface,
  LaborMetricsInterval,
  Maybe,
  Scalars,
} from '__generated__/graphql';
import { COST_CODE_NAME_QUERY } from 'apollo/queries/cost-code-queries';
import {
  COST_CODES_WITH_EQUIPMENT_METRICS_QUERY,
  COST_CODES_WITH_EQUIPMENT_TIME_ONLY_METRICS_QUERY,
  COST_CODES_WITH_MEMBER_EQUIPMENT_METRICS_QUERY,
  COST_CODES_WITH_MEMBER_EQUIPMENT_TIME_ONLY_METRICS_QUERY,
  COST_CODES_WITH_PROJECT_AND_EQUIPMENT_METRICS_QUERY,
  COST_CODES_WITH_PROJECT_EQUIPMENT_AND_PROJECT_METRICS_QUERY,
} from 'containers/activity-reports/queries/cost-code-activity-queries';
import {
  SIMPLE_EQUIPMENT_WITH_COSTCODE_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_COSTCODE_TIME_ONLY_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_MEMBER_COSTCODE_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_MEMBER_COSTCODE_TIME_ONLY_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_PROJECT_COSTCODE_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_PROJECT_COSTCODE_TIME_ONLY_METRIC_QUERY,
} from 'containers/activity-reports/queries/equipment-activity-queries';
import { activityReportUtils } from 'containers/activity-reports/utils/ActivityReportUtils';
import { useApolloPaging, useTableSorting } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import { Dictionary, first, isEmpty, isNil, keyBy, sumBy, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import { mapNotNil, mapNotNull, sortByIgnoreCase } from 'utils/collectionUtils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { getCostHistoryForRange } from 'utils/equipmentUtils';
import { combineMetricFilters, metricConditionFilter, metricNullFilter } from 'utils/jitMetricUtils';
import { getGeneratedMetricLaborTotals, getGeneratedProjectEquipmentMetricLaborTotals } from 'utils/metricUtils';
import { getCostCodeDisplay, getEquipmentDisplay } from 'utils/stringUtils';
import { ActivityReportType } from '../../ActivityReportFilter/ActivityReportFilter';
import {
  activityIdFilter,
  aggregateActivityRows,
  calculateRemainingActivityData,
  filterByProjectIdsOrEmptyItemId,
  getActivityItemTotal,
  graphQLContainsIdOrEmptyItemId,
} from '../../hooks/ActivitySummaryQueryUtils';
import { IEquipmentActivityTableRowInfo } from './useEquipmentActivity';

export interface IEquipmentCostCodeActivityTableRowInfo extends IEquipmentActivityTableRowInfo {
  costCodeId: string | null;
  costCode: CostCode | null;
}

interface EquipmentCostCodeMetricRow extends LaborMetricsInterface {
  equipmentId?: Maybe<Scalars['Uuid']['output']>;
  costCodeId?: Scalars['Uuid']['output'] | Maybe<Scalars['Uuid']['output']>;
}

export default function useEquipmentActivityCostCodeDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>,
  filterIdWithDescendants?: boolean
) {
  const { getAll } = useApolloPaging();
  const client = useApolloClient();
  const canViewCost = useHasCostPermission();
  const [data, setData] = useState<IEquipmentCostCodeActivityTableRowInfo[]>([]);
  const remainingItemRef = useRef<IEquipmentCostCodeActivityTableRowInfo>();
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'equipment',
    SortDirection.ASCENDING,
    getSortField
  );

  function getIdFilter() {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return activityIdFilter(filterId, ConditionNullableFieldType.MemberId, 'memberIds');
      case ActivityReportType.BY_PROJECT:
        return activityIdFilter(filterId, ConditionNullableFieldType.ProjectId, 'projectIds');
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function hasTimeIdFilter() {
    const idArray = filterId ? [filterId] : undefined;
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return {
          memberId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_PROJECT:
        return filterByProjectIdsOrEmptyItemId(idArray, filterIdWithDescendants);
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getQuery() {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return canViewCost
          ? SIMPLE_EQUIPMENT_WITH_MEMBER_COSTCODE_METRIC_QUERY
          : SIMPLE_EQUIPMENT_WITH_MEMBER_COSTCODE_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_PROJECT:
        return canViewCost
          ? SIMPLE_EQUIPMENT_WITH_PROJECT_COSTCODE_METRIC_QUERY
          : SIMPLE_EQUIPMENT_WITH_PROJECT_COSTCODE_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return canViewCost
          ? SIMPLE_EQUIPMENT_WITH_COSTCODE_METRIC_QUERY
          : SIMPLE_EQUIPMENT_WITH_COSTCODE_TIME_ONLY_METRIC_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getCostCodeQuery() {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return canViewCost
          ? COST_CODES_WITH_MEMBER_EQUIPMENT_METRICS_QUERY
          : COST_CODES_WITH_MEMBER_EQUIPMENT_TIME_ONLY_METRICS_QUERY;
      case ActivityReportType.BY_PROJECT:
        return canViewCost
          ? COST_CODES_WITH_PROJECT_AND_EQUIPMENT_METRICS_QUERY
          : COST_CODES_WITH_PROJECT_EQUIPMENT_AND_PROJECT_METRICS_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return canViewCost
          ? COST_CODES_WITH_EQUIPMENT_METRICS_QUERY
          : COST_CODES_WITH_EQUIPMENT_TIME_ONLY_METRICS_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getSortField(
    item: IEquipmentCostCodeActivityTableRowInfo,
    key: keyof IEquipmentCostCodeActivityTableRowInfo
  ) {
    if (key === 'equipment') {
      return getEquipmentDisplay(item.equipment);
    } else {
      return item[key];
    }
  }

  async function getCostCodes(costCodeIds: string[]) {
    if (isEmpty(costCodeIds)) {
      return [];
    }

    return await getAll<CostCode & ICursorable>('costCodes', {
      query: COST_CODE_NAME_QUERY,
      variables: {
        first: 100,
        filter: {
          id: { contains: costCodeIds },
        },
      },
      fetchPolicy: 'network-only',
    });
  }

  async function loadData() {
    const equipment = await getAll<Equipment & ICursorable>('equipment', {
      query: getQuery(),
      variables: {
        first: 100,
        filter: {
          equipmentWithTime: {
            startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
            ...hasTimeIdFilter(),
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        ...getIdFilter(),
      },
      fetchPolicy: 'network-only',
    });

    const costCodeLookup = await getCostCodeLookup(equipment);
    const tableRows = mapNotNull(equipment, (equipment) => createTableRowInfo(costCodeLookup, equipment));

    const remainingData = await createNoEquipmentRow(tableRows);

    if (!isNil(remainingData)) {
      remainingItemRef.current = remainingData;
    } else {
      remainingItemRef.current = undefined;
    }

    setData(tableRows);
  }

  const getCostCodeLookup = async (data: Equipment[]) => {
    const metrics = data.flatMap((e) => laborMetrics(e));
    const codeCodeIds = mapNotNil(metrics, (item) => item.costCodeId);
    const costCodes = await getCostCodes(uniq(codeCodeIds));

    return keyBy(costCodes, (costCode) => costCode.id);
  };

  const createTableRowInfo = (
    costCodeLookup: Dictionary<CostCode>,
    equipment: Equipment
  ): IEquipmentCostCodeActivityTableRowInfo | null => {
    const metrics = laborMetrics(equipment);

    let noCostCodeRow: IEquipmentCostCodeActivityTableRowInfo | undefined = undefined;
    if (metrics.some((metric) => isNil(metric.costCodeId))) {
      const noEquipment = first(metrics.filter((metric) => isNil(metric.costCodeId))) ?? emptyMetricRow(equipment.id);
      const metricTotal = getGeneratedMetricLaborTotals(noEquipment, filterIdWithDescendants === false);

      noCostCodeRow = {
        id: equipment.id + remainingDataItemId,
        costCodeId: remainingDataItemId,
        costCode: null,
        equipment,
        ...activityReportUtils.metricToRowInfo(metricTotal),
        equipmentCost: metricTotal.equipmentCost,
        equipmentRate: getCostHistoryForRange(equipment.costHistory ?? [], timeRange)?.operatorCostRate ?? 0,
        equipmentTotalCost: metricTotal.equipmentCost + metricTotal.totalCost,
      };
    }

    const unsortedCostCodeRows = mapNotNull(metrics, (metric) => {
      const metricTotal = getGeneratedProjectEquipmentMetricLaborTotals(metric);
      if ((metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) || isNil(metric.costCodeId)) {
        return null;
      }

      return {
        id: equipment.id + metric.costCodeId,
        costCodeId: metric.costCodeId,
        costCode: costCodeLookup[metric.costCodeId] ?? null,
        equipment,
        ...activityReportUtils.metricToRowInfo(metricTotal),
        equipmentCost: metricTotal.equipmentCost,
        equipmentRate: getCostHistoryForRange(equipment.costHistory ?? [], timeRange)?.operatorCostRate ?? 0,
        equipmentTotalCost: metricTotal.equipmentCost + metricTotal.totalCost,
      };
    });

    const costCodeRows = sortByIgnoreCase(unsortedCostCodeRows, (row) => getCostCodeDisplay(row.costCode));

    if (isEmpty(costCodeRows) && noCostCodeRow === undefined) {
      return null;
    } else {
      const equipmentTotalMetrics = aggregateActivityRows(
        noCostCodeRow === undefined ? costCodeRows : [...costCodeRows, noCostCodeRow]
      );

      const detailRows = noCostCodeRow === undefined ? costCodeRows : [...costCodeRows, noCostCodeRow];

      return {
        ...equipmentTotalMetrics,
        id: equipment.id,
        costCodeId: null,
        costCode: null,
        equipment,
        detailRows,
        equipmentCost: sumBy(detailRows, (row) => row.equipmentCost),
        equipmentRate: getCostHistoryForRange(equipment.costHistory ?? [], timeRange)?.operatorCostRate ?? 0,
        equipmentTotalCost: sumBy(detailRows, (row) => row.equipmentCost) + equipmentTotalMetrics.totalCost,
      };
    }
  };

  const createNoEquipmentRow = async (
    tableRows: IEquipmentCostCodeActivityTableRowInfo[]
  ): Promise<IEquipmentCostCodeActivityTableRowInfo | null> => {
    const totalData = await getActivityItemTotal(client, filterType, filterId, timeRange, canViewCost, filterIdWithDescendants);

    const remainingData = calculateRemainingActivityData(tableRows, totalData);

    if (remainingData.totalHours <= 0) {
      return null; // there is not a No Cost Code row
    }

    let metricFilter = undefined;
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        metricFilter = combineMetricFilters([
          metricNullFilter(ConditionNullableFieldType.EquipmentId, ConditionOperationNullType.IsNull),
          metricConditionFilter(ConditionFieldType.MemberId, ConditionOperationType.Equal, filterId),
        ]);
        break;
      case ActivityReportType.BY_PROJECT:
        if (filterId === remainingDataItemId) {
          metricFilter = combineMetricFilters([
            metricNullFilter(ConditionNullableFieldType.EquipmentId, ConditionOperationNullType.IsNull),
            metricNullFilter(ConditionNullableFieldType.ProjectId, ConditionOperationNullType.IsNull),
          ]);
        } else {
          metricFilter = combineMetricFilters([
            metricNullFilter(ConditionNullableFieldType.EquipmentId, ConditionOperationNullType.IsNull),
            metricConditionFilter(ConditionFieldType.ProjectId, ConditionOperationType.Equal, filterId),
          ]);
        }
        break;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        metricFilter = metricNullFilter(ConditionNullableFieldType.EquipmentId, ConditionOperationNullType.IsNull);
        break;
    }
    const costCodes = await getAll<CostCode & ICursorable>('costCodes', {
      query: getCostCodeQuery(),
      variables: {
        first: 100,
        filter: {
          costCodesWithTime: {
            startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
            ...hasTimeIdFilter(),
            equipmentId: { isNull: true },
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        metricFilter: metricFilter,
      },
      fetchPolicy: 'network-only',
    });

    const unsortedCostCodeRows: IEquipmentCostCodeActivityTableRowInfo[] = costCodes.flatMap((costCode) => {
      const metrics = costCodeLaborMetrics(costCode);

      return mapNotNull(metrics, (metric) => {
        const metricTotal = getGeneratedMetricLaborTotals(metric, filterIdWithDescendants === false);
        if (metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) {
          return null;
        }

        return {
          id: remainingDataItemId + costCode.id,
          costCodeId: costCode.id,
          costCode: costCode,
          equipment: null,
          ...activityReportUtils.metricToRowInfo(metricTotal),
          equipmentCost: 0,
          equipmentRate: 0,
          equipmentTotalCost: metricTotal.totalCost,
        };
      });
    });

    const costCodeRows = sortByIgnoreCase(unsortedCostCodeRows, (row) => getCostCodeDisplay(row.costCode));

    const remainingCostCodeData = calculateRemainingActivityData(
      costCodeRows,
      activityReportUtils.rowInfoToMetric(remainingData)
    );

    if (remainingCostCodeData.totalHours > 0) {
      // add the no cost code item under no equipment details
      costCodeRows.push({
        ...remainingCostCodeData,
        id: remainingDataItemId + remainingDataItemId,
        costCode: null,
        equipment: null,
        costCodeId: remainingDataItemId,
        equipmentCost: 0,
        equipmentRate: 0,
        equipmentTotalCost: remainingCostCodeData.totalCost,
      });
    }

    return {
      costCodeId: null,
      costCode: null,
      equipment: null,
      ...remainingData,
      id: remainingDataItemId,
      detailRows: costCodeRows,
      equipmentCost: 0,
      equipmentRate: 0,
      equipmentTotalCost: sumBy(costCodeRows, (row) => row.equipmentCost) + remainingData.totalCost,
    };
  };

  const emptyMetricRow = (equipmentId: string): EquipmentCostCodeMetricRow => {
    return {
      equipmentId,
      start: '',
      end: '',
    };
  };

  const laborMetrics = (equipment: Equipment): EquipmentCostCodeMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return equipment.equipmentMemberCostCodeLaborMetrics;
      case ActivityReportType.BY_PROJECT:
        return equipment.equipmentProjectCostCodeLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return equipment.equipmentCostCodeLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  const costCodeLaborMetrics = (costCode: CostCode): EquipmentCostCodeMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return costCode.costCodeMemberEquipmentLaborMetrics;
      case ActivityReportType.BY_PROJECT:
        return costCode.costCodeProjectEquipmentLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return costCode.costCodeEquipmentLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  return {
    loadData,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
    remainingData: remainingItemRef.current,
  };
}
