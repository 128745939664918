import { useQueryParams } from 'hooks';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { QueryParam } from 'utils/constants/queryParam';
import useTimeRange from './useTimeRange';

function useTimeRangeQueryParams(
  timeRangeType: TimeRangeType,
  range?: ITimeRange<DateTime> | null,
  minimumTime?: DateTime | null,
  maximumTime?: DateTime | null,
  zone: 'system' | 'utc' = 'system'
) {
  const { timeRange, ...rest } = useTimeRange(timeRangeType, range, minimumTime, maximumTime, zone);
  const { updateParams } = useQueryParams();

  const startIso = timeRange.startTime.toISODate();
  const endIso = timeRange.endTime.toISODate();
  const year = timeRange.startTime.year.toString();

  useEffect(() => {
    const params =
      timeRangeType === TimeRangeType.YEARLY
        ? {
            [QueryParam.YEAR]: year,
          }
        : {
            [QueryParam.START_DATE]: startIso,
            [QueryParam.END_DATE]: endIso,
          };
    updateParams(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startIso, endIso, year]);

  return { timeRange, ...rest };
}

export default useTimeRangeQueryParams;
