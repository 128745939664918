import { useApolloClient } from '@apollo/client';
import { ISortPayload, SortDirection } from '@busybusy/webapp-react-ui';
import {
  LaborMetricsInterval,
  MemberHasTimeOff,
  MemberPermissions,
  MemberSort,
  OperationType,
  QueryMembersForTimeMetericsWithMoreQuery,
  QueryMembersForTimeMetericsWithMoreQueryVariables,
  SafetySignature,
  SortOrder,
} from '__generated__/graphql';
import { MEMBERS_WITH_ALL_JOINED_DATA_QUERY } from 'apollo/queries/member-queries';
import { hasTimeOffColumn } from 'containers/timesheets/hooks/useTimesheetsColumns';
import { useOrganization } from 'hooks';
import useApolloMemberNameSort from 'hooks/models/member/useApolloMemberNameSort';
import _, { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'store/reducers';
import { updateCheckedMemberIds } from 'store/timesheets/Timesheets';
import { IVisibleTableColumn } from 'types/TableColumn';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { dateTimeFromISOWithoutZone } from 'utils/dateUtils';
import { getRegularMetricTimeTotals } from 'utils/jitMetricUtils';
import { IMemberSignInSubmission } from 'types/MemberSignInSubmission';
import { SIMPLE_SUBMISSIONS_REPORT_QUERY } from 'apollo/queries/member-sign-in-submission';
import useReactQueryBaseKey from 'hooks/react-query/useReactQueryBaseKey/useReactQueryBaseKey';
import { useReactQueryLazyLoading } from 'hooks/react-query/useReactQueryLazyLoading/useReactQueryLazyLoading';
import useGraphQLClient from 'hooks/graphql/useGraphQLClient/useGraphQLClient';
import IJitLaborMetric from 'types/aggregate/JitLaborMetric';
import { ArchivedStatus } from 'components/domain/archived/ArchivedPicker/ArchivedPicker';
import { getApolloArchivedTimestampComparison } from 'utils/archivedUtils';
import { Nullable } from 'types/util/Nullable';
import { TimesheetSummaryMember } from '../../types/types';

export interface IEmployeeSummaryRowInfo {
  id: string;
  member: TimesheetSummaryMember;
  payPeriodEmployeeSigned: boolean | null;
  payPeriodSupervisorSigned: boolean | null;
  timeAccurate: boolean | null;
  injured: boolean | null;
  breakPolicyFollowed: boolean | null;
  regularSeconds: number | null;
  overtimeSeconds: number | null;
  doubletimeSeconds: number | null;
  ptoSeconds: number | null;
  breakSeconds: number | null;
  totalSeconds: number | null;
  flaggedSignInAmount: number | null;
  flaggedSignOffAmount: number | null;
  cursor: string;
}

interface IEmployeeSummaryDataContextProviderProps {
  children: ReactNode;
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  memberPermission: MemberPermissions;
  scroller: Nullable<HTMLElement>;
  memberId?: string | null;
  memberGroupId?: string | null;
  positionId?: string | null;
  archivedStatus: ArchivedStatus;
}

export default function useTimesheetSummaryData({
  scroller,
  positionId,
  memberId,
  memberGroupId,
  timeRange,
  timeRangeType,
  memberPermission,
  archivedStatus,
}: Omit<IEmployeeSummaryDataContextProviderProps, 'children'>) {
  const sectionSize = 10;

  const client = useApolloClient();
  const graphqlClient = useGraphQLClient();
  const organization = useOrganization();
  const canBeSigned =
    timeRangeType === TimeRangeType.PAY_PERIOD &&
    organization.signatureDate &&
    dateTimeFromISOWithoutZone(organization.signatureDate!) <= timeRange.startTime;
  const columnSettings = useSelector<IReduxState, IVisibleTableColumn[]>(
    (state) => state.timesheet.summaryAndTimeCardTableColumns
  );
  const dispatch = useDispatch();
  const getSortWithNameFormat = useApolloMemberNameSort();
  const archivedOn = useMemo(() => getApolloArchivedTimestampComparison(archivedStatus), [archivedStatus]);

  const sortIsDirty = useRef<boolean>(false);
  const [sort, setSort] = useState<ISortPayload<IEmployeeSummaryRowInfo>>({
    sortBy: 'member',
    sortDir: SortDirection.ASCENDING,
  });

  const baseQueryKey = useReactQueryBaseKey();
  // This uses the same base query as time card report can maybe double dip into using the members not sure if they're the exact same
  const queryKey = useMemo(
    () => [
      baseQueryKey,
      'timesheets',
      timeRange.startTime.toISO(),
      timeRange.endTime.toISO(),
      memberId,
      memberGroupId,
      positionId,
      archivedOn,
      getSortWithNameFormat,
      columnSettings,
    ],
    [
      archivedOn,
      baseQueryKey,
      columnSettings,
      getSortWithNameFormat,
      memberGroupId,
      memberId,
      positionId,
      timeRange.endTime,
      timeRange.startTime,
    ]
  );

  const querySubmissions = async (memberId: string) => {
    const memberSignInSubmissionQuery = await client.query<{ memberSignInSubmissions: IMemberSignInSubmission[] }>({
      query: SIMPLE_SUBMISSIONS_REPORT_QUERY,
      variables: {
        filter: {
          memberId: {
            equal: memberId,
          },
          localTime: {
            lessThanOrEqual: timeRange.endTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
            greaterThanOrEqual: timeRange.startTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
          },
          flagged: {
            equal: true,
          },
          deletedOn: {
            isNull: true,
          },
        },
      },
    });

    return memberSignInSubmissionQuery.data.memberSignInSubmissions;
  };

  async function createSummaryRowInfo(
    member: TimesheetSummaryMember,
    submissions: IMemberSignInSubmission[]
  ): Promise<IEmployeeSummaryRowInfo> {
    const signOffItems = member.safetySignatures;
    const memberTimeDocument = _.first(member.memberTimeDocuments);
    let injured: boolean | null = null; // default to null for the case that it hasn't been answered
    let timeAccurate: boolean | null = null;
    let breakPolicyFollowed: boolean | null = null;
    let payPeriodEmployeeSigned: boolean | null = null;
    let payPeriodSupervisorSigned: boolean | null = null;

    const signOffByDay = _.groupBy(signOffItems, (signOff) => {
      dateTimeFromISOWithoutZone(signOff.startTime).startOf('day');
    });

    // check if the latest answer for each day reports time as inaccurate or that the break policy was not followed
    _.forEach(signOffByDay, (items) => {
      const latestForDay = _.maxBy(items, (item) => dateTimeFromISOWithoutZone(item.endTime));

      if (latestForDay) {
        // set time accurate if it hasn't been set yet, otherwise we only change the value if its marked as not accurate
        if (timeAccurate === null) {
          timeAccurate = latestForDay.timeAccurate ?? null;
        } else if (latestForDay.timeAccurate === false) {
          timeAccurate = false;
        }

        // set break policy followed if it hasn't been set yet, otherwise we only change the value if its marked as not followed
        if (breakPolicyFollowed === null) {
          breakPolicyFollowed = latestForDay.breakPolicyFollowed ?? null;
        } else if (latestForDay.breakPolicyFollowed === false) {
          breakPolicyFollowed = false;
        }
      }
    });

    // NOTE: `injured = _.some(signOffItems, (item) => item.injured)` doesn't work because we want to leave `injured` as null if there is no answer for the sign off
    if (_.some(signOffItems, (item) => item.injured === true)) {
      // there is at least 1 report of an injury
      injured = true;
    } else if (_.some(signOffItems, (item) => item.injured === false)) {
      // there are no injuries and there has been at least 1 time that the user reported that they were not injured
      injured = false;
    }

    const needsToResign = memberTimeDocument && (memberTimeDocument.canceled || memberTimeDocument.edited);

    // check if the pay period has been signed by employee
    if (memberTimeDocument && memberTimeDocument.selfSignature && !needsToResign) {
      payPeriodEmployeeSigned = true;
    } else if (canBeSigned) {
      // we only set pay period as not signed if it can be signed, otherwise it stays null
      payPeriodEmployeeSigned = false;
    }

    // check if the pay period has been signed by supervisor
    if (memberTimeDocument && memberTimeDocument.authoritativeSignature && !needsToResign) {
      payPeriodSupervisorSigned = true;
    } else if (canBeSigned) {
      // we only set pay period as not signed if it can be signed, otherwise it stays null
      payPeriodSupervisorSigned = false;
    }

    // TODO: maybe change util to accept correct type member labor metrics
    const timeMetric = getRegularMetricTimeTotals(_.first(member.memberLaborMetrics) as IJitLaborMetric);
    const hasTimeEntryTime = (timeMetric?.totalSeconds ?? 0) > 0;

    // we need to subtract paid time off from regular seconds, so its not counted twice
    // we also need to guard against negative values just in case the aggregate is off
    let regularSeconds = timeMetric.regularSeconds ?? 0;
    if (!hasTimeOffColumn(columnSettings) && timeMetric) {
      const updatedRegularTotal = timeMetric.regularSeconds + timeMetric.paidTimeOffSeconds;
      regularSeconds = updatedRegularTotal > 0 ? updatedRegularTotal : 0;
    }

    const flaggedSignIns = submissions.filter((submission) => submission.flagged);

    const flaggedSignOffs = member.safetySignatures?.filter(
      (signature) =>
        !signature.breakPolicyFollowed ||
        !signature.timeAccurate ||
        signature.injured ||
        signature.customQuestionsFlagged
    );

    function countFlaggedSignOffs(
      signOffs: Array<
        Pick<SafetySignature, 'breakPolicyFollowed' | 'timeAccurate' | 'injured' | 'customQuestionsFlagged'>
      >
    ): number {
      return signOffs.reduce((count, signature) => {
        if (!signature.breakPolicyFollowed) {
          count++;
        }
        if (!signature.timeAccurate) {
          count++;
        }
        if (signature.injured) {
          count++;
        }
        if (signature.customQuestionsFlagged) {
          count++;
        }
        return count;
      }, 0);
    }

    return {
      id: member.id,
      member,
      payPeriodEmployeeSigned,
      payPeriodSupervisorSigned,
      timeAccurate: hasTimeEntryTime ? timeAccurate : null,
      injured: hasTimeEntryTime ? injured : null,
      breakPolicyFollowed: hasTimeEntryTime ? breakPolicyFollowed : null,
      regularSeconds: timeMetric ? regularSeconds : null,
      overtimeSeconds: timeMetric ? timeMetric.overtimeSeconds : null,
      doubletimeSeconds: timeMetric ? timeMetric.doubleTimeSeconds : null,
      ptoSeconds: timeMetric ? timeMetric.paidTimeOffSeconds : null,
      breakSeconds: null,
      totalSeconds: timeMetric ? timeMetric.totalSeconds : null,
      flaggedSignInAmount: flaggedSignIns ? flaggedSignIns.length : null,
      flaggedSignOffAmount: flaggedSignOffs ? countFlaggedSignOffs(flaggedSignOffs) : null,
      cursor: member.cursor,
    };
  }

  async function getMoreMembers(cursor: string | null, first: number): Promise<IEmployeeSummaryRowInfo[]> {
    const membersResult = await graphqlClient.request<
      QueryMembersForTimeMetericsWithMoreQuery,
      QueryMembersForTimeMetericsWithMoreQueryVariables
    >({
      document: MEMBERS_WITH_ALL_JOINED_DATA_QUERY,
      variables: getMemberVariables(cursor, first),
    });

    if (!membersResult?.members) {
      return [];
    }

    return Promise.all(
      membersResult.members.map(async (member) => {
        const submissions = await querySubmissions(member.id);
        return createSummaryRowInfo(member, submissions);
      })
    );
  }

  function getMemberVariables(cursor: string | null, first: number) {
    const sortVariable = getSortWithNameFormat(sort.sortDir.toLowerCase() as 'asc' | 'desc');

    const variables: QueryMembersForTimeMetericsWithMoreQueryVariables = {
      first,
      after: cursor ?? undefined,
      filter: {
        permissions: {
          permissions: [memberPermission],
          operationType: OperationType.And,
        },
        memberGroupId: memberGroupId ? { equal: memberGroupId } : undefined,
        positionId: positionId ? { equal: positionId } : undefined,
        id: memberId ? { equal: memberId } : undefined,
        archivedOn,
        hasTime: isNil(memberId)
          ? {
              startTime: timeRange.startTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
              endTime: timeRange.endTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
              includeOpenEntry: false,
              hasTimeOff: MemberHasTimeOff.All,
            }
          : undefined,
      },
      sort: sortVariable as MemberSort,
      memberTimeDocumentFilter: {
        startTime: { equal: timeRange.startTime.toISO() },
        endTime: { equal: timeRange.endTime.toISO() },
        deletedOn: { isNull: true },
      },
      memberTimeDocumentSort: [{ submittedOn: SortOrder.Desc }],

      metricsInterval: LaborMetricsInterval.Custom,
      metricsStartDate: timeRange.startTime.toUTC().toISODate(),
      metricsEndDate: timeRange.endTime.toUTC().toISODate(),
      safetySignaturesFilter: {
        startTime: { greaterThanOrEqual: timeRange.startTime.toISO() },
        endTime: { lessThanOrEqual: timeRange.endTime.toISO() },
        deletedOn: { isNull: true },
        member: {
          permissions: {
            permissions: [MemberPermissions.ManageTimeEntries],
            operationType: OperationType.And,
          },
        },
      },
      safetySignaturesSort: [{ startTime: SortOrder.Asc }, { createdOn: SortOrder.Asc }],
    };

    return variables;
  }

  async function onCheckAll(checked: boolean) {
    // Component handles checking when the data is loaded and we only need to load when checked
    if (checked) {
      const rows = await loadAll();
      const allMembers = rows.filter((r) => isNil(r.member.archivedOn)).map((r) => r.member.id);
      dispatch(updateCheckedMemberIds(allMembers));
    }
  }

  async function refreshDataForMembers(_memberIds: string[]) {
    clearReactQueryData();
  }

  function handleSort(newSort: ISortPayload<IEmployeeSummaryRowInfo>) {
    setSort(newSort);
    sortIsDirty.current = true;
  }

  const {
    data,
    loadedAll,
    isLoading,
    isError,
    clearData: clearReactQueryData,
    loadAll,
  } = useReactQueryLazyLoading(scroller, queryKey, getMoreMembers, sectionSize);

  return useMemo(
    () => ({
      data: data ?? [],
      loadedAll,
      getMoreMembers,
      onCheckAll,
      handleSort,
      refreshDataForMembers,
      sortBy: sort.sortBy as keyof IEmployeeSummaryRowInfo,
      sortDir: sort.sortDir,
      sortIsDirty: sortIsDirty.current,
      forceLoadAll: loadAll,
      isLoading,
      isError,
      clearData: clearReactQueryData,
    }),
    [data, loadedAll, sort, sortIsDirty, getSortWithNameFormat]
  );
}
