import classNames from 'classnames';
import DashboardContainer from 'components/foundation/dashboard/DashboardContainer/DashboardContainer';
import Helmet from 'react-helmet';
import { ClassName } from 'types/ClassName';
import { t } from 'utils/localize';
import { DashboardSettingsContextProvider } from '../context/DashboardSettingsContext';
import DashboardGrid from '../dashboard-grid/DashboardGrid/DashboardGrid';
import './Dashboard.scss';

interface IDashboardProps {
  className?: ClassName;
}

function Dashboard({ className }: IDashboardProps) {
  const classes = classNames('dashboard', className);

  return (
    <DashboardContainer className={classes}>
      <Helmet>
        <title>{t('Dashboard')}</title>
      </Helmet>
      <DashboardSettingsContextProvider>
        <DashboardGrid />
      </DashboardSettingsContextProvider>
    </DashboardContainer>
  );
}

export default Dashboard;
