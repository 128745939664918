import { Button, Dialog, DialogHeader, IDialogProps, Position, Size, Tray } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { t } from 'utils/localize';
import { Panel } from '../../../components';
import './DeleteConfirmationDialog.scss';

export interface IDeleteConfirmationDialogProps extends IDialogProps {
  title?: string;
  deleteText: string;
  showHeaderDivider?: boolean;
  onDelete?: () => void;
}

const DeleteConfirmationDialog = ({
  title,
  onDelete,
  deleteText,
  showHeaderDivider,
  children,
  className,
  ...dialogProps
}: IDeleteConfirmationDialogProps) => {
  const classes = classNames('photo-delete-confirmation-dialog', className);

  const header = title ? (
    <DialogHeader divider={showHeaderDivider !== undefined ? showHeaderDivider : true}>
      <h1>{title}</h1>
    </DialogHeader>
  ) : null;

  return (
    <Dialog className={classes} {...dialogProps}>
      <Panel>
        {header}
        <div className="content">{children}</div>
        <Tray className="tray-right" align="left">
          <Button className="right-button" type="secondary" onClick={dialogProps.onClose}>
            {t('Cancel')}
          </Button>
          <Button className="right-button" type="danger" onClick={onDelete}>
            {deleteText}
          </Button>
        </Tray>
      </Panel>
    </Dialog>
  );
};

DeleteConfirmationDialog.defaultProps = {
  overlay: true,
  allowOverlayClose: true,
  hasDismiss: true,
  size: Size.MEDIUM,
  layer: 1,
  position: Position.TOP,
  deleteText: t('Delete'),
};

export default DeleteConfirmationDialog;
