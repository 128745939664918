import type { UseQueryOptions } from '@tanstack/react-query';
import { Duration } from 'luxon';
import type { AnyObject } from 'types/util/Object';

const staleTime = Duration.fromObject({ minutes: 1 }).toMillis();
const refetchInterval = Duration.fromObject({ minutes: 5 }).toMillis();

export const getJsaReportsManagementQueryConfig = <TQueryFnData = AnyObject>(): Omit<
  UseQueryOptions<TQueryFnData, Error>,
  'queryKey'
> => {
  return {
    staleTime,
    refetchInterval,
    refetchOnMount: 'always',
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: true,
  };
};
