import { Organization } from '__generated__/graphql';
import { IEmployeeSummaryRowInfo } from 'components/domain/member/EmployeeSummaryTable/EmployeeSummaryTable';
import { isNil, isNull } from 'lodash';
import { Duration } from 'luxon';
import Papa from 'papaparse';
import { convertDurationToHoursDecimal, secondsToHmmString } from 'utils/dateUtils';
import { t } from 'utils/localize';

export function convertTimesheetSummaryRowsToCsv(
  rows: IEmployeeSummaryRowInfo[],
  organization: Pick<Organization, 'breakPolicy' | 'timeAccuracy' | 'safetySignature'>
) {
  const csv = Papa.unparse(
    rows.map((row) => {
      const result: any = {
        [t('First Name')]: row.member.firstName,
        [t('Last Name')]: row.member.lastName,
      };

      if (!isNil(row.member.memberGroup) && !isNil(row.member.memberGroup.groupName)) {
        result[t('Employee Group')] = row.member.memberGroup.groupName;
      }

      if (organization.breakPolicy) {
        result[t('Break Policy')] = !isNull(row.breakPolicyFollowed)
          ? row.breakPolicyFollowed
            ? t('Yes')
            : t('No')
          : '';
      }

      if (organization.timeAccuracy) {
        result[t('Time Accurate')] = !isNull(row.timeAccurate) ? (row.timeAccurate ? t('Yes') : t('No')) : '';
      }

      if (organization.safetySignature) {
        result[t('Injured')] = !isNull(row.injured) ? (row.injured ? t('Yes') : t('No')) : '';
      }

      // If we decide to allow import/reimport we should get rid of the translation
      // due to having to untranslate on reimporting the file.
      return {
        ...result,
        [t('Regular Time')]: secondsToHmmString(row.regularSeconds ?? 0),
        [t('Regular Time (Decimal)')]: convertDurationToHoursDecimal(
          Duration.fromMillis((row.regularSeconds ?? 0) * 1000),
          2
        ),

        [t('Overtime')]: secondsToHmmString(row.overtimeSeconds ?? 0),
        [t('Overtime (Decimal)')]: convertDurationToHoursDecimal(
          Duration.fromMillis((row.overtimeSeconds ?? 0) * 1000),
          2
        ),

        [t('Double Time')]: secondsToHmmString(row.doubletimeSeconds ?? 0),
        [t('Double Time (Decimal)')]: convertDurationToHoursDecimal(
          Duration.fromMillis((row.doubletimeSeconds ?? 0) * 1000),
          2
        ),
        [t('Paid Time Off')]: secondsToHmmString(row.ptoSeconds ?? 0),
        [t('Total')]: secondsToHmmString(row.totalSeconds ?? 0),
        [t('Total (Decimal)')]: convertDurationToHoursDecimal(Duration.fromMillis((row.totalSeconds ?? 0) * 1000), 2),
      };
    })
  );

  return csv;
}
