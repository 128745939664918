import {} from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import MemberTimeEntryDataReport from 'components/domain/time-entry/TimeEntryDataReport/MemberTimeEntryDataReport/MemberTimeEntryDataReport';
import useMemberTimeEntryDataReportData, {
  IMemberTimeEntryReportDataFilter,
} from 'components/domain/time-entry/TimeEntryDataReport/MemberTimeEntryDataReport/hooks/useMemberTimeEntryDataReportData';
import { ITimeEntryDataTableRow } from 'components/domain/time-entry/TimeEntryDataTable/TimeEntryDataTable';
import useTimesheetsColumns from 'containers/timesheets/hooks/useTimesheetsColumns';
import { usePrintComplexDataChannelListener } from 'contexts/ChannelContext/hooks/usePrintComplexDataChannelListener';
import { useEmployeeNameFormat, useReduxSelector } from 'hooks';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import { useLoaderPromise } from 'hooks/promise';
import { first, isEmpty, isNil, isNull, map, partition } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { updateCheckedTimeEntryIds, updateCheckedTimeOffIds } from 'store/TimeEntryTable/TimeEntryTable';
import { ClassName } from 'types/ClassName';
import { dateUtils } from 'utils';
import { t } from 'utils/localize';
import { useTimeEntryExportChannelListener } from '../hooks/useTimeEntryExportChannelListener';
import { emptyList } from 'types/util/Empty';
import useReactQueryBaseKey from 'hooks/react-query/useReactQueryBaseKey/useReactQueryBaseKey';
import { useTimeEntryActionBarState } from '../TimeEntryActionBar/TimeEntryActionBarContext';
import { useAfterFirstRenderEffect } from 'hooks/utils/useAfterFirstRenderEffect/useAfterFirstRenderEffect';

export interface IMemberTimeEntryTableListeningContainerProps {
  className?: ClassName;
  filter: IMemberTimeEntryReportDataFilter;
  scroller: HTMLElement | null;
  applyPrintOptions: boolean;
}

const MemberTimeEntryTableListeningContainer = (props: IMemberTimeEntryTableListeningContainerProps) => {
  const { className, scroller, filter, applyPrintOptions } = props;

  const [employeeNameFormat] = useEmployeeNameFormat();

  const baseQueryKey = useReactQueryBaseKey();
  const queryKey = useMemo(
    () => [baseQueryKey, 'timesheets-member-time-data', employeeNameFormat, filter],
    [baseQueryKey, employeeNameFormat, filter]
  );

  const {
    data,
    onDataChange: onChange,
    loadedAll,
    loading,
    loadAll,
  } = useMemberTimeEntryDataReportData(scroller, filter, queryKey);

  const [timeEntryActionBarState] = useTimeEntryActionBarState();

  useAfterFirstRenderEffect(() => {
    if (!isEmpty(timeEntryActionBarState.entries)) {
      onChange();
    } else if (!isEmpty(timeEntryActionBarState.memberIds)) {
      onChange();
    } else if (!isNull(timeEntryActionBarState.formData)) {
      onChange();
    }
  }, [timeEntryActionBarState]);

  const dispatch = useDispatch();
  const selectedIds = useReduxSelector((state) =>
    state.timeEntryTable.checkedTimeEntryIds.concat(state.timeEntryTable.checkedTimeOffIds)
  );
  const { timeEntryColumns } = useTimesheetsColumns();

  const onSelect = useCallback(
    (rows: ITimeEntryDataTableRow[]) => {
      const [timeEntryIds, timeOffIds] = partition(rows, (row) => isNil(row.timeOffType));
      dispatch(updateCheckedTimeEntryIds(map(timeEntryIds, 'id')));
      dispatch(updateCheckedTimeOffIds(map(timeOffIds, 'id')));
    },
    [dispatch]
  );

  const exportWithLoader = useLoaderPromise(loadAll, t('Exporting...'));
  const printWithLoader = useLoaderPromise(loadAll, t('Preparing to print...'));

  const nonNullData = data ?? emptyList;
  const brand = useBrandTitle();

  useTimeEntryExportChannelListener(
    nonNullData,
    loadedAll,
    `${brand}-${t('activity-report-time-entries')}-${dateUtils.isoTimeStampLocal()}`,
    exportWithLoader
  );

  usePrintComplexDataChannelListener(nonNullData, loadedAll, printWithLoader);

  const classes = classNames('member-time-entry-table-container', className);

  return (
    <MemberTimeEntryDataReport
      className={classes}
      columnSettings={timeEntryColumns}
      onSelectChange={data?.some((item) => item.canManage) ? onSelect : undefined}
      selectedIds={selectedIds}
      timeRange={filter.timeRange}
      onDataChange={() => onChange()}
      data={nonNullData}
      loading={loading}
      projectId={first(filter.projectIds)}
      costCodeId={first(filter.costCodeIds)}
      equipmentId={first(filter.equipmentIds)}
      applyPrintOptions={applyPrintOptions}
    />
  );
};

export default MemberTimeEntryTableListeningContainer;
