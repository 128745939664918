import { Theme, Toast } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import Dropzone, { Accept, FileRejection } from 'react-dropzone';
import { Trans } from 'react-i18next';
import { ClassName } from 'types/ClassName';
import Typography from '../../text/Typography/Typography';
import './FileUploader.scss';
import { t } from 'i18next';
import { IFileWithPreview } from 'types';

export interface IFileUploaderContainerProps {
  filesWithPreview: IFileWithPreview[];
  onChange: (files: IFileWithPreview[]) => void;
  className?: ClassName;
  maxSize: number;
  multiple: boolean;
  allowedFiles: string[];
  error: boolean;
  message?: ReactNode;
  header?: ReactNode;
  hideDropZone?: boolean;
  maxFiles?: number;
  maxFilesErrorMessage?: string;
}

function FileUploaderContainer({
  filesWithPreview,
  onChange,
  error,
  ...fileUploaderProps
}: IFileUploaderContainerProps) {
  function onAdd(files: File[]) {
    const newFiles = files.map((file: File) => {
      return {
        file,
        preview: createPreviewImage(file),
      };
    });

    const allFiles = [...filesWithPreview, ...newFiles];
    onChange(allFiles);
  }

  // Create temporary preview image (Currently only supports images)
  function createPreviewImage(file: File) {
    return URL.createObjectURL(file);
  }

  return <FileUploader {...fileUploaderProps} onAdd={onAdd} currentFileCount={filesWithPreview.length} error={error} />;
}

export interface IFileUploaderProps extends Omit<IFileUploaderContainerProps, 'onChange' | 'filesWithPreview'> {
  currentFileCount: number;
  onAdd: (files: File[]) => void;
}

export function FileUploader({
  hideDropZone: hideDropZoneProp,
  allowedFiles,
  maxSize,
  multiple,
  currentFileCount,
  onAdd,
  header,
  error,
  message,
  maxFiles,
  maxFilesErrorMessage,
  className,
}: IFileUploaderProps) {
  const hideDropZone = (!multiple && currentFileCount > 0) || hideDropZoneProp;

  const [uploadError, setUploadError] = useState<string>('');

  // User selects/drops files
  function handleDrop(acceptedFiles: File[], rejectedFiles: FileRejection[]) {
    if (maxFiles && currentFileCount + acceptedFiles.length > maxFiles) {
      setUploadError(maxFilesErrorMessage ?? t(`Limit ${maxFiles} files`));
      return;
    }

    if (rejectedFiles.length) {
      setUploadError('Unsupported file type');
    }

    onAdd(acceptedFiles);
  }

  function closeErrorToast() {
    setUploadError('');
  }

  const classes = classNames(
    'file-uploader',
    {
      'file-uploader-error': error,
    },
    className
  );

  return (
    <div className={classes}>
      <div className="file-uploader-upload-section">
        <div className="file-uploader-header">{header}</div>
        {header && <div className="file-uploader-divider" />}
        {hideDropZone !== true && (
          <Dropzone
            onDrop={handleDrop}
            accept={convertStringExtensionsToMap(allowedFiles)}
            maxSize={maxSize}
            multiple={multiple}
            maxFiles={maxFiles}
          >
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <div {...getRootProps()} className={classNames('dropzone', { 'dropzone--isActive': isDragActive })}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <Trans>
                      <p>Drop files here...</p>
                    </Trans>
                  ) : (
                    <>{message}</>
                  )}
                </div>
              );
            }}
          </Dropzone>
        )}
      </div>
      <Typography tag="div" color="light-gray" fontSize="0.8rem" className="mt-1">
        {`Supported: ${allowedFiles.join(', ')}`}
      </Typography>
      <Toast isOpen={uploadError !== ''} onClose={closeErrorToast} timeout={3000} theme={Theme.DANGER}>
        {uploadError}
      </Toast>
    </div>
  );
}

function convertStringExtensionsToMap(extensions: string[]): Accept {
  const map: { [key: string]: string[] } = {};
  extensions.forEach((extension) => {
    const withoutDot = extension.substring(1);

    const key = fileExtensionToFileTypeLookup[withoutDot];
    if (key) {
      map[key] = [extension];
    }
  });

  return map;
}

export const fileExtensionToFileTypeLookup: Record<string, string> = {
  csv: 'text/csv',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  jpg: 'image/jpeg',
  pdf: 'application/pdf',
  png: 'image/png',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  rtf: 'application/rtf',
  txt: 'text/plain',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

const defaultProps = {
  allowedFiles: ['.png', '.jpg', '.jpeg'], // TODO: parse allowedFiles into string and apply
  error: false,
  maxSize: Infinity,
  multiple: true,
  message: (
    <Trans>
      <p className="fw-semi-bold">
        <span>Click</span>&nbsp;or drag photos to upload
      </p>
    </Trans>
  ),
} as const;

FileUploaderContainer.defaultProps = defaultProps;
FileUploader.defaultProps = defaultProps;

export default FileUploaderContainer;
