import { Col } from '@busybusy/webapp-react-ui';
import { InspectionQuestionAnswerAttachment, ObservationAttachment } from '__generated__/graphql';
import { BulletinPostAttachment } from 'containers/bulletin/types/types';
import * as React from 'react';
import { useState } from 'react';
import { ErrorState, LoadingState } from '../../../../components';
import { IMediaEntry } from '../../../../types/MediaEntry';
import './PhotoFullscreen.scss';
import { TSafetyTrainingsTableData } from 'containers/safety-trainings/SafetyTrainingsReportContent/SafetyTrainingsReportContent';
import classNames from 'classnames';

export interface IPhotoFullScreenProps {
  photo?:
    | IMediaEntry
    | BulletinPostAttachment
    | InspectionQuestionAnswerAttachment
    | Partial<ObservationAttachment>
    | TSafetyTrainingsTableData['attachments'][number];
  performShake: boolean;
  showAnnotations?: boolean;
  annotationFileUrl?: string;
}

const PhotoFullScreen: React.FunctionComponent<IPhotoFullScreenProps> = (props) => {
  const { photo, performShake, showAnnotations, annotationFileUrl } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<boolean>(false);
  const classes = classNames('photo-full-screen', 'image-container', {
    'ac-shake': performShake,
  });

  function handleImageFinishedLoading() {
    setIsLoading(false);
  }

  function handleImageErrorLoading() {
    setError(true);
  }

  return (
    <Col className={classes}>
      <div className="image">
        {photo && (
          <img
            src={showAnnotations && annotationFileUrl ? annotationFileUrl : photo.fileUrl!}
            onLoad={handleImageFinishedLoading}
            onError={handleImageErrorLoading}
            alt=""
          />
        )}
      </div>
      {isLoading && !error ? <LoadingState /> : null}
      {error && <ErrorState />}
    </Col>
  );
};

export default PhotoFullScreen;
