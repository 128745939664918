import { IFeatureFlags } from 'types';
import { staging } from './staging';

// inherits staging configuration
export const beta: IFeatureFlags = Object.assign({}, staging, {
  KIOSK_REPORT: true,
  SCHEDULING: true,
  EMPTY_ROUTE: true,
  GROUP_MANAGEMENT: true,
  TIMESHEETS: true,
  MANAGEMENT_LISTS_EQUIPMENT: true,
  EQUIPMENT_IMPORT: true,
  EMPLOYEE_TIMERS: true,
  LANDING_PAGES: true,
  DASHBOARD: true,
  SETTINGS: true,
  SAFETY_SIGN_OFF: true,
  MY_STATUS: true,
  MAP_SECTION: true,
  GRAPHQL_BATCH: true,
  QUICK_TIME_TEMPLATES: true,
  TIME_OFF_REPORT: true,
  BULK_PROJECT_COST_CODE_CREATE: true,
  MANAGE_PROJECT_MAP: true,
  MANAGE_EQUIPMENT_MAP: true,
  LABOR_BUDGETS: true,
  REACT_BY_DATE_RANGE_ACTIVITY_REPORTS: true,
  HOUR_METER_REPORT: true,
  SCHEDULE_PRINT: true,
  TIME_LOG_FEED: true,
  PAYROLL_JOURNAL_ENTRIES: true,
  CONVERT_SCHEDULE_TO_TIME_ENTRY: true,
  ACTIVITY_REPORTS_PHASE_3000: true,
  BUDGET_PERFORMANCE: true,
  DASHBOARD_WEB_REFRESH: true,
  BULLETIN: true,
  SCHEDULING_DRAFTS: true,
  SCHEDULING_RECURRING: true,
  REQUIRE_EQUIPMENT_POSITION_SETTING: true,
  REACT_SETTINGS_INTEGRATION_QUICKBOOKS: true,
  PAYROLL_SUBCONTRACTORS: false,
  RECENT_BUDGET_FILTER: true,
  PAYROLL_SCAN_BREAK_COMPLIANCE: true,
  PHOTOS_VIEW_COLLECTION_PERMISSION: true,
  IFRAME_FEATURES: true,
  AUTOINVITE_NEW_EMPLOYEES: true,
  SAFETY_OBSERVATIONS: true,
  SAFETY_INCIDENTS_OSHA_FORM_5020: true,
  SAFETY_SIGN_OFF_EXPORT_FOLLOWUPS: true,
  SAFETY_TRAINING: true,
  INTEGRATION_AGAVE_INTEGRATIONS: true,
  INTEGRATION_AGAVE_INTEGRATIONS_QBO: true,
  JSA_REPORTS: true,
  JSA_REPORTS_PARTICIPANTS: false,
  INTEGRATION_ADP: true,
  INTEGRATION_ADP_LOGIN: true,
  SAFETY_OVERVIEW: true,
});

Object.freeze(beta);
