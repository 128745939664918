import { ClassName } from '@busybusy/webapp-react-ui';
import { MemberPermissions } from '__generated__/graphql';
import classNames from 'classnames';
import { ArchivedStatus } from 'components/domain/archived/ArchivedPicker/ArchivedPicker';
import { ITimeActionsFormData } from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import { ITimeOffFormData } from 'components/domain/time-off/form/CreateTimeOffForm/CreateTimeOffForm';
import TimeRangeDialog from 'components/foundation/dialogs/TimeRangeDialog/TimeRangeDialog';
import TimeCardReadyToSignWell from 'containers/my-status/TimeCardReadyToSignWell/TimeCardReadyToSignWell';
import { useTimeRange } from 'hooks';
import useTimesheetsGraylog from 'hooks/analytics/useTimesheetsGraylog';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearCheckedMemberIds } from 'store/timesheets/Timesheets';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { Nullable } from 'types/util/Nullable';
import { TimesheetsTypes } from 'utils/constants/graylogActionTypes';
import { t } from 'utils/localize';
import TimeCardReport from '../TimeCardReport/TimeCardReport';
import useTimeCardReportData from '../TimeCardReport/hooks/useTimeCardReportData';
import TimesheetsMemberActionBar from '../action-bar/TimesheetsMemberActionBar/TimesheetsMemberActionBar';
import useTimesheetsRowHeight from '../hooks/useTimesheetsRowHeight/useTimesheetsRowHeight';
import './TimeCardReportDialog.scss';

export interface ITimeCardReportDialogProps {
  isOpen: boolean;
  onClose: () => void;
  memberIds?: string[] | null;
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  onDataChange?: (members: string[]) => void;
  onSignatureComplete?: (member: string) => void;
  permission: MemberPermissions.ManageTimeEntries | MemberPermissions.TimeEvents;
  archivedStatus: ArchivedStatus;
  className?: ClassName;
}

const TimeCardReportDialog = (props: ITimeCardReportDialogProps) => {
  const {
    className,
    isOpen,
    onClose,
    memberIds,
    timeRange: initialTimeRange,
    timeRangeType,
    permission,
    onDataChange,
    archivedStatus,
    onSignatureComplete,
  } = props;

  const userEvents = useTimesheetsGraylog();
  const dispatch = useDispatch();
  const [forceReadyToSignReload, setForceReadyToSignReload] = useState<boolean>(false);
  const scroller = useRef<Nullable<HTMLElement>>();

  const timeRangeDetails = useTimeRange(timeRangeType, initialTimeRange, undefined, undefined, 'utc');
  const timeRange = timeRangeDetails.timeRange;
  const onUserEvent = useRef(userEvents.events);

  // TODO: I don't really know what the solution is here
  // We carry over columns and other settings for timesheets even if the dialog isn't in timesheets
  const [rowHeight] = useTimesheetsRowHeight();

  const {
    data: members,
    isLoading,
    isError,
    refreshMembersData,
  } = useTimeCardReportData({
    timeRange,
    timeRangeType,
    archivedStatus,
    permission,
    memberIds,
    scroller: scroller.current,
  });

  useEffect(() => {
    if (isOpen) {
      onUserEvent.current(TimesheetsTypes.events.action_type.VIEW_TIME_CARDS);
    }
  }, [isOpen]);

  function renderTimeCardReport(): ReactNode {
    function onSignComplete(memberId: string) {
      setForceReadyToSignReload(!forceReadyToSignReload);
      onSignatureComplete?.(memberId);
    }

    return (
      <>
        {!isNil(memberIds) && memberIds.length === 1 && (
          <TimeCardReadyToSignWell
            memberId={memberIds[0]}
            type={'time-card-report'}
            timeRange={timeRange}
            timeRangeType={timeRangeType}
            forceReload={forceReadyToSignReload}
          />
        )}
        <TimeCardReport
          members={members ?? []}
          timeRange={timeRange}
          timeRangeType={timeRangeType}
          onMemberDataChange={refreshMembersData}
          onSignatureComplete={onSignComplete}
          isLoading={isLoading}
          rowHeight={rowHeight}
          error={isError ? t('Something went wrong.') : undefined}
        />
      </>
    );
  }

  function renderActionBar() {
    function refreshData(employeeIds: string[]) {
      refreshMembersData(employeeIds);
      onDataChange?.(employeeIds);
      dispatch(clearCheckedMemberIds());
    }

    function onTimeEntryFormDataSubmit(formData: ITimeActionsFormData) {
      refreshData(formData.members);
    }

    function onTimeOffFormDataSubmit(formData: ITimeOffFormData | undefined) {
      if (formData) {
        refreshData(formData.members);
      }
    }

    return (
      <TimesheetsMemberActionBar
        onTimeEntryFormSubmit={onTimeEntryFormDataSubmit}
        onTimeOffFormSubmit={onTimeOffFormDataSubmit}
      />
    );
  }

  const classes = classNames('time-range-report-dialog', className);

  return (
    <TimeRangeDialog
      className={classes}
      isOpen={isOpen}
      onClose={onClose}
      title={t('Time Card')}
      actionBar={renderActionBar()}
      setRef={(elt) => (scroller.current = elt)}
      {...timeRangeDetails}
    >
      {renderTimeCardReport()}
    </TimeRangeDialog>
  );
};

export default TimeCardReportDialog;
