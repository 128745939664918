import { QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { Bar, Position, Size } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import _ from 'lodash';
import { Component } from 'react';
import { IEquipmentType } from 'types/EquipmentType';
import LazyLoadPicker from '../../../foundation/pickers/LazyLoadPicker/LazyLoadPicker';
import { equipmentTypeQuery, equipmentTypesQuery } from './equipment-type-picker-queries';

export interface IEquipmentTypePickerProps {
  value: string | null;
  onSelect: (equipmentType: string | null) => void;
  minWidth?: string; // has default
  className?: ClassName;
  placeholder?: string;
  error?: boolean;
  position?: Position;
  client?: any;
  fetchPolicy?: string;
  searchArchived?: boolean;
  autofocus?: boolean;
}

interface IState {
  type: IEquipmentType[];
  loadedAll: boolean;
}

class EquipmentTypePicker extends Component<WithApolloClient<IEquipmentTypePickerProps>, IState> {
  public static defaultProps: any;
  public state: IState = {
    loadedAll: false,
    type: [],
  };

  // Fetch a page of equipment types
  public fetchEquipmentTypes = (after?: string): Promise<IEquipmentType[]> => {
    return new Promise((resolve, reject) => {
      this.props.client
        .query({
          fetchPolicy: this.props.fetchPolicy,
          query: equipmentTypesQuery,
          variables: {
            after,
            filter: {
              display: { equal: true },
              deletedOn: { isNull: true },
            },
            first: 10,
          },
        })
        .then((result: { data: { equipmentTypes: IEquipmentType[] } }) => {
          resolve(result.data.equipmentTypes);
        })
        .catch(() => {
          reject();
        });
    });
  };

  // Handle new results from the server
  public handleDidLoad = (type: IEquipmentType[], error: boolean, loadedAll: boolean) => {
    if (!error) {
      this.setState({
        loadedAll,
        type,
      });
    }
  };

  // Render the picker's value
  public renderValueTemplate = () => {
    const value = this.props.value;

    if (value) {
      return (
        <Query query={equipmentTypeQuery} variables={{ typeId: value }}>
          {({ data }: QueryResult) => {
            return <>{_.get(data, 'equipmentTypes[0][title]')}</>;
          }}
        </Query>
      );
    }
    return <></>;
  };

  // Render a row withing the picker
  public renderRow = (type: IEquipmentType) => (
    <Bar size={Size.SMALL} className="px-3">
      <div className="ellipsis">{type.title}</div>
    </Bar>
  );

  // Handle when a value is selected from the list
  public handleSelect = (type: IEquipmentType | null) => {
    this.props.onSelect(type ? type.id : null);
  };

  // Handle the picker closing
  public handleClose = () => {
    this.setState({
      loadedAll: false,
      type: [],
    });
  };

  // Render
  public render() {
    const { className, placeholder, minWidth, position, value, autofocus } = this.props;

    const classes = classNames(
      {
        'equipment-type-picker': true,
      },
      className
    );

    return (
      <LazyLoadPicker
        data-testid={'equipment-type-picker'}
        value={value}
        getData={this.fetchEquipmentTypes}
        didLoad={this.handleDidLoad}
        data={this.state.type}
        loadedAll={this.state.loadedAll}
        onSelect={this.handleSelect}
        onClose={this.handleClose}
        valueTemplate={this.renderValueTemplate()}
        renderRow={this.renderRow}
        minWidth={minWidth}
        position={position}
        placeholder={placeholder}
        autofocus={autofocus}
        className={classes}
      />
    );
  }
}

EquipmentTypePicker.defaultProps = {
  fetchPolicy: 'cache-first',
  minWidth: '350px',
};

export default withApollo<IEquipmentTypePickerProps>(EquipmentTypePicker);
