import { IStoreEnvironmentTemplate, TEnvironmentKeys } from '../environmentType';
import localhost from './localhost';
import production from './production';

const getBusyEnvironment = (envKey: TEnvironmentKeys): IStoreEnvironmentTemplate =>
  Object.assign({}, production, {
    REACT_APP_APP_URL: `https://app-${envKey}.busybusy.io/`,
    REACT_APP_GRAPHQL_URL: `https://graphql-${envKey}.busybusy.io/`,
    REACT_APP_LEGACY_URL: `https://${envKey}.busybusy.io/`,
    REACT_APP_EQUIPMENT_URL: `https://${envKey}-equipment.busybusy.io/`,
    REACT_APP_RESTAPI_URL: `https://api-${envKey}.busybusy.io/`,
    REACT_APP_PLATFORM_REPORT_URL: `https://reports-platform-api-${envKey}.busybusy.io/`,
    REACT_APP_CUSTOM_REPORT_URL: `https://reports-api-${envKey}.busybusy.io/`,
    REACT_APP_DISCOVERY_URL: `https://discovery.busybusy.io/${envKey}`,
    REACT_APP_GUSTO_URL: `https://gusto-${envKey}.busybusy.io/`,
    REACT_APP_LEGACY_QBRPC_URL: `https://qb-${envKey}.busybusy.io`,
    REACT_APP_COMPANY_CAM_URL: `https://company-cam-api-${envKey}.busybusy.io`,
    REACT_APP_COMPANY_CAM_SOCKET_URL: `wss://company-cam-api-${envKey}.busybusy.io`,
    REACT_APP_QBD_API_URL: `https://qbd-api-${envKey}.busybusy.io`,
    REACT_SLACK_URL: `https://hooks.slack.com/services/`,
    REACT_APP_AGAVE_URL: `https://agave-${envKey}.busybusy.io`,
    REACT_APP_ADP_API_URL: `https://adp-${envKey}.busybusy.io`,
  });

export const environmentTemplates = new Map<TEnvironmentKeys, IStoreEnvironmentTemplate>([
  ['localhost', localhost],
  ['production', production],

  ['alpha', getBusyEnvironment('alpha')],
  ['beta', getBusyEnvironment('beta')],
  ['staging', getBusyEnvironment('staging')],
]);
