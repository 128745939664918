import { ClassName, Divider, Justify, Row } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PickerRow from '../PickerRow/PickerRow';
import useOnMount from 'hooks/utils/useOnMount/useOnMount';
import { t } from 'utils/localize';
import Scroller from 'components/foundation/Scroller/Scroller';
import './PickerList.scss';
import KeyboardKey from 'types/enum/KeyboardKey';
import Spinner from 'components/foundation/Spinner/Spinner';
import IIdable from 'types/Idable';

export interface IPickerListProps<T extends IIdable<K>, K extends string> {
  data: T[];
  value: K | null;
  isLoading: boolean;
  loadedAll: boolean;
  closeMenu: (shouldFocus: boolean) => void;
  onSelect: (row: T | null, index?: number, e?: React.MouseEvent) => void;
  error?: boolean;
  onRightKeyDown?: (row: T, e: React.KeyboardEvent) => void;
  onLeftKeyDown?: (row: T, e: React.KeyboardEvent) => void;
  header?: (
    onKeyDown: (event: React.KeyboardEvent) => void,
    setFocusIndex: (n: number) => void
  ) => JSX.Element | JSX.Element[] | HTMLElement | ReactNode;
  defaultFocusIndex?: number;
  children: (wrappedRenderRow: (row: T, index: number) => ReactNode) => ReactNode;
  setScrollerRef?: (scroller: HTMLElement) => void;
  renderRow: (row: T, index: number, e?: React.KeyboardEvent) => ReactNode;
  autofocus?: boolean;
  showSpinnerWhenLoading?: boolean;
  className?: ClassName;
}

export default function PickerList<T extends IIdable<K>, K extends string>({
  data,
  value,
  isLoading,
  loadedAll,
  closeMenu,
  onSelect,
  error,
  onRightKeyDown,
  onLeftKeyDown,
  header,
  children,
  renderRow,
  setScrollerRef,
  defaultFocusIndex,
  autofocus,
  className,
}: IPickerListProps<T, K>) {
  const isDirty = useRef(false);
  const [focusIndex, setFocusIndex] = useState(defaultFocusIndex ?? -1);

  // If no focus index is set set to the current index
  const currentIndex = useMemo(() => {
    if (focusIndex !== -1) {
      return focusIndex;
    }

    const currentValueIndex = data.findIndex(({ id }) => id === value);

    if (currentValueIndex !== -1) {
      return currentValueIndex;
    }

    if (value !== null) {
      return -1;
    }

    return 0;
  }, [data, focusIndex, value]);

  const listContainer = useRef<HTMLElement>();
  const scroller = useRef<HTMLElement>();

  useOnMount(() => {
    if (autofocus) {
      listContainer.current?.focus();
    }
  });

  const getFocusedRowRef = useCallback(() => {
    if (scroller.current) {
      return scroller.current.querySelector('.focused') || null;
    }
    return null;
  }, []);

  const scrollIntoView = useCallback(
    (scroller: HTMLElement) => {
      const focused = getFocusedRowRef();

      if (focused) {
        isDirty.current = true;

        const focusedRect = focused.getBoundingClientRect();
        const scrollerRect = scroller.getBoundingClientRect();

        if (focusedRect.bottom > scrollerRect.bottom) {
          focused.scrollIntoView(false);
        } else if (focusedRect.top < scrollerRect.top) {
          focused.scrollIntoView();
        }
      }
    },
    [getFocusedRowRef]
  );

  const handleSetFocusIndex = useCallback(
    (index: number) => {
      setFocusIndex(index);
      setTimeout(() => {
        if (scroller.current) {
          scrollIntoView(scroller.current);
        }
      }, 0);
    },
    [scrollIntoView]
  );

  useEffect(() => {
    function getIndexOfRowById(id?: string | null) {
      return data.findIndex((item) => id === item.id);
    }

    function setFocusToValue() {
      if (value) {
        const index = getIndexOfRowById(value);

        if (index > -1) {
          handleSetFocusIndex(index);
        }
      }
    }

    if (!isDirty.current) {
      if (value !== null) {
        // Only want to try to scroll into view once for passed value.
        isDirty.current = true;
        setFocusToValue();
      } else {
        setFocusIndex(0);
      }
    }
  }, [data, defaultFocusIndex, handleSetFocusIndex, scrollIntoView, value]);

  function handleKeyDown(e: React.KeyboardEvent) {
    const key = e.key;

    if (
      [
        KeyboardKey.ArrowLeft,
        KeyboardKey.ArrowUp,
        KeyboardKey.ArrowRight,
        KeyboardKey.ArrowDown,
        KeyboardKey.Escape,
        KeyboardKey.Enter,
      ].some((keyboardKey) => keyboardKey === key)
    ) {
      const focused = getFocusedRowRef();
      e.stopPropagation();
      e.preventDefault();

      switch (key) {
        case KeyboardKey.ArrowUp: // up - Move focus up one unless already at the top or the focused row isn't rendered.
          isDirty.current = true;

          if (currentIndex > 0 && focused) {
            handleSetFocusIndex(currentIndex - 1);
          } else if (currentIndex !== 0) {
            handleSetFocusIndex(0); // If the row is unrendered, set the focus to the first row.
          }
          break;
        case KeyboardKey.ArrowDown:
          isDirty.current = true;

          if (currentIndex < data.length - 1) {
            // Don't allow wrapping to the top.
            if (focused) {
              handleSetFocusIndex(currentIndex + 1);
              return;
            }
          }

          if (!focused) {
            // If the row that should be focused is not rendered, set focus to the first row.
            handleSetFocusIndex(defaultFocusIndex ?? 0);
          }
          break;
        case KeyboardKey.ArrowRight:
          // Feels arbitrary that this sets isDirty to false but it's specifically for project picker
          if (onRightKeyDown) {
            isDirty.current = false;
            onRightKeyDown(data[currentIndex], e);
          }
          break;
        case KeyboardKey.ArrowLeft:
          if (onLeftKeyDown) {
            onLeftKeyDown(data[currentIndex], e);
          }
          break;
        case KeyboardKey.Escape:
          closeMenu(true);
          break;
        case KeyboardKey.Enter:
          if (currentIndex > -1 && data[currentIndex]) {
            onSelect(data[currentIndex]);
            closeMenu(true);
          }
          break;
        default:
          return;
      }
    }
  }

  // Wrapper due to needing to attach to focused row to scroll in
  function handleRenderRow(row: T, index: number) {
    const handleRowClick = (e: React.MouseEvent) => {
      onSelect(row, index, e);
      closeMenu(true);
    };

    return (
      <PickerRow key={row.id} onClick={handleRowClick} focused={index === currentIndex}>
        {renderRow(row, index)}
      </PickerRow>
    );
  }

  function setScrollerRefs(scrollerRef: HTMLElement) {
    scroller.current = scrollerRef;
    setScrollerRef?.(scrollerRef);
  }

  const isEmpty = data.length === 0 && loadedAll;

  const classes = classNames('picker-list', className);

  return (
    <div
      className={classes}
      tabIndex={0}
      ref={(el: HTMLDivElement) => (listContainer.current = el)}
      onKeyDown={handleKeyDown}
    >
      {header && (
        <>
          {header(handleKeyDown, setFocusIndex)}
          <Divider />
        </>
      )}
      {error && (
        <Row justify={Justify.CENTER} className="p-4 fc-3">
          {t('Network error')}
        </Row>
      )}

      {!error && isEmpty && (
        <Row justify={Justify.CENTER} className="p-4 fc-3">
          {t('No results')}
        </Row>
      )}

      {!error && (
        <Scroller mode="vertical" className="picker-sheet-list" tag="div" forwardRef={setScrollerRefs}>
          {children(handleRenderRow)}

          {isLoading && (
            <Row justify={Justify.CENTER} className="py-5 no-print">
              <Spinner />
            </Row>
          )}
        </Scroller>
      )}
    </div>
  );
}
