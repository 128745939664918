import { ApolloError } from '@apollo/client';
import { EQUIPMENT_WITH_CATEGORY_QUERY } from 'apollo/queries/equipment-queries';
import classNames from 'classnames';
import { useApolloPaging, useOrganization } from 'hooks';
import { useMounted } from 'hooks/utils/useMounted';
import isNil from 'lodash/isNil';
import uniqBy from 'lodash/uniqBy';
import { useEffect, useState } from 'react';
import ICursorable from 'types/Cursorable';
import IEquipment from 'types/Equipment';
import { getErrorMessage } from 'utils/errorUtils';
import { logError } from 'utils/testUtils';
import BasicPicker, { IBasicPickerProps } from '../../../foundation/pickers/basic-picker/BasicPicker/BasicPicker';

export type IEquipmentCategoryPickerProps = Omit<IBasicPickerProps, 'data'>;

const EquipmentCategoryPicker = ({ className, ...rest }: IEquipmentCategoryPickerProps) => {
  const [data, setData] = useState<IEquipment[]>([]);
  const [error, setError] = useState<string | ApolloError | null | undefined>(null);
  const { getAll } = useApolloPaging();
  const isMounted = useMounted();

  useEffect(() => {
    if (isMounted) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  async function getData() {
    try {
      const equipment = await getAll<IEquipment & ICursorable>('equipment', {
        query: EQUIPMENT_WITH_CATEGORY_QUERY,
        variables: {
          first: 500,
          filter: {
            deletedOn: { isNull: true },
          },
          sort: [{ model: { category: { title: 'asc' } } }],
        },
        fetchPolicy: 'network-only',
      });
      setData(equipment);
    } catch (err: unknown) {
      logError(err);
      setError(getErrorMessage(err));
    }
  }

  const parsed = uniqBy(
    data?.map((datum) => ({ id: datum.model.category.id, name: datum.model.category.title })),
    (category) => category.id
  );

  const classes = classNames('equipment-category-picker', className);

  return <BasicPicker {...rest} data={parsed} className={classes} error={!isNil(error) && error !== ''} />;
};

const EquipmentCategoryPickerContainer = (props: IEquipmentCategoryPickerProps) => {
  const organization = useOrganization();
  if (!organization.trackEquipment) {
    return null;
  }

  return <EquipmentCategoryPicker {...props} />;
};

export default EquipmentCategoryPickerContainer;
