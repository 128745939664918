import { Align, Icon, ITableColumn, Justify, Label, Row, Size, Table, TextAlign } from '@busybusy/webapp-react-ui';
import FlexContainer from '@busybusy/webapp-react-ui/dist/components/FlexContainer/FlexContainer';
import { LockIcon } from 'assets/icons';
import classNames from 'classnames';
import TimeEntryViewBreak from 'components/domain/time-entry-break/TimeEntryViewBreak/TimeEntryViewBreak';
import { ITimeEntryDataTableRow } from 'components/domain/time-entry/TimeEntryDataTable/TimeEntryDataTable';
import { ExpandedTimeCardsPrintDensityType } from 'containers/timesheets/TimesheetPrintOptionsForm/TimesheetPrintOptions';
import { TimeCardReportExpandedBreakFormat } from 'containers/timesheets/TimesheetsSidePanel/hooks/useTimeCardSettings';
import { useOrganization } from 'hooks';
import { t } from 'i18next';
import { groupBy, isEmpty, isNil, sortBy, sumBy, toNumber } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useCallback, useMemo, useRef } from 'react';
import { ClassName } from 'types/ClassName';
import ITimeEntryBreak from 'types/TimeEntryBreak';
import { dateTimeFromISOWithoutZone } from 'utils/dateUtils';
import { inhibitsActions } from 'utils/memberLockUtils';
import { getFormattedPathFromProject } from 'utils/projectUtils';
import { getCostCodeDisplay, getEquipmentDisplay, getTotalAsHoursMinutesSeconds } from 'utils/stringUtils';
import { getTimeOffTypeTitle } from 'utils/timeOffUtils';
import { formatTime } from 'utils/timeUtils';
import { ExpandedTimeCardReportMemberData } from '../../types/types';
import './ExpandedTimeCardsTimeEntriesTable.scss';

export interface IExpandedTimeCardsTimeEntriesTableProps {
  className?: ClassName;
  scroller?: HTMLElement;
  data: ExpandedTimeCardReportMemberData | null;
  onTimeEntryFormDialogOpen: (row: IExpandedTimeCardRowInfo) => void;
  onLockDialogOpen: (row: IExpandedTimeCardRowInfo) => void;
  onTimeEntryViewDialogOpen: (row: IExpandedTimeCardRowInfo) => void;
  onTimeOffFormDialogOpen: (row: IExpandedTimeCardRowInfo) => void;
  onTimeOffViewDialogOpen: (row: IExpandedTimeCardRowInfo) => void;
  showDecimalFormat?: boolean;
  breakFormat: TimeCardReportExpandedBreakFormat;
  printDensity?: ExpandedTimeCardsPrintDensityType;
}

export interface IExpandedTimeCardRowInfo {
  date?: string | null;
  total?: string | null;
  timeData?: ITimeEntryDataTableRow | null;
}

const ExpandedTimeCardsTimeEntriesTable = (props: IExpandedTimeCardsTimeEntriesTableProps) => {
  const {
    className,
    scroller,
    data,
    onTimeEntryFormDialogOpen,
    onLockDialogOpen,
    onTimeEntryViewDialogOpen,
    onTimeOffFormDialogOpen,
    onTimeOffViewDialogOpen,
    showDecimalFormat,
    breakFormat,
    printDensity,
  } = props;

  const org = useOrganization();
  const lockDate = useRef<string | undefined | null>(data?.memberLock?.effectiveDate);

  const formatTimeValue = useCallback(
    (seconds: number): string | undefined => {
      if (seconds > 0) {
        if (!isNil(showDecimalFormat) && showDecimalFormat) {
          return formatTime({ type: 'DECIMAL', seconds: seconds, places: 2 });
        }
        return getTotalAsHoursMinutesSeconds(seconds);
      }
      return undefined;
    },
    [showDecimalFormat]
  );

  const tableData = useMemo(() => {
    if (!isNil(data?.timeEntryData) && !isEmpty(data?.timeEntryData)) {
      const groupedData = groupBy(data?.timeEntryData, (row) => row.date);
      const tableData: IExpandedTimeCardRowInfo[] = [];
      Object.keys(groupedData).forEach((date) => {
        tableData.push({
          date,
          total: formatTimeValue(sumBy(groupedData[date].map((item) => item.totalSeconds))),
        });
        groupedData[date].forEach((item) => {
          tableData.push({
            timeData: {
              ...item,
              breaks: formatTimeValue(item.breakSeconds ?? 0),
            },
          });
        });
      });

      return tableData;
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderEmptyState = useCallback((): ReactNode => {
    return (
      <Row justify={Justify.CENTER} align={Align.CENTER}>
        <Label>{t('No Time Data')}</Label>
      </Row>
    );
  }, []);

  function renderDateRow(row: IExpandedTimeCardRowInfo, col: ITableColumn<IExpandedTimeCardRowInfo>): ReactNode {
    if (!isNil(row.date)) {
      if (col.key === 'date') {
        return (
          <div className="date-row">
            <div className="ml-2 mb-0 p-2">{row.date}</div>
            {lockDate.current &&
            inhibitsActions(
              DateTime.fromISO(lockDate.current),
              DateTime.fromFormat(row.date, 'ccc, LLL d')!.endOf('day').startOf('second')
            ) ? (
              <div className="lock" data-testid="lock_icon">
                <Icon key="lock" size={Size.SMALL} svg={LockIcon} className={'lock-button no-print'} />
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      }
      return (
        <div className="date-row">
          <></>
        </div>
      );
    }
    return <></>;
  }

  function renderDateColumnFooter(): ReactNode {
    return (
      <div className="date-row">
        <div className="ml-2 mb-0 p-2">{t('Total')}</div>
      </div>
    );
  }

  function renderStartRow(row: IExpandedTimeCardRowInfo, _col: ITableColumn<IExpandedTimeCardRowInfo>): ReactNode {
    if (!isNil(row.date)) {
      return (
        <div className="date-row">
          <></>
        </div>
      );
    }
    return (
      <div>
        <div className="ml-2 mb-0 p-2">
          {row.timeData?.start}
          {row.timeData?.startSup ? <sup>{row.timeData?.startSup}</sup> : null}
        </div>
      </div>
    );
  }

  function renderStopRow(row: IExpandedTimeCardRowInfo, _col: ITableColumn<IExpandedTimeCardRowInfo>): ReactNode {
    if (!isNil(row.date)) {
      return (
        <div className="date-row">
          <></>
        </div>
      );
    }
    return (
      <div>
        <div className="ml-2 mb-0 p-2">
          {row.timeData?.end}
          {row.timeData?.endSup ? <sup>{row.timeData?.endSup}</sup> : null}
        </div>
      </div>
    );
  }

  const renderTimeEntryBreakTotal = useCallback(
    (row: IExpandedTimeCardRowInfo) => {
      return (
        <>
          {row.timeData?.breaks ??
            (!isNil(showDecimalFormat) && showDecimalFormat
              ? formatTime({ type: 'DECIMAL', seconds: 0, places: 2 })
              : '00:00')}
        </>
      );
    },
    [showDecimalFormat]
  );

  const renderTimeEntryViewBreaks = useCallback(
    (row: IExpandedTimeCardRowInfo, showTotal = false) => {
      if (!isNil(row.timeData) && !isNil(row.timeData.entry)) {
        const breakEntries = row.timeData.entry.breaks.filter((brk) => {
          const isNotDeleted = brk.deletedOn === null;
          const lessThanEnd = dateTimeFromISOWithoutZone(brk.startTime) < row.timeData!.day.endOf('day');
          const greaterThanStart = brk.endTime
            ? dateTimeFromISOWithoutZone(brk.endTime!) >= row.timeData!.day.startOf('day')
            : DateTime.utc() >= row.timeData!.day.startOf('day');
          return isNotDeleted && lessThanEnd && greaterThanStart;
        });

        return (
          <>
            {!isEmpty(breakEntries) ? (
              sortBy(breakEntries, 'startTime').map((item: ITimeEntryBreak, index: number) => {
                return (
                  <FlexContainer key={index}>
                    <TimeEntryViewBreak
                      entryBreak={item}
                      timeEntry={row.timeData!.entry!}
                      showDecimalFormat={showDecimalFormat}
                    />
                  </FlexContainer>
                );
              })
            ) : (
              <div>{showTotal && renderTimeEntryBreakTotal(row)}</div>
            )}
          </>
        );
      }
      return <></>;
    },
    [renderTimeEntryBreakTotal, showDecimalFormat]
  );

  const renderBreaksRow = useCallback(
    (row: IExpandedTimeCardRowInfo, _col: ITableColumn<IExpandedTimeCardRowInfo>): ReactNode => {
      if (!isNil(row.date)) {
        return (
          <div className="date-row">
            <></>
          </div>
        );
      }
      switch (breakFormat) {
        case 'total':
          return (
            <div>
              <div className="ml-2 mb-0 p-2">{renderTimeEntryBreakTotal(row)}</div>
            </div>
          );
        case 'list':
          return <div className="ml-2 mt-1 mb-0 p-2 activity-row">{renderTimeEntryViewBreaks(row, true)}</div>;
        case 'totalAndList':
          return (
            <div className="ml-2 mt-1 mb-0 p-2 activity-row">
              <div>{renderTimeEntryBreakTotal(row)}</div>
              <div>{renderTimeEntryViewBreaks(row)}</div>
            </div>
          );
      }
      return (
        <div>
          <div className="ml-2 mb-0 p-2">{renderTimeEntryBreakTotal(row)}</div>
        </div>
      );
    },
    [breakFormat, renderTimeEntryBreakTotal, renderTimeEntryViewBreaks]
  );

  const renderActivityRow = useCallback(
    (row: IExpandedTimeCardRowInfo, _col: ITableColumn<IExpandedTimeCardRowInfo>): ReactNode => {
      if (!isNil(row.date)) {
        return (
          <div className="date-row">
            <></>
          </div>
        );
      }
      if (!isNil(row.timeData)) {
        if (!isNil(row.timeData.timeOffType)) {
          return (
            <div className="ml-2 mb-0 p-2 activity-row">
              <FlexContainer>
                <div className="activity-title">{t('Time Off') + ':'}</div>
                <div className="wrap-text time-off-type">{getTimeOffTypeTitle(row.timeData.timeOffType)}</div>
              </FlexContainer>
              {!isEmpty(row.timeData?.description) && (
                <FlexContainer>
                  <div className="activity-title">{t('Description') + ':'}</div>
                  <div className="wrap-text">{row.timeData.description ? row.timeData.description : ''}</div>
                </FlexContainer>
              )}
            </div>
          );
        }

        return (
          <div className="ml-2 mb-0 p-2 activity-row">
            {!isNil(row.timeData?.project) && (
              <FlexContainer>
                <div className="activity-title">{t('Project') + ':'}</div>
                <div className="wrap-text">{getFormattedPathFromProject(row.timeData?.project, true)}</div>
              </FlexContainer>
            )}
            {!isNil(row.timeData?.project?.projectInfo?.number) && (
              <FlexContainer>
                <div className="activity-title">{t('Project #') + ':'}</div>
                <div className="wrap-text">{row.timeData?.project?.projectInfo?.number}</div>
              </FlexContainer>
            )}
            {org.trackCostCode === true && !isNil(row.timeData.costCode) && (
              <FlexContainer>
                <div className="activity-title">{t('Cost Code') + ':'}</div>
                <div className="wrap-text">{getCostCodeDisplay(row.timeData?.costCode)}</div>
              </FlexContainer>
            )}
            {org.trackEquipment === true && !isNil(row.timeData?.equipment) && (
              <FlexContainer>
                <div className="activity-title">{t('Equipment') + ':'}</div>
                <div className="wrap-text">{getEquipmentDisplay(row.timeData?.equipment)}</div>
              </FlexContainer>
            )}
            {!isEmpty(row.timeData?.description) && row.timeData?.description !== '---' && (
              <FlexContainer>
                <div className="activity-title">{t('Description') + ':'}</div>
                <div className="wrap-text">{row.timeData?.description}</div>
              </FlexContainer>
            )}
          </div>
        );
      }
      return <></>;
    },
    [org.trackCostCode, org.trackEquipment]
  );

  function renderTypeRow(row: IExpandedTimeCardRowInfo, _col: ITableColumn<IExpandedTimeCardRowInfo>): ReactNode {
    if (!isNil(row.date)) {
      return (
        <div className="date-row">
          <></>
        </div>
      );
    }
    return (
      <div>
        <div className="ml-2 mb-0 p-2">{row.timeData?.type}</div>
      </div>
    );
  }

  const renderTotalRow = useCallback(
    (row: IExpandedTimeCardRowInfo, _col: ITableColumn<IExpandedTimeCardRowInfo>): ReactNode => {
      if (!isNil(row.date)) {
        return (
          <div className="total-date-row">
            <div className="ml-2 mb-0 p-2">{row.total}</div>
          </div>
        );
      }
      return (
        <div>
          <div className="ml-2 mb-0 p-2">{formatTimeValue(row.timeData?.totalSeconds ?? 0)}</div>
        </div>
      );
    },
    [formatTimeValue]
  );

  function renderColumnFooter(): ReactNode {
    return (
      <div className="date-row">
        <></>
      </div>
    );
  }

  const renderTotalColumnFooter = useCallback((): ReactNode => {
    return (
      <div className="total-date-row">
        <div className="ml-2 mb-0 p-2">{formatTimeValue(data?.totalSeconds ?? 0)}</div>
      </div>
    );
  }, [data?.totalSeconds, formatTimeValue]);

  const tableWidth = () => {
    const columns = getColumns();
    return sumBy(columns, (column) => toNumber(column.size?.replace('px', '') ?? '0')) + 220;
  };

  function handleRowClick(row: IExpandedTimeCardRowInfo) {
    if (!isNil(row.timeData)) {
      const isBehindLock =
        row.timeData.member.memberLock?.effectiveDate &&
        inhibitsActions(DateTime.fromISO(row.timeData.member.memberLock.effectiveDate), row.timeData.endDate);

      if (row.timeData.timeOffType) {
        if (!row.timeData.canManage || row.timeData.isArchived) {
          onTimeOffViewDialogOpen(row);
        } else if (isBehindLock) {
          onLockDialogOpen(row);
        } else {
          onTimeOffFormDialogOpen(row);
        }
      } else {
        if (!row.timeData.canManage || row.timeData.isArchived) {
          onTimeEntryViewDialogOpen(row);
        } else if (isBehindLock) {
          onLockDialogOpen(row);
        } else {
          onTimeEntryFormDialogOpen(row);
        }
      }
    }
  }

  const getColumns = useCallback((): Array<ITableColumn<IExpandedTimeCardRowInfo>> => {
    return [
      {
        cell: renderDateRow,
        key: 'date',
        sort: false,
        title: t('Date'),
        align: TextAlign.LEFT,
        cellClassName: 'table-view-cell-no-border',
        headerClassName: 'table-view-header-no-border',
        footerClassName: 'table-view-cell-no-border',
        footer: renderDateColumnFooter,
      },
      {
        cell: renderStartRow,
        key: 'start',
        sort: false,
        title: t('Start'),
        align: TextAlign.LEFT,
        cellClassName: 'table-view-cell-no-border',
        headerClassName: 'table-view-header-no-border',
        footerClassName: 'table-view-cell-no-border',
        size: '120px',
        footer: renderColumnFooter,
      },
      {
        cell: renderStopRow,
        key: 'stop',
        sort: false,
        title: t('Stop'),
        align: TextAlign.LEFT,
        cellClassName: 'table-view-cell-no-border',
        headerClassName: 'table-view-header-no-border',
        footerClassName: 'table-view-cell-no-border',
        size: '120px',
        footer: renderColumnFooter,
      },
      {
        cell: renderBreaksRow,
        key: 'breaks',
        sort: false,
        title: t('Breaks'),
        align: TextAlign.LEFT,
        cellClassName: 'table-view-cell-no-border',
        headerClassName: 'table-view-header-no-border',
        footerClassName: 'table-view-cell-no-border',
        size: '180px',
        footer: renderColumnFooter,
      },
      {
        cell: renderActivityRow,
        key: 'activity',
        sort: false,
        title: t('Activity'),
        align: TextAlign.LEFT,
        cellClassName: 'table-view-cell-no-border expanded-activity-report-row',
        headerClassName: 'table-view-header-no-border expanded-activity-report-header',
        footerClassName: 'table-view-cell-no-border',
        footer: renderColumnFooter,
      },
      {
        cell: renderTypeRow,
        key: 'type',
        sort: false,
        title: t('Type'),
        align: TextAlign.RIGHT,
        cellClassName: 'table-view-cell-no-border',
        headerClassName: 'table-view-header-no-border',
        footerClassName: 'table-view-cell-no-border',
        size: '100px',
        footer: renderColumnFooter,
      },
      {
        cell: renderTotalRow,
        key: 'total',
        sort: false,
        title: t('Total'),
        align: TextAlign.RIGHT,
        cellClassName: 'table-view-cell-no-border',
        headerClassName: 'table-view-header-no-border',
        footerClassName: 'table-view-cell-no-border',
        size: '150px',
        footer: renderTotalColumnFooter,
      },
    ];
  }, [renderActivityRow, renderBreaksRow, renderTotalColumnFooter, renderTotalRow]);

  const classes = classNames(
    'expanded-time-cards-time-entries-table',
    printDensity === 'standard' ? 'standard' : '',
    className
  );

  return (
    <>
      <Table
        className={classes}
        cols={getColumns()}
        data={tableData}
        strokeCols={true}
        lazyLoad={false}
        emptyTemplate={renderEmptyState()}
        footer="standard"
        header="standard"
        scroller={scroller}
        lazyScrollSectionSize={30}
        minWidth={`${tableWidth()}px`}
        onRowClick={handleRowClick}
      />
    </>
  );
};

export default ExpandedTimeCardsTimeEntriesTable;
