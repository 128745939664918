import { SortDirection } from '@busybusy/webapp-react-ui';
import { SortOrder } from '__generated__/graphql';
import _ from 'lodash';

export function getSortFieldValue<T>(field: T) {
  if (typeof field === 'string') {
    return _.toLower(field);
  } else {
    return field;
  }
}

export function sortByField<T>(items: T[], key: keyof T, order: 'ASC' | 'DESC') {
  return _.orderBy(
    items,
    (item) => {
      const field = item[key];
      return getSortFieldValue(field);
    },
    convertTableSortToOrderBySort(order)
  );
}

export function sortByCustomField<T>(items: T[], order: 'ASC' | 'DESC', getSortField: (item: T) => any) {
  return _.orderBy(
    items,
    (item) => {
      const field = getSortField(item);
      return getSortFieldValue(field);
    },
    convertTableSortToOrderBySort(order)
  );
}

export function convertTableSortToOrderBySort(order: 'ASC' | 'DESC') {
  return order === 'ASC' ? 'asc' : 'desc';
}

export function getApolloSortDirection(direction: SortDirection): SortOrder.Asc | SortOrder.Desc {
  switch (direction) {
    case SortDirection.ASCENDING:
      return SortOrder.Asc;
    case SortDirection.DESCENDING:
      return SortOrder.Desc;
    default:
      throw Error('Invalid direction passed to `getApolloSortValue.`');
  }
}
