export function openInNewTab(url: string) {
  return window.open(url, '_blank');
}

export function openPdfInNewTab(fileUrl: string) {
  const viewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(fileUrl)}&embedded=true`;
  return window.open(viewerUrl, '_blank', 'noopener,noreferrer');
}

export function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return false;
  }
}
