import { ClassName } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ElementType, ReactNode, useRef } from 'react';

export interface IScrollerProps {
  tag: ElementType;
  children: ReactNode;
  mode: 'horizontal' | 'vertical' | 'both';
  onScroll?: (event: React.UIEvent) => void;
  forwardRef?: (ref: HTMLElement) => void;
  className?: ClassName;
}

export default function Scroller({ tag: Tag, children, onScroll, mode, className, forwardRef }: IScrollerProps) {
  function setRefs(element: HTMLElement) {
    forwardRef?.(element);
  }

  const classes = classNames(
    'scroller',
    {
      'overflow-x-auto': mode !== 'vertical',
      'overflow-y-auto': mode !== 'horizontal',
    },
    className
  );

  return (
    <Tag className={classes} ref={setRefs} onScroll={onScroll}>
      {children}
    </Tag>
  );
}
