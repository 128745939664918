import {
  MemberHasTimeOff,
  MemberPermissions,
  QueryMembersWithTimeDocumentsQuery,
  QueryMembersWithTimeDocumentsQueryVariables,
} from '__generated__/graphql';
import { MEMBERS_WITH_TIME_DOCUMENTS_QUERY } from 'apollo/queries/member-queries';
import { ArchivedStatus } from 'components/domain/archived/ArchivedPicker/ArchivedPicker';
import { ITimeEntryDataTableData } from 'components/domain/time-entry/TimeEntryDataTable/hooks/useTimeEntryDataTableRow';
import {
  filterByProjectIdsOrEmptyItemId,
  graphQLContainsIdOrEmptyItemId,
} from 'containers/activity-reports/hooks/ActivitySummaryQueryUtils';
import { TimesheetReportType } from 'containers/timesheets/Timesheets';
import { useToastOpen } from 'contexts/ToastContext';
import { useCostCode } from 'hooks';
import useGraphQLClient from 'hooks/graphql/useGraphQLClient/useGraphQLClient';
import useApolloMemberNameSort from 'hooks/models/member/useApolloMemberNameSort';
import useProject from 'hooks/models/project/useProject';
import useTimeEntryQuery from 'hooks/models/time-entry/useTimeEntryQuery';
import useTimeOffQuery from 'hooks/models/time-off/useTimeOffQuery';
import { Dictionary, groupBy, isEmpty, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import ICursorable from 'types/Cursorable';
import OperationType from 'types/enum/OperationType';
import ITimeEntry from 'types/TimeEntry';
import ITimeOff from 'types/TimeOff';
import ITimeRange from 'types/TimeRange';
import { getApolloArchivedTimestampComparison } from 'utils/archivedUtils';
import { isNilOrEmpty, isNilOrEmptyOrOnlyHasRemainingItem } from 'utils/collectionUtils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { t } from 'utils/localize';
import { logError } from 'utils/testUtils';
import { MemberTimeEntryDataMember } from '../types/types';
import { Nullable } from 'types/util/Nullable';

export interface IMemberTimeEntryFilter {
  timeRange: ITimeRange<DateTime>;
  archivedStatus: ArchivedStatus;
  memberPermissions: MemberPermissions[];
  includeOpenEntries?: boolean;
  memberIds?: Nullable<string[]>;
  memberGroupId?: Nullable<string>;
  projectIds?: Nullable<string[]>;
  costCodeIds?: Nullable<string[]>;
  equipmentIds?: Nullable<string[]>;
  positionId?: Nullable<string>;
  reportType?: Nullable<TimesheetReportType>;
  projectIdWithDescendants?: boolean;
  costCodeGroupId?: Nullable<string>;
  projectGroupId?: Nullable<string>;
}

export function useMemberTimeData({
  timeRange,
  memberIds,
  memberGroupId,
  projectIds,
  costCodeIds,
  equipmentIds,
  positionId,
  reportType,
  includeOpenEntries: includeOpen,
  memberPermissions,
  archivedStatus,
  projectIdWithDescendants,
  costCodeGroupId,
  projectGroupId,
}: IMemberTimeEntryFilter) {
  const graphqlClient = useGraphQLClient();
  const toast = useToastOpen();
  const getTimeEntries = useTimeEntryQuery();
  const getTimeOffs = useTimeOffQuery();
  const getMemberNameSort = useApolloMemberNameSort();
  const { getProjects } = useProject();
  const { getCostCodes } = useCostCode();

  const getMemberVariables = useCallback(
    async (first?: number, cursor?: string | null) => {
      const variables: any = {
        first,
        filter: {
          permissions: {
            permissions: memberPermissions,
            operationType: OperationType.AND,
            includeSelf: true,
          },
          memberGroupId: memberGroupId ? { equal: memberGroupId } : undefined,
          positionId: positionId ? { equal: positionId } : undefined,
          id: !isNilOrEmpty(memberIds) ? { contains: memberIds } : undefined,
          archivedOn: getApolloArchivedTimestampComparison(archivedStatus ?? 'unarchived'),
        },
        sort: getMemberNameSort('asc'),
        memberTimeDocumentFilter: {
          startTime: { equal: timeRange.startTime.toISO() },
          endTime: { equal: timeRange.endTime.toISO() },
          deletedOn: { isNull: true },
        },
        memberTimeDocumentSort: [{ submittedOn: 'desc' }],
      };

      // optimize report by only loading the members that will show up
      // no need to optimize if we are scoped to certain members
      if (isNilOrEmpty(memberIds)) {
        if (
          reportType === TimesheetReportType.TIME_OFF_BOTH ||
          reportType === TimesheetReportType.TIME_OFF_PAID ||
          reportType === TimesheetReportType.TIME_OFF_UNPAID
        ) {
          // we're only showing time off
          let paid = undefined;
          // only set paid if we're looking at just paid or just unpaid time off
          if (reportType === TimesheetReportType.TIME_OFF_PAID || reportType === TimesheetReportType.TIME_OFF_UNPAID) {
            paid = reportType === TimesheetReportType.TIME_OFF_PAID;
          }

          variables.filter.timeOffs = {
            deletedOn: { isNull: true },
            dateStamp: {
              lessThanOrEqual: timeRange.endTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
              greaterThanOrEqual: timeRange.startTime
                .toUTC()
                .toISO({ suppressMilliseconds: true, includeOffset: false }),
            },
            paid: paid !== undefined ? { equal: paid } : undefined,
          };
        } else if (reportType === TimesheetReportType.ENTRY_AND_TIME_OFF_PAID) {
          // we're showing time entries and time off in the activity reports
          variables.filter.hasTime = {
            startTime: timeRange.startTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: !isNil(includeOpen) ? includeOpen : false,
            paidTimeOff:
              isNilOrEmptyOrOnlyHasRemainingItem(costCodeIds) &&
              isNilOrEmptyOrOnlyHasRemainingItem(equipmentIds) &&
              isNilOrEmptyOrOnlyHasRemainingItem(projectIds) &&
              !isNil(projectGroupId) &&
              !isNil(costCodeGroupId)
                ? true
                : null,
            costCodeId: graphQLContainsIdOrEmptyItemId(costCodeIds),
            equipmentId: graphQLContainsIdOrEmptyItemId(equipmentIds),
            ...filterByProjectIdsOrEmptyItemId(projectIds, projectIdWithDescendants),
          };
        } else if (reportType === TimesheetReportType.ENTRY_AND_ALL_TIME_OFF) {
          variables.filter.hasTime = {
            startTime: timeRange.startTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: !isNil(includeOpen) ? includeOpen : false,
            hasTimeOff: MemberHasTimeOff.All, // show both paid and unpaid time off
          };
        } else if (
          (!isNil(includeOpen) || !includeOpen) &&
          (!isNilOrEmpty(projectIds) ||
            !isNilOrEmpty(costCodeIds) ||
            !isNilOrEmpty(equipmentIds) ||
            !isNil(projectGroupId) ||
            !isNil(costCodeGroupId) ||
            reportType === TimesheetReportType.ENTRY)
        ) {
          const hasTimeProjectFilter = async () => {
            let targetProjectIds: string[] = [];

            if (!isNil(projectGroupId)) {
              const rootGroupProjects = await getProjects(projectGroupId);
              targetProjectIds.push(...rootGroupProjects.map((p) => p.id));
            }

            if (!isNilOrEmpty(projectIds)) {
              targetProjectIds = targetProjectIds.concat(projectIds ?? []);
            }

            if (!isEmpty(targetProjectIds)) {
              if (targetProjectIds.includes(remainingDataItemId)) {
                return { isNull: true };
              }

              return {
                contains: targetProjectIds,
              };
            }

            return undefined;
          };

          const hasTimeCostCodeFilter = async () => {
            let targetCostCodeIds: string[] = [];

            if (!isNil(costCodeGroupId)) {
              const costCodesInGroup = await getCostCodes(costCodeGroupId);
              targetCostCodeIds.push(...costCodesInGroup.map((p) => p.id));
            }

            if (!isNilOrEmpty(costCodeIds)) {
              targetCostCodeIds = targetCostCodeIds.concat(costCodeIds ?? []);
            }

            if (!isEmpty(targetCostCodeIds)) {
              if (targetCostCodeIds.includes(remainingDataItemId)) {
                return { isNull: true };
              }
              return {
                contains: targetCostCodeIds,
              };
            }

            return undefined;
          };

          // we're only showing time entries
          variables.filter.hasTime = {
            startTime: timeRange.startTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: !isNil(includeOpen) ? includeOpen : false,
            equipmentId: graphQLContainsIdOrEmptyItemId(equipmentIds),
            costCodeId: await hasTimeCostCodeFilter(),
            projectId: projectIdWithDescendants !== true ? await hasTimeProjectFilter() : undefined,
            projectIdWithDescendants: projectIdWithDescendants === true ? await hasTimeProjectFilter() : undefined,
          };
        } else if (isNil(reportType)) {
          // we're showing time entries and time off
          variables.filter.hasTime = {
            startTime: timeRange.startTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: !isNil(includeOpen) ? includeOpen : false,
            paidTimeOff: true,
          };
        }
      }

      if (cursor) {
        variables.after = cursor;
      }

      return variables;
    },
    [
      memberPermissions,
      memberGroupId,
      positionId,
      memberIds,
      archivedStatus,
      getMemberNameSort,
      timeRange.startTime,
      timeRange.endTime,
      reportType,
      includeOpen,
      projectIds,
      costCodeIds,
      equipmentIds,
      projectGroupId,
      costCodeGroupId,
      projectIdWithDescendants,
      getProjects,
      getCostCodes,
    ]
  );

  const getMemberData = useCallback(
    async (cursor: string | null, first: number) => {
      const results = await graphqlClient.request<
        QueryMembersWithTimeDocumentsQuery,
        QueryMembersWithTimeDocumentsQueryVariables
      >({
        document: MEMBERS_WITH_TIME_DOCUMENTS_QUERY,
        variables: await getMemberVariables(first, cursor),
      });

      return results.members ?? [];
    },
    [graphqlClient, getMemberVariables]
  );

  const addTimeData = useCallback(
    async (members: MemberTimeEntryDataMember[]) => {
      let groupedEntries: Dictionary<ITimeEntry[]> | null = null;
      let groupedTimeOffs: Dictionary<ITimeOff[]> | null = null;

      const memberIds = members.map((m) => m.id);

      if (isNil(reportType) || reportType === TimesheetReportType.ENTRY_AND_ALL_TIME_OFF) {
        try {
          const entries = await getTimeEntries(
            timeRange,
            includeOpen,
            memberIds,
            projectIds ?? undefined,
            costCodeIds ?? undefined,
            equipmentIds ?? undefined,
            projectIdWithDescendants,
            undefined,
            undefined,
            costCodeGroupId,
            projectGroupId,
            200
          );

          groupedEntries = groupBy(entries, 'memberId');
          if (
            isNilOrEmptyOrOnlyHasRemainingItem(projectIds) &&
            isNilOrEmptyOrOnlyHasRemainingItem(costCodeIds) &&
            isNilOrEmptyOrOnlyHasRemainingItem(equipmentIds) &&
            isNil(projectGroupId) &&
            isNil(costCodeGroupId)
          ) {
            const timeOffs = await getTimeOffs(timeRange, memberIds);
            groupedTimeOffs = groupBy(timeOffs, 'memberId');
          }
        } catch (error) {
          logError(error as Error);
          toast({ label: t('Something went wrong. Please try again later.') });
        }
      } else if (reportType === TimesheetReportType.ENTRY) {
        try {
          const entries = await getTimeEntries(
            timeRange,
            includeOpen,
            memberIds,
            projectIds ?? undefined,
            costCodeIds ?? undefined,
            equipmentIds ?? undefined,
            projectIdWithDescendants,
            undefined,
            undefined,
            costCodeGroupId,
            projectGroupId,
            200
          );
          groupedEntries = groupBy(entries, 'memberId');
        } catch (error) {
          logError(error as Error);
          toast({ label: t('Something went wrong. Please try again later.') });
        }
      } else if (
        reportType === TimesheetReportType.TIME_OFF_BOTH ||
        reportType === TimesheetReportType.TIME_OFF_PAID ||
        reportType === TimesheetReportType.TIME_OFF_UNPAID
      ) {
        // we're only showing time off
        let paid;
        // only set paid if we're looking at just paid or just unpaid time off
        if (reportType === TimesheetReportType.TIME_OFF_PAID || reportType === TimesheetReportType.TIME_OFF_UNPAID) {
          paid = reportType === TimesheetReportType.TIME_OFF_PAID;
        }

        try {
          const timeOffs = await getTimeOffs(timeRange, memberIds, paid);
          groupedTimeOffs = groupBy(timeOffs, 'memberId');
        } catch (error) {
          logError(error as Error);
          toast({ label: t('Something went wrong. Please try again later.') });
        }
      } else if (reportType === TimesheetReportType.ENTRY_AND_TIME_OFF_PAID) {
        try {
          const entries = await getTimeEntries(
            timeRange,
            includeOpen,
            memberIds,
            projectIds ?? undefined,
            costCodeIds ?? undefined,
            equipmentIds ?? undefined,
            projectIdWithDescendants,
            undefined,
            undefined,
            costCodeGroupId,
            projectGroupId,
            200
          );
          groupedEntries = groupBy(entries, 'memberId');
          const timeOffs = await getTimeOffs(timeRange, memberIds, true);
          groupedTimeOffs = groupBy(timeOffs, 'memberId');
        } catch (error) {
          logError(error as Error);
          toast({ label: t('Something went wrong. Please try again later.') });
        }
      }

      const allData = members.map((member) => {
        const item: ITimeEntryDataTableData<MemberTimeEntryDataMember> & ICursorable = {
          item: member,
          entries: groupedEntries?.[member.id] ?? [],
          timeOffs: groupedTimeOffs?.[member.id] ?? [],
          cursor: member.cursor,
        };
        return item;
      });
      if (!isNilOrEmpty(memberIds) && memberIds.length === 1) {
        return allData;
      }
      return allData.filter((item) => !isEmpty(item.entries) || !isEmpty(item.timeOffs));
    },
    [
      costCodeGroupId,
      costCodeIds,
      equipmentIds,
      getTimeEntries,
      getTimeOffs,
      includeOpen,
      projectGroupId,
      projectIdWithDescendants,
      projectIds,
      reportType,
      timeRange,
      toast,
    ]
  );

  return useCallback(
    async (cursor: string | null, first: number) => {
      try {
        const members = await getMemberData(cursor, first);

        if (isEmpty(members)) {
          return [];
        }

        return addTimeData(members);
      } catch (error) {
        logError(error as Error);
        toast({ label: t('Something went wrong. Please try again later.') });
      }

      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getMemberData, addTimeData, toast, timeRange]
  );
}
