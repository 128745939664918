import { Checkbox, Size, Theme } from '@busybusy/webapp-react-ui';
import { SettingsIcon } from 'assets/icons';
import classNames from 'classnames';
import IconButton from 'components/foundation/buttons/IconButton/IconButton';
import {
  DashboardSettingKey,
  defaultDashboardSettings,
  isDashboardSettingsKey,
} from 'containers/dashboard/types/types';
import { isNil } from 'lodash';
import * as React from 'react';
import { ClassName } from 'types/ClassName';
import { IDictionary } from 'types/Dictionary';
import { t } from 'utils/localize';
import { DashboardTimeType } from '../DashboardTimeSelect/DashboardTimeSelectFormField';
import './DashboardSettingsItem.scss';

export interface IDashboardSettingsItemProps<Key extends string = string> {
  item: IDashboardSettingsItem<Key>;
  onCheckChange: (checked: boolean) => void;
  onSettingsIconClicked: (item: IDashboardSettingsItem<Key>) => void;
  disabled?: boolean;
  className?: ClassName;
}

export interface IDashboardSettingsItem<Key extends string = string> {
  key: Key;
  title: string;
  visible: boolean;
  position: number;
  customizable: boolean;
  options?: IDictionary<any>;
}

function DashboardSettingsItem<Key extends string = string>(props: IDashboardSettingsItemProps<Key>) {
  const { item, onCheckChange, onSettingsIconClicked, className, disabled } = props;
  const isDefaultDashboardKey = isDashboardSettingsKey(item.key);
  const defaultSettings = defaultDashboardSettings;
  const defaultItem = isDefaultDashboardKey ? defaultSettings[item.key as DashboardSettingKey] : item;

  const handleSettingsAction = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    onSettingsIconClicked(item);
  };

  function getOptionsText() {
    if (!isNil(defaultItem.options?.time)) {
      switch (item.options?.time) {
        case DashboardTimeType.PAST_7_DAYS:
          return t('Past 7 days');
        case DashboardTimeType.PAST_14_DAYS:
          return t('Past 14 days');
        case DashboardTimeType.WEEKLY:
          return t('Weekly');
        case DashboardTimeType.MONTHLY:
          return t('Monthly');
        case DashboardTimeType.PAY_PERIOD:
          return t('Pay Period');
        default:
          return t('Daily');
      }
    } else if (!isNil(defaultItem.options?.timeOffTimeFrame)) {
      switch (item.options?.timeOffTimeFrame) {
        case '7 days':
          return t('Next 7 days');
        case '30 days':
          return t('Next 30 days');
        default:
          return t('Upcoming');
      }
    }
    return '';
  }

  const classes = classNames('dashboard-settings-item py-2 ml-2', className);

  return (
    <div className={classes}>
      <Checkbox
        data-testid="settings_checkbox"
        className="checkbox-item"
        checked={item.visible}
        onChange={onCheckChange}
        label={item.title}
        disabled={disabled}
      />
      {getOptionsText()}

      {defaultItem.customizable && (
        <IconButton
          className="gears-icon"
          onClick={handleSettingsAction}
          svg={SettingsIcon}
          theme={Theme.LIGHT}
          buttonSize={Size.SMALL}
          tooltipLabel={t('Settings')}
          disabled={disabled}
        />
      )}
    </div>
  );
}

export default DashboardSettingsItem;
