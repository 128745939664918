import ActivityReportTableDataLoader from 'containers/activity-reports/types/ActivityReportTableDataLoader';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { LaborMetricsInterval } from '__generated__/graphql';
import { ActivityReportType } from '../../ActivityReportFilter/ActivityReportFilter';
import { IEquipmentActivityTableRowInfo } from './useEquipmentActivity';
import useEquipmentActivityBasicDetails from './useEquipmentActivityBasicDetails';
import useEquipmentActivityCostCodeDetails from './useEquipmentActivityCostCodeDetails';
import useEquipmentActivityDateRangeDetails from './useEquipmentActivityDateRangeDetails';
import useEquipmentActivityMemberDetails from './useEquipmentActivityMemberDetails';
import useEquipmentActivityProjectDetails from './useEquipmentActivityProjectDetails';

export default function useEquipmentActivityDetails(
  filterId: string,
  filterType: ActivityReportType,
  secondaryFilterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>,
  filterIdWithDescendants?: boolean,
  dateRangeReportType?: TimeRangeType
): ActivityReportTableDataLoader<IEquipmentActivityTableRowInfo> {
  const [isLoading, setIsLoading] = useState(false);

  const dateRangeInterval = useMemo(() => {
    if (secondaryFilterType === ActivityReportType.BY_DAY) {
      return LaborMetricsInterval.Day;
    }

    if (dateRangeReportType === TimeRangeType.PAY_PERIOD) {
      return LaborMetricsInterval.PayPeriod;
    }

    if (dateRangeReportType === TimeRangeType.MONTHLY) {
      return LaborMetricsInterval.Month;
    }

    return LaborMetricsInterval.OvertimePeriod;
  }, [secondaryFilterType, dateRangeReportType]);

  const basicDetails = useEquipmentActivityBasicDetails(filterId, filterType, timeRange, filterIdWithDescendants);
  const projectDetails = useEquipmentActivityProjectDetails(filterId, filterType, timeRange);
  const costCodeDetails = useEquipmentActivityCostCodeDetails(filterId, filterType, timeRange, filterIdWithDescendants);
  const dateRangeDetails = useEquipmentActivityDateRangeDetails(
    filterId,
    filterType,
    timeRange,
    dateRangeInterval,
    filterIdWithDescendants
  );
  const memberDetails = useEquipmentActivityMemberDetails(filterId, filterType, timeRange, filterIdWithDescendants);

  const dataLoader = useMemo(() => {
    if (secondaryFilterType === ActivityReportType.BASIC) {
      return basicDetails;
    } else if (secondaryFilterType === ActivityReportType.BY_EMPLOYEE) {
      return memberDetails;
    } else if (secondaryFilterType === ActivityReportType.BY_DATE_RANGE) {
      return dateRangeDetails;
    } else if (secondaryFilterType === ActivityReportType.BY_DAY) {
      return dateRangeDetails;
    } else if (secondaryFilterType === ActivityReportType.BY_COST_CODE) {
      // load from cost code details
      return costCodeDetails;
    } else if (secondaryFilterType === ActivityReportType.BY_PROJECT) {
      // load from project details
      return projectDetails;
    } else {
      throw Error('Not supported secondary report type when loading equipment: ' + secondaryFilterType);
    }
  }, [basicDetails, projectDetails, costCodeDetails, dateRangeDetails, memberDetails]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRange, filterType, filterId, secondaryFilterType, dateRangeInterval]);

  async function loadData() {
    setIsLoading(true);
    await dataLoader.loadData();
    setIsLoading(false);
  }

  return {
    isLoading: isLoading,
    sortedData: dataLoader.sortedData,
    onSort: dataLoader.onSort,
    sortedBy: dataLoader.sortedBy as keyof IEquipmentActivityTableRowInfo,
    sortDirection: dataLoader.sortDirection,
    sortIsDirty: dataLoader.sortIsDirty,
    remainingData: dataLoader.remainingData,
  };
}
