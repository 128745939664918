import { ClassName, Col, Label } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import useMemberSettings from 'hooks/models/member/useMemberSettings';

export interface IFormattedProjectNameProps {
  className?: ClassName;
  title: string;
  number: string;
}

export default function FormattedProjectName({ className, title, number }: IFormattedProjectNameProps) {
  const classes = classNames('formatted-project-name', className);

  const memberSettings = useMemberSettings();

  return (
    <Col className={classes} align="start">
      <Label className="project-name mb-0">{title}</Label>
      {memberSettings?.web?.features?.activityReport?.showProjectNumber && (
        <Label className="project-name fc-2 mb-0">{number}</Label>
      )}
    </Col>
  );
}
