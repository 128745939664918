import { useApolloClient } from '@apollo/client';
import { Avatar, Button, Icon, Image, Justify, Label, Link, Row, Size, Tray } from '@busybusy/webapp-react-ui';
import { SAFETY_SIGNATURES_DETAILS_QUERY } from 'apollo/queries/safety-signature-query';
import { SIMPLE_TIME_ENTRIES_QUERY } from 'apollo/queries/time-entry-queries';
import { FlagIcon, SafetyIcon } from 'assets/icons';
import classNames from 'classnames';
import { FormAccordion, HeaderDialog } from 'components';
import DailySignOffFormDialog from 'components/domain/daily-sign-off/DailySignOff/DailySignOffForm/DailySignOffFormDialog';
import ReportTimeEntriesDialog from 'components/domain/time-entry/dialog/BreakHoursReportTimeEntriesDialog/BreakHoursReportTimeEntriesDialog';
import YesNo from 'components/foundation/YesNo/YesNo';
import PseudoLinkText from 'components/foundation/text/PseudoLinkText/PseudoLinkText';
import ReviewSafetyIncidentDialog from 'containers/safety-incident-reports/ReviewSafetyIncidentDialog/ReviewSafetyIncidentDialog';
import { ISafetyIncidentsOshaRowItem } from 'containers/safety-incident-reports/SafetyIncidentsOsha300Table/SafetyIncidentsOsha300Table';
import { ISafetyIncidentsReportedRowItem } from 'containers/safety-incident-reports/SafetyIncidentsReportedTable/SafetyIncidentsReportedTable';
import SubmitSafetyIncidentForm from 'containers/safety-incident-reports/SubmitSafetyIncidentForm/SubmitSafetyIncidentForm';
import { useCreateSafetyIncidentsReportedRowItem } from 'containers/safety-incident-reports/hooks/useCreateSafetyIncidentsReportedRowItem';
import HeaderText from 'containers/settings/components/HeaderText/HeaderText';
import { useOpenable, usePermissions, useSafetySignature } from 'hooks';
import useMemberSignOffAnswer from 'hooks/models/member-sign-off-answer/useMemberSignOffAnswer';
import { useCustomSignOffEnabled } from 'hooks/models/organization/useCustomSignOffEnabled';
import { useActiveMember, useOrganization } from 'hooks/store';
import _, { first, isEmpty, isNil, isNull, last } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { IMember } from 'types';
import { ClassName } from 'types/ClassName';
import ISafetySignature from 'types/SafetySignature';
import ITimeEntry from 'types/TimeEntry';
import { dateUtils } from 'utils';
import { dateTimeFromISOKeepZone, dateTimeFromISOWithoutZone, dateTimeFromUtcISO } from 'utils/dateUtils';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import { fullName } from 'utils/memberUtils';
import { getSafeString } from 'utils/optionalUtils';
import ArchivedEmployeeWarningDialog from '../ArchivedEmployeeWarningDialog/ArchivedEmployeeWarningDialog';
import DailySignOffResolveCustomQuestionDialog from '../DailySignOffResolveCustomQuestionDialog/DailySignOffResolveCustomQuestionDialog';
import DailySignOffResolveDialog from '../DailySignOffResolveDialog/DailySignOffResolveDialog';
import DailySignOffResolveInjuryDialog from '../DailySignOffResolveInjuryDialog/DailySignOffResolveInjuryDialog';
import './DailySignOffReportDetailsForm.scss';

export interface IDailySignOffReportDetailsFormProps {
  className?: ClassName;
  member: IMember;
  currentDate: DateTime;
  onShouldRefresh?: () => void;
}

const DailySignOffReportDetailsForm = ({
  className,
  member,
  currentDate,
  onShouldRefresh,
}: IDailySignOffReportDetailsFormProps) => {
  const [data, setData] = useState<ISafetySignature[]>([]);
  const latestTimeEntry = useRef<ITimeEntry>();
  const client = useApolloClient();
  const loading = useRef<boolean>(true);
  const organization = useOrganization();
  const activeMember = useActiveMember();
  const canManageIncident = activeMember.position?.manageIncident ?? false;
  const canCreateIncident = activeMember.position?.createIncident ?? false;
  const timeEntryDialogOpenable = useOpenable();
  const resolveTimeAccuracyIssueDialogOpenable = useOpenable();
  const resolveBreakPolicyIssueDialogOpenable = useOpenable();
  const resolveCustomQuestionDialogOpenable = useOpenable();
  const signOffOpenable = useOpenable();
  const resolveInjuryDialogOpenable = useOpenable();
  const archiveDialog = useOpenable();
  const createIncidentDialog = useOpenable();
  const reviewIncidentDialog = useOpenable();
  const resolvingSubmission = useRef<ISafetySignature>();
  const resolvingCustomQuestionAnswerId = useRef<string>();
  const { resolveSafetySignature } = useSafetySignature();
  const { resolveSignOffAnswer } = useMemberSignOffAnswer();
  const dailySignOffResolutionEnabled = useFeatureFlags('DAILY_SIGN_OFF_RESOLUTION');
  const { hasPermissionToManage } = usePermissions();
  const canManageTime = useRef<boolean>(hasPermissionToManage(member, 'manageTimeEntries'));
  const isCustomSignOffEnabled = useCustomSignOffEnabled();
  const isSafetyIncidentsEnabled = useFeatureFlags('SAFETY_INCIDENTS');
  const [incidentRow, setIncidentRow] = useState<ISafetyIncidentsReportedRowItem | ISafetyIncidentsOshaRowItem>();
  const createRows = useCreateSafetyIncidentsReportedRowItem();

  useEffect(() => {
    getData();
  }, [member]);

  async function getData() {
    loading.current = true;
    const signaturesResult = await client.query<{
      safetySignatures: ISafetySignature[];
    }>({
      query: SAFETY_SIGNATURES_DETAILS_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 500,
        filter: {
          endTime: {
            lessThanOrEqual: currentDate
              .endOf('day')
              .toUTC(0, { keepLocalTime: true })
              .toISO({ suppressMilliseconds: true, includeOffset: false }), // TODO: Might not need .UTC bruh
            greaterThanOrEqual: currentDate
              .startOf('day')
              .toUTC(0, { keepLocalTime: true })
              .toISO({ suppressMilliseconds: true, includeOffset: false }),
          },
          memberId: { equal: member.id },
          deletedOn: { isNull: true },
        },
        sort: [{ createdOn: 'asc' }],
      },
    });

    const latestTimeEntryQuery = await client.query<{
      timeEntries: ITimeEntry[];
    }>({
      query: SIMPLE_TIME_ENTRIES_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 1,
        filter: {
          startTime: {
            lessThanOrEqual: currentDate
              .endOf('day')
              .toUTC(0, { keepLocalTime: true })
              .toISO({ suppressMilliseconds: true, includeOffset: false }), // TODO: Might not need .UTC bruh
            greaterThanOrEqual: currentDate
              .startOf('day')
              .toUTC(0, { keepLocalTime: true })
              .toISO({ suppressMilliseconds: true, includeOffset: false }),
          },
          endTime: { isNull: false },
          memberId: { equal: member.id },
          deletedOn: { isNull: true },
        },
        sort: [{ endTime: 'desc' }],
      },
    });

    latestTimeEntry.current = first(latestTimeEntryQuery.data.timeEntries);
    loading.current = false;
    setData(signaturesResult.data.safetySignatures);
  }

  const classes = classNames('sign-off-report-details-form', 'm-5', { 'data-loading': loading.current }, className);

  function renderHeader() {
    return (
      <Row className="mx-2" justify={Justify.SPACE_BETWEEN}>
        <Tray className="mb-8">
          <Avatar
            firstName={getSafeString(member.firstName)}
            lastName={getSafeString(member.lastName)}
            size={Size.MEDIUM}
            image={member.imageUrl ?? undefined}
          />
          <Label className="ml-4">{fullName(member)}</Label>
        </Tray>
        {needsToSign() && (
          <Button className="mt-2" type="primary" onClick={signOffOpenable.open}>
            {t('Sign Now')}
          </Button>
        )}
      </Row>
    );
  }

  function renderEmptyState() {
    return (
      <div className="no-results-card mb-4">
        <Label className="no-results-label">{t('Sign Off Required')}</Label>
        {t(
          'Click on the "Sign Now" button above, or use the Daily Sign-Off Report on a mobile device to have the employee complete the sign-off process.'
        )}
      </div>
    );
  }

  function getSubmittedTimeString(submittedTime: DateTime) {
    if (dateUtils.isSameDay(currentDate, submittedTime)) {
      return submittedTime.toFormat('t');
    } else {
      return submittedTime.toFormat('t (MMM d)');
    }
  }

  function onCompleteSignOff() {
    signOffOpenable.close();
    refreshFromDataChange();
  }

  function refreshFromDataChange() {
    getData();
    if (onShouldRefresh) {
      onShouldRefresh();
    }
  }

  function onResolvedInjury(comment: string) {
    resolveSafetySignature(resolvingSubmission.current!, activeMember.id!, comment).then(refreshFromDataChange);
  }

  function onResolvedTimeInaccuracy(comment: string) {
    if (isLatestEntry('timeAccurate')) {
      data.map((d) => {
        resolveSafetySignature(d, undefined, undefined, activeMember.id!, comment).then(refreshFromDataChange);
      });
    } else {
      resolveSafetySignature(resolvingSubmission.current!, undefined, undefined, activeMember.id!, comment).then(
        refreshFromDataChange
      );
    }
  }

  function onResolvedBreakPolicy(comment: string) {
    if (isLatestEntry('breakPolicy')) {
      data.map((d) => {
        resolveSafetySignature(d, undefined, undefined, undefined, undefined, activeMember.id!, comment).then(
          refreshFromDataChange
        );
      });
    } else {
      resolveSafetySignature(
        resolvingSubmission.current!,
        undefined,
        undefined,
        undefined,
        undefined,
        activeMember.id!,
        comment
      ).then(refreshFromDataChange);
    }
  }

  function onResolvedCustomQuestion(comment: string) {
    resolveSignOffAnswer(resolvingCustomQuestionAnswerId.current!, comment)
      .then(() => {
        if (
          resolvingSubmission.current?.customAnswers
            .filter((answer) => !(answer.id === resolvingCustomQuestionAnswerId.current))
            .every((answer) => !answer.flagged || !isNil(answer.resolvedOn))
        ) {
          resolveSafetySignature(
            resolvingSubmission.current!,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            activeMember.id!
          ).then(refreshFromDataChange);
        }
      })
      .then(refreshFromDataChange);
  }

  function handleFixEntriesClick() {
    if (member.archivedOn) {
      archiveDialog.open();
    } else {
      timeEntryDialogOpenable.open();
    }
  }

  const handleCreateIncidentForm = () => {
    createIncidentDialog.close();
    refreshFromDataChange();
  };

  function renderInjuryButtons(submission: ISafetySignature): ReactNode {
    if (dailySignOffResolutionEnabled && submission.injured) {
      if (isSafetyIncidentsEnabled && canCreateIncident && !submission.injuredResolvedOn) {
        if (!isNull(submission.incident)) {
          return <></>;
        }
        return (
          <>
            <Button
              type={'primary'}
              onClick={() => {
                resolvingSubmission.current = submission;
                createIncidentDialog.open();
              }}
            >
              {t('File Report')}
            </Button>
            <Button
              type={'secondary'}
              className={'mx-3'}
              onClick={() => {
                resolvingSubmission.current = submission;
                resolveInjuryDialogOpenable.open();
              }}
            >
              {t('No Report Required')}
            </Button>
          </>
        );
      } else if (canManageTime.current && !submission.injuredResolvedOn) {
        return (
          <Button
            type={'primary'}
            onClick={() => {
              resolvingSubmission.current = submission;
              resolveInjuryDialogOpenable.open();
            }}
          >
            {t('Process')}
          </Button>
        );
      }
    }
    return <></>;
  }

  function renderInjuryResolvedStatus(submission: ISafetySignature): ReactNode {
    if (dailySignOffResolutionEnabled && submission.injured && submission.injuredResolvedOn) {
      if (isSafetyIncidentsEnabled) {
        if (!isNil(submission.incident)) {
          return (
            <>
              <HeaderText title={t('Report Filed')} className="mt-5 mb-5">
                {dateTimeFromUtcISO(submission.incident.occurredAt).toFormat('DD')}
                {canManageIncident && (
                  <>
                    <br />
                    <Link
                      onClick={() => {
                        setIncidentRow(first(createRows(!isNil(submission.incident) ? [submission.incident] : [])));
                        reviewIncidentDialog.open();
                      }}
                      href="#"
                    >
                      {t('View Report')}
                    </Link>
                  </>
                )}
              </HeaderText>
            </>
          );
        } else if (submission.injuredResolvedOn) {
          return (
            <>
              <HeaderText title={t('Processed')} className="mt-5 mb-5">
                {dateTimeFromUtcISO(submission.injuredResolvedOn).toFormat('t D')}
                <br />
                {submission.injuredResolvedByMember ? fullName(submission.injuredResolvedByMember!) : '---'}
              </HeaderText>
              <HeaderText title={t('Notes')} className="notes mt-5 mb-5">
                {isEmpty(submission.injuredResolvedComment) ? '---' : submission.injuredResolvedComment}
              </HeaderText>
              <HeaderText title={t('No Report Needed')} className="mt-5 mb-5">
                {canCreateIncident && (
                  <>
                    <Link
                      onClick={() => {
                        resolvingSubmission.current = submission;
                        createIncidentDialog.open();
                      }}
                      href="#"
                    >
                      {t('File Report Anyway')}
                    </Link>
                  </>
                )}
              </HeaderText>
            </>
          );
        }
      } else {
        return (
          <>
            <HeaderText title={t('Processed')} className="mt-5 mb-5">
              {dateTimeFromUtcISO(submission.injuredResolvedOn).toFormat('t D')}
              <br />
              {submission.injuredResolvedByMember ? fullName(submission.injuredResolvedByMember!) : '---'}
            </HeaderText>
            <HeaderText title={t('Notes')} className="notes mt-5 mb-5">
              {isEmpty(submission.injuredResolvedComment) ? '---' : submission.injuredResolvedComment}
            </HeaderText>
          </>
        );
      }
    }
    return <></>;
  }

  function renderSubmissionResults(submission: ISafetySignature) {
    return (
      <>
        {organization.safetySignature && (
          <div
            className={`details-card ${
              submission.injured && !submission.injuredResolvedOn ? 'details-card-error' : ''
            }`}
          >
            <HeaderText title={t('Were you injured?')}>
              <YesNo value={submission.injured} />
            </HeaderText>
            {submission.injured && (
              <HeaderText title={t('Please describe your injury')} className="notes mt-5 mb-5">
                {submission.injuryDescription ? submission.injuryDescription : '---'}
              </HeaderText>
            )}
            {renderInjuryButtons(submission)}
            {renderInjuryResolvedStatus(submission)}
          </div>
        )}

        {organization.breakPolicy && (
          <div
            className={`details-card ${
              submission.breakPolicyFollowed === false && !submission.breakPolicyFollowedResolvedOn
                ? 'details-card-error'
                : ''
            }`}
          >
            <HeaderText
              title={t(`Did you follow ${organization.breakPolicyCalifornia ? 'California' : 'company'} break policy?`)}
            >
              <YesNo value={submission.breakPolicyFollowed} />
            </HeaderText>
            {(!submission.breakPolicyFollowed || submission.breakPolicyFollowedReason) && (
              <HeaderText title={t(`Why didn't you have the expected break time?`)} className="notes mt-5">
                {submission.breakPolicyFollowedReason ? submission.breakPolicyFollowedReason : '---'}
              </HeaderText>
            )}

            {dailySignOffResolutionEnabled &&
              canManageTime.current &&
              !submission.breakPolicyFollowed &&
              !submission.breakPolicyFollowedResolvedOn && (
                <div className="button-bar">
                  <Button
                    type={'primary'}
                    onClick={() => {
                      resolvingSubmission.current = submission;
                      resolveBreakPolicyIssueDialogOpenable.open();
                    }}
                  >
                    {t('Resolve')}
                  </Button>
                  <Button type={'secondary'} onClick={handleFixEntriesClick}>
                    {t('Fix Entries')}
                  </Button>
                </div>
              )}

            {dailySignOffResolutionEnabled &&
              canManageTime.current &&
              !submission.breakPolicyFollowed &&
              submission.breakPolicyFollowedResolvedOn && (
                <>
                  <HeaderText title={t('Resolved')} className="mt-5 mb-5">
                    {dateTimeFromUtcISO(submission.breakPolicyFollowedResolvedOn).toFormat('t D')}
                    <br />
                    {submission.breakPolicyFollowedResolvedByMember
                      ? fullName(submission.breakPolicyFollowedResolvedByMember!)
                      : '---'}
                  </HeaderText>
                  <HeaderText title={t('How was the issue resolved?')} className="notes mt-5 mb-5">
                    {isEmpty(submission.breakPolicyFollowedResolvedComment)
                      ? '---'
                      : submission.breakPolicyFollowedResolvedComment}
                  </HeaderText>
                </>
              )}
          </div>
        )}

        {organization.timeAccuracy && (
          <div
            className={`details-card ${
              submission.timeAccurate === false && !submission.timeAccurateResolvedOn ? 'details-card-error' : ''
            }`}
          >
            <HeaderText title={t('Is your time card for today correct?')}>
              <YesNo value={submission.timeAccurate} />
            </HeaderText>
            {!submission.timeAccurate && (
              <HeaderText title={t('Why was your time incorrect?')} className="notes mt-5">
                {submission.timeAccurateReason ? submission.timeAccurateReason : '---'}
              </HeaderText>
            )}
            {dailySignOffResolutionEnabled &&
              canManageTime.current &&
              !submission.timeAccurate &&
              !submission.timeAccurateResolvedOn && (
                <div className="button-bar">
                  <Button
                    type={'primary'}
                    onClick={() => {
                      resolvingSubmission.current = submission;
                      resolveTimeAccuracyIssueDialogOpenable.open();
                    }}
                  >
                    {t('Resolve')}
                  </Button>
                  <Button type={'secondary'} onClick={handleFixEntriesClick}>
                    {t('Fix Entries')}
                  </Button>
                </div>
              )}
            {dailySignOffResolutionEnabled && !submission.timeAccurate && submission.timeAccurateResolvedOn && (
              <>
                <HeaderText title={t('Resolved')} className="mt-5 mb-5">
                  {dateTimeFromUtcISO(submission.timeAccurateResolvedOn).toFormat('t D')}
                  <br />
                  {submission.timeAccurateResolvedByMember ? fullName(submission.timeAccurateResolvedByMember!) : '---'}
                </HeaderText>
                <HeaderText title={t('How was the issue resolved?')} className="notes mt-5 mb-5">
                  {isEmpty(submission.timeAccurateResolvedComment) ? '---' : submission.timeAccurateResolvedComment}
                </HeaderText>
              </>
            )}
          </div>
        )}

        {isCustomSignOffEnabled &&
          [...submission.customAnswers]
            .sort((a, b) => (a.createdOn > b.createdOn ? 1 : a.createdOn < b.createdOn ? -1 : 0))
            .map((answer) => (
              <div
                key={answer.id}
                className={`details-card ${answer.flagged && !answer.resolvedOn ? 'details-card-error' : ''}`}
              >
                <HeaderText title={t(answer.questionTitle)} className="notes mt-5 mb-5">
                  {answer.answer}
                </HeaderText>
                {answer.description && (
                  <HeaderText title={t(answer.questionPrompt)} className="notes mt-5 mb-5">
                    {answer.description}
                  </HeaderText>
                )}
                {dailySignOffResolutionEnabled && canManageTime.current && answer.flagged && !answer.resolvedOn && (
                  <Button
                    type={'primary'}
                    onClick={() => {
                      resolvingSubmission.current = submission;
                      resolvingCustomQuestionAnswerId.current = answer.id;
                      resolveCustomQuestionDialogOpenable.open();
                    }}
                  >
                    {t('Mark As Processed')}
                  </Button>
                )}
                {dailySignOffResolutionEnabled && answer.flagged && answer.resolvedOn && (
                  <>
                    <HeaderText title={t('Processed')} className="mt-5 mb-5">
                      {dateTimeFromUtcISO(answer.resolvedOn).toFormat('t D')}
                      <br />
                      {answer.resolverMember ? fullName(answer.resolverMember!) : '---'}
                    </HeaderText>
                    <HeaderText title={t('Comment')} className="notes mt-5 mb-5">
                      {isEmpty(answer.resolvedComment) ? '---' : answer.resolvedComment}
                    </HeaderText>
                  </>
                )}
              </div>
            ))}
      </>
    );
  }

  function isLatestEntry(type: string) {
    const currentSubmission = resolvingSubmission.current;

    switch (type) {
      case 'timeAccurate': {
        const filteredEntries = data.filter((d) => d.timeAccurate === false && !d.timeAccurateResolvedOn);
        const sortedEntries = _.sortBy(filteredEntries, (d) => d.createdOn);
        const latestEntry = sortedEntries[sortedEntries.length - 1];
        return sortedEntries.length > 1 && currentSubmission === latestEntry;
      }
      case 'breakPolicy': {
        const filteredEntries = data.filter((d) => d.breakPolicyFollowed === false && !d.breakPolicyFollowedResolvedOn);
        const sortedEntries = _.sortBy(filteredEntries, (d) => d.createdOn);
        const latestEntry = sortedEntries[sortedEntries.length - 1];
        return sortedEntries.length > 1 && currentSubmission === latestEntry;
      }
      default:
        break;
    }
  }

  const renderSubmission = (submission: ISafetySignature, { ...form } = {}) => {
    const submittedTime = DateTime.fromISO(submission.createdOnLocal);
    let resolvedStatus = undefined;
    if (
      submission.injured ||
      submission.breakPolicyFollowed === false ||
      submission.timeAccurate === false ||
      submission.customQuestionsFlagged
    ) {
      let resolutionText: string | undefined = undefined;
      let injuryIcon = undefined;
      let timeIcon = undefined;

      const breakOrTimeOrCustomResolved =
        (!(submission.breakPolicyFollowed === false) || !isNil(submission.breakPolicyFollowedResolvedOn)) &&
        (!(submission.timeAccurate === false) || !isNil(submission.timeAccurateResolvedOn)) &&
        (!(submission.customQuestionsFlagged === true) || !isNil(submission.customQuestionsResolvedOn));

      if (breakOrTimeOrCustomResolved && !submission.injured) {
        resolutionText = t('(Resolved) ');
      }

      if (submission.injuredResolvedOn && breakOrTimeOrCustomResolved) {
        resolutionText = t('(Processed) ');
      }

      if (breakOrTimeOrCustomResolved) {
        timeIcon = (
          <span className="accordion-icon">
            <Icon svg={FlagIcon} />
          </span>
        );
      } else if (
        submission.breakPolicyFollowed === false ||
        submission.timeAccurate === false ||
        submission.customQuestionsFlagged
      ) {
        timeIcon = <Icon svg={FlagIcon} />;
      }

      if (submission.injuredResolvedOn) {
        injuryIcon = (
          <span className="accordion-icon">
            <Icon svg={SafetyIcon} />
          </span>
        );
      } else if (submission.injured) {
        injuryIcon = <Icon svg={SafetyIcon} />;
      }

      resolvedStatus = (
        <>
          {resolutionText && <span className="header-resolution-text mr-2">{resolutionText}</span>}
          {injuryIcon}
          {timeIcon}
        </>
      );
    }

    return (
      <FormAccordion
        key={submission.id}
        className="py-4"
        title={getSubmittedTimeString(submittedTime)}
        form={form}
        rightChildren={resolvedStatus}
        openByDefault={data.length === 1 && !needsToSign()}
      >
        <div className="member-details-label">
          {t('Account') + ': ' + (submission.proxyMember ? fullName(submission.proxyMember!) : '---')}
        </div>
        <div className="member-details-label">{t('Report Submitted') + ': ' + submittedTime.toFormat('t D')}</div>
        <div className="member-details-label">
          {submission.deviceType ? t('Device') + ': ' + submission.deviceType : '---'}
        </div>
        <PseudoLinkText
          className="member-details-label"
          label={t('View Entries')}
          onClick={timeEntryDialogOpenable.open}
        />
        <div className="details-container mb-4">
          {renderSubmissionResults(submission)}
          {submission.signatureUrl && (
            <div className="details-card">
              <Label>{t('Employee Signature')}</Label>
              <Image className="signature-image" src={submission.signatureUrl} alt="employee signature" />
            </div>
          )}
        </div>
      </FormAccordion>
    );
  };

  const signOffTimeRange = () => {
    let endTime = currentDate.endOf('day');

    // sign off for the entire day when its a previous day
    // for the current day either use the time from the latest time entry or the current time
    if (DateTime.local() < endTime) {
      endTime = latestTimeEntry.current?.endTime
        ? dateTimeFromISOKeepZone(latestTimeEntry.current!.endTime)
        : DateTime.local();
    }

    return {
      startTime: currentDate.startOf('day'),
      endTime,
    };
  };

  function needsToSign(): boolean {
    return (
      !loading.current &&
      (isEmpty(data) ||
        (!isNil(latestTimeEntry.current) &&
          dateTimeFromISOWithoutZone(last(data)!.endTime) <
            dateTimeFromISOWithoutZone(latestTimeEntry.current.endTime!)))
    );
  }

  return (
    <div className={classes}>
      {renderHeader()}
      {needsToSign() && renderEmptyState()}
      {data.map((d) => renderSubmission(d))}

      <ReportTimeEntriesDialog date={currentDate} memberId={member.id} viewEntriesDialog={timeEntryDialogOpenable} />

      {resolveTimeAccuracyIssueDialogOpenable.isOpen && (
        <DailySignOffResolveDialog
          isOpen={resolveTimeAccuracyIssueDialogOpenable.isOpen}
          onClose={resolveTimeAccuracyIssueDialogOpenable.close}
          onSave={(comment) => onResolvedTimeInaccuracy(comment)}
          isLatestEntry={isLatestEntry('timeAccurate')}
        />
      )}
      {resolveInjuryDialogOpenable.isOpen && (
        <DailySignOffResolveInjuryDialog
          isOpen={resolveInjuryDialogOpenable.isOpen}
          onClose={resolveInjuryDialogOpenable.close}
          onSave={(notes) => onResolvedInjury(notes)}
        />
      )}
      {resolveBreakPolicyIssueDialogOpenable.isOpen && (
        <DailySignOffResolveDialog
          isOpen={resolveBreakPolicyIssueDialogOpenable.isOpen}
          onClose={resolveBreakPolicyIssueDialogOpenable.close}
          onSave={(comment) => onResolvedBreakPolicy(comment)}
          isLatestEntry={isLatestEntry('breakPolicy')}
        />
      )}
      <DailySignOffResolveCustomQuestionDialog
        isOpen={resolveCustomQuestionDialogOpenable.isOpen}
        onClose={resolveCustomQuestionDialogOpenable.close}
        onSave={(comment) => onResolvedCustomQuestion(comment)}
      />
      <DailySignOffFormDialog
        member={member}
        timeRange={signOffTimeRange()}
        fromReport={true}
        isOpen={signOffOpenable.isOpen}
        onClose={signOffOpenable.close}
        onComplete={onCompleteSignOff}
      />
      <ArchivedEmployeeWarningDialog isOpen={archiveDialog.isOpen} onClose={archiveDialog.close} />
      {!isNil(resolvingSubmission.current) && (
        <HeaderDialog
          isOpen={createIncidentDialog.isOpen}
          onClose={createIncidentDialog.close}
          title={t('Incident Report')}
          divider={false}
        >
          <SubmitSafetyIncidentForm
            safetySignature={resolvingSubmission.current}
            handleCreateIncidentForm={handleCreateIncidentForm}
          />
        </HeaderDialog>
      )}
      {incidentRow && (
        <ReviewSafetyIncidentDialog
          isOpen={reviewIncidentDialog.isOpen}
          onClose={() => {
            setIncidentRow(undefined);
            reviewIncidentDialog.close();
          }}
          incident={incidentRow}
          onSubmitReview={reviewIncidentDialog.close}
          isReview={false}
        />
      )}
    </div>
  );
};

export default DailySignOffReportDetailsForm;
