import { ClassName } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import Panel from 'components/layout/Panel/Panel';
import useIsInIframe from 'hooks/store/useIsInIframe';
import { IChildrenProps } from 'types/ChildrenProps';
import './DashboardContainer.scss';

export interface IDashboardContainerProps extends IChildrenProps {
  className?: ClassName;
}

export default function DashboardContainer({ children, className }: IDashboardContainerProps) {
  const isInIframe = useIsInIframe();
  const classes = classNames('dashboard-container', { 'is-in-iframe': isInIframe }, className);
  return <Panel className={classes}>{children}</Panel>;
}
