import { List } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { Nullable } from 'types/util/Nullable';
import PickerListPicker, { IPickerListPickerProps } from '../PickerListPicker/PickerListPicker';
import PickerList, { IPickerListProps } from '../PickerList/PickerList';
import PickerListSearchHeader from '../PickerListSearchHeader/PickerListSearchHeader';
import IIdable from 'types/Idable';

export interface IDropDownPickerProps<T extends IIdable<K>, K extends string>
  extends Omit<IPickerListPickerProps<T, K>, 'onSelect' | 'autofocus' | 'children' | 'minWidth'>,
    Omit<IPickerListProps<T, K>, 'children' | 'autofocus' | 'showSpinnerWhenLoading' | 'closeMenu'> {
  value: K | null;
  dataError: boolean;
  clearable?: boolean;
  className?: ClassName;
  disabled?: boolean;
  searchValue?: string;
  minWidth?: string;
  onSelect: (value: Nullable<T>) => void;
  onSearch?: (value: string, e: React.ChangeEvent) => void;
}

export default function DropDownPicker<T extends IIdable<K>, K extends string>({
  value,
  data,
  searchValue,
  dataError,
  onSelect,
  onSearch,
  header,
  renderRow,
  setScrollerRef,
  isLoading,
  loadedAll,
  className,
  ...pickerProps
}: IDropDownPickerProps<T, K>) {
  const isSearchable = onSearch && searchValue !== undefined;

  const classes = classNames('drop-down-picker', className);

  return (
    <PickerListPicker {...pickerProps} className={classes} value={value} onSelect={onSelect}>
      {(closeMenu) => (
        <PickerList
          value={value}
          onSelect={onSelect}
          closeMenu={closeMenu}
          isLoading={isLoading}
          data={data}
          error={dataError}
          loadedAll={loadedAll}
          renderRow={renderRow}
          autofocus={!isSearchable}
          setScrollerRef={setScrollerRef}
          header={(onKeyDown, setFocusIndex) => {
            return isSearchable ? (
              <PickerListSearchHeader
                className="p-3"
                searchValue={searchValue}
                autofocus={true}
                onSearch={onSearch}
                onKeyDown={onKeyDown}
                setFocusIndex={setFocusIndex}
              />
            ) : (
              header?.(onKeyDown, setFocusIndex)
            );
          }}
        >
          {(wrappedRowRender) => <List>{data.map(wrappedRowRender)}</List>}
        </PickerList>
      )}
    </PickerListPicker>
  );
}
