import {
  LaborMetricsInterval,
  MemberHasTimeOff,
  MemberPermissions,
  MemberSort,
  OperationType,
  QueryMembersForTimeMetericsWithMoreQuery,
  QueryMembersForTimeMetericsWithMoreQueryVariables,
  SortOrder,
} from '__generated__/graphql';
import { MEMBERS_WITH_ALL_JOINED_DATA_QUERY } from 'apollo/queries/member-queries';
import { ArchivedStatus } from 'components/domain/archived/ArchivedPicker/ArchivedPicker';
import { hasTimeOffColumn } from 'containers/timesheets/hooks/useTimesheetsColumns';
import { useOrganization } from 'hooks';
import useApolloMemberNameSort from 'hooks/models/member/useApolloMemberNameSort';
import _, { isNil, some } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IReduxState } from 'store/reducers';
import { IMember } from 'types';
import { IVisibleTableColumn } from 'types/TableColumn';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { getApolloArchivedTimestampComparison } from 'utils/archivedUtils';
import { dateTimeFromISOWithoutZone, getDateTimesBetween, numberOfDaysBetween } from 'utils/dateUtils';
import { getRegularMetricTimeTotals } from 'utils/jitMetricUtils';
import useReactQueryBaseKey from 'hooks/react-query/useReactQueryBaseKey/useReactQueryBaseKey';
import useGraphQLClient from 'hooks/graphql/useGraphQLClient/useGraphQLClient';
import { useReactQueryLazyLoading } from 'hooks/react-query/useReactQueryLazyLoading/useReactQueryLazyLoading';
import { TimeCardReportMemberData } from '../types/types';

export interface PayPeriodSignaturesLoaded {
  memberId: string;
  signaturesLoaded: boolean | null;
}

interface IUseTimeCardReportDataProps {
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  memberIds?: string[] | null;
  memberGroupId?: string | null;
  positionId?: string | null;
  archivedStatus: ArchivedStatus;
  permission: MemberPermissions.TimeEvents | MemberPermissions.ManageTimeEntries;
  scroller?: HTMLElement | null;
}

export default function useTimeCardReportData({
  memberIds,
  memberGroupId,
  positionId,
  timeRange,
  archivedStatus,
  permission,
  timeRangeType,
  scroller,
}: IUseTimeCardReportDataProps) {
  const graphqlClient = useGraphQLClient();

  const columnSettings = useSelector<IReduxState, IVisibleTableColumn[]>(
    (state) => state.timesheet.summaryAndTimeCardTableColumns
  );

  const archivedOn = useMemo(() => getApolloArchivedTimestampComparison(archivedStatus), [archivedStatus]);
  const getSortWithNameFormat = useApolloMemberNameSort();

  const signaturesLoaded = useRef<PayPeriodSignaturesLoaded[]>([]);
  const organization = useOrganization();
  const timeRangeIsPayPeriod = timeRangeType === TimeRangeType.PAY_PERIOD;
  const showSignaturesFooter =
    timeRangeIsPayPeriod && organization.signatureDate && timeRange.endTime < DateTime.local();

  const baseQueryKey = useReactQueryBaseKey();
  const queryKey = useMemo(
    () => [
      baseQueryKey,
      'time-card-report',
      timeRange.startTime.toISO(),
      timeRange.endTime.toISO(),
      memberIds,
      memberGroupId,
      positionId,
      archivedOn,
      getSortWithNameFormat,
      columnSettings,
    ],
    [
      archivedOn,
      baseQueryKey,
      columnSettings,
      getSortWithNameFormat,
      memberGroupId,
      memberIds,
      positionId,
      timeRange.endTime,
      timeRange.startTime,
    ]
  );

  const populateMemberData = useCallback(
    <T extends IMember>(member: T, days: DateTime[]): TimeCardReportMemberData => {
      if (!signaturesLoaded.current.map((item) => item.memberId).includes(member.id)) {
        signaturesLoaded.current.push({ memberId: member.id, signaturesLoaded: null });
      }
      const signOffs = member.safetySignatures ?? [];
      const metricsDictionary = _.keyBy(member.memberLaborMetrics, (daySecond) => daySecond.start);

      const dataRows = days.map((day) => {
        const dayStart = day.startOf('day');
        const dayEnd = day.endOf('day');

        const filteredSignatures = signOffs.filter((signature) => {
          return (
            dateTimeFromISOWithoutZone(signature.startTime) <= dayEnd &&
            dateTimeFromISOWithoutZone(signature.endTime) >= dayStart
          );
        });

        const memberDayMetrics = getRegularMetricTimeTotals(metricsDictionary[dayStart.toISODate()]);
        const orderedSignatures = _.orderBy(filteredSignatures, (item) => item.endTime, ['desc']);

        const hasTimeEntryTime = memberDayMetrics.totalSeconds > 0;

        let regularSeconds = memberDayMetrics.regularSeconds ?? 0;
        if (!hasTimeOffColumn(columnSettings) && memberDayMetrics) {
          const updatedRegularTotal = memberDayMetrics.regularSeconds + memberDayMetrics.paidTimeOffSeconds;
          regularSeconds = updatedRegularTotal > 0 ? updatedRegularTotal : 0;
        }

        return {
          date: day,
          timeAccurate: hasTimeEntryTime
            ? orderedSignatures.length > 0
              ? orderedSignatures[0].timeAccurate
              : null
            : null,
          injured: hasTimeEntryTime
            ? orderedSignatures.length > 0
              ? orderedSignatures.filter((signature: any) => signature.injured).length > 0
              : null
            : null,
          breakCompliance: hasTimeEntryTime
            ? orderedSignatures.length > 0
              ? orderedSignatures[0].breakPolicyFollowed
              : null
            : null,
          regularHours: regularSeconds,
          overtimeHours: memberDayMetrics.overtimeSeconds,
          doubleTimeHours: memberDayMetrics.doubleTimeSeconds,
          paidTimeOff: memberDayMetrics.paidTimeOffSeconds,
          break: 0, // we're not implementing this for now
          totalHours: memberDayMetrics.totalSeconds,
        };
      });

      return {
        ...member,
        cursor: member.cursor!,
        totalSeconds: _.sumBy(dataRows, (row) => row.totalHours ?? 0),
        rows: dataRows,
      };
    },
    [columnSettings]
  );

  const getMemberVariables = useCallback(
    (
      requireHasTime: boolean,
      first: number,
      after?: string | null
    ): QueryMembersForTimeMetericsWithMoreQueryVariables => {
      return {
        first,
        after: after ?? undefined,
        filter: {
          id: memberIds ? { contains: memberIds } : undefined,
          permissions:
            permission === MemberPermissions.TimeEvents
              ? {
                  permissions: [permission],
                  operationType: OperationType.And,
                  includeSelf: true,
                }
              : {
                  permissions: [permission],
                  operationType: OperationType.And,
                },
          memberGroupId: memberGroupId ? { equal: memberGroupId } : undefined,
          archivedOn,
          positionId: positionId ? { equal: positionId } : undefined,
          hasTime: requireHasTime
            ? {
                startTime: timeRange.startTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
                endTime: timeRange.endTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
                includeOpenEntry: false,
                hasTimeOff: MemberHasTimeOff.All,
              }
            : undefined,
        },
        metricsInterval: LaborMetricsInterval.Day,
        metricsStartDate: timeRange.startTime.toUTC().toISODate(),
        metricsEndDate: timeRange.endTime.toUTC().toISODate(),
        memberTimeDocumentFilter: {
          startTime: { lessThanOrEqual: timeRange.endTime.toISO() },
          endTime: { greaterThanOrEqual: timeRange.startTime.toISO() },
          deletedOn: { isNull: true },
        },
        memberTimeDocumentSort: [{ submittedOn: SortOrder.Desc }],
        safetySignaturesFilter: {
          startTime: { greaterThanOrEqual: timeRange.startTime.toISO() },
          endTime: { lessThanOrEqual: timeRange.endTime.toISO() },
          deletedOn: { isNull: true },
          member: {
            permissions: {
              permissions: [MemberPermissions.ManageTimeEntries],
              operationType: OperationType.And,
            },
          },
        },
        safetySignaturesSort: [{ startTime: SortOrder.Asc }, { createdOn: SortOrder.Asc }],
        sort: getSortWithNameFormat('asc') as MemberSort,
      };
    },
    [
      archivedOn,
      getSortWithNameFormat,
      memberGroupId,
      memberIds,
      permission,
      positionId,
      timeRange.endTime,
      timeRange.startTime,
    ]
  );

  const processMembersResult = useCallback(
    (membersResult: QueryMembersForTimeMetericsWithMoreQuery['members'], timeRange: ITimeRange<DateTime>) => {
      const days = getDateTimesBetween(timeRange.startTime, timeRange.endTime);
      const members = (membersResult ?? []) as unknown as IMember[];
      const mappedMembers = members.map((member) => populateMemberData(member, days));
      return mappedMembers;
    },
    [populateMemberData]
  );

  const getMoreMembers = useCallback(
    async (cursor: string | null, first: number): Promise<TimeCardReportMemberData[]> => {
      const membersResult = await graphqlClient.request<
        QueryMembersForTimeMetericsWithMoreQuery,
        QueryMembersForTimeMetericsWithMoreQueryVariables
      >({
        document: MEMBERS_WITH_ALL_JOINED_DATA_QUERY,
        variables: getMemberVariables(isNil(memberIds), first, cursor),
      });

      return processMembersResult(membersResult.members, timeRange);
    },
    [getMemberVariables, graphqlClient, memberIds, processMembersResult, timeRange]
  );

  function clearData() {
    clearReactQueryData();
    signaturesLoaded.current = [];
  }

  function areAllSignaturesLoaded(): boolean {
    if (showSignaturesFooter) {
      return !some(signaturesLoaded.current, (item) => item.signaturesLoaded === false);
    }
    return true;
  }

  const onSignaturesLoaded = useCallback((memberId: string, isLoaded: boolean) => {
    const index = signaturesLoaded.current.findIndex((item) => item.memberId === memberId);
    if (index > -1) {
      signaturesLoaded.current[index].signaturesLoaded = isLoaded;
    } else {
      signaturesLoaded.current.push({ memberId, signaturesLoaded: isLoaded });
    }
  }, []);

  const updateSignaturesLoaded = useCallback((memberIds: string[]) => {
    memberIds.forEach((memberId) => {
      const index = signaturesLoaded.current.findIndex((item) => item.memberId === memberId);
      if (index > -1) {
        // If the member is already in the list, do nothing
      } else {
        signaturesLoaded.current.push({ memberId, signaturesLoaded: null });
      }
    });
  }, []);

  const pageSize = numberOfDaysBetween(timeRange.startTime, timeRange.endTime) > 20 ? 1 : 3;
  const {
    data,
    loadedAll,
    isLoading,
    isError,
    refetch: clearReactQueryData,
    loadAll,
  } = useReactQueryLazyLoading(scroller ?? null, queryKey, getMoreMembers, pageSize);

  const refreshMembersData = useCallback(
    async (ids: string[]) => {
      clearReactQueryData();
      updateSignaturesLoaded(ids);
    },
    [clearReactQueryData, updateSignaturesLoaded]
  );

  const forceLoadAll = useCallback(async (): Promise<TimeCardReportMemberData[]> => {
    try {
      const all = await loadAll();
      updateSignaturesLoaded(all.map((item) => item.id));
      return all;
    } catch (error) {
      return [];
    }
  }, [loadAll, updateSignaturesLoaded]);

  return useMemo(
    () => ({
      data,
      loadedAll,
      getMoreMembers,
      refreshMembersData,
      forceLoadAll,
      clearData,
      signaturesLoaded: signaturesLoaded.current,
      onSignaturesLoaded,
      areAllSignaturesLoaded,
      isLoading,
      isError,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadedAll, memberIds, memberGroupId, signaturesLoaded.current, data, isLoading, isError]
  );
}
