import { ArchivedStatus } from 'components/domain/archived/ArchivedPicker/ArchivedPicker';
import { useApolloPaging } from 'hooks';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import MemberPermission from 'types/enum/MemberPermission';
import ITimeRange from 'types/TimeRange';
import { getDateTimesBetween, getMonthsBetween } from 'utils/dateUtils';
import { metricDataToTimeGraphData } from 'utils/timeGraphDataUtils';
import {
  memberCostCodeMetricQueryProvider,
  memberEquipmentMetricQueryProvider,
  memberLaborMetricQueryProvider,
  memberProjectMetricQueryProvider,
} from './metrics/MemberMetricQueryProviders';
import { useMetricAggregates } from './useMetricAggregates';
import { useQuery } from '@tanstack/react-query';
import useReactQueryBaseKey from 'hooks/react-query/useReactQueryBaseKey/useReactQueryBaseKey';
import { useClearReactQueryKey } from 'hooks/react-query/useClearReactQueryKey/useClearReactQueryKey';
import { emptyList } from 'types/util/Empty';

export interface ITimeGraphData {
  day: number;
  totalHours: number;
  regularHours: number;
  overtimeHours: number;
  doubleTimeHours: number;
}

export default function useMemberGraphAggregates(
  timeRange: ITimeRange<DateTime>,
  memberIds?: string[] | null,
  projectIds?: string[] | null,
  costCodeIds?: string[] | null,
  equipmentIds?: string[] | null,
  memberGroupId?: string | null,
  monthLimitToSwitch: number = 2,
  archivedStatus: ArchivedStatus = 'unarchived'
) {
  const queryProvider = useCallback(() => {
    if (projectIds) {
      return memberProjectMetricQueryProvider(
        getAll,
        MemberPermission.MANAGE_TIME_ENTRIES,
        memberIds ?? undefined,
        projectIds ?? undefined,
        memberGroupId ?? undefined,
        archivedStatus,
        true,
        true,
        500
      );
    }

    if (costCodeIds) {
      return memberCostCodeMetricQueryProvider(
        getAll,
        MemberPermission.MANAGE_TIME_ENTRIES,
        memberIds ?? undefined,
        costCodeIds ?? undefined,
        memberGroupId ?? undefined,
        archivedStatus,
        true,
        true,
        500
      );
    }

    if (equipmentIds) {
      return memberEquipmentMetricQueryProvider(
        getAll,
        MemberPermission.MANAGE_TIME_ENTRIES,
        memberIds ?? undefined,
        equipmentIds ?? undefined,
        memberGroupId ?? undefined,
        archivedStatus,
        true,
        true,
        500
      );
    }

    return memberLaborMetricQueryProvider(
      getAll,
      MemberPermission.MANAGE_TIME_ENTRIES,
      memberIds ?? undefined,
      memberGroupId ?? undefined,
      archivedStatus,
      true,
      500
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberIds, projectIds, costCodeIds, equipmentIds, memberGroupId, archivedStatus]);

  const baseQueryKey = useReactQueryBaseKey();
  const queryKey = useMemo(
    () => [baseQueryKey, timeRange, memberIds, projectIds, costCodeIds, equipmentIds, memberGroupId, archivedStatus],
    [archivedStatus, baseQueryKey, timeRange, costCodeIds, equipmentIds, memberGroupId, memberIds, projectIds]
  );

  const { getAll } = useApolloPaging();
  const getData = useMetricAggregates(timeRange, queryProvider(), monthLimitToSwitch, false);

  const { data, error: metricAggregateError } = useQuery({
    queryKey,
    queryFn: async () => {
      const jitMetrics = await getData();
      const monthRanges = getMonthsBetween(timeRange.startTime, timeRange.endTime);

      const ranges =
        monthRanges.length > monthLimitToSwitch
          ? monthRanges
          : getDateTimesBetween(timeRange.startTime, timeRange.endTime);

      return metricDataToTimeGraphData(jitMetrics, ranges);
    },
  });

  const refetchMetricAggregates = useClearReactQueryKey(queryKey);

  return { data: data ?? emptyList, error: metricAggregateError, refetchData: refetchMetricAggregates };
}
