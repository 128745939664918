import gql from 'graphql-tag';

export const POSITION_FRAGMENT = gql`
  fragment PositionDetails on Position {
    canEditPosition
    canManageProjectCostCodeQuantity
    canRequestTimeOff
    clockInRestriction
    clockInRestrictionStartTime
    createdOn
    dailyProjectPrompts
    dailyProjectPromptsUseSubprojects
    deletedOn
    equipmentSelectionRequirement
    id
    jobCostingPrompt
    level
    manageBudgets
    manageBulletinPosts
    manageCompanySettings
    manageCostCodes
    manageEmployeeGroups
    manageEmployees
    manageEquipment
    manageGpsApproval
    manageMediaCollection
    managePerDiem
    manageProjectGroups
    manageProjects
    manageSchedule
    manageTimeEntries
    manageTimeOff
    memberLock
    organizationId
    photoVerificationSettings
    requestSignatures
    scopeByGroup
    submittedOn
    timeEvents
    title
    updatedOn
    viewAllActivityReport
    viewBudgets
    viewEquipmentDetails
    viewEquipmentStatus
    viewMediaCollection
    viewMemberActivityReport
    viewMemberExpenses
    viewProjectExpenses
    viewSchedule
    createIncident
    manageIncident
    performInspections
    manageInspections
    performTraining
    manageTraining
    manageObservations
    reportObservations
    createSafetyAnalysisReports
    manageSafetyAnalysisReports
    useKiosk
  }
`;

export const POSITION_QUERY = gql`
  query QueryFlatPositions($first: Int, $after: String, $filter: PositionFilter) {
    positions(first: $first, after: $after, filter: $filter, sort: [{ level: asc }]) {
      cursor
      __typename
      ...PositionDetails
    }
  }
  ${POSITION_FRAGMENT}
`;

export const POSITION_TITLE_QUERY = gql`
  query queryPositionWithTitle($first: Int, $after: String, $filter: PositionFilter) {
    positions(first: $first, after: $after, filter: $filter, sort: [{ level: asc }]) {
      cursor
      id
      title
    }
  }
`;
