import { ClassName } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { HeaderDialog } from 'components';
import { IHeaderDialogProps } from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import { t } from 'i18next';
import TimesheetPrintOptionsForm from '../TimesheetPrintOptionsForm/TimesheetPrintOptionsForm';
import { ITimesheetPrintOptionsData } from '../TimesheetPrintOptionsForm/TimesheetPrintOptions';

export interface ITimesheetsPrintDialogProps extends Pick<IHeaderDialogProps, 'isOpen' | 'onClose'> {
  onPrint: (options: ITimesheetPrintOptionsData) => void;
  className?: ClassName;
}

export default function TimesheetsPrintDialog({ isOpen, onPrint, onClose, className }: ITimesheetsPrintDialogProps) {
  const classes = classNames('timesheets-print-dialog', className);
  return (
    <HeaderDialog isOpen={isOpen} title={t('Print Options')} onClose={onClose} divider={false} className={classes}>
      <div className="p-8 mb-6">
        <TimesheetPrintOptionsForm onPrint={onPrint} />
      </div>
    </HeaderDialog>
  );
}
