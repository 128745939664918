import classNames from 'classnames';
import * as React from 'react';
import { Component, ReactNode } from 'react';
import { ClassName } from 'types/ClassName';
import LazyLoadPickerList from '../LazyLoadPickerList/LazyLoadPickerList';
import PickerListSearchHeader from '../../PickerListSearchHeader/PickerListSearchHeader';

export interface ILazyLoadSearchablePickerListProps<T extends { id: string; cursor?: string }> {
  searchValue: string;
  onSearch: (value: string, e: React.ChangeEvent) => void;

  // LazyLoadPickerList props
  data: T[];
  value: string | null;
  loadedAll: boolean;
  getData: (after?: string) => Promise<T[]>;
  didLoad: (items: T[], error: boolean, loadedAll: boolean) => void;
  renderRow: (row: T, index: number, e?: React.KeyboardEvent) => ReactNode;
  closeMenu: (shouldFocus: boolean) => void;
  onSelect: (row: T | null, index?: number, e?: React.MouseEvent) => void;
  renderSize: number; // has default
  offset: number; // has default
  loadingTemplate?: ReactNode;
  className?: ClassName;
  error?: boolean;
  onRightKeyDown?: (row: T, e: React.KeyboardEvent) => void;
  onLeftKeyDown?: (row: T, e: React.KeyboardEvent) => void;
  willLoad?: () => void;
}

export class LazyLoadSearchablePickerList<T extends { id: string; cursor?: string }> extends Component<
  ILazyLoadSearchablePickerListProps<T>
> {
  public static defaultProps: any;

  public renderHeader = (onKeyDown: (event: React.KeyboardEvent) => void, setFocusIndex: (n: number) => void) => {
    return (
      <PickerListSearchHeader
        searchValue={this.props.searchValue}
        onSearch={this.props.onSearch}
        onKeyDown={onKeyDown}
        setFocusIndex={setFocusIndex}
        className="p-3"
        autofocus={true}
      />
    );
  };

  public render() {
    const { className, ...rest } = this.props;

    const classes = classNames({}, className);

    return <LazyLoadPickerList className={classes} header={this.renderHeader} {...rest} autoFocus={false} />;
  }
}

export default LazyLoadSearchablePickerList;
