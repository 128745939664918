import ICursorable from 'types/Cursorable';
import { Optional } from 'types/util/Optional';
import { getLastCursor } from './apolloUtils';
import { isoTimeStampUtc } from './dateUtils';
import { AnyObject } from 'types/util/Object';

// eslint-disable-next-line @typescript-eslint/ban-types
export async function pageGraphQl<T extends ICursorable, U>(
  fn: (after: string | null, first: number) => Promise<U>,
  resolver: (value: U) => Optional<T[]>,
  first: number = 50,
  firstAfter: string | null = null
) {
  const values: T[] = [];
  let after: string | null = firstAfter ?? null;
  let previousCursor;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const page = await fn(after, first);
    const pageData = resolver(page);
    if (pageData && pageData.length) {
      values.push(...pageData);
    }

    if (graphqlPagingLoopShouldBreak(pageData, first)) {
      break;
    }

    const cursor = parseCursor(pageData, previousCursor);

    previousCursor = cursor;
    after = cursor;
  }

  return values;
}

export function parseCursor(pageData: ICursorable[], previousCursor: Optional<string>) {
  const cursor = pageData ? getLastCursor(pageData) : null;

  if (!cursor) {
    throw Error('No cursor in fields of query.');
  } else if (previousCursor && cursor === previousCursor) {
    throw Error(
      "It appears you have requested the same page twice while paging. Make sure you've included after and first parameters in your query."
    );
  }

  return cursor;
}

export function graphqlPagingLoopShouldBreak(
  pageData: Optional<ICursorable[]>,
  pageSize: number
): pageData is undefined | null {
  if (!pageData) {
    return true;
  }

  return pageData.length < pageSize;
}

export function withCreatedOn<T extends AnyObject>(obj: T): T & { createdOn: string } {
  return { ...obj, createdOn: isoTimeStampUtc() };
}
