import { ClassName, Menu, Position, Size } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import IconButton from 'components/foundation/buttons/IconButton/IconButton';
import * as React from 'react';
import { Component, ReactNode } from 'react';
import { CloseIcon } from '../../../../assets/icons';
import './Picker.scss';
import PickerDropDownIcon from './PickerDropDownIcon/PickerDropDownIcon';

export interface IPickerProps {
  'value': string | null;
  'onSelect': (row: null) => void;
  'valueTemplate': JSX.Element;
  'contentTemplate': (closeMenu: () => void) => JSX.Element;
  'dropDownButtonRender': ReactNode;
  'closeButtonRender': (handleClear: (event: React.KeyboardEvent | React.MouseEvent) => void) => ReactNode;
  'minWidth': string; // has default
  'placeholder': string; // has default
  'position'?: Position;
  'className'?: ClassName;
  'data-testid'?: string;
  'error'?: boolean;
  'onClear'?: () => void;
  'onOpen'?: () => void;
  'onClose'?: () => void;
  'onChange'?: (value: string | null) => void;
  'forwardRef'?: (el: HTMLElement) => void;
  'disabled'?: boolean;
}

interface IState {
  minWidth: string;
}

export class Picker<T> extends Component<IPickerProps, IState> {
  public static defaultProps: any;
  public trigger?: HTMLElement; // ref
  public state: IState = {
    minWidth: this.props.minWidth,
  };

  public componentDidUpdate(prevProps: IPickerProps) {
    const { value, onChange } = this.props;

    if (prevProps.value !== value) {
      if (onChange) {
        onChange(value);
      }
    }
  }

  // Clear/reset the picker
  public handleClear = (e: React.KeyboardEvent | React.MouseEvent) => {
    const { onSelect, onClear } = this.props;

    e.stopPropagation();
    onSelect(null);

    if (onClear) {
      onClear();
    }
  };

  // Handle the opening of the picker (dropdown appears)
  public handleOnOpen = () => {
    const { onOpen, disabled } = this.props;

    if (onOpen && !disabled) {
      onOpen();
    }

    if (this.trigger) {
      const offsetWidth = this.trigger.offsetWidth;

      // Try to match the width of the dropdown to the width of the trigger, unless the trigger is too narrow.
      if (offsetWidth > Number.parseFloat(this.state.minWidth)) {
        this.setState({ minWidth: offsetWidth + 'px' });
      }
    }
  };

  // Handle keyboard events when the picker is focused.
  public handleKeyDown = (e: React.KeyboardEvent) => {
    if (this.trigger) {
      switch (e.keyCode) {
        case 40: // down
          this.trigger.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              view: window,
            })
          );
          break;
        case 8: // delete
          this.handleClear(e);
          break;
        case 27: // esc
          this.handleClear(e);
          break;
        default:
          return null;
      }
    }
  };

  public setRefs = (ref: (el: HTMLElement) => void, el: HTMLElement) => {
    this.trigger = el;
    ref(el);

    if (this.props.forwardRef) {
      this.props.forwardRef(el);
    }
  };

  public renderTrigger = (ref: (el: HTMLElement) => void) => {
    const {
      value,
      placeholder,
      valueTemplate,
      error,
      disabled,
      dropDownButtonRender,
      closeButtonRender,
      className,
      'data-testid': testId,
    } = this.props;

    const classes = classNames(
      'picker',
      {
        'picker-error': error,
        'picker-disabled': disabled,
      },
      className
    );

    const setRefs = (el: HTMLDivElement) => {
      this.setRefs(ref, el);
    };

    return (
      <div data-testid={testId} ref={setRefs} className={classes} tabIndex={0} onKeyDown={this.handleKeyDown}>
        {value ? (
          <div className="picker-title ellipsis">{valueTemplate}</div>
        ) : (
          <div className="picker-title ellipsis fc-3">{placeholder}</div>
        )}
        {value ? (!disabled ? closeButtonRender(this.handleClear) : null) : dropDownButtonRender}
      </div>
    );
  };

  public render() {
    const { position, contentTemplate, onClose, disabled } = this.props;

    return (
      <Menu
        onOpen={this.handleOnOpen}
        onClose={onClose}
        disabled={disabled}
        className="picker-menu"
        position={position}
        width={this.state.minWidth}
        trigger={this.renderTrigger}
        content={contentTemplate}
      />
    );
  }
}

Picker.defaultProps = {
  minWidth: '100px',
  placeholder: '',
  dropDownButtonRender: <PickerDropDownIcon />,
  closeButtonRender: (handleClear: () => void) => (
    <span className="drop-icon">
      <IconButton size={Size.SMALL} buttonSize={Size.SMALL} tabIndex={-1} onClick={handleClear} svg={CloseIcon} />
    </span>
  ),
};

export default Picker;
