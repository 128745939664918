import { List } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { ReactNode } from 'react';
import * as React from 'react';
import { LazyLoader } from '../../../..';
import { isEmpty } from 'lodash';
import PickerList from '../../PickerList/PickerList';

interface IProps<T extends { id: string; cursor?: string }> {
  data: T[];
  value: string | null; // UUID
  loadedAll: boolean; // The lazy loader will continue to attempt network calls until loadedAll is set to false
  getData: (after?: string) => Promise<T[]>;
  didLoad: (items: T[], error: boolean, loadedAll: boolean) => void; // Needed to update the data and loadedAll props
  renderRow: (row: T, index: number) => ReactNode;
  closeMenu: (shouldFocus: boolean) => void;
  onSelect: (row: T | null, index?: number, e?: React.MouseEvent) => void;
  renderSize: number; // has default
  offset: number; // has default
  loadingTemplate?: ReactNode;
  className?: ClassName;
  error?: boolean;
  onRightKeyDown?: (row: T, e: React.KeyboardEvent) => void;
  onLeftKeyDown?: (row: T, e: React.KeyboardEvent) => void;
  willLoad?: () => void;
  header?: (
    onKeyDown: (event: React.KeyboardEvent) => void,
    setFocusIndex: (n: number) => void
  ) => JSX.Element | JSX.Element[] | HTMLElement | ReactNode;
  defaultFocusIndex?: number;
  autofocus?: boolean;
}

export function LazyLoadPickerList<T extends { id: string; cursor?: string }>({
  error,
  data,
  loadedAll,
  renderSize,
  getData,
  didLoad,
  willLoad,
  loadingTemplate,
  offset,
  className,
  ...pickerListProps
}: IProps<T>) {
  const scroller = React.useRef<HTMLElement>();
  const renderTemplate = (rows: ReactNode) => <List>{rows}</List>;

  const classes = classNames('picker-sheet', className);

  return (
    <PickerList
      data={data}
      isLoading={!loadedAll}
      loadedAll={loadedAll}
      className={classes}
      error={error}
      setScrollerRef={(elt) => (scroller.current = elt)}
      {...pickerListProps}
    >
      {(wrappedRowRender) => (
        <LazyLoader
          data={data}
          loadedAll={loadedAll}
          getData={getData}
          didLoad={didLoad}
          offset={offset}
          renderSize={renderSize}
          scroller={scroller.current}
          willLoad={willLoad}
          loadingTemplate={loadingTemplate}
          className={classNames({ 'py-3': !isEmpty(data) })}
          renderRow={wrappedRowRender}
          template={renderTemplate}
          showLoaderWhenLoading={false}
        />
      )}
    </PickerList>
  );
}

LazyLoadPickerList.defaultProps = {
  offset: 150,
  renderSize: 10,
  autoFocus: true,
};

export default LazyLoadPickerList;
